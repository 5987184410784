/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useHeaderData } from './getHeader';

export const useStoreDetailsData = () => {
  return useHeaderData({ select: (headerData) => headerData.storeDetails });
};
