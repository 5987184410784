/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import * as React from 'react';

type XMPreviewState = {
  workspaceId: string | undefined;
  previewDate: string | undefined;
};

export interface XMPreviewStateAction {
  type: 'XM_PREVIEW_SET';
  payload: XMPreviewState;
}

export const defaultState: XMPreviewState = {
  workspaceId: undefined,
  previewDate: undefined,
};

type Dispatch = (action: XMPreviewStateAction) => void;

const XMPreviewStateContext = React.createContext<
  (XMPreviewState & { dispatch: Dispatch }) | undefined
>(undefined);

type XMPreviewStateProviderProps = {
  initialState?: XMPreviewState | undefined;
  children: React.ReactNode;
};

function reducer(state: XMPreviewState, action: XMPreviewStateAction) {
  if (action.type === 'XM_PREVIEW_SET') {
    return {
      ...state,
      workspaceId: action.payload.workspaceId,
      previewDate: action.payload.previewDate,
    };
  }
  throw new Error(`Unhandled action type: ${action.type}`);
}

export function XMPreviewStateProvider({ initialState, children }: XMPreviewStateProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState || defaultState);
  const value = React.useMemo(() => {
    return { previewDate: state.previewDate, workspaceId: state.workspaceId, dispatch };
  }, [state, dispatch]);

  return <XMPreviewStateContext.Provider value={value}>{children}</XMPreviewStateContext.Provider>;
}

export function useXMPreviewState() {
  const context = React.useContext(XMPreviewStateContext);
  if (context === undefined) {
    throw new Error('useXMPreviewState must be used within a XMPreviewStateProvider');
  }
  return context;
}

export const getXMPreviewState = (
  workspaceId: string | undefined,
  previewDate: string | undefined
) => {
  if (showXMPreviewDate()) {
    return {
      workspaceId: workspaceId,
      previewDate: previewDate,
    };
  }

  return {
    workspaceId: undefined,
    previewDate: undefined,
  };
};
