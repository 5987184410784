/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { combineReducers } from 'redux';
import details from './details';
import addressDetails from './addressDetails';

const orderReducers = {
  details,
  addressDetails,
};

export default combineReducers(orderReducers);
