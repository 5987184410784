/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { deleteCookie } from '@/utils/cookie';
import { localStorage } from '@/utils/localStorage';
import { clickTrack } from '@/utils/analytics/clickTrack';
import type { CustomLinkName } from '@/types/analytics';

export type TrackSuccessfulLoginProps = {
  eventType?: string;
  customLinkName?: CustomLinkName;
  loginMethod?: 'Email' | 'Username';
  pageName?: string;
  pageType?: string;
  pageURL?: string;
  siteSection?: string;
  loginInteractionMethod?: string | boolean | undefined | null;
};

export const trackSuccessfulLogin = (analyticsData: TrackSuccessfulLoginProps) => {
  const isCreateAccount = localStorage.getItem('signInModalSelectedTab') === 'createAccount';
  let linkName = 'createAccountLogin';

  if (isCreateAccount) {
    const rememberMeChecked = localStorage.getItem('rememberMeChecked');
    /* Evaluating that rememberMeChecked is a string  can be used as an equivalent of
      feature flag REMEMBER_ME_CREATE_ACCOUNT_ENABLED because variable only
      exists as a string when feature is enabled, else will not be in the storage and will be undefined
    */
    const rememberMeCreateAccountEnabled = typeof rememberMeChecked === 'string';
    if (rememberMeCreateAccountEnabled) {
      linkName = linkName.concat(
        rememberMeChecked === 'true' ? ' | persistentLoginTrue' : ' | persistentLoginFalse'
      );
    }
  }

  clickTrack({
    eventType: 'successfulLogin',
    ...(isCreateAccount && { createAccount: linkName }),
    ...(isCreateAccount && { customLinkName: linkName }),

    ...analyticsData,
    pageURL: analyticsData.pageURL ?? location.href,
  });
  deleteCookie('loginInteractionMethod');
};
