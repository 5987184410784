/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { InteractionLocation } from '@/types/analytics';
import { localStorage } from '@/utils/localStorage';

const interactionLocationsKey = 'interactionLocation';

const isPageTypeInInteractionLocations = (
  pageType: string
): pageType is keyof typeof interactionLocations => {
  return pageType in interactionLocations;
};

export const getInteractionLocation = (pageType: string): string => {
  if (isPageTypeInInteractionLocations(pageType)) {
    return interactionLocations[pageType];
  }

  return pageType;
};

export const setInteractionLocation = (pageType?: InteractionLocation): void => {
  if (
    pageType &&
    localStorage.getItem(interactionLocationsKey) !== getInteractionLocation(pageType)
  ) {
    localStorage.setItem(interactionLocationsKey, getInteractionLocation(pageType));
  }
};

export const getStoredInteractionLocation = (): string => {
  return localStorage.getItem(interactionLocationsKey) ?? '';
};

const interactionLocations = {
  header: 'Universal Header',
  home: 'YMME',
  category: 'YMME',
  shelf: 'YMME',
  shelfCheckFits: '"Check if this fits your vehicle" label',
  pdpCheckFits: '"Check if this fits your vehicle" label',
  pdpThisFits: '"This part fits these vehicles" label',
  cart: '"Check if this fits your vehicle" label',
  myVehicle: 'My Vehicle YMME',
  bundleDealsDrawer: 'bundleDealsDrawer',
  search: 'YMME',
  url: 'URL',
  vinDecoder: 'VIN-Decoder',
  campaign: 'campaign',
  scanVin: 'Scan Vin',
  enterVin: 'Enter VIN',
  licensePlateLookup: 'License Plate',
  ymmeAlert: 'Ymme Alert',
} as const;
