/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { crashReporter } from './crashReporter';

export const reduxErrorLogger =
  (): ((next: (a: any) => void) => (action: any) => void) =>
  (next: (a: any) => void) =>
  (action: any) => {
    const isException = action.type.endsWith('ERROR') || action.type.endsWith('FAILURE');

    if (isException) {
      crashReporter('redux exception', action.type, action.errorLog, action.logLevel);
    }

    return next(action);
  };
