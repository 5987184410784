/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import styles from './Dialog.module.scss';
type Props = {
  children?: React.ReactNode;
  className?: string;
  ariaDescribedByID?: string;
  dividers?: boolean;
};

export const DialogContent = React.forwardRef<HTMLDivElement, Props>(function DialogContent(
  props,
  ref
) {
  const { children, className, ariaDescribedByID, dividers = false } = props;
  return (
    <div
      className={cx(
        styles.dialogContentRoot,
        {
          [styles.dialogContentDividers]: dividers,
        },
        className
      )}
      id={ariaDescribedByID}
      ref={ref}
    >
      {children}
    </div>
  );
});
