/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type AxiosInstance } from 'axios';
import type { Store } from '@/types';
import createSessionRefreshInterceptor from './interceptors/sessionRefresh';
import createUpdateAuthStateInterceptor from './interceptors/updateAuthState';
import createErrorHandlerInterceptor from './interceptors/errorHandler';
import { type QueryClient } from '@tanstack/react-query';

export const configureAxiosForClientWithStore = (
  axiosClient: AxiosInstance,
  store: Store,
  queryClient: QueryClient
) => {
  const sessionRefreshInterceptor = createSessionRefreshInterceptor(
    axiosClient,
    queryClient,
    store.dispatch
  );
  const updateAuthStateInterceptor = createUpdateAuthStateInterceptor(axiosClient, store);
  const errorHandlerInterceptor = createErrorHandlerInterceptor(axiosClient, store, queryClient);

  axiosClient.interceptors.request.use(sessionRefreshInterceptor);
  axiosClient.interceptors.response.use(updateAuthStateInterceptor, errorHandlerInterceptor);
};
