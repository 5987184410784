/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useAppState } from '@/stores/AppState';
import { useRouter } from 'next/router';
import { useMediaQuery } from './useMediaQuery';

export function useIsMobileQRExperience(): boolean {
  const router = useRouter();
  const isMobileOrTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { isMobileApp } = useAppState().state;
  return (isMobileOrTablet || isMobileApp) && router.asPath.includes('OFL:US:EN:AD:NL:');
}
