/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRouter } from 'next/router';
import { pageTypes } from '@/constants/page';
import { usePageType as usePageTypeData } from '@/features/category/api/getPageType';

const getPageType = (pattern: string) => {
  switch (pattern) {
    case '/':
      return pageTypes.home;
    case '/searchresult':
      return pageTypes.search;
    case '/brands/[brandName]':
      return pageTypes.BrandShelf;
    case '/products/[...slug]':
      return pageTypes.ProductDetail;
    case '/deals/[cmsPath]/[[...slug]]':
      return pageTypes.MerchShelf;
    default:
      return undefined;
  }
};

export const usePageType = () => {
  const router = useRouter();

  const isSeoUrlPath = router.route === '/[...seoUrlPath]';
  // TODO: we need to figure out how to avoid the API request and remove this option
  const { data: pageTypeData } = usePageTypeData();

  if (isSeoUrlPath) {
    return {
      pageType: pageTypeData?.pageType ?? undefined,
    } as const;
  }

  return {
    status: 'success',
    pageType: getPageType(router.route),
  } as const;
};
