/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { Fade } from '../Fade/Fade';
import styles from './Backdrop.module.scss';

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * @ignore
   */
  className?: string;
  /**
   * If `true`, the backdrop is open.
   */
  open?: boolean;
};

export function Backdrop({ children, className, open, ...other }: Props) {
  return (
    <Fade open={open}>
      <div className={cx(styles.root, className)} aria-hidden {...other}>
        {children}
      </div>
    </Fade>
  );
}
