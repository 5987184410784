/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import mediaQuery from 'css-mediaquery';
import { BREAKPOINTS } from '../constants/screen';
import { useTheme } from '../theme/ThemeContext';
import type { Theme } from '../theme/ThemeContext';

// @ts-expect-error redux
function getDeviceWidth(deviceType) {
  switch (deviceType) {
    case 'DESKTOP':
      return BREAKPOINTS.DESKTOP_MIN;

    case 'TABLET':
      return BREAKPOINTS.TABLET_MIN;

    default:
      return BREAKPOINTS.MOBILE_MAX;
  }
}

export function useMediaQuery(queryInput: (theme: Theme) => string): boolean {
  const theme = useTheme();
  const query = queryInput(theme).replace(/^@media( ?)/m, '');
  // Wait for jsdom to support the match media feature.
  // All the browsers we support have this built-in.
  // This defensive check is here for simplicity.
  // Most of the time, the match media logic isn't central to people tests.

  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';
  const matchMedia = supportMatchMedia ? window.matchMedia : null;
  // @ts-expect-error redux
  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: getDeviceWidth(theme.deviceType),
    }),
  });

  const [match, setMatch] = React.useState(() => {
    return ssrMatchMedia(query).matches;
  });

  React.useEffect(() => {
    let active = true;

    if (!supportMatchMedia || !matchMedia) {
      return;
    }

    const queryList = matchMedia(query);

    const updateMatch = () => {
      // Workaround Safari wrong implementation of matchMedia
      // TODO can we remove it?
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active) {
        setMatch(queryList.matches);
      }
    };

    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      active = false;
      queryList.removeListener(updateMatch);
    };
  }, [query, matchMedia, supportMatchMedia]);

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useDebugValue({
      query,
      match,
    });
  }

  return match;
}
