/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getEnvironmentConfig } from '../config/environment';

export const showXMPreviewDate = (): boolean => {
  const config = getEnvironmentConfig() || {};
  return !!config.SHOW_XM_PREVIEW_DATE;
};
