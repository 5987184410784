/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * This function renames oldProp key to newProp, unless newProp exists, then the
 * oldProp is removed.
 *
 * TODO: make sure this behavior is expected and rewrite or remove this helper.
 */
export const renameProp = (
  oldProp: string,
  newProp: string,
  { [oldProp]: old, ...others }: any
): any => ({
  [newProp]: old,
  ...others,
});
