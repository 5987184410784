/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import type { Reducer } from 'redux';
import thunk from 'redux-thunk';
import type { AxiosInstance } from 'axios';
import actionTypes from './types/action';
import type { ReduxState, Store } from '@/types';
import { reducers } from './reducers';
import { actionLogger } from './utils/actionLogger';
import { reduxErrorLogger } from './utils/reduxErrorLogger';
import { HYDRATE } from 'next-redux-wrapper';

const combinedReducers = combineReducers(reducers);

const rootReducer: Reducer<ReduxState> = (state, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    };
  } else if (action.type === actionTypes.LOG_OUT) {
    // TODO: fix type errors
    // @ts-expect-error
    const { appData, shelf } = state;
    // TODO: State is stripped from the reducers we assume that are here, so in
    // case we e.g. start to persist reducers in some local storage, it may
    // happen that the hydrated store is missing those reducers, while
    // components are not aware of that. Instead an undefined state should be
    // passed to all reducers that need to be reset after logout.
    // @ts-expect-error
    state = {
      appData: { ...appData, userAuthenticated: false },
      shelf,
    };
  }

  return combinedReducers(state, action);
};

const configureStore = (axios: AxiosInstance): Store => {
  const middlewares = [thunk.withExtraArgument(axios), actionLogger.log(), reduxErrorLogger];
  const composeEnhancers =
    (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  const store: Store = createStore(rootReducer, {}, enhancers);

  return store;
};

export default configureStore;
