/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { AnyAction } from 'redux';
import type { PaymentOption, PaymentOptionList } from '../types';
import { cardVendors } from '../constants/cardVendors';
import actionType from '../types/action';

export const initialState: PaymentOption = {
  readyStatus: '',
  paymentOptionList: {},
  // redux-todo: this is not a list, it is a dict; reconsider how this is named and even if this is the right way to structure this data
  paymentOptionErr: '',
};

const paymentOption = (state: PaymentOption = initialState, action: AnyAction): PaymentOption => {
  switch (action.type) {
    case actionType.PAYMENT_OPTION_REQUESTING:
      return { ...state, readyStatus: actionType.PAYMENT_OPTION_REQUESTING };

    case actionType.PAYMENT_OPTION_SUCCESS: {
      const paymentOptionObject: PaymentOptionList = {};
      action.data.paymentOptionList?.forEach((key: any) => {
        if (!(key.numberOfCardsAllowed > 0)) {
          return;
        }

        if (key.paymentMethod === cardVendors.giftCard) {
          paymentOptionObject.giftCardEnabled = true;
          paymentOptionObject.noOfGiftCardAllowed = key.numberOfCardsAllowed;
        } else if (key.paymentMethod === cardVendors.merchCard) {
          paymentOptionObject.merchCardEnabled = true;
          paymentOptionObject.noOfMerchCardAllowed = key.numberOfCardsAllowed;
        } else if (key.paymentMethod === cardVendors.loyaltyCard) {
          paymentOptionObject.rewardsEnabled = true;
          paymentOptionObject.noOfRewardsEnabled = key.numberOfCardsAllowed;
        } else if (key.paymentMethod === cardVendors.amexCard) {
          paymentOptionObject.amexEnabled = true;
          paymentOptionObject.noOfAmexEnabled = key.numberOfCardsAllowed;
        } else if (key.paymentMethod === cardVendors.masterCard) {
          paymentOptionObject.masterEnabled = true;
          paymentOptionObject.noOfMasterEnabled = key.numberOfCardsAllowed;
        } else if (key.paymentMethod === cardVendors.visaCard) {
          paymentOptionObject.visaEnabled = true;
          paymentOptionObject.noOfVisaEnabled = key.numberOfCardsAllowed;
        } else if (key.paymentMethod === cardVendors.paypal) {
          paymentOptionObject.paypalEnabled = true;
          paymentOptionObject.noOfPaypalEnabled = key.numberOfCardsAllowed;
        } else if (key.paymentMethod === cardVendors.discoverCard) {
          paymentOptionObject.discoverEnabled = true;
          paymentOptionObject.noOfDiscoverEnabled = key.numberOfCardsAllowed;
        } else if (key.paymentMethod === cardVendors.googlePay) {
          paymentOptionObject.googlePayEnabled = true;
        } else if (key.paymentMethod === cardVendors.applePay) {
          paymentOptionObject.applePayEnabled = true;
        }
      });
      return {
        ...state,
        readyStatus: actionType.PAYMENT_OPTION_SUCCESS,
        paymentOptionList: paymentOptionObject,
        paymentOptionErr: '',
      };
    }

    case actionType.PAYMENT_OPTION_FAILURE:
      return {
        ...state,
        readyStatus: actionType.PAYMENT_OPTION_FAILURE,
        paymentOptionErr: action.err,
      };

    default:
      return state;
  }
};

export default paymentOption;
