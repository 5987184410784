/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AnyAction } from 'redux';
import type { OrdersDetailsFromState } from '@/types/reduxStore/orders';
import actionTypes from '../../types/action';

export const initialState = {
  readyStatus: actionTypes.ORDER_DETAILS_INVALID,
  err: null,
  orderItems: [] as Array<any>,
  orderSummary: null,
  paymentInfoList: [] as Array<any>,
  cartData: {} as {},
  rewardsData: null,
  cartVerifyData: {} as {},
  cartItemError: {} as {},
  orderDetailsError: null,
  loadingSkuNumber: null,
  quantity: '',
  rewardsCreditEligible: undefined,
  orderStatus: '',
};

const {
  ORDER_DETAILS_FAILURE,
  ORDER_DETAILS_REQUESTING,
  ORDER_DETAILS_SUCCESS,
  UPDATE_ORDERITEM_REQUESTING,
  REMOVE_ORDERITEM_FAILURE,
  REMOVE_ORDERITEM_SUCCESS,
  UPDATE_ORDERITEM_SUCCESS,
  UPDATE_ORDERITEM_FAILURE,
  UPDATE_ITEM_QUANTITY_SUCCESS,
  ORDER_DETAILS_CLEAR,
  CLEAR_ORDER_DETAILS,
  SET_CHECKOUT_BUTTON,
  SET_LOADING_FOR_PRODUCT,
  SET_ORDER_STATUS,
} = actionTypes;

const details = (
  state: OrdersDetailsFromState = initialState,
  action: AnyAction
): OrdersDetailsFromState => {
  switch (action.type) {
    case ORDER_DETAILS_REQUESTING:
      return { ...state, readyStatus: ORDER_DETAILS_REQUESTING };

    case ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        readyStatus: ORDER_DETAILS_SUCCESS,
        orderItems: action.data.orders,
        orderSummary: action.data.grandOrderPriceInfo,
        paymentInfoList: action.data.grandPaymentAccountList,
        rewardsCreditEligible: action.data.rewardsCreditEligible,
      };

    case ORDER_DETAILS_FAILURE:
      return { ...state, readyStatus: ORDER_DETAILS_FAILURE, err: action.err };

    case UPDATE_ORDERITEM_REQUESTING:
      return { ...state, readyStatus: UPDATE_ORDERITEM_REQUESTING };

    case UPDATE_ORDERITEM_SUCCESS:
      return {
        ...state,
        readyStatus: UPDATE_ORDERITEM_SUCCESS,
        orderItems: action.data.orders,
        orderSummary: action.data.grandOrderPriceInfo,
        paymentInfoList: action.data.grandPaymentAccountList,
        orderDetailsError: null,
      };

    case UPDATE_ITEM_QUANTITY_SUCCESS:
      return {
        ...state,
        readyStatus: UPDATE_ITEM_QUANTITY_SUCCESS,
        skuId: action.data.skuId,
        quantity: action.data.quantity,
      };

    case UPDATE_ORDERITEM_FAILURE:
      return {
        ...state,
        readyStatus: UPDATE_ORDERITEM_FAILURE,
        orderDetailsError: action.err,
      };

    case REMOVE_ORDERITEM_SUCCESS:
      return {
        ...state,
        readyStatus: actionTypes.REMOVE_ORDERITEM_SUCCESS,
      };

    case REMOVE_ORDERITEM_FAILURE:
      return {
        ...state,
        readyStatus: REMOVE_ORDERITEM_FAILURE,
        err: action.err,
      };

    case ORDER_DETAILS_CLEAR:
      return {
        ...state,
        readyStatus: actionTypes.ORDER_DETAILS_INVALID,
        err: null,
        orderItems: [],
        orderSummary: null,
        paymentInfoList: [],
      };

    case CLEAR_ORDER_DETAILS:
      return initialState;

    case SET_CHECKOUT_BUTTON:
      return { ...state, enableCheckoutButton: action.setCheckoutButton };

    case SET_ORDER_STATUS:
      return { ...state, orderStatus: action.orderStatus };

    case SET_LOADING_FOR_PRODUCT:
      return { ...state, loadingSkuNumber: action.skuId };

    default:
      return state;
  }
};

export default details;
