/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { useLocale } from '@/hooks/useLocale';
import { getAxios } from '@/lib/axios';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import { stringifyUrl } from '@/utils/urlHelpers';
import { AxiosInstance } from 'axios';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { HomepageContent, Response } from '../interface';

const url = `${requestBaseURL}/ecomm/b2c/v1/xm/home`;

const getHomePageContent = async (
  params?: {
    workspaceId: string | undefined;
    previewDate: string | undefined;
  },
  axiosInstance?: AxiosInstance
) => {
  const maybeXMPreviewUrl =
    params?.workspaceId && params.previewDate
      ? stringifyUrl({
          url,
          query: { workspaceId: params.workspaceId, previewDate: params.previewDate },
        })
      : url;
  const response = await getAxios(axiosInstance).get<Response>(maybeXMPreviewUrl);
  return selector(response.data);
};

const selector = (data: Response): HomepageContent => {
  const pageContents = data.contents[0];

  return {
    mainContent: pageContents.homePageBody,
    navContent: pageContents.homeTippyTop,
    homeHead: pageContents.homeHead || [],
  };
};

export const getHomePageKey = (
  locale: string,
  workspaceId: string | undefined,
  previewDate: string | undefined
) => [locale, workspaceId, previewDate, 'page', 'home'].filter(Boolean);

type UseHomePageContentOptions = {
  enabled?: boolean;
};

export const useHomePageContent = (options: UseHomePageContentOptions = {}) => {
  const locale = useLocale();
  const { workspaceId, previewDate } = useXMPreviewState();
  return useQuery({
    queryKey: getHomePageKey(locale, workspaceId, previewDate),
    queryFn: () => getHomePageContent({ workspaceId, previewDate }),
    ...options,
  });
};

export const prefetchHomePageContent = (
  queryClient: QueryClient,
  locale: string,
  axiosInstance: AxiosInstance
) => {
  return queryClient.prefetchQuery({
    queryKey: getHomePageKey(locale, undefined, undefined),
    queryFn: () =>
      getHomePageContent({ workspaceId: undefined, previewDate: undefined }, axiosInstance),
  });
};
