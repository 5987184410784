/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import NextImage from '../../../components/NextImage';
import { SmartLink } from '../../../utils/smartLink';
import styles from './ContactUsModal.module.scss';
import cx from 'classnames';

type Props = {
  icon: {
    height: number;
    width: number;
    src: string;
  };
  title: string;
  subtext: string;
  isDisabled?: boolean;
  linkTo?: string;
  onClick?: () => void;
};

export function ContactUsOption({
  icon,
  title,
  subtext,
  linkTo,
  onClick,
  isDisabled = false,
}: Props) {
  return (
    <SmartLink
      to={linkTo ? linkTo : ''}
      className={cx({ [styles.isDisabled]: isDisabled }, styles.contactUsOption)}
      onClick={onClick}
      role={linkTo ? 'link' : 'button'}
      aria-label={`${title} ${subtext}`}
    >
      <div className={styles.iconAndTitle}>
        <NextImage src={icon.src} height={icon.height} width={icon.width} />
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.subtext}>{subtext}</div>
    </SmartLink>
  );
}
