/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { formControlState, State } from '../FormControl/formControlState';
import FormControlContext, { useFormControl } from '../FormControl/FormControlContext';
import { pascalCase } from '../../utils/pascalCase';
import { useForkRef } from '@/hooks/useForkRef';
import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';
import { TextareaAutosize } from './TextareaAutosize/TextareaAutosize';
import { isFilled } from './utils';
import styles from './InputBase.module.scss';

type Props = Pick<
  React.InputHTMLAttributes<HTMLElement>,
  | 'autoComplete'
  | 'autoFocus'
  | 'name'
  | 'onChange'
  | 'onFocus'
  | 'onBlur'
  | 'onClick'
  | 'onKeyDown'
  | 'onKeyUp'
  | 'type'
> &
  React.HTMLAttributes<HTMLDivElement> & {
    /**
     * @ignore
     */
    className?: string;
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color?: 'primary' | 'secondary';
    /**
     * The default `input` element value. Use when the component is not controlled.
     */
    defaultValue?: any;
    /**
     * If `true`, the `input` element will be disabled.
     */
    disabled?: boolean;
    /**
     * End `InputAdornment` for this component.
     */
    endAdornment?: React.ReactNode;
    /**
     * If `true`, the input will indicate an error. This is normally obtained via context from
     * FormControl.
     */
    error?: boolean;
    /**
     * If `true`, the input will take up the full width of its container.
     */
    fullWidth?: boolean;
    /**
     * The component used for the `input` element.
     * Either a string to use a HTML element or a component.
     */
    inputComponent?: React.ComponentType<any> | string;
    /**
     * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
     */
    inputProps?: {
      [key: string]: any;
    };
    /**
     * Pass a ref to the `input` element.
     */
    inputRef?: React.Ref<any>;
    /**
     * If `dense`, will adjust vertical spacing. This is normally obtained via context from
     * FormControl.
     */
    margin?: 'dense' | 'none';
    multiline?: boolean;
    /**
     * The short hint displayed in the input before the user enters a value.
     */
    placeholder?: string;
    /**
     * It prevents the user from changing the value of the field
     * (not from interacting with the field).
     */
    readOnly?: boolean;
    /**
     * @ignore
     */
    renderSuffix?: (state: {
      disabled?: boolean;
      error?: boolean;
      filled?: boolean;
      focused?: boolean;
      margin?: 'dense' | 'none' | 'normal';
      required?: boolean;
      startAdornment?: React.ReactNode;
    }) => React.ReactNode;
    /**
     * Number of rows to display when multiline option is set to true.
     */
    rows?: number | string;
    /**
     * Maximum number of rows to display when multiline option is set to true.
     */
    rowsMax?: number | string;
    /**
     * Minimum number of rows to display when multiline option is set to true.
     */
    rowsMin?: number | string;
    /**
     * Start `InputAdornment` for this component.
     */
    startAdornment?: React.ReactNode;
    /**
     * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
     */
    type?: string;
    /**
     * The value of the `input` element, required for a controlled component.
     */
    value?: any;
    classes?: {
      disabled?: string;
      error?: string;
      fullWidth?: string;
      focused?: string;
      formControl?: string;
      marginDense?: string;
      multiline?: string;
      adornedStart?: string;
      adornedEnd?: string;
      input?: string;
    };
    ariaLabel?: string;
  };

/**
 * `InputBase` contains as few styles as possible.
 * It aims to be a simple building block for creating an input.
 * It contains a load of style reset and some state logic.
 */
export const InputBase = React.forwardRef<HTMLDivElement, Props>(function InputBase(props, ref) {
  const {
    'aria-describedby': ariaDescribedby,
    autoComplete,
    autoFocus,
    className,
    color,
    defaultValue,
    disabled,
    endAdornment,
    error,
    fullWidth = false,
    id,
    inputComponent = 'input',
    inputProps: inputPropsProp = {},
    inputRef: inputRefProp,
    margin,
    multiline = false,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly,
    renderSuffix,
    rows,
    rowsMax,
    rowsMin,
    startAdornment,
    type = 'text',
    value: valueProp,
    classes = {},
    ariaLabel,
    ...other
  } = props;
  const value = inputPropsProp.value != null ? inputPropsProp.value : valueProp;
  const { current: isControlled } = React.useRef(value != null);
  const inputRef = React.useRef<HTMLInputElement>();
  const handleInputRefWarning = React.useCallback((instance: any) => {
    if (process.env.NODE_ENV !== 'production') {
      if (instance && instance.nodeName !== 'INPUT' && !instance.focus) {
        // eslint-disable-next-line no-console
        console.error(
          [
            'You have provided a `inputComponent` to the input component',
            'that does not correctly handle the `inputRef` prop.',
            'Make sure the `inputRef` prop is called with a HTMLInputElement.',
          ].join('\n')
        );
      }
    }
  }, []);
  const handleInputPropsRefProp = useForkRef(inputPropsProp.ref, handleInputRefWarning);
  const handleInputRefProp = useForkRef(
    // @ts-expect-error refine type
    inputRefProp,
    handleInputPropsRefProp
  );
  const handleInputRef = useForkRef(inputRef, handleInputRefProp);
  const [focused, setFocused] = React.useState(false);
  const muiFormControl = useFormControl();

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (muiFormControl) {
        return muiFormControl.registerEffect?.();
      }

      return undefined;
    }, [muiFormControl]);
  }

  const fcs = formControlState({
    props,
    muiFormControl,
    states: [
      ...Object.keys({
        color,
        disabled,
        error,
        margin,
      }),
      'required',
      'hiddenLabel',
      'filled',
    ] as State[],
  });
  fcs.focused = muiFormControl ? muiFormControl.focused : focused; // The blur won't fire when the disabled state is set on a focused input.
  // We need to book keep the focused state manually.

  React.useEffect(() => {
    if (!muiFormControl && disabled && focused) {
      setFocused(false);

      if (onBlur) {
        // @ts-expect-error refine type
        onBlur();
      }
    }
  }, [muiFormControl, disabled, focused, onBlur]);
  const onFilled = muiFormControl?.onFilled;
  const onEmpty = muiFormControl?.onEmpty;
  const checkDirty = React.useCallback(
    (obj?: { value?: any; defaultValue?: any }) => {
      if (isFilled(obj)) {
        if (onFilled) {
          onFilled();
        }
      } else if (onEmpty) {
        onEmpty();
      }
    },
    [onFilled, onEmpty]
  );
  useIsomorphicLayoutEffect(() => {
    if (isControlled) {
      checkDirty({
        value,
      });
    }
  }, [value, checkDirty, isControlled]);

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    // Fix a bug with IE 11 where the focus/blur events are triggered
    // while the input is disabled.
    if (fcs.disabled) {
      event.stopPropagation();
      return;
    }

    if (onFocus) {
      onFocus(event);
    }

    if (inputPropsProp.onFocus) {
      inputPropsProp.onFocus(event);
    }

    if (muiFormControl?.onFocus) {
      muiFormControl.onFocus(event);
    } else {
      setFocused(true);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (onBlur) {
      onBlur(event);
    }

    if (inputPropsProp.onBlur) {
      inputPropsProp.onBlur(event);
    }

    if (muiFormControl?.onBlur) {
      muiFormControl.onBlur(event);
    } else {
      setFocused(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLElement>, ...args: any[]) => {
    if (!isControlled) {
      const element = event.target || inputRef.current;

      if (element == null) {
        throw new Error(
          'Expected valid input target. ' +
            'Did you use a custom `inputComponent` and forget to forward refs? ' +
            'See https://material-ui.com/r/input-component-ref-interface for more info.'
        );
      }

      checkDirty({
        // @ts-expect-error revisit this
        value: element.value,
      });
    }

    if (onChange) {
      // @ts-expect-error verify
      onChange(event, ...args);
    }

    if (inputPropsProp.onChange) {
      inputPropsProp.onChange(event, ...args);
    }
  }; // Check the input state on mount, in case it was filled by the user
  // or auto filled by the browser before the hydration (for SSR).

  React.useEffect(() => {
    checkDirty(inputRef.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (inputRef.current && event.currentTarget === event.target) {
      inputRef.current.focus();
    }

    if (onClick) {
      onClick(event);
    }
  };

  let InputComponent = inputComponent;
  let inputProps = { ...inputPropsProp, ref: handleInputRef } as any;

  if (typeof InputComponent !== 'string') {
    inputProps = {
      // Rename ref to inputRef as we don't know the
      // provided `inputComponent` structure.
      inputRef: handleInputRef,
      type,
      ...inputProps,
      ref: null,
    };
  } else if (multiline) {
    if (rows && !rowsMax && !rowsMin) {
      InputComponent = 'textarea';
    } else {
      inputProps = {
        rows,
        rowsMax,
        ...inputProps,
      };
      InputComponent = TextareaAutosize;
    }
  } else {
    inputProps = {
      type,
      ...inputProps,
    };
  }

  const handleAutoFill = (event: React.AnimationEvent) => {
    // Provide a fake value as Chrome might not let you access it for security reasons.
    checkDirty(
      event.animationName.includes('mui-auto-fill-cancel')
        ? inputRef.current
        : {
            value: 'x',
          }
    );
  };

  const { setAdornedStart } = muiFormControl ?? {};
  const hasAdornment = Boolean(startAdornment);

  React.useEffect(() => {
    if (setAdornedStart) {
      setAdornedStart(hasAdornment);
    }
  }, [setAdornedStart, hasAdornment]);

  return (
    <div
      className={cx(
        styles.root,
        styles[`color${pascalCase(fcs.color || 'primary')}`],
        {
          [cx(classes.disabled, styles.disabled)]: fcs.disabled,
          [cx(classes.error, styles.error)]: fcs.error,
          [cx(classes.fullWidth, styles.fullWidth)]: fullWidth,
          [cx(classes.focused, styles.focused)]: fcs.focused,
          [cx(classes.formControl, styles.formControl)]: muiFormControl,
          [cx(classes.marginDense, styles.marginDense)]: fcs.margin === 'dense',
          [cx(classes.multiline, styles.multiline)]: multiline,
          [cx(classes.adornedStart, styles.adornedStart)]: startAdornment,
          [cx(classes.adornedEnd, styles.adornedEnd)]: endAdornment,
        },
        className
      )}
      onClick={handleClick}
      ref={ref}
      {...other}
    >
      {startAdornment}
      <FormControlContext.Provider value={undefined}>
        <InputComponent
          aria-invalid={fcs.error}
          aria-describedby={ariaDescribedby}
          aria-label={ariaLabel}
          autoComplete={autoComplete}
          autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
          defaultValue={defaultValue}
          disabled={fcs.disabled}
          id={id}
          onAnimationStart={handleAutoFill}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          required={fcs.required}
          rows={rows}
          value={value}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          {...inputProps}
          className={cx(
            cx(classes.input, styles.input),
            {
              [cx(classes.disabled, styles.disabled)]: fcs.disabled,
              [styles.inputTypeSearch]: type === 'search',
              [styles.inputTypeDate]: type === 'date',
              [styles.value]: value,
              [styles.inputMultiline]: multiline,
              [styles.inputMarginDense]: fcs.margin === 'dense',
              [styles.inputHiddenLabel]: fcs.hiddenLabel,
              [styles.inputAdornedStart]: startAdornment,
              [styles.inputAdornedEnd]: endAdornment,
            },
            inputPropsProp.className
          )}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus} // required for input type="date" to make focus work correctly on Firefox and Safari
          onClick={handleFocus}
        />
      </FormControlContext.Provider>
      {endAdornment}
      {renderSuffix ? renderSuffix({ ...fcs, startAdornment }) : null}
    </div>
  );
});
