/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const siteSections = {
  siteSectionCartAndCheckout: 'cart-and-checkout',
  siteSectionMyzone: 'myzone',
  siteSectionCatalog: 'catalog',
  siteSectionCatalogNew: 'catalog-new',
  siteSectionHome: 'home',
  siteSectionContinueAsGuest: 'continueAsGuest',
  siteSectionOther: 'other',
  siteSectionDeals: 'deals',
  siteSectionCart: 'cart-and-checkout',
  siteSectionMyStore: 'mystore',
  siteSectionMyStoreLocator: 'store-locator',
  siteSectionStoreDetails: 'storedetails',
  siteSectionContactUs: 'customer-service',
  siteSectionRepairHelp: 'repair-help',
  siteSectionBrandShelf: 'Brand Profile',
  siteSectionBrandsMain: 'Brands Main',
  siteSectionlandingPage: 'landing-page',
  siteSectionShopByMake: 'catalog',
  siteSectionShopByModel: 'catalog',
  siteSectionPdp: 'pdp',
  siteSectioncategory: 'category',
  siteSectionCatalogSearchResults: 'catalog',
  siteSectionProductShelf: 'catalog',
  siteSectionGiftCard: 'landing-page',
  siteSectionTrackOrder: 'customer-service',
  siteSectionOrderConfirmation: 'order-confirmation',
  siteSectionPageUnavailable: 'page_unavailable',
  siteSectionSms: 'sms-signup',
  siteSectionSitemap: 'sitemap',
  siteSectionSignIn: 'signIn',
  coupons: 'deals_savings',
  deals: 'deals_savings',
  rebates: 'deals_savings',
  circular: 'deals_savings',
  siteSectionWarranty: 'warranty',
  siteSectionWarrantyDetails: 'warranty-details',
  reviews: 'reviews',
} as const;
