/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState } from 'react';
import { openChatWindow } from '../utils/openChatWindow';
import styles from './ChatSlider.module.scss';
import { trackChatLink } from '@/utils/analytics/track/trackChatLink';
import NextImage, { pureLoader } from '@/components/NextImage';

const liveChatSlider = {
  src: '/images/tag-live-chat.png',
  alt: '',
};

const liveChatSliderHover = {
  src: '/images/live-chat.png',
  alt: '',
};

const showLiveChat = () => {
  openChatWindow();
  /* Analytics start */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (window.utag_data?.pageName) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
    trackChatLink(window.utag_data.pageName);
  }
  /* Analytics end */
};

export const ChatSlider = () => {
  const [imageUrl, setImageUrl] = useState(liveChatSlider.src);
  const handleMouseEnter = () => setImageUrl(liveChatSliderHover.src);
  const handleMouseOut = () => setImageUrl(liveChatSlider.src);

  return (
    <div className={styles.chatSliderContainer}>
      <div className={styles.chatSliderImgContent}>
        <a
          id="liveChat"
          data-testid="liveChat"
          href="#0"
          onClick={showLiveChat}
          className={styles.liveChat}
        >
          <span>
            <NextImage
              className={styles.chatBtn}
              src={imageUrl}
              alt="LIVE CHAT"
              onMouseEnter={handleMouseEnter}
              onMouseOut={handleMouseOut}
              width={37}
              height={150}
              loader={pureLoader}
            />
          </span>
          <span className={styles.sr}>Opens a new window</span>
        </a>
      </div>
    </div>
  );
};

export default ChatSlider;
