/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export function getPageHierarchy(): string {
  if (typeof window !== 'undefined' && window.location) {
    const url = window.location.pathname.split('/');
    url.map((path) => path.replace(/\.[^/.]+$/, ''));

    if (url.length > 1) {
      url.shift();
    }

    return url.join('|');
  }

  return '';
}
