/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackFooterNavMenu = (linkName: string) => {
  const data = {
    footerNavLinkName: linkName,
    eventType: 'footerNavLinkInteraction',
  };
  clickTrack(data);
};
