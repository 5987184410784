/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/*
The objective of this module is to have an in-memory session-store so that
repeat API calls need not be made for static data or data that wouldn't change
so often. The data is refreshed with a TTL set as a configurable number in milli-seconds
*/
import type { AxiosResponse } from 'axios';

const defaultOptions = {
  ttl: process.env.NODE_SESSION_DATA_TTL || '3600000',
  // 60min of node session default
  cacheExpiry: 5 * 60, // 5 min default
};
const cache = new Map<string, AxiosResponse<any>>();

export const azDataStore = {
  clear() {
    cache.clear();
  },

  getItem(key: string, siteLocale: string): AxiosResponse<any> | void {
    const cacheKey = getKeyForLocale(siteLocale, key);
    const data = cache.get(cacheKey);

    if (data !== undefined) {
      const age = (Date.now() - Date.parse(data.headers?.date ?? '')) / 1000;
      const expiryTime = defaultOptions.cacheExpiry;

      if (age < expiryTime) {
        return data;
      } else {
        cache.delete(cacheKey);
      }
    } else {
      return data;
    }
  },

  setItem(key: string, val: AxiosResponse<any>, siteLocale: string) {
    cache.set(getKeyForLocale(siteLocale, key), val);
  },
};

// clear the session data for node instance at given intervals
if (typeof window === 'undefined') {
  setInterval(azDataStore.clear, Number.parseInt(defaultOptions.ttl));
}

function getKeyForLocale(siteLocale: string, key: string) {
  return siteLocale + key;
}
