/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { sessionStorage } from '@/utils/sessionStorage';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useNavigationCount = () => {
  const router = useRouter();
  useEffect(() => {
    if (!sessionStorage.getItem('pageCount')) {
      sessionStorage.setItem('pageCount', '1');
    } else {
      const pageCount = sessionStorage.getItem('pageCount');
      sessionStorage.setItem('pageCount', `${parseInt(pageCount ?? '1') + 1}`);
    }
  }, [router.asPath]);
};

export { useNavigationCount };
