/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { InputBase } from '../InputBase/InputBase';
import styles from './Input.module.scss';

export type Props = Pick<
  React.InputHTMLAttributes<HTMLElement>,
  | 'autoComplete'
  | 'autoFocus'
  | 'name'
  | 'value'
  | 'onChange'
  | 'onFocus'
  | 'onBlur'
  | 'onClick'
  | 'onKeyDown'
  | 'onKeyUp'
  | 'type'
> &
  React.HTMLAttributes<HTMLDivElement> & {
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color?: 'primary' | 'secondary';

    /**
     * The default `input` element value. Use when the component is not controlled.
     */
    defaultValue?: any;

    /**
     * If `true`, the `input` element will be disabled.
     */
    disabled?: boolean;

    /**
     * If `true`, the input will not have an underline.
     */
    disableUnderline?: boolean;

    /**
     * End `InputAdornment` for this component.
     */
    endAdornment?: React.ReactNode;

    /**
     * If `true`, the input will indicate an error. This is normally obtained via context from
     * FormControl.
     */
    error?: boolean;

    /**
     * If `true`, the input will take up the full width of its container.
     */
    fullWidth?: boolean;

    /**
     * The component used for the `input` element.
     * Either a string to use a HTML element or a component.
     */
    inputComponent?: React.ComponentType<any>;

    /**
     * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
     */
    inputProps?: {};

    /**
     * Pass a ref to the `input` element.
     */
    inputRef?: React.Ref<any>;

    /**
     * If `dense`, will adjust vertical spacing. This is normally obtained via context from
     * FormControl.
     */
    margin?: 'dense' | 'none';

    /**
     * If `true`, a textarea element will be rendered.
     */
    multiline?: boolean;

    /**
     * The short hint displayed in the input before the user enters a value.
     */
    placeholder?: string;

    /**
     * It prevents the user from changing the value of the field
     * (not from interacting with the field).
     */
    readOnly?: boolean;

    /**
     * Number of rows to display when multiline option is set to true.
     */
    rows?: string | number;

    /**
     * Maximum number of rows to display when multiline option is set to true.
     */
    rowsMax?: string | number;

    /**
     * Start `InputAdornment` for this component.
     */
    startAdornment?: React.ReactNode;

    classes?: {
      disabled?: string;
      error?: string;
      fullWidth?: string;
      focused?: string;
      formControl?: string;
      marginDense?: string;
      multiline?: string;
      adornedStart?: string;
      adornedEnd?: string;
      input?: string;
    };
    className?: string;
    ariaLabel?: string;
  };

export const Input = React.forwardRef<HTMLDivElement, Props>(function Input(props, ref) {
  const {
    disableUnderline,
    fullWidth = false,
    inputComponent = 'input',
    className,
    multiline = false,
    type = 'text',
    ...other
  } = props;
  return (
    <InputBase
      className={cx(styles.root, { [styles.underline]: !disableUnderline }, className)}
      fullWidth={fullWidth}
      inputComponent={inputComponent}
      multiline={multiline}
      ref={ref}
      type={type}
      {...other}
    />
  );
});
// @ts-expect-error for input adornment
Input.muiName = 'Input';
