/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const cardVendors = {
  applePay: 'APPLEPAY',
  googlePay: 'GOOGLEPAY',
  paypal: 'PAYPAL',
  giftCard: 'GIFTCARD',
  loyaltyCard: 'LOYALTYCARD',
  creditCard: 'CREDITCARD',
  merchCard: 'MERCHCARD',
  card: 'CARD',
  amexCard: 'AMERICANEXPRESS',
  masterCard: 'MASTERCARD',
  visaCard: 'VISA',
  discoverCard: 'DISCOVER',
  carnetCard: 'CARNET',
};
