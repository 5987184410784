/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import HtmlBlock from '../../ContentBlocks/HtmlBlock/HtmlBlock';
import PencilAdContentBlock from '../../ContentBlocks/HomePencilContent/PencilComponent';
import ContainOverflow from '../../AZCustomComponent/ContainOverflow';
import { getCartridgeType } from '@/utils/getCartridgeType';

export const TippyTopGeneric = ({ content }: { content: Array<any> }) => {
  const renderContentBlocks = (contentType: string, contentItem: any, i: any) => {
    const key = `${contentType}-${i}`;

    switch (contentType) {
      case 'HTMLBlock':
        return (
          //eslint-disable-next-line
          <div className="htmlContentBlock" key={key}>
            <ContainOverflow key={key}>
              <HtmlBlock content={contentItem} key={key} />
            </ContainOverflow>
          </div>
        );

      case 'PencilAdLiveText':
        return <PencilAdContentBlock data={contentItem} key={key} />;

      default:
        return null;
    }
  };

  return (
    <>
      {content?.map((contentItem, i) => {
        const contentType = getCartridgeType(contentItem['@type']);

        if (contentType === 'Slot' && contentItem.contents?.length > 0) {
          return contentItem.contents.map((singleContentItem: any, mappedContentIndex: number) =>
            renderContentBlocks(
              getCartridgeType(singleContentItem['@type']),
              singleContentItem,
              mappedContentIndex
            )
          );
        }

        return renderContentBlocks(contentType, contentItem, i);
      })}
    </>
  );
};

export default TippyTopGeneric;
