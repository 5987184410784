/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const globalConstant = {
  nextDay: 'Next Day',
  freight: 'Freight',
  online: 'online',
  store: 'store',
  shipping: 'shipping',
  newAddress: 'newAddress',
  savedAddress: 'savedAddress',
  sameAsShipping: 'sameAsShipping',
  saveThisAddress: 'saveThisAddress',
  postalCode: 'postalCode',
  zipCode: 'zipCode',
  shippingAddress: 'shippingAddress',
  billingAddress: 'billingAddress',
  homeAddress: 'homeAddress',
  receivePromoPrint: 'receivePromoPrint',
  ldirection: 'left',
  rdirection: 'right',
  replace: 'Replace',
  inspect: 'Inspect',
  rotate: 'Rotate',
  reset: 'Reset',
  warn: 'warn',
  error: 'error',
  applied: 'applied',
  removed: 'removed',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
  fullscreen: 'fullscreen',
  buyGiftCard: 'buyGiftCard',
  giftcard: 'giftcard',
  giftcards: 'giftcards',
  success: 'success',
  failure: 'failure',
  inStore: 'inStore',
  referenceNumber: 'referenceNumber',
  tenderPaypal: 'paypal',
  tenderGCorRewards: 'GCorRewards',
  storePickUp: 'storePickUp',
  shipToHome: 'shipToHome',
  processing: 'PROCESSING',
  submitted: 'SUBMITTED',
  complete: 'COMPLETE',
  pickedup: 'PICKED UP',
  hash: '#',
  shipped: 'SHIPPED',
  orderReady: 'ORDER READY',
  shippedMultiple: 'SHIPPED_MULTIPLE',
  noPendingAction: 'NO_PENDING_ACTION',
  delayed: 'DELAYED',
  unknown: 'UNKNOWN_STATUS',
  unkown: 'UNKOWN_STATUS',
  unknownStatus: 'UNKNOWN STATUS',
  issueWithDelivery: 'ISSUE WITH DELIVERY',
  vdpDirect: 'VDP Direct',
  cancelled: 'CANCELLED',
  undeliverable: 'UNDELIVERABLE',
  outForDelivery: 'OUT FOR DELIVERY',
  readyToShip: 'READY TO SHIP',
  pickedUp: 'PICKED UP',
  delivered: 'DELIVERED',
  deliveredInMultiplePackages: 'DELIVERED_MULTIPLE',
  readyForPickUp: 'READY FOR PICK UP',
  ready: 'READY',
  cancellationInProgress: 'CANCELLATION IN PROGRESS',
  shippedMx: 'ENVIADA',
  completeMx: 'COMPLETA',
  submittedMx: 'PRESENTADA',
  outForDeliveryMx: 'FUERA PARA ENTREGA',
  pickedUpMx: 'RECOGIDO',
  deliveredMx: 'ENTREGADA',
  readyMx: 'LISTA',
  readyForPickUpMx: 'LISTA PARA RECOGER',
  processingMx: 'PROCESANDO ORDEN',
  readyToShipMx: 'LISTA PARA ENVIAR',
  readyForPickUpAtStoreMx: 'LISTO PARA RECOGER EN TIENDA',
  readyListMx: 'LISTO',
  cancelledMx: 'CANCELADO',
  cancellationInProgressMx: 'CANCELACION EN CURSO',
} as const;
