/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect } from 'react';
import cx from 'classnames';
import { Drawer } from '../../Drawer/Drawer';
import { IconButton } from '../../IconButton/IconButton';
import { Toolbar } from '../../Toolbar';
import { AppBar } from '../../AppBar';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import azCommonStyles from '../../../theme/globals.module.scss';
import styles from './styles.module.scss';
import { TrapFocus } from '@/components/Dialog/Modal/TrapFocus';

type Props = {
  open: boolean;
  children: React.ReactNode;
  handleClose: () => void;
  anchor?: 'left' | 'right';
  handleBack?: () => void;
};

const labelMap = {
  lblCloseDrawer: 'label_Search_Results_Close_Drawer',
};

function SearchDrawer(props: Props) {
  const labels = useLabels(labelMap);

  useEffect(() => {
    // We need to disable the rest of the inputs on the page to prevent Safari mobile users
    // from using up / down buttons in the iOS keyboard to navigate to them while the drawer is still open
    const disabledInputs: HTMLInputElement[] = [];
    if (props.open) {
      document.querySelectorAll('input').forEach((inputEl) => {
        if (inputEl.id === 'searchMobile' || inputEl.disabled === true) {
          return;
        }
        inputEl.disabled = true;
        disabledInputs.push(inputEl);
      });
    }

    return () => {
      disabledInputs.forEach((inputEl) => {
        inputEl.disabled = false;
      });
    };
  }, [props.open]);

  return (
    <Drawer
      anchor={props.anchor ?? 'right'}
      open={props.open}
      classes={{
        paperAnchorRight: styles.drawerMobile,
        paperAnchorLeft: styles.drawerMobile,
      }}
      className={styles.customDrawer}
      transitionDuration={100}
      disableScrolling={false}
    >
      <TrapFocus open={props.open}>
        <div role="dialog" aria-modal="true">
          <AppBar position="static">
            <Toolbar>
              <span className={cx(styles.searchHeading, azCommonStyles['az-subtitle-3'])}>
                <Label label="label_Search_Results_Search" />
              </span>
              <IconButton
                id="drawer-close-button"
                data-testid="drawer-close-button"
                color="inherit"
                ariaLabel={labels.lblCloseDrawer}
                role="button"
                onClick={props.handleClose}
                className={styles.toolbarIconButton}
              >
                <img src="/images/close-light.svg" alt="Close" />
              </IconButton>
            </Toolbar>
          </AppBar>
          {props.children}
        </div>
      </TrapFocus>
    </Drawer>
  );
}

export default SearchDrawer;
