/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button } from '@/components/Button';
import cx from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import KeyboardBackspaceIcon from '@/public/images/keyboard-backspace.svg';
import iconStyles from '@/theme/iconStyles.module.scss';

type BackButtonProps = {
  backRef: React.Ref<HTMLButtonElement>;
  handleBack?: () => void;
  label: string;
};

export const BackButton = ({ backRef, handleBack, label }: BackButtonProps) => {
  return (
    <Button
      className={styles.backButton}
      data-testid="back-button"
      onClick={handleBack}
      reference={backRef}
      tabIndex={0}
      type="button"
      ariaLabel={label}
    >
      <KeyboardBackspaceIcon className={cx(iconStyles.defaultIconStyle, styles.backIcon)} />
      {label}
    </Button>
  );
};
