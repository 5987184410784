/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { useLabel } from '@/hooks/useLabels';
import CookieButtonStyles from './CookieButton.module.scss';

type Props = {
  isMobile: boolean;
};

const Button = ({ isMobile }: Props) => {
  const lblCookieSettings = useLabel('labels_Cookie_Settings');

  return (
    <button
      id="ot-sdk-btn"
      className={cx(
        CookieButtonStyles.commonCookieBtnStyles,
        {
          [CookieButtonStyles.linkItem]: !isMobile,
          [CookieButtonStyles.labelLinkItem]: isMobile,
        },
        'ot-sdk-show-settings'
      )}
    >
      {lblCookieSettings}
    </button>
  );
};

export default Button;
