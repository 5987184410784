/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const storeModalType = {
  searchStore: 'SEARCH_STORE',
  nearByStore: 'NEARBY_STORE',
  myStore: 'MY_STORE',
};
