/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import root from 'window-or-global';
import { Paper } from '../Paper';
import type { Props as PaperProps } from '../Paper';
import { useEventCallback } from '../../hooks/useEventCallback';
import snackbarStyles from './snackbar.module.scss';

const SnackbarContent = React.forwardRef<HTMLElement, SnackbarContentProps>(
  function SnackbarContent(
    { action, className, message, role = 'alert', ...other }: SnackbarContentProps,
    ref
  ) {
    return (
      <Paper
        role={role}
        square
        elevation={6}
        className={cx(snackbarStyles.contentRoot, className)}
        ref={ref}
        {...other}
      >
        <div className={snackbarStyles.contentMessage}>{message}</div>
        {action ? <div className={snackbarStyles.contentAction}>{action}</div> : null}
      </Paper>
    );
  }
);

export const Snackbar = React.forwardRef<HTMLDivElement, Props>(function Snackbar(
  {
    action,
    anchorOrigin: { vertical, horizontal } = {
      vertical: 'bottom',
      horizontal: 'left',
    },
    anchorClasses,
    autoHideDuration = null,
    children,
    className,
    ContentProps,
    message,
    onClose,
    open,
    ...other
  },
  ref
) {
  const timerAutoHide = React.useRef();
  const handleClose = useEventCallback((...args) => {
    if (onClose) {
      // @ts-expect-error refine type
      onClose(...args);
    }
  });

  const setAutoHideTimer = useEventCallback((autoHideDurationParam) => {
    if (!onClose || autoHideDurationParam == null) {
      return;
    }

    clearTimeout(timerAutoHide.current);
    // @ts-expect-error node vs browser timeout type difference
    timerAutoHide.current = setTimeout(() => {
      handleClose(null, 'timeout');
    }, autoHideDurationParam);
  });

  React.useEffect(() => {
    if (open) {
      setAutoHideTimer(autoHideDuration);
    }

    return () => {
      clearTimeout(timerAutoHide.current);
    };
  }, [open, autoHideDuration, setAutoHideTimer]); // Pause the timer when the user is interacting with the Snackbar
  // or when the user hide the window.

  const handlePause = () => {
    clearTimeout(timerAutoHide.current);
  }; // Restart the timer when the user is no longer interacting with the Snackbar
  // or when the window is shown back.

  const handleResume = React.useCallback(() => {
    if (autoHideDuration != null) {
      setAutoHideTimer(autoHideDuration * 0.5);
    }
  }, [autoHideDuration, setAutoHideTimer]);
  React.useEffect(() => {
    if (open) {
      root.addEventListener('focus', handleResume);
      root.addEventListener('blur', handlePause);
      return () => {
        root.removeEventListener('focus', handleResume);
        root.removeEventListener('blur', handlePause);
      };
    }

    return;
  }, [handleResume, open]); // So we only render active snackbars.

  if (!open) {
    return null;
  }

  return (
    <div
      className={cx(
        snackbarStyles.root,
        {
          [snackbarStyles.anchorOriginTop]: vertical === 'top',
          [snackbarStyles.anchorOriginBottom]:
            vertical === 'bottom' && !anchorClasses?.anchorOriginBottom,
          [`${anchorClasses?.anchorOriginBottom}`]:
            vertical === 'bottom' && anchorClasses?.anchorOriginBottom,
          [snackbarStyles.anchorOriginLeft]: horizontal === 'left',
          [snackbarStyles.anchorOriginRight]: horizontal === 'right',
          [snackbarStyles.anchorOriginCenter]: horizontal === 'center',
        },
        className
      )}
      ref={ref}
      {...other}
    >
      {children || <SnackbarContent message={message} action={action} {...ContentProps} />}
    </div>
  );
}); // Other properties can be added later from MUI library, once they are used

type Props = {
  /**
   * The action to display. It renders after the message, at the end of the snackbar.
   */
  action?: React.ReactNode;

  /**
   * The anchor of the `Snackbar`.
   * On smaller screens, the component grows to occupy all the available width,
   * the horizontal alignment is ignored.
   * @default { vertical: 'bottom', horizontal: 'left' }
   */
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right';
    vertical: 'bottom' | 'top';
  };

  anchorClasses?: {
    anchorOriginBottom?: string;
  };

  /**
   * The number of milliseconds to wait before automatically calling the
   * `onClose` function. `onClose` should then set the state of the `open`
   * prop to hide the Snackbar. This behavior is disabled by default with
   * the `null` value.
   * @default null
   */
  autoHideDuration?: number;

  /**
   * Replace the `SnackbarContent` component.
   */
  children?: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;

  /**
   * Props applied to the [`SnackbarContent`](/api/snackbar-content/) element.
   */
  ContentProps?: PaperProps;

  /**
   * When displaying multiple consecutive Snackbars from a parent rendering a single
   * <Snackbar/>, add the key prop to ensure independent treatment of each message.
   * e.g. <Snackbar key={message} />, otherwise, the message may update-in-place and
   * features such as autoHideDuration may be canceled.
   */
  key?: string;

  /**
   * The message to display.
   */
  message?: React.ReactNode;

  /**
   * Callback fired when the component requests to be closed.
   * Typically `onClose` is used to set state in the parent component,
   * which is used to control the `Snackbar` `open` prop.
   * The `reason` parameter can optionally be used to control the response to `onClose`,
   * for example ignoring `clickaway`.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"timeout"` (`autoHideDuration` expired), `"clickaway"`.
   */
  onClose?: (event: any, reason: string) => void;

  /**
   * If `true`, the component is shown.
   */
  open: boolean;

  /**
   * @ignore
   */
  'data-testid'?: string;
  id?: string;
  role?: string;
};
type SnackbarContentProps = {
  /**
   * The action to display. It renders after the message, at the end of the snackbar.
   */
  action?: React.ReactNode;

  /**
   * The message to display.
   */
  message?: React.ReactNode;
} & PaperProps;
