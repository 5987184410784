/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export enum ActionTypes {
  USER_USER_ID_SET = 'USER_USER_ID_SET',
  USER_REWARDS_ID_SET = 'USER_REWARDS_ID_SET',
  SERVER_PROXIEDHOSTNAME_SET = 'SERVER_PROXIEDHOSTNAME_SET',
  UISTATE_SET = 'UISTATE_SET',
}
