/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { ReduxState } from '@/types';
import { sessionStorage } from '@/utils/sessionStorage';
import { localStorage } from '@/utils/localStorage';

/** Clears state(s), local and session storage items related to the sign-in success snackbar notification and password reset toast notification when a user signs out
 *
 * @param setIsResetPasswordToastNotificationOpen sets this state to false, closing the notification if a user signs out without doing so manually
 */
export function useResetSignInNotifications(
  setIsResetPasswordToastNotificationOpen: (value: boolean) => void
) {
  const appData = useSelector(({ appData }: ReduxState) => appData);
  useEffect(() => {
    if (!appData.userAuthenticated) {
      sessionStorage.removeItem('isNewAccount');
      sessionStorage.removeItem('resetPasswordFrom');
      sessionStorage.removeItem('signInAlertShown');
      sessionStorage.removeItem('signInAlertDisabled');
      localStorage.removeItem('signInModalSelectedTab');
      setIsResetPasswordToastNotificationOpen(false);
    }
  }, [appData.userAuthenticated, setIsResetPasswordToastNotificationOpen]);
}
