/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { serverParamReducer } from './server-params';
import { uiStateReducer } from './ui-state';
import type { AppState, AppStateAction } from '../interface';

type Reducer = (state: AppState, action: AppStateAction) => AppState;

const combineReducers =
  (...reducers: Reducer[]) =>
  (state: AppState, action: AppStateAction) =>
    reducers.reduce((acc, nextReducer) => nextReducer(acc, action), state);

export const rootReducer = combineReducers(serverParamReducer, uiStateReducer);
