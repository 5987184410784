/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const ymmeActionSources = {
  contentBlock: 'YMME',
  vinLookup: 'Enter VIN',
  directURL: 'URL',
  vehicleSearch: 'Vehicle Search',
  storage: 'ymmeActionSource',
} as const;
