/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect } from 'react';
import { trackFooterNavMenu } from '../utils/track';
import { SmartLink as Link } from '@/utils/smartLink';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type Props = {
  CTALink: string;
  name: string;
  className?: string;
  footerSectionName?: '' | 'top' | 'middle' | 'bottom';
};

export function FooterLink({ CTALink, name, className, footerSectionName = '' }: Props) {
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const [shouldFocusTop, setShouldFocusTop] = useState(false);
  useEffect(() => {
    if (shouldFocusTop) {
      const azLogoFixed = document.getElementById('azLogoLinkFixed');
      const azLogoScroll = document.getElementById('azLogoLinkScroll');
      const hamburgerMenuBtn = document.getElementById('menuNavBtnScroll');
      if (matchesTablet) {
        azLogoScroll?.focus();
      }
      if (matchesMobile) {
        hamburgerMenuBtn?.focus();
      } else {
        azLogoFixed?.focus();
      }
      setShouldFocusTop(false);
    }
  }, [shouldFocusTop, matchesMobile, matchesTablet]);

  const handleOnClick = () => {
    setShouldFocusTop(true);
    trackFooterNavMenu(name);
  };

  return (
    <Link
      to={CTALink}
      onClick={handleOnClick}
      data-testid={`footer_${footerSectionName}_link_${name.replace(/\s/g, '-').toLowerCase()}`}
      className={className}
    >
      {name}
    </Link>
  );
}
