/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import environmentConfig from '../config/environment';
import logger from './logger';
import type { LogLevels } from './logger';

export function crashReporter(message: string, type: string, error?: any, logLevel?: LogLevels) {
  const meta = {
    type,
    error: {
      message: error?.message,
    } as { [key: string]: unknown },
    isClient: !__IS_SERVER__,
  };

  if (error?.response) {
    meta.error = {
      ...meta.error,
      response: {
        data: error.response.data,
        status: error.response.status,
        headers: error.response.headers,
      },
      config: error.config,
    };
  } else if (error?.request) {
    meta.error = {
      ...meta.error,
      request: error.request,
      config: error.config,
    };
  } else {
    meta.error = { ...meta.error, stack: error?.stack };
  }

  const samplingRate = environmentConfig.REDUX_LOGGING_SAMPLE_RATE;
  if (logLevel) {
    logger[logLevel]({
      message,
      meta,
    });
  } else if (Math.random() < samplingRate) {
    logger.error({
      message,
      meta,
    });
  } else {
    // eslint-disable-next-line testing-library/no-debugging-utils
    logger.debug({
      message,
      meta,
    });
  }
}
