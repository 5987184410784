/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const STAND_ALONE_USER_TYPE = '4';
const SIGNED_IN_USER_WITH_REWARDS_USER_TYPE = '7';
const SIGNED_IN_MX_USER = '11';

export { STAND_ALONE_USER_TYPE, SIGNED_IN_USER_WITH_REWARDS_USER_TYPE, SIGNED_IN_MX_USER };
