/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useGlobalConfig } from '../api';
import type { GlobalConfig } from '../interface';
import { getFeatureFlag } from '../utils/getFeatureFlag';

export function useFeatureFlag<T extends keyof GlobalConfig>(flag: T): GlobalConfig[T] | undefined {
  const { data: globalConfig } = useGlobalConfig();

  return getFeatureFlag(globalConfig, flag);
}
