/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useSelector } from 'react-redux';

type UseEagerLoadParams = {
  isEagerLoadDesktop?: boolean;
  isEagerLoadMobile?: boolean;
  isEagerLoadTablet?: boolean;
};

export const useEagerLoad = ({
  isEagerLoadDesktop: desktop,
  isEagerLoadMobile: phone,
  isEagerLoadTablet: tablet,
}: UseEagerLoadParams): boolean => {
  const deviceType = useSelector(({ appData }: any) => appData.deviceType);
  const cases = {
    desktop,
    phone,
    tablet,
  };
  // @ts-expect-error refine
  return cases[deviceType] || false;
};
