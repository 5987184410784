/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useContext, useEffect, useState } from 'react';
import root from 'window-or-global';
import actionConstants from '@/types/action';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { LinkRewardsContext } from '@/components/LinkRewardsContext';
import { useValidateRewardsAccountMutation } from '@/features/mergeRewards/api/postValidateRewards';
import { CustomerLookupFields, onlyNumbers, useCustomerLookupMutation } from '@/features/checkout';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { globalConstant } from '@/constants/global';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import dynamic from 'next/dynamic';
import { RewardsMergeConfirmationModalProps } from './RewardsMergeConfirmationModal/RewardsMergeConfirmationModal';
import { analyticsPageNames } from '@/constants/analytics/analyticsPageNames';
import { pageTypes } from '@/constants/page';
import { siteSections } from '@/utils/siteSections';
import { ValidateRewardsAccountResponse } from '../interface';
import { CustomerLookupAnalytics, CustomLinkName } from '@/types/analytics';
import { getErrorMessage } from '@/utils/errorsHandling';
import { AxiosError } from 'axios';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';

const LazySignMeUpFormModal = dynamic(() =>
  import('./sign_me_up_form').then(({ SignMeUpFormModal }) => SignMeUpFormModal)
);

const LazyShowListOfProfiles = dynamic(() =>
  import('./show_list_of_profiles').then(({ ShowListOfProfiles }) => ShowListOfProfiles)
);

const LazyEnterRewardsIdFormModal = dynamic(() =>
  import('./enter_rewards_id_form').then(({ EnterRewardsIdFormModal }) => EnterRewardsIdFormModal)
);

const LazySearchCriteriaFound = dynamic(() =>
  import('./search_criteria_found').then(({ SearchCriteriaFound }) => SearchCriteriaFound)
);

const LazyShowRewardConfirmationCongrats = dynamic(() =>
  import('./show_reward_confirmation_congrats').then(
    ({ ShowRewardConfirmationCongrats }) => ShowRewardConfirmationCongrats
  )
);

const LazyLinkRewardsAccountForm = dynamic(() =>
  import('./link_rewards_account_form').then(({ LinkRewardsAccountForm }) => LinkRewardsAccountForm)
);

const LazyRewardSignInModalV2 = dynamic(() =>
  import('./RewardSigninModal/RewardSignInModalV2').then(
    ({ RewardSignInModalV2 }) => RewardSignInModalV2
  )
);

const LazyLinkRewardsAccountModalV2 = dynamic(() =>
  import('./LinkRewardsAccountModal/LinkRewardsAccountModalV2').then(
    ({ LinkRewardsAccountModalV2 }) => LinkRewardsAccountModalV2
  )
);

const LazyValidateRewardsFormBodyV2 = dynamic(() =>
  import('./ValidateRewardsModal/ValidateRewardsFormBodyV2').then(
    ({ ValidateRewardsFormBodyV2 }) => ValidateRewardsFormBodyV2
  )
);

const LazyRewardsMergeConfirmationModal = dynamic<RewardsMergeConfirmationModalProps>(
  () =>
    import('./RewardsMergeConfirmationModal/RewardsMergeConfirmationModal').then(
      ({ RewardsMergeConfirmationModal }) => RewardsMergeConfirmationModal
    ),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

export function RewardsModalsV2() {
  const {
    setModalReadyStatus,
    modalReadyStatus,
    setOpenModal,
    clearModal,
    phoneNumber,
    email,
    zipcode,
    rewardsNumber,
    setRewardsNumber,
    setPhoneNumber,
    setZipOrEmail,
    setLastName,
    setGlobalError,
    lastName,
    setAnalyticsData,
  } = useContext(LinkRewardsContext);
  const [isRegisterFlow, setIsRegisterFlow] = useState(false);
  const [clickedEnterMyId, setClickedEnterMyId] = useState(false);

  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const modalSize = isTablet ? globalConstant.medium : globalConstant.small;
  const { show } = useContext(LinkRewardsContext);

  const router = useRouter();
  const hideModal = async () => {
    if (router.asPath === routePaths.checkRewardAccount) {
      await router.push(routePaths.blankURL);
    }
    clearModal();
  };

  const getLoyaltyCustLookupType = () => {
    const searchFields = [];
    if (lastName) {
      searchFields.push('Last Name');
    }
    if (phoneNumber) {
      searchFields.push('Phone Number');
    }
    if (zipcode) {
      searchFields.push('Zip');
    }
    if (email) {
      searchFields.push('Email');
    }
    return searchFields.join(' + ');
  };

  const {
    mutate: lookupCustomer,
    reset: resetLookup,
    isSuccess: lookupIsSuccess,
    isLoading: lookupIsLoading,
    data: lookupResponse,
    reset: resetLookUpResponseData,
  } = useCustomerLookupMutation({
    onSuccess: (data) => {
      /**
       * This renders an error box if no rewards id found. However, it is a `200OK` response.
       * Need additional information as to analytics values on `utag.link` call
       */
      const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
      const customerLookupAttempt: CustomLinkName = 'Loyalty customer lookup attempt';
      const lookupResultCount = data.totalCount;
      let lookupResult;
      switch (data.totalCount) {
        case 0:
          lookupResult = 'No Results Found';
          break;
        case 1:
          lookupResult = '1 to 1';
          break;
        default:
          lookupResult = '1 to many';
      }
      if (data.totalCount === 0) {
        setGlobalError('Rewards ID not found');
      }
      /**Start Analytics */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const getAnalyticsData = (
        lookupResult: string,
        lookupResultCount: number | undefined
      ): CustomerLookupAnalytics => ({
        eventType: 'linkRewards',
        pageName,
        pageType,
        siteSection,
        loginFlow: 'Standalone Online',
        loyaltyCustLookup: lookupResult,
        loyaltyCustLookupType: getLoyaltyCustLookupType(),
        customLinkName: `${pageName}:${customerLookupAttempt}`,
        ...(lookupResult === '1 to many' &&
          lookupResultCount && {
            loyaltyCustLookupCount: lookupResultCount.toString(),
          }),
      });

      const analyticsDataValues = getAnalyticsData(lookupResult, lookupResultCount);
      const loyaltyCustLookup = {
        ...analyticsDataValues,
        eventType: 'loyaltyCustLookup',
      };

      clickTrack(loyaltyCustLookup);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setAnalyticsData(analyticsDataValues);

      /**End Analytics */
    },
    onError: (error) => {
      const errorCode = (error as AxiosError<{ errorDetails: Array<{ errorCode: string }> }>)
        .response?.data?.errorDetails?.[0]?.errorCode;
      const errorMessageDefault = (
        error as AxiosError<{ errorDetails: Array<{ errorMessageDefault: string }> }>
      ).response?.data?.errorDetails?.[0]?.errorMessageDefault;

      const errorMsgType = 'Account Linking Error';
      /**Start Analytics */
      clickTrack({
        loginFlow: 'Standalone Online',
        pageName: analyticsPageNames.azHome,
        pageType: pageTypes.home,
        siteSection: siteSections.siteSectionHome,
        customLinkName: 'Account Linking Error',
        errorCode,
        errorMsg: errorMessageDefault,
        errorMsgType,
        eventType: 'linkRewardsFailure',
        loginMethod: 'Email',
        pageURL: router.route,
      });
      /**End Analytics */
      // Additional logic on error
      const errorMsg = getErrorMessage(error);
      setGlobalError(errorMsg);
    },
  });

  if (
    lookupIsSuccess &&
    modalReadyStatus === actionConstants.LINK_REWARDS_ACC_FORM &&
    lookupResponse.totalCount > 0
  ) {
    setModalReadyStatus(actionConstants.SEARCH_CRITERIA_FOUND);
  }

  const validateRewardsAccountMutation = useValidateRewardsAccountMutation({
    onSuccess: (data: ValidateRewardsAccountResponse) => {
      const isValidRewardsNumber = Boolean(data?.azProfileInfo);
      if (isValidRewardsNumber) {
        /**Start Analytics */
        clickTrack({
          loginFlow: 'Standalone Online',
          customLinkName: 'linkRewardsLoginFlow',
          eventType: 'linkRewards',
          pageName: analyticsPageNames.azHome,
          pageType: pageTypes.home,
          siteSection: siteSections.siteSectionHome,
        });
        /**End Analytics */
      }
    },
    onError: (error: unknown) => {
      if (error && typeof error === 'object' && 'response' in error) {
        const errorResponse = error.response as { data?: { errorMessageDefault?: string } };
        setGlobalError(errorResponse.data?.errorMessageDefault || 'An error occurred');
      } else {
        setGlobalError('An unexpected error occurred');
      }
    },
  });

  const {
    status: validateRewardsAccountStatus,
    data: validateRewardsAccountData,
    mutate: validateRewardsAccount,
    isLoading: validateRewardsAccountIsLoading,
  } = validateRewardsAccountMutation;

  const validateRewardsPostObject = {
    rewardsId: rewardsNumber,
  };

  useEffect(() => {
    const isValidRewardsId = Boolean(validateRewardsAccountData?.azProfileInfo);
    if (
      validateRewardsAccountStatus === 'success' &&
      validateRewardsAccountData.profileUpdateRequired
    ) {
      void router.push(routePaths.updateRewardsAccount);
    }

    if (validateRewardsAccountStatus === 'success') {
      const { acountsLinked, validateQuestionsRequired } = validateRewardsAccountData;

      if (acountsLinked) {
        setModalReadyStatus(actionConstants.SHOW_REWARD_CONFIRMATION_CONGRATS);
      }

      if (validateQuestionsRequired) {
        setModalReadyStatus(actionConstants.VALIDATE_REWARD_QUESTIONS);
      }
    }
    if (validateRewardsAccountData && !isValidRewardsId) {
      setGlobalError('Invalid Rewards ID');
    }
  }, [
    router,
    setGlobalError,
    setModalReadyStatus,
    validateRewardsAccountData,
    validateRewardsAccountStatus,
  ]);

  if (!show) {
    return null;
  }

  const onClose = () => {
    resetLookUpResponseData();
    setGlobalError(null);
    setOpenModal(false);
    clearModal();
  };

  const handleYesClick = () => {
    /**Start Analytics */
    clickTrack({
      loginFLow: 'Standalone Online',
      customLinkName: 'Yes, I have rewards',
      pageName: analyticsPageNames.azHome,
      pageType: pageTypes.home,
      siteSection: siteSections.siteSectionHome,
    });
    /**End Analytics */
    resetLookUpResponseData();
    setIsRegisterFlow(false);
    return setModalReadyStatus(actionConstants.LINK_REWARDS_ACC);
  };

  const handleEnterMyIdClick = () => {
    setGlobalError(null);
    setRewardsNumber('');
    setClickedEnterMyId(true);
    return setModalReadyStatus(actionConstants.ENTER_REWARDS_ID_FORM);
  };

  const cleanUpLookupMyIdForm = () => {
    setPhoneNumber('');
    setZipOrEmail('');
    setLastName('');
  };

  const handleLookUpMyIdClick = () => {
    cleanUpLookupMyIdForm();
    setClickedEnterMyId(false);
    return setModalReadyStatus(actionConstants.LINK_REWARDS_ACC_FORM);
  };

  const handleSearchClick = () => {
    const strippedPhoneNumber = onlyNumbers(phoneNumber);
    const searchData: CustomerLookupFields = {
      lastName: lastName,
      telephoneNumber: strippedPhoneNumber,
      emailAddress: email,
      postalCode: zipcode,
    };
    lookupCustomer({ customerData: searchData, maxResult: 5, pageNumber: 1 });
  };

  const handleBackSearchClick = () => {
    resetLookup();
    setGlobalError(null);
    setModalReadyStatus(actionConstants.LINK_REWARDS_ACC_FORM);
  };

  const handleConfirmClick = () => {
    validateRewardsAccount(validateRewardsPostObject);
    setGlobalError(null);
  };

  const handleSecurityQuestionsConfirmClick = () => {
    return setModalReadyStatus(actionConstants.SHOW_REWARD_CONFIRMATION_CONGRATS);
  };

  const handleSignMeUpClick = () => {
    /**Start Analytics */
    clickTrack({
      loginFLow: 'Standalone Online',
      customLinkName: 'No, Sign me up',
      pageName: analyticsPageNames.azHome,
      pageType: pageTypes.home,
      siteSection: siteSections.siteSectionHome,
    });
    /**End Analytics */
    setIsRegisterFlow(true);
    return setModalReadyStatus(actionConstants.SIGN_ME_UP_FORM);
  };

  const getCurrentModal = () => {
    switch (modalReadyStatus) {
      case actionConstants.SHOW_REWARD_SIGNIN:
        return (
          <LazyRewardSignInModalV2
            handleYesClick={handleYesClick}
            handleSignMeUpClick={handleSignMeUpClick}
            hideModal={hideModal}
            onClose={onClose}
          />
        );

      case actionConstants.LINK_REWARDS_ACC:
        return (
          <LazyLinkRewardsAccountModalV2
            handleEnterMyIdClick={handleEnterMyIdClick}
            handleLookUpMyIdClick={handleLookUpMyIdClick}
            hideModal={hideModal}
            onClose={onClose}
          />
        );

      case actionConstants.SIGN_ME_UP_FORM:
        return <LazySignMeUpFormModal onClose={onClose} />;

      case actionConstants.ENTER_REWARDS_ID_FORM:
        return (
          <LazyEnterRewardsIdFormModal
            isBtnLoading={validateRewardsAccountIsLoading}
            handleUseThisAcctClick={handleConfirmClick}
            onClose={onClose}
          />
        );

      case actionConstants.LINK_REWARDS_ACC_FORM:
        return (
          <LazyLinkRewardsAccountForm
            onClose={onClose}
            handleSearchClick={handleSearchClick}
            lookupResponse={lookupResponse}
            isBtnLoading={lookupIsLoading}
            resetLookup={resetLookup}
          />
        );

      case actionConstants.SEARCH_CRITERIA_FOUND:
        return (
          <LazySearchCriteriaFound
            handleBackSearchClick={handleBackSearchClick}
            onClose={onClose}
            lookupResponse={lookupResponse}
          />
        );

      case actionConstants.SHOW_LIST_OF_PROFILES:
        return <LazyShowListOfProfiles lookupResponse={lookupResponse} />;

      case actionConstants.VALIDATE_REWARD_QUESTIONS:
        return (
          <LazyValidateRewardsFormBodyV2
            handleSecurityQuestionsConfirmClick={handleSecurityQuestionsConfirmClick}
            isRegisterFlow={isRegisterFlow}
            clickedEnterMyId={clickedEnterMyId}
            onClose={onClose}
          />
        );

      case actionConstants.SHOW_REWARD_CONFIRMATION_CONGRATS:
        return <LazyShowRewardConfirmationCongrats hideModal={hideModal} onClose={onClose} />;

      case actionConstants.SHOW_REWARD_CONFIRMATION:
        return (
          <LazyRewardsMergeConfirmationModal
            validateRewardsAccountData={validateRewardsAccountMutation.data}
            hideModal={hideModal}
            modalSize={modalSize}
          />
        );
    }
  };

  return <>{getCurrentModal()}</>;
}
