/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { isAxiosError } from './isAxiosError';

export function getErrorCode(error: unknown): string | undefined {
  if (isAxiosError(error)) {
    return error.response?.data.errorCode;
  }
}
