/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import root from 'window-or-global';
import { DEFAULT_PUBLIC_GATEWAY_URL } from './index';

/*
 It returns current environment config.
 On the backend it is taken from environments variables.
 On the frontend it is expected to be assigned to window.config, by a script that fetches it from backend
 On the frontend it is expected to be assigned to window.config, by a jest config
*/

const useConfigFromGlobal = typeof window !== 'undefined' || process.env.NODE_ENV === 'test';

type PublicEnvironmentConfig = {
  DEFAULT_TIMEOUT: number;
  API_TIMEOUT_XS: number;
  API_TIMEOUT_S: number;
  API_TIMEOUT_M: number;
  API_TIMEOUT_XL: number;
  IDLE_SESSION_TIMEOUT: number;
  LOG_LEVEL: string;
  LONG_TIMEOUT: number;
  NODE_ENV: string;
  PAYPAGE_TIMEOUT: number;
  PUBLIC_GATEWAY_URL: string;
  EXTERNAL_GCP_GATEWAY_URL: string;
  REDUX_LOGGING_SAMPLE_RATE: number;
  SHOW_XM_PREVIEW_DATE: boolean;
  CMS_PREVIEW_ENABLED: boolean;
  SCANDIT_LICENSE_KEY: string;
  THREE_SIXTY_IMAGE_BASE_URL: string;
  TARGET_ENV: string;
  METRIX_URL: string;
  CYBERSOURCE_MERCHANT_ID: string;
  CYBERSOURCE_MERCHANT_ID_MX: string;
  CYBERSOURCE_ORG_ID: string;
  WORLD_PAY_PUBLIC_KEY: string;
  LOQATE_KEY: string;
  LOQATE_KEY_MX: string;
  BV_API_KEY: string;
  BV_BASE_URL: string;
  BV_AUTH_CALLBACK_URL: string;
  RECAPTCHA_SITE_KEY: string;
  MERCHANT_PAY_PAGE_ID: string;
  GOOGLE_PAYMENT_GATEWAY: string;
  GOOGLE_PAY_MERCH_ID: string;
  CITRUS_PROXY_DISABLED: boolean;
};

export function getEnvironmentConfig(): PublicEnvironmentConfig {
  return useConfigFromGlobal
    ? ((root.config || global.config) as PublicEnvironmentConfig)
    : {
        CITRUS_PROXY_DISABLED: process.env.CITRUS_PROXY_DISABLED === 'true',
        DEFAULT_TIMEOUT: parseInt(process.env.DEFAULT_TIMEOUT || '') || 10000,
        LONG_TIMEOUT: parseInt(process.env.LONG_TIMEOUT || '') || 20000,
        API_TIMEOUT_XS: parseInt(process.env.API_TIMEOUT_XS || '') || 6000,
        API_TIMEOUT_S: parseInt(process.env.API_TIMEOUT_S || '') || 12000,
        API_TIMEOUT_M: parseInt(process.env.API_TIMEOUT_M || '') || 22000,
        API_TIMEOUT_XL: parseInt(process.env.API_TIMEOUT_XL || '') || 65000,
        LOG_LEVEL: process.env.LOG_LEVEL || 'error',
        NODE_ENV: process.env.NODE_ENV || '',
        PUBLIC_GATEWAY_URL: process.env.PUBLIC_GATEWAY_URL || DEFAULT_PUBLIC_GATEWAY_URL,
        EXTERNAL_GCP_GATEWAY_URL:
          process.env.EXTERNAL_GCP_GATEWAY_URL ||
          process.env.PUBLIC_GATEWAY_URL ||
          DEFAULT_PUBLIC_GATEWAY_URL,
        IDLE_SESSION_TIMEOUT: parseInt(process.env.IDLE_SESSION_TIMEOUT || '') || 1000 * 60 * 30,
        PAYPAGE_TIMEOUT: parseInt(process.env.PAYPAGE_TIMEOUT || '') || 1000 * 60,
        REDUX_LOGGING_SAMPLE_RATE: parseFloat(process.env.REDUX_LOGGING_SAMPLE_RATE || '') || 0.1,
        SHOW_XM_PREVIEW_DATE: process.env.SHOW_XM_PREVIEW_DATE === 'true',
        CMS_PREVIEW_ENABLED: !!process.env.CONTENTSTACK_MANAGEMENT_TOKEN,
        TARGET_ENV: process.env.TARGET_ENV || 'lower-env',
        METRIX_URL: process.env.METRIX_URL || '',
        CYBERSOURCE_MERCHANT_ID: process.env.CYBERSOURCE_MERCHANT_ID || '',
        CYBERSOURCE_MERCHANT_ID_MX: process.env.CYBERSOURCE_MERCHANT_ID_MX || '',
        CYBERSOURCE_ORG_ID: process.env.CYBERSOURCE_ORG_ID || '',
        SCANDIT_LICENSE_KEY: process.env.SCANDIT_LICENSE_KEY || '',
        THREE_SIXTY_IMAGE_BASE_URL: process.env.THREE_SIXTY_IMAGE_BASE_URL || '',
        WORLD_PAY_PUBLIC_KEY: process.env.WORLD_PAY_PUBLIC_KEY || '',
        LOQATE_KEY: process.env.LOQATE_KEY || '',
        LOQATE_KEY_MX: process.env.LOQATE_KEY_MX || '',
        BV_API_KEY: process.env.BV_API_KEY || '',
        BV_BASE_URL: process.env.BV_BASE_URL || '',
        BV_AUTH_CALLBACK_URL: process.env.BV_AUTH_CALLBACK_URL || '',
        RECAPTCHA_SITE_KEY:
          process.env.RECAPTCHA_SITE_KEY || '6LdxfT0pAAAAAMtgYXP1h8mAAO1ms7zEqiFtkpMI',
        MERCHANT_PAY_PAGE_ID: process.env.MERCHANT_PAY_PAGE_ID || 'example',
        GOOGLE_PAYMENT_GATEWAY: process.env.GOOGLE_PAYMENT_GATEWAY || 'exampleGatewayMerchantId',
        GOOGLE_PAY_MERCH_ID: process.env.GOOGLE_PAY_MERCH_ID || '',
      };
}

const environmentConfig: PublicEnvironmentConfig = useConfigFromGlobal
  ? ((root.config || global.config) as PublicEnvironmentConfig)
  : {
      CITRUS_PROXY_DISABLED: process.env.CITRUS_PROXY_DISABLED === 'true',
      DEFAULT_TIMEOUT: parseInt(process.env.DEFAULT_TIMEOUT || '') || 10000,
      LONG_TIMEOUT: parseInt(process.env.LONG_TIMEOUT || '') || 20000,
      API_TIMEOUT_XS: parseInt(process.env.API_TIMEOUT_XS || '') || 6000,
      API_TIMEOUT_S: parseInt(process.env.API_TIMEOUT_S || '') || 12000,
      API_TIMEOUT_M: parseInt(process.env.API_TIMEOUT_M || '') || 22000,
      API_TIMEOUT_XL: parseInt(process.env.API_TIMEOUT_XL || '') || 65000,
      LOG_LEVEL: process.env.LOG_LEVEL || 'error',
      NODE_ENV: process.env.NODE_ENV || '',
      PUBLIC_GATEWAY_URL: process.env.PUBLIC_GATEWAY_URL || DEFAULT_PUBLIC_GATEWAY_URL,
      EXTERNAL_GCP_GATEWAY_URL:
        process.env.EXTERNAL_GCP_GATEWAY_URL ||
        process.env.PUBLIC_GATEWAY_URL ||
        DEFAULT_PUBLIC_GATEWAY_URL,
      IDLE_SESSION_TIMEOUT: parseInt(process.env.IDLE_SESSION_TIMEOUT || '') || 1000 * 60 * 30,
      PAYPAGE_TIMEOUT: parseInt(process.env.PAYPAGE_TIMEOUT || '') || 1000 * 60,
      REDUX_LOGGING_SAMPLE_RATE: parseFloat(process.env.REDUX_LOGGING_SAMPLE_RATE || '') || 0.1,
      SHOW_XM_PREVIEW_DATE: process.env.SHOW_XM_PREVIEW_DATE === 'true',
      CMS_PREVIEW_ENABLED: !!process.env.CONTENTSTACK_MANAGEMENT_TOKEN,
      TARGET_ENV: process.env.TARGET_ENV || 'lower-env',
      METRIX_URL: process.env.METRIX_URL || '',
      CYBERSOURCE_MERCHANT_ID: process.env.CYBERSOURCE_MERCHANT_ID || '',
      CYBERSOURCE_MERCHANT_ID_MX: process.env.CYBERSOURCE_MERCHANT_ID_MX || '',
      CYBERSOURCE_ORG_ID: process.env.CYBERSOURCE_ORG_ID || '',
      SCANDIT_LICENSE_KEY: process.env.SCANDIT_LICENSE_KEY || '',
      THREE_SIXTY_IMAGE_BASE_URL: process.env.THREE_SIXTY_IMAGE_BASE_URL || '',
      WORLD_PAY_PUBLIC_KEY: process.env.WORLD_PAY_PUBLIC_KEY || '',
      LOQATE_KEY: process.env.LOQATE_KEY || '',
      LOQATE_KEY_MX: process.env.LOQATE_KEY_MX || '',
      BV_API_KEY: process.env.BV_API_KEY || '',
      BV_BASE_URL: process.env.BV_BASE_URL || '',
      BV_AUTH_CALLBACK_URL: process.env.BV_AUTH_CALLBACK_URL || '',
      RECAPTCHA_SITE_KEY:
        process.env.RECAPTCHA_SITE_KEY || '6LdxfT0pAAAAAMtgYXP1h8mAAO1ms7zEqiFtkpMI',
      MERCHANT_PAY_PAGE_ID: process.env.MERCHANT_PAY_PAGE_ID || 'example',
      GOOGLE_PAYMENT_GATEWAY: process.env.GOOGLE_PAYMENT_GATEWAY || 'exampleGatewayMerchantId',
      GOOGLE_PAY_MERCH_ID: process.env.GOOGLE_PAY_MERCH_ID || '',
    };

export default environmentConfig;
