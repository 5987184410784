/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import styles from './Skeleton.module.scss';

type Props = {
  /**
   * The animation.
   * If `false` the animation effect is disabled.
   */
  animation?: 'pulse' | 'wave' | false;

  /**
   * @ignore
   */
  className?: string;

  /**
   * Height of the skeleton.
   * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
   */
  height?: number | string;

  /**
   * The type of content that will be rendered.
   */
  variant?: 'text' | 'rect';

  /**
   * Width of the skeleton.
   * Useful when the skeleton is inside an inline element with no width of its own.
   */
  width?: number | string;
  'data-testid'?: string;
};

function Skeleton({
  animation = 'pulse',
  className,
  height,
  variant = 'text',
  width,
  'data-testid': dataTestId,
}: Props) {
  return (
    <span
      data-testid={dataTestId}
      className={cx(
        styles.root,
        styles[variant],
        animation !== false ? styles[animation] : null,
        className
      )}
      style={{
        width,
        height,
      }}
    />
  );
}

export default Skeleton;
