/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import root from 'window-or-global';
import { PersistentStorage } from './PersistentStorage';
import type { Storage } from './PersistentStorage';

type LocalStorageKey =
  | 'azCartOrderId'
  | 'azCheckoutSubscriptions'
  | 'customLinkNameLoginMethod'
  | 'disableProactiveChatExpiry'
  | 'email'
  | 'forgotPasswordFlowCompleted'
  | 'fulfillmentOptionsGroupList'
  | 'hideSmartBanner'
  | 'interactionLocation'
  | 'loggedInFrom'
  | 'loggedInFromEmptyCart'
  | 'loggedInFromMerge'
  | 'loyaltyBenefitEarned'
  | 'rememberMeChecked'
  | 'rewardsAccountNo'
  | 'rewardsIdEnteredForCommit'
  | 'rewardsLinkingId'
  | 'signInModalSelectedTab'
  | 'shouldDisplayLoginMsgOnReload'
  | 'username'
  | 'vehicles'
  | 'trackedOrderEmail'
  | 'serviceHistoryItem'
  | 'ymmeActionSource'
  | 'recentSearches'
  | 'reviewSubmissionId'
  | 'reviewFeedback'
  | 'delaySignInSnackBar'
  | 'google.parameters.gateway'
  | 'google.parameters.gatewayMerchantId'
  | 'google.merchantInfo.merchantId'
  | 'google.merchantInfo.merchantName'
  | 'google.environment'
  | 'google.parameters.allowedAuthMethods'
  | 'savedForLaterItem'
  | 'isSDDOrder'
  | 'sflLoginNotify'
  | 'canShowToastNotification'
  | 'hideModal';

export const localStorage: Readonly<Storage<LocalStorageKey>> =
  new PersistentStorage<LocalStorageKey>(root.localStorage);
