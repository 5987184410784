/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getBaseSchemaUrl } from '../utils/common';
import { countryCodes } from '@/constants/locale';
import type { Locale } from '@/types/i18n';

export default {
  global: {
    apiDomainUrl: 'https://www.autozone.com',
  },
  app: {
    mobileMeta: {
      meta: [
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'format-detection',
          content: 'telephone:yes',
        },
        {
          name: 'HandheldFriendly',
          content: 'True',
        },
      ],
      scriptUS: [
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "AutoZone (AZO)",
            "url": "${getBaseSchemaUrl(countryCodes.us as Locale)}",
            "logo": "https://www.autozone.com/images/az-logo-full.svg",
            "contactPoint" : [
              {
                "@type" : "ContactPoint",
                "telephone" : "+1-800-288-6966",
                "contactType" : ["Sales","Customer Service"],
                "contactOption" : "TollFree",
                "areaServed" : "US",
                "availableLanguage" : ["English", "Spanish"]
              }
            ],
            "sameAs" : [
              "https://en.wikipedia.org/wiki/AutoZone",
              "https://www.facebook.com/autozone",
              "https://twitter.com/autozone",
              "https://www.youtube.com/user/AutoZone"
            ]
          }`,
        },
      ],
      scriptMX: [
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org",
          "@type": "Organization",
          "name": "AutoZone Mexico",
          "url": "${getBaseSchemaUrl(countryCodes.mx as Locale)}",
          "logo": "https://www.autozone.com.mx/images/logoautozonemx.svg"
          }`,
        },
      ],
    },
    orderHistory: {
      meta: [
        {
          name: 'description',
          content: 'Order History',
        },
      ],
    },
    orderConfirmation: {
      title: 'Order Confirmation ',
      titleTemplate: 'AutoZone.com | %s',
      meta: [
        {
          name: 'description',
          content: 'Order Confirmation',
        },
      ],
    },
    forgotPassword: {
      title: 'Forgot Password ',
      titleTemplate: 'AutoZone.com | %s',
      meta: [
        {
          name: 'description',
          content: 'Forgot Password',
        },
      ],
    },
    signin: {
      title: 'AutoZone MyZone ',
      titleTemplate: 'AutoZone.com | %s',
      meta: [
        {
          name: 'description',
          content: 'Sign In',
        },
      ],
    },
    accountPage: {
      title: 'AutoZone Promotions Center ',
    },
    vinDecoder: {
      title: 'Vin Decoder',
      titleTemplate: 'AutoZone.com | %s',
    },
  },
};
