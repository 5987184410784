/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const orderTypes = {
  storeOrder: 'STOREORDER',
  onlineOrder: 'ONLINEORDER',
  onlineStoreOrder: 'STOREORDER',
  inStore: 'InStore',
  inStorePurchase: 'INSTOREORDER',
  inStorePurchasePascalCase: 'InStore',
  order: 'order',
  returns: 'RETURN',
  adjustment: 'Adjustment',
} as const;

export const validOrderTypes = {
  ONLINEORDER: true,
  STOREORDER: true,
  order: true,
} as const;

export type OrderType = (typeof orderTypes)[keyof typeof orderTypes];
