/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import { pascalCase } from '../../utils/pascalCase';
import styles from './IconButton.module.scss';

type Props = React.HTMLAttributes<HTMLElement> & {
  /**
   * The icon element.
   */
  children?: React.ReactNode;
  /**
   * @ignore
   */
  className?: string;
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  /**
   * If `true`, the button will be disabled.
   */
  disabled?: boolean;
  /**
   * If given, uses a negative margin to counteract the padding on one
   * side (this is often helpful for aligning the left or right
   * side of the icon with content above or below, without ruining the border
   * size and shape).
   */
  edge?: 'start' | 'end' | false;
  /**
   * For screen readers
   */
  ariaLabel?: string;
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size?: 'small' | 'medium';
  component?: React.ElementType;
};

/**
 * Refer to the [Icons](/components/icons/) section of the documentation
 * regarding the available icon options.
 */
export const IconButton = React.forwardRef<HTMLElement, Props>(function IconButton(props, ref) {
  const {
    edge = false,
    children,
    className,
    color = 'default',
    disabled = false,
    ariaLabel,
    size = 'medium',
    ...other
  } = props;
  return (
    <ButtonBase
      data-testid="icon-button"
      className={cx(
        styles.root,
        {
          [styles[`color${pascalCase(color)}`]]: color !== 'default',
          [styles.disabled]: disabled,
          [styles[`size${pascalCase(size)}`]]: size !== 'medium',
          [styles.edgeStart]: edge === 'start',
          [styles.edgeEnd]: edge === 'end',
        },
        className
      )}
      disabled={disabled}
      ariaLabel={ariaLabel}
      ref={ref}
      {...other}
    >
      <span className={styles.label}>{children}</span>
    </ButtonBase>
  );
});
