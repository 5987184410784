/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import styles from './BrazilFooter.module.scss';
import Head from 'next/head';
import { SmartLink as Link } from '@/utils/smartLink';
import hashes from '@/tools/publicCSSHashes.json';
import NextImage from '@/components/NextImage';

export function BrazilFooter() {
  return (
    <>
      <Head>
        <link rel="stylesheet" href={`/css/brazil_legacy.css?v=${hashes.brazilLegacy}`} />
      </Head>
      <footer id="main-footer" className={styles.footerContainer}>
        <div id="main-footer-bg" className="row-fluid">
          <div className="span-24 flush" id="footer-top">
            <NextImage
              alt="AutoZone Logo"
              src="/images/br/azLogo_color_sm.png"
              width={163}
              height={24}
            />
          </div>
          <div className={cx('span-6 flush-left', styles.footerListContainerFirst)}>
            <h2>Lojas AutoZone</h2>
            <ul>
              <li>
                <a href="https://www.autozone.com.br/nossas-lojas/">Informações Das Lojas</a>
              </li>
            </ul>
          </div>
          <div className={cx('span-6', styles.footerListContainer)}>
            <h2>Informações Corporativas</h2>
            <ul>
              <li>
                <Link to="/lp/nossahistoria">Nossa História</Link>
              </li>
            </ul>
          </div>
          <div className={cx('span-8', styles.footerListContainer)}>
            <h2>Outros Sites</h2>
            <ul>
              <li>
                <a href="https://www.autozone.com">AutoZone.com</a>
              </li>
              <li>
                <a href="https://www.alldata.com">ALLDATA</a>
              </li>
              <li>
                <a href="https://www.alldatadiy.com">ALLDATAdiy</a>
              </li>
              <li>
                <a href="https://www.autozone.com.mx">México</a>
              </li>
            </ul>
          </div>
          <div className={cx('span-4', styles.footerListContainer)}>
            <h2>Redes Sociais:</h2>
            <ul className="socialMedia clearfix">
              <li className="facebook">
                <a href="https://www.facebook.com/AutoZoneBrasil/" title="Facebook">
                  Facebook
                </a>
              </li>
              <li className="instagram">
                <a href="https://www.instagram.com/autozonebrasil/" title="Instagram">
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className="span-24">
            <NextImage
              src="/images/br/az-logo-footer.gif"
              alt="AutoZone.com"
              className="left"
              width={156}
              height={19}
            />
            <ul className="inline legal">
              <li>
                <Link to="/lp/informacoeslegais">
                  Termos e Condições, e Política de Privacidade
                </Link>
              </li>
            </ul>
            <p className="copyright">
              © 2012-<span id="currentyear">2023</span> AutoZone do Brasil. Todos os direitos
              reservados.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
