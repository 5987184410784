/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import axios, { type AxiosInstance } from 'axios';
import { getSalesChannel } from '@/utils/common';

const axiosClient = axios.create({
  headers: {
    'sales-channel': getSalesChannel(),
    'X-Requested-By': 'MF',
    post: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  },
  withCredentials: true,
});

export const getAxios = (axiosInstance: AxiosInstance | undefined) => {
  return axiosInstance ? axiosInstance : axiosClient;
};

export const getAxiosForClient = () => {
  if (__IS_SERVER__) {
    throw new Error('Cannot use getAxiosForClient on the server');
  }

  return axiosClient;
};
