/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { GlobalConfig } from '../interface';

export function getFeatureFlag<T extends keyof GlobalConfig>(
  globalConfig: GlobalConfig | undefined,
  flag: T
): GlobalConfig[T] | undefined {
  if (globalConfig) {
    return globalConfig[flag];
  }

  return undefined;
}
