/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Ported from @material-ui/core@4.11.3
// A grid component using the following libs as inspiration.
//
// For the implementation:
// - https://getbootstrap.com/docs/4.3/layout/grid/
// - https://github.com/kristoferjoseph/flexboxgrid/blob/master/src/css/flexboxgrid.css
// - https://github.com/roylee0704/react-flexbox-grid
// - https://material.angularjs.org/latest/layout/introduction
//
// Follow this flexbox Guide to better understand the underlying model:
// - https://css-tricks.com/snippets/css/a-guide-to-flexbox/
import * as React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

// Default CSS values
// flex: '0 1 auto',
// flexDirection: 'row',
// alignItems: 'flex-start',
// flexWrap: 'nowrap',
// justifyContent: 'flex-start',

export type GridItemsAlignment = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';

export type GridContentAlignment =
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around';

export type GridDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

export type GridSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type GridJustification =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
type GridWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number;

export type Props = {
  /**
   * Defines the `align-content` style property.
   * It's applied for all screen sizes.
   */
  alignContent?: GridContentAlignment;
  /**
   * Defines the `align-items` style property.
   * It's applied for all screen sizes.
   */
  alignItems?: GridItemsAlignment;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * @ignore
   */
  className?: string;
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: React.ElementType;
  /**
   * If `true`, the component will have the flex *container* behavior.
   * You should be wrapping *items* with a *container*.
   */
  container?: boolean;
  /**
   * Defines the `flex-direction` style property.
   * It is applied for all screen sizes.
   */
  direction?: GridDirection;
  /**
   * If `true`, the component will have the flex *item* behavior.
   * You should be wrapping *items* with a *container*.
   */
  item?: boolean;
  /**
   * Defines the `justify-content` style property.
   * It is applied for all screen sizes.
   */
  justify?: GridJustification;
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `lg` breakpoint and wider screens if not overridden.
   */
  lg?: GridSize | boolean;

  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `md` breakpoint and wider screens if not overridden.
   */
  md?: GridSize | boolean;
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `sm` breakpoint and wider screens if not overridden.
   */
  sm?: GridSize | boolean;
  /**
   * Defines the space between the type `item` component.
   * It can only be used on a type `container` component.
   */
  spacing?: GridSpacing;
  /**
   * Defines the `flex-wrap` style property.
   * It's applied for all screen sizes.
   */
  wrap?: GridWrap;
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `xl` breakpoint and wider screens.
   */
  xl?: GridSize | boolean;
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for all the screen sizes with the lowest priority.
   */
  xs?: GridSize | boolean;
  /**
   * onMouseEnter/onMouseLeave setting child image by event rather than relying on event + css.
   */
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  /**
   * If `true`, it sets `min-width: 0` on the item.
   * Refer to the limitations section of the documentation to better understand the use case.
   */
  zeroMinWidth?: boolean;
  /**
   * If `true`, it sets HTMLtag to 'li'.
   */
  isListItem?: boolean;
  /**
   * If `true`, it sets HTMLtag to 'ul'.
   */
  isULTag?: boolean;
  id?: string;
  'data-testid'?: string;
  'data-test'?: string;
  style?: React.HTMLAttributes<HTMLDivElement>['style'];
  role?: string;
  dangerouslySetInnerHTML?: {
    __html: string;
  }; // TODO: remove this prop
};

export const Grid = React.forwardRef<HTMLElement, Props>(function Grid(
  {
    alignContent = 'stretch',
    alignItems = 'stretch',
    className: classNameProp,
    isListItem,
    isULTag,
    component: Component = isListItem ? 'li' : isULTag ? 'ul' : 'div',
    container = false,
    direction = 'row',
    item = false,
    justify = 'flex-start',
    lg = false,
    md = false,
    sm = false,
    spacing = 2,
    wrap = 'wrap',
    xl = false,
    xs = false,
    zeroMinWidth = false,
    ...other
  },
  ref
) {
  const className = cx(
    {
      [styles.container]: container,
      [styles.item]: item,
      [styles.zeroMinWidth]: zeroMinWidth,
      [styles[`spacing-xs-${String(spacing)}`]]: container && spacing !== 0,
      [styles[`direction-xs-${String(direction)}`]]: direction !== 'row',
      [styles[`wrap-xs-${String(wrap)}`]]: wrap !== 'wrap',
      [styles[`align-items-xs-${String(alignItems)}`]]: alignItems !== 'stretch',
      [styles[`align-content-xs-${String(alignContent)}`]]: alignContent !== 'stretch',
      [styles[`justify-xs-${String(justify)}`]]: justify !== 'flex-start',
      [styles[`grid-xs-${String(xs)}`]]: xs !== false,
      [styles[`grid-sm-${String(sm)}`]]: sm !== false,
      [styles[`grid-md-${String(md)}`]]: md !== false,
      [styles[`grid-lg-${String(lg)}`]]: lg !== false,
      [styles[`grid-xl-${String(xl)}`]]: xl !== false,
    },
    classNameProp
  );

  return <Component className={className} ref={ref} {...other} />;
});
