/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { serializeError } from 'serialize-error';
import axios from 'axios';
import { loggerURL } from '../config/serviceAPI';
import { isBackend } from '../constants/isBackend';
import environmentConfig from '../config/environment';

const nodeEnv = environmentConfig.NODE_ENV || 'development';
const isDev = nodeEnv === 'development';

const LOG_LEVELS = {
  error: 0,
  warn: 1,
  info: 2,
  debug: 3,
  verbose: 4,
  silly: 5,
};

export type LogLevels = 'error' | 'warn' | 'info' | 'verbose' | 'debug' | 'silly';

export type LogObject = {
  level?: LogLevels;
  meta?: any;
  message: string;
};

let winston;
let winstonConfig: {
  exitOnError: boolean;
  transports: Array<any>;
};

if (isBackend) {
  const { transports, createLogger } = require('winston');

  winstonConfig = {
    transports: [
      new transports.Console({
        level: environmentConfig.LOG_LEVEL,
      }),
    ],
    exitOnError: false,
  };
  winston = createLogger(winstonConfig);
}

const webLogging = {
  log: async (payload: unknown) => {
    try {
      const res = await axios.post(loggerURL, payload);
      if (res.status !== 200) {
        throw new Error();
      }
    } catch (e) {
      // Specifically enabling logging to the console for this case, since this means the log would be lost otherwise
      // eslint-disable-next-line no-console
      console.log('Failed to remotely log message with ' + String(e));
    }
  },
};

const logSink = isBackend ? winston : webLogging;

const log = (payload: LogObject) => {
  if (payload.level === undefined) {
    payload.level = 'info';
  }

  if (
    // @ts-expect-error implicit any because environmentConfig is not TS yet
    LOG_LEVELS[payload.level] <= LOG_LEVELS[environmentConfig.LOG_LEVEL] &&
    logSink
  ) {
    let { meta } = payload;

    if (meta?.error) {
      meta = {
        ...meta,
        error: serializeError(payload.meta.error),
      };
    }

    const serializablePayload = { ...payload, meta, level: payload.level };
    logSink.log(serializablePayload);

    if (isDev) {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(serializablePayload));
    }
  }
};

const createLogFn = (level: LogLevels) => (payload: LogObject) => log({ ...payload, level });

const logger = {
  log,
  error: createLogFn('error') as (payload: LogObject) => void,
  warn: createLogFn('warn') as (payload: LogObject) => void,
  info: createLogFn('info') as (payload: LogObject) => void,
  verbose: createLogFn('verbose') as (payload: LogObject) => void,
  debug: createLogFn('debug') as (payload: LogObject) => void,
  silly: createLogFn('silly') as (payload: LogObject) => void,
};

process.on('unhandledException', (err) => {
  logger.error({
    message: 'Server encountered an unhandled exception',
    meta: serializeError(err),
  });
});

export default logger;
