/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useFeatureFlag } from '@/features/globalConfig';
import { setChatAgentStatus } from '@/actions/app';
import { ReduxState } from '@/types';
import { routePaths } from '@/constants/routePaths';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { API_URLS } from '@/constants/apiUrls';

/**
 * Utility hook that takes care of the following if GENESYS_AVAILABILITY_CALL_ENABLED === 'true'
 *  - Calls /integrations/genesys/v1/StatLookup/stats/CurrentGroupAgentTargetState on route change
 *  - Calls availability check every $GENESYS_CALL_FREQUENCY_IN_SECS for $GENESYS_CALL_DURATION_IN_SECS
 */
export function useChatAgentAvailabilityCheck() {
  const dispatch = useDispatch();
  const router = useRouter();
  const deviceType = useSelector(({ appData }: ReduxState) => appData.deviceType);

  const isABot = deviceType === 'bot';
  const chatMobileEnabled = useFeatureFlag('CHAT_ON_MOBILE_ENABLED') === 'true';
  const chatAvailabilityCallEnabled =
    useFeatureFlag('GENESYS_AVAILABILITY_CALL_ENABLED') === 'true' && !isABot;
  const checkChatAvailibilityFrequency = parseInt(
    useFeatureFlag('GENESYS_CALL_FREQUENCY_IN_SECS') || '30'
  );
  const checkChatAvailibilityDuration = parseInt(
    useFeatureFlag('GENESYS_CALL_DURATION_IN_SECS') || '180'
  );
  const isNewChatVendorEnabled = useFeatureFlag('ENABLE_NEW_CHAT_VENDOR') === 'true';

  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const isV2ChatAgentAvailable = useFeatureFlag('CHECK_AGENT_AVAILABILITY_V2') === 'true';

  const { NEW_CHAT_AVAILABLE_ENDPOINT_FALLBACK } = API_URLS;

  const NEW_CHAT_AVAILABLE_ENDPOINT =
    useFeatureFlag('NEW_CHAT_AVAILABLE_ENDPOINT') ?? NEW_CHAT_AVAILABLE_ENDPOINT_FALLBACK;

  useEffect(() => {
    if (chatAvailabilityCallEnabled && !isV2ChatAgentAvailable) {
      dispatch(setChatAgentStatus({ isNewChatVendorEnabled, NEW_CHAT_AVAILABLE_ENDPOINT }));
    }
  }, [
    dispatch,
    chatAvailabilityCallEnabled,
    router.asPath,
    isV2ChatAgentAvailable,
    isNewChatVendorEnabled,
    NEW_CHAT_AVAILABLE_ENDPOINT,
  ]);

  useEffect(() => {
    if (chatAvailabilityCallEnabled && !isV2ChatAgentAvailable) {
      if (!chatMobileEnabled && matchesMobile) {
        return;
      }
      if (router.pathname === routePaths.circular) {
        dispatch(setChatAgentStatus({ isNewChatVendorEnabled, NEW_CHAT_AVAILABLE_ENDPOINT }));
      } else {
        /** Check for agent availability every x secs,
         * multiply * 1000 to get the number in milliseconds */
        const freqInterval = setInterval(() => {
          dispatch(setChatAgentStatus({ isNewChatVendorEnabled, NEW_CHAT_AVAILABLE_ENDPOINT }));
        }, checkChatAvailibilityFrequency * 1000);
        /** Maximum allowed time to check
         * agent availability every x total secs,
         * multiply * 1000 to get the number in milliseconds */

        const timeout = setTimeout(
          () => clearInterval(freqInterval),
          checkChatAvailibilityDuration * 1000
        );
        return () => {
          clearInterval(freqInterval);
          clearInterval(timeout);
        };
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matchesMobile,
    checkChatAvailibilityFrequency,
    checkChatAvailibilityDuration,
    router.asPath,
    chatAvailabilityCallEnabled,
    dispatch,
  ]);
}
