/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import styles from './Toolbar.module.scss';

type Props = {
  /**
   * Toolbar children, usually a mixture of `IconButton`, `Button` and `Typography`.
   */
  children?: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: string;
};

export const Toolbar = React.forwardRef<HTMLElement, Props>(function Toolbar(props, ref) {
  const { className, component: Component = 'div', ...other } = props;
  return (
    <Component
      // @ts-expect-error
      className={cx(styles.root, styles.gutters, className)}
      ref={ref}
      {...other}
    />
  );
});
