/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  color?: string;
  size?: number;
};

export const LoadingIndicatorDots = ({ color, size = 16 }: Props) => {
  const dotStyle = {
    width: size,
    height: size,
    borderRadius: size / 2,
    marginleft: size / 5,
    marginRight: size / 5,
    backgroundColor: color ? color : '#1f7400',
  };

  return (
    <div className={styles.container}>
      <span style={dotStyle} />
      <span style={dotStyle} />
      <span style={dotStyle} />
    </div>
  );
};
