/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { analyticsDataLayerVariable } from '../analyticsDataLayerVariable';
import { getDecodedCookies } from '@/utils/cookie';

export function analyticsGlobalPreferredVehicle() {
  const cookies = getDecodedCookies([
    'preferredVehicleYear',
    'preferredVehicleMake',
    'preferredVehicleModel',
    'preferredVehicleEngine',
    'preferredVehicleId',
    'userVehicleCount',
  ]);

  analyticsDataLayerVariable({
    preferredVehicleYear: cookies.get('preferredVehicleYear'),
    preferredVehicleMake: cookies.get('preferredVehicleMake'),
    preferredVehicleModel: cookies.get('preferredVehicleModel'),
    preferredVehicleEngine: cookies.get('preferredVehicleEngine'),
    preferredVehicleId: cookies.get('preferredVehicleId'),
    userVehicleCount: cookies.get('userVehicleCount') || '0',
  });
}
