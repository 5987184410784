/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import type { ContentStackLink } from '@/features/contentstack/interface';
import { SmartLink } from '@/utils/smartLink';
import { View, Text } from '@az/starc-ui';
import styles from './NavItem.module.scss';

type Props = {
  close: (event: React.MouseEvent<HTMLElement>) => void;
  isNested?: boolean;
  link: ContentStackLink;
  target?: '_self' | '_blank';
};

const NavItem = ({ close, isNested, link, target = '_self' }: Props) => {
  return (
    <View.Item>
      <SmartLink
        to={link.href}
        className={cx(styles.navLinkContainer, isNested && styles.navLinkContainerNested)}
        onClick={close}
        target={target}
      >
        <Text variant="small-body" size="100" color="600">
          {link.title}
        </Text>
      </SmartLink>
    </View.Item>
  );
};

export { NavItem };
