/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// eslint-disable-next-line no-restricted-imports
import { useDispatch as defaultUseDispatch } from 'react-redux';
import type { Store } from '@/types';

export const useDispatch: () => Store['dispatch'] = defaultUseDispatch;
