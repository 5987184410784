/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { countryCodes } from '@/constants/locale';
import type { Locale } from '@/types/i18n';

export const getCountryFromLocale = (locale: Locale) => {
  switch (locale) {
    case countryCodes.us:
      return 'USA';
    case countryCodes.mx:
      return 'MEX';
    case countryCodes.ptBr:
      return 'BRA';
    default:
      return 'USA';
  }
};
