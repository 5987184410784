/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const vehicleSelectedIconSource = {
  mobile: '/images/icon-vehicle-selected-m.svg',
  tablet: '/images/icon-vehicle-selected-t.svg',
  desktop: '/images/icon-vehicle-selected-d.svg',
};
