/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxiosForClient } from '@/lib/axios';
import { useMutation } from '@tanstack/react-query';
import { RewardsAcknowledgeResponse, PostRewardsAcknowlegeRequest } from '../interface';
import type { AxiosResponse } from 'axios';

const URL = `${requestBaseURL}/ecomm/b2c/v1/currentUser/rewards/acknowledge`;

type RewardsAcknowledgeProps = {
  onSuccess?: (data: RewardsAcknowledgeResponse) => void;
  onError?: (error: unknown, values: PostRewardsAcknowlegeRequest) => void;
  onMutate?: (values: PostRewardsAcknowlegeRequest) => void;
};

const selector = (
  response: AxiosResponse<RewardsAcknowledgeResponse>
): RewardsAcknowledgeResponse => response.data;

const postRewardsAcknowledgement = async (values: PostRewardsAcknowlegeRequest) => {
  const response = await getAxiosForClient().post<RewardsAcknowledgeResponse>(URL, {
    ...values,
  });

  return selector(response);
};

export const usePostAcknowldegeMutation = ({
  onSuccess,
  onError,
  onMutate,
}: RewardsAcknowledgeProps) => {
  return useMutation({
    mutationFn: (requestProps: PostRewardsAcknowlegeRequest) =>
      postRewardsAcknowledgement(requestProps),
    onSuccess: (data) => onSuccess?.(data),
    onError: (error: unknown, values: PostRewardsAcknowlegeRequest) => onError?.(error, values),
    onMutate: (data) => onMutate?.(data),
  });
};
