/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type {
  ContentStackNavigationMenu,
  FormattedContentStackTopNav,
} from '@/features/contentstack/interface';
import { getAxiosForClient } from '@/lib/axios';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

const URL = `/api/contentstack/top-nav`;

const getCMSTopNav = async () => {
  const response = await getAxiosForClient().get<FormattedContentStackTopNav>(URL, {});
  return selector(response.data);
};

const selector = (data: FormattedContentStackTopNav) => data;

export const getCMSTopNavKey = () => ['cms', 'top-nav'] as const;

export const useCMSTopNavContent = (
  options: Omit<
    UseQueryOptions<
      ContentStackNavigationMenu,
      unknown,
      ContentStackNavigationMenu,
      ReturnType<typeof getCMSTopNavKey>
    >,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryKey: getCMSTopNavKey(),
    queryFn: getCMSTopNav,
    enabled: options.enabled ?? true,
  });
};
