/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useCallback, useMemo, useContext, useRef } from 'react';
import root from 'window-or-global';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Backdrop } from '@/components/Backdrop/Backdrop';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { eventConstants } from '@/constants/event';
import { globalConstant } from '@/constants/global';
import { countryCodes } from '@/constants/locale';
import { routePaths } from '@/constants/routePaths';
import { ONLYSPACES } from '@/utils/validatorRegex';
import { useRemoveUnsupportedSearchChars } from '@/hooks/useRemoveUnsupportedSearchChars';
import type { ReduxState } from '@/types';
import { useRouter } from 'next/router';
import styles from './SearchBox.module.scss';
import { Button } from '@/components/Button';
import { useSuggestions } from '@/features/search/api/getSuggestions';
import CloseIcon from '@/public/images/close.svg';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { setCookie } from '@/utils/cookie';
import { cookieConstant } from '@/constants/cookies';
import { SearchBoxContext } from '@/components/SearchBoxContext';
import SearchDrawer from '@/components/AZCustomComponent/Drawer/SearchDrawer';
import { analyticsCookie } from '@/constants/analytics/analyticsCookie';
import { analyticsDataLayerVariable } from '@/utils/analytics/analyticsDataLayerVariable';
import { analyticsGlobalVariables } from '@/utils/analytics/dataLayer/analyticsGlobalVariables';
import useDebounce from '@/utils/debounce';
import { useFeatureFlag } from '@/features/globalConfig';
import Suggestions from './Suggestions';
import { sessionStorage } from '@/utils/sessionStorage';
import RecentSearches from '../../../components/RecentSearches';
import { useRecentSearches } from '@/hooks/useRecentSearches';
import { useGlobalState } from '@/hooks/useGlobalState';

const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export type SearchBoxProps = {
  scrolled: boolean;
  programaticallyScrolled: boolean;
  setProgramaticallyScrolled: (scrolled: boolean) => void;
  isFocused: boolean;
  setIsFocused: (focused: boolean) => void;
  showMobileDrawer: (drawerShown: boolean) => void;
  searchText: string;
  onSearchTextChange: (searchText: string) => void;
  suggestionText: string;
  searchInputDataTestId?: string;
  magniSearchBtnDataTestId: string;
  magniSearchBtnDataTestId2: string;
  onSuggestionTextChange: (suggestionText: string) => void;
  isFixedHeader?: boolean;
};

const labelMap = {
  searchPartsAndProducts: 'label_FindPartsAndProducts',
  searchResultsSearch: 'label_Search_Results_Search',
  searchResultsClear: 'label_Search_Results_Clear',
} as const;

const SearchBoxComponent = ({
  scrolled,
  setProgramaticallyScrolled,
  programaticallyScrolled,
  isFocused,
  setIsFocused,
  showMobileDrawer,
  searchText,
  onSearchTextChange,
  suggestionText,
  searchInputDataTestId,
  magniSearchBtnDataTestId,
  magniSearchBtnDataTestId2,
  onSuggestionTextChange,
  isFixedHeader,
}: SearchBoxProps) => {
  const { setSearchBoxOpen } = useContext(SearchBoxContext);
  const appData = useSelector(({ appData }: ReduxState) => appData);
  const [cursor, setCursor] = useState(-1);
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const desktopSearchInputRef = useRef<HTMLInputElement | null>(null);
  const mobileSearchInputRef = useRef<HTMLInputElement | null>(null);
  const magnifierButtonRef = useRef<HTMLButtonElement | null>(null);
  const locale = useLocale();
  const isMx = locale === countryCodes.mx;
  const router = useRouter();
  const removeUnsupportedSearchChars = useRemoveUnsupportedSearchChars();
  const labels = useLabels(labelMap);
  const debounceLength = Number(useFeatureFlag('SUGGESTIONS_API_KEYSTROKE_DELAY_MS') ?? 200);
  const minCharsToEnableSuggestions = Number(
    useFeatureFlag('SUGGESTIONS_API_MINIMUM_CHARACTERS') ?? 3
  );

  const searchCharLength =
    Number(useFeatureFlag('SEARCH_CHARACTERS_LENGTH_TO_SHOW_PRODUCT_RECS')) || 5;
  const debouncedSuggestionText = useDebounce(suggestionText, debounceLength);

  const [, setSearchState] = useGlobalState('search');

  const { data: suggestionsData } = useSuggestions({
    searchTerm: debouncedSuggestionText.trim(),
    enabled:
      isFocused &&
      (suggestionText.trim().length === 0 ||
        debouncedSuggestionText.length >= minCharsToEnableSuggestions),
  });
  const { filteredRecentSearches, addRecentSearch, deleteRecentSearch } =
    useRecentSearches(suggestionText);
  const suggestions = useMemo(
    () => suggestionsData ?? { generalSuggestions: [] },
    [suggestionsData]
  );
  const { accordionDispatch } = useProductListView();

  const onClearClick = () => {
    onSearchTextChange('');
    onSuggestionTextChange('');
    magnifierButtonRef.current?.focus();
    if (!isMobile) {
      setCursor(-1);
    }
  };

  const getDocumentHeight = () => {
    const body = document.body || {};
    const html = document.documentElement || {};

    return Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
  };

  const handleClose = useCallback(() => {
    showMobileDrawer(false);
    setIsFocused(false);
    setSearchDrawerOpen(false);
    setSearchBoxOpen(false);
  }, [setIsFocused, setSearchBoxOpen, showMobileDrawer]);

  useEffect(() => {
    if (cursor !== -1) {
      const recentsAndSuggestions = [...filteredRecentSearches, ...suggestions.generalSuggestions];
      onSearchTextChange(recentsAndSuggestions[cursor]);
    }
  }, [cursor, onSearchTextChange, suggestions, filteredRecentSearches]);

  const handleKeydownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const activeElementOnKeydown = document.activeElement ?? null;
    if (event.keyCode === eventConstants.escKeyCode) {
      handleClose();
      if (activeElementOnKeydown instanceof HTMLElement) {
        activeElementOnKeydown.blur();
      }
    }
  };

  const blockScrolling = async () => {
    const html = document.documentElement;

    if (isTablet) {
      // wait to avoid the virtual keyboard pushing the content up out of screen
      await sleep(50);
    }
    if (
      getDocumentHeight() > root.innerHeight &&
      //check if scroll bar exists
      !html.classList.contains(`${styles.noScroll}`)
    ) {
      const scrollTop = html.scrollTop || 0;

      //add class to maintain scrollbar and prevent layout shift
      html.classList.add(`${styles.noScroll}`);

      //maintain scroll position
      html.style.setProperty('top', `${-scrollTop}px`);

      //set scrolled to true, so we do not lose sticky nav if scrolled
      scrolled && setProgramaticallyScrolled(true);
    }
  };

  const unblockScrolling = () => {
    const html = document.documentElement;
    const scrollTop = html.style.top;
    html.classList.remove(`${styles.noScroll}`);
    html.style.removeProperty('overflow');

    if (scrollTop) {
      const strippedValue = scrollTop.replace('px', '').replace('-', '');
      html?.scrollTo({ top: parseInt(strippedValue), behavior: 'instant' });
      html.style.removeProperty('top');
    }
    setProgramaticallyScrolled(false);
  };

  // Block scrolling when search box is in focus
  useEffect(() => {
    if (!isMobile) {
      if (isFocused) {
        void blockScrolling();
      } else {
        unblockScrolling();
      }
      return () => {
        unblockScrolling();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, isMobile]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === eventConstants.enterKeyType && searchText.length) {
      onClickSearch();
    }
  };

  const onChangeFocus = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === eventConstants.arrowUpKeyType && cursor >= 0) {
      setCursor(cursor - 1);
    } else if (
      e.key === eventConstants.arrowDownKeyType &&
      cursor < filteredRecentSearches.length + suggestions.generalSuggestions.length - 1
    ) {
      setCursor(cursor + 1);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutUnsupportedChars = removeUnsupportedSearchChars(e.target.value);

    onSearchTextChange(valueWithoutUnsupportedChars);
    onSuggestionTextChange(valueWithoutUnsupportedChars);
    if (cursor !== -1) {
      setCursor(-1);
    }
  };

  const onClickSearch = () => {
    sessionStorage.setItem('searchMethod', 'Standard');
    const searchQuery = searchText.trim();
    const checkGiftCardKeyword = searchQuery.toLowerCase().replace(ONLYSPACES, '');
    const isGiftcardSearch =
      checkGiftCardKeyword === globalConstant.giftcard ||
      checkGiftCardKeyword === globalConstant.giftcards;

    setSearchState({
      preferredVehicleChanged: false,
    });
    addRecentSearch(searchQuery);
    setCookie(cookieConstant.names.filterOpenStates, '');
    accordionDispatch({ dimensionName: 'clearAccordionOpenStates', expanded: false });

    if (isGiftcardSearch) {
      handleClose();
      onClearClick();
      void router.push(isMx ? routePaths.blankURL : routePaths.giftCardLanding);
    } else {
      routeToSearchResults(searchQuery);
    }

    const docActiveElem = document.activeElement ?? null;
    if (docActiveElem && global.innerWidth) {
      // @ts-expect-error find a replacement for accessing element directly from document
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      docActiveElem.blur();
    }
    setCursor(-1);
  };

  const updateAnalytics = (selectedSuggestion?: string) => {
    if (root.utag_data) {
      const allSearchTerms = root.utag_data.allSearchTerms
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
          `${root.utag_data.allSearchTerms} | ${
            selectedSuggestion ? selectedSuggestion : searchText
          }`
        : selectedSuggestion
        ? selectedSuggestion
        : searchText;

      sessionStorage.setItem('performedSearch', 'true');
      analyticsDataLayerVariable({ searchKeyword: searchText, allSearchTerms });
      setCookie(analyticsCookie.cookieSearchTerms, allSearchTerms);
      analyticsGlobalVariables({ userAuthenticated: appData.userAuthenticated });
    }
  };

  const routeToSearchResults = (searchQuery: string) => {
    if (searchQuery.length > 0) {
      const parsedSearchQuery = removeUnsupportedSearchChars(searchQuery);
      void router.push(`/searchresult?searchText=${encodeURIComponent(parsedSearchQuery)}`);
      updateAnalytics();
    }

    handleClose();
  };

  const handleOpenDrawer = () => {
    if (isMobile) {
      showMobileDrawer(true);
    }
    setSearchDrawerOpen(true);
    setSearchBoxOpen(true);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === eventConstants.enterKeyCode) {
      onClearClick();
    }
  };

  const handleMobileClose = () => {
    setTimeout(() => {
      desktopSearchInputRef.current?.blur();
      setIsFocused(false);
    }, 100);
    handleClose();
  };

  const handleFocus = () => {
    onSearchTextChange(removeUnsupportedSearchChars(searchText || ''));
    if (!isMobile) {
      setSearchDrawerOpen(true);
      setSearchBoxOpen(true);
    }
    setIsFocused(true);
  };

  const searchPartsAndProducts = labels.searchPartsAndProducts;
  const shouldShowProductRecs = Boolean(
    suggestions.productTitleSuggestions?.length &&
      suggestions.productTitleSuggestions.length >= 1 &&
      searchText.length >= Number(searchCharLength)
  );

  return (
    <div className={styles.relative}>
      <div
        onBlur={(e) => {
          e.preventDefault();
          const currentTarget = e.currentTarget;

          // Give browser time to focus the next element
          requestAnimationFrame(() => {
            // Check if the new focused element is a child of the original container
            if (!currentTarget.contains(document.activeElement) && !isMobile) {
              setIsFocused(false);
              setSearchDrawerOpen(false);
              setSearchBoxOpen(false);
              showMobileDrawer(false);
            }
          });
        }}
        onKeyDown={(event) => {
          onChangeFocus(event);
          handleKeydownEvent(event);
        }}
      >
        <div data-testid="backDropSearchBox" className={cx(styles.search, styles.fullSearch)}>
          <div
            className={cx(styles.searchInput, {
              [styles.searchInputSticky]: isFixedHeader,
            })}
            role="search"
          >
            <Button
              reference={magnifierButtonRef}
              onClick={onClickSearch}
              className={cx(
                styles.searchImgButton,
                { [styles.searchImgButtonSticky]: isFixedHeader },
                styles.searchImage
              )}
              type="button"
              data-testid={magniSearchBtnDataTestId}
            >
              <img
                src="/images/icon-search-20x20.svg"
                alt={labels.searchResultsSearch}
                width={isMobile || isFixedHeader ? 16 : 20}
                height={isMobile || isFixedHeader ? 16 : 20}
                decoding="async"
              />
            </Button>
            <input
              ref={desktopSearchInputRef}
              inputMode={isMobile ? 'none' : undefined} // we do not need virtual keyboard on mobile since SearchDrawer opens
              data-testid={searchInputDataTestId}
              aria-label={searchPartsAndProducts}
              autoComplete="off"
              className={cx(styles.noOutline, styles.noMobileCursor, {
                [styles.inputBorders]:
                  searchDrawerOpen && (suggestions.generalSuggestions.length ?? 0) > 0,
                [styles.fixedNavsearchInput]: scrolled || programaticallyScrolled,
                [styles.withNoRightPadding]: isMobile && !isFocused,
              })}
              placeholder={searchPartsAndProducts}
              onKeyPress={(event) => onKeyPress(event)}
              value={searchText}
              onChange={onChange}
              onClick={handleOpenDrawer}
              onFocus={(e) => {
                e.preventDefault();
                handleFocus();
              }}
            />
            {!isMobile && (!!searchText.trim() || searchDrawerOpen) && (
              <div className={styles.searchBoxButtons}>
                <>
                  {searchText.trim().length > 0 && (
                    <Button
                      onClick={onClearClick}
                      onKeyDown={handleOnKeyDown}
                      customClass={styles.clearButton}
                      variant="gray"
                      ariaLabel={labels.searchResultsClear}
                    >
                      <CloseIcon className={styles.clearButtonImg} />
                    </Button>
                  )}
                  <div className={styles.verticalDivider} />
                </>
                <Button
                  id="searchBtnDesktopAndTablet"
                  onClick={onClickSearch}
                  customClass={`${styles.searchBtn}`}
                  data-testid="locationSearch"
                  variant="gray"
                >
                  {labels.searchResultsSearch}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div
          className={cx(styles.searchDrawerContainer, {
            [styles.searchDrawerScrolledHeight]: scrolled || programaticallyScrolled,
            [styles.searchDrawerHeight]: !(scrolled || programaticallyScrolled),
            [styles.searchDrawerContainerSticky]: isFixedHeader,
          })}
        >
          {!isMobile && searchDrawerOpen && filteredRecentSearches.length > 0 && (
            <RecentSearches
              recentSearches={filteredRecentSearches}
              searchText={searchText}
              cursor={cursor}
              addRecentSearch={addRecentSearch}
              deleteRecentSearch={deleteRecentSearch}
              onSearchTextChange={onSearchTextChange}
              handleClose={handleClose}
              magnifierButtonRef={magnifierButtonRef}
            />
          )}
          {!isMobile &&
            (Boolean(suggestions.generalSuggestions.length) || shouldShowProductRecs) && (
              <div className={styles.suggestionList}>
                <Suggestions
                  suggestions={suggestions}
                  searchDrawerOpen={searchDrawerOpen}
                  isMobile={isMobile}
                  onSearchTextChange={onSearchTextChange}
                  updateAnalytics={updateAnalytics}
                  accordionDispatch={accordionDispatch}
                  cursor={cursor}
                  handleClose={handleClose}
                  searchText={searchText}
                  addRecentSearch={addRecentSearch}
                  filteredRecentSearches={filteredRecentSearches}
                />
              </div>
            )}
        </div>
      </div>
      {isMobile ? (
        <SearchDrawer open={searchDrawerOpen} handleClose={handleMobileClose}>
          <div className={styles.searchDrawer}>
            <form
              action="search"
              className={`${styles.searchInputDrawer}`}
              onSubmit={(e) => {
                e.preventDefault();
                onClickSearch();
              }}
            >
              <Button
                reference={magnifierButtonRef}
                onClick={onClickSearch}
                className={cx(
                  styles.searchImgButton,
                  { [styles.searchImgButtonSticky]: isFixedHeader },
                  styles.searchImage
                )}
                type="button"
                data-testid={magniSearchBtnDataTestId2}
              >
                <img
                  src="/images/icon-search-20x20.svg"
                  alt={labels.searchResultsSearch}
                  width={16}
                  height={16}
                  decoding="async"
                />
              </Button>
              <input
                type="text"
                autoFocus
                autoComplete="off"
                id="searchMobile"
                ref={mobileSearchInputRef}
                placeholder={searchPartsAndProducts}
                onChange={onChange}
                value={searchText}
                aria-label={searchPartsAndProducts}
                onFocus={handleFocus}
                onBlur={() => setIsFocused(false)}
                className={cx(styles.noOutline, styles.mobileInput, {
                  [styles.hasText]: searchText.length,
                })}
              />
              {(isFocused || !!searchText.trim()) && (
                <div className={styles.searchBoxButtons}>
                  {searchText.trim().length > 0 && (
                    <Button
                      id="clearBtnMobile"
                      onClick={onClearClick}
                      onKeyDown={handleOnKeyDown}
                      customClass={styles.clearButton}
                      variant="gray"
                      type="button"
                      ariaLabel={labels.searchResultsClear}
                    >
                      <CloseIcon className={styles.clearButtonImg} />
                    </Button>
                  )}
                  <div className={styles.verticalDivider} />
                  <Button
                    id="searchBtnDesktopAndTablet"
                    onClick={onClickSearch}
                    customClass={`${styles.searchBtn}`}
                    data-testid="locationSearch"
                    variant="gray"
                  >
                    {labels.searchResultsSearch}
                  </Button>
                </div>
              )}
            </form>
          </div>
          {filteredRecentSearches.length > 0 && (
            <RecentSearches
              recentSearches={filteredRecentSearches}
              searchText={searchText}
              cursor={cursor}
              addRecentSearch={addRecentSearch}
              deleteRecentSearch={deleteRecentSearch}
              onSearchTextChange={onSearchTextChange}
              handleClose={handleClose}
            />
          )}
          <Suggestions
            suggestions={suggestions}
            searchDrawerOpen={searchDrawerOpen}
            isMobile={isMobile}
            onSearchTextChange={onSearchTextChange}
            updateAnalytics={updateAnalytics}
            accordionDispatch={accordionDispatch}
            cursor={cursor}
            handleClose={handleClose}
            searchText={searchText}
            addRecentSearch={addRecentSearch}
            filteredRecentSearches={filteredRecentSearches}
          />
        </SearchDrawer>
      ) : (
        <Backdrop
          id="TopNavBackDrop"
          open={searchDrawerOpen}
          className={cx(isMx && appData.userAuthenticated ? styles.backdropMx : styles.backdrop)}
        />
      )}
    </div>
  );
};

export default SearchBoxComponent;
