/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import stringify from 'fast-json-stable-stringify';

const defaultOptions = {
  cacheExpiry: 5 * 60 * 1000, // 5 min default
};

type Value = {
  value: any;
  timestamp: number;
  expiresIn: number | undefined;
};
const storage = new Map<string, Value>();

/*
  In memory cache for server side requests.
*/
export const cache = {
  clear() {
    storage.clear();
  },

  getItem<T>(cacheKey: object | string): T | null {
    const key = stringify(cacheKey);
    const maybeCachedItem = storage.get(key);

    if (maybeCachedItem !== undefined) {
      const ageInMs = Date.now() - maybeCachedItem.timestamp;
      const expiryTimeInMs = maybeCachedItem.expiresIn ?? defaultOptions.cacheExpiry;

      if (ageInMs < expiryTimeInMs) {
        return maybeCachedItem.value;
      } else {
        storage.delete(key);
      }
    }

    return null;
  },

  setItem<T>(cacheKey: object | string, val: T, expiresIn?: number) {
    const key = stringify(cacheKey);
    storage.set(key, { value: val, timestamp: Date.now(), expiresIn });
  },

  removeItem(cacheKey: object | string) {
    const key = stringify(cacheKey);
    storage.delete(key);
  },

  getKeys() {
    return storage.keys();
  },

  removeItemsStartingWith(cacheKey: unknown) {
    const key = Array.isArray(cacheKey) ? stringify(cacheKey).slice(0, -1) : stringify(cacheKey);

    for (const savedKey of this.getKeys()) {
      if (savedKey.startsWith(key)) {
        storage.delete(key);
      }
    }
  },
};
