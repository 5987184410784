/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { AnyAction } from 'redux';
import type { CompletePurchaseState } from '../types';
import actionTypes from '../types/action';
const initialState = {
  readyStatus: actionTypes.CHECKOUT_INVALID,
  err: null,
  completePurchaseData: null,
  errorMessage: {},
};

const complete = (
  state: CompletePurchaseState = initialState,
  action: AnyAction
): CompletePurchaseState => {
  switch (action.type) {
    case actionTypes.COMPLETE_REQUESTING:
      return { ...state, readyStatus: actionTypes.CHECKOUT_REQUESTING };

    case actionTypes.COMPLETE_FAILURE:
      return {
        ...state,
        readyStatus: actionTypes.CHECKOUT_FAILURE,
        err: action.err,
      };

    case actionTypes.CHECKOUT_UNKNOWN:
      return {
        ...state,
        readyStatus: actionTypes.CHECKOUT_UNKNOWN,
        err: null,
        errorMessage: null,
      };

    case actionTypes.COMPLETE_SUCCESS:
      return {
        ...state,
        readyStatus: actionTypes.CHECKOUT_SUCCESS,
        completePurchaseData: action.data,
      };

    case actionTypes.LITLEPAY_ERROR:
      return {
        ...state,
        readyStatus: actionTypes.LITLEPAY_ERROR,
        errorMessage: action.data,
      };

    case actionTypes.RESET_COMPLETE_PURCHASE_ERROR_MSG:
      return {
        ...state,
        err: null,
        errorMessage: null,
        readyStatus: actionTypes.RESET_COMPLETE_PURCHASE_ERROR_MSG,
      };

    default:
      return state;
  }
};

export default complete;
