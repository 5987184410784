/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import root from 'window-or-global';
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';

function getScrollPosition() {
  if (__IS_SERVER__) {
    return 0;
  }
  return root.scrollY;
}
/**
 * Helper hook that will track if the scrolling is below a certain threshold
 * args:
 *  - effect: a callback function which returns a boolean as a param
 *  - deps: dependencies for the component
 *  - threshhold (number)
 **/

export function useScrollBelowThreshold(
  effect: (belowThreshold: boolean) => void,
  deps: Array<any>,
  threshold: number,
  showMobileDrawer?: boolean
) {
  const callBack = () => {
    const currPos = getScrollPosition();
    !showMobileDrawer && effect(currPos > threshold);
  };

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('scroll', callBack);
    return () => window.removeEventListener('scroll', callBack);
  }, [...deps, showMobileDrawer]);
}
