/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import root from 'window-or-global';
import { PersistentStorage } from './PersistentStorage';
import type { Storage } from './PersistentStorage';

type SessionStorageKey =
  | 'AZ_SESSION_STATUS'
  | 'fromCheckout'
  | 'selectedFirstLevelItem'
  | 'level1Label'
  | 'configureTechVehicle'
  | 'pageNumber'
  | 'dateFilter'
  | 'orderTypeFilter'
  | 'pdpUtagViewAffinity'
  | 'pdpUtagLinkAffinity'
  | 'popularSearchClicked'
  | 'fromSignin'
  | 'signInAlertShown'
  | 'signInAlertDisabled'
  | 'signInPopupShown'
  | 'signInPopupDisabled'
  | 'DropDownRendered'
  | 'isNewAccount'
  | 'addOrUpdateCart'
  | 'skuId'
  | 'sister_store_update_type'
  | 'tcPilotPrompt'
  | 'quantity'
  | 'storeNumber'
  | 'productId'
  | 'productFindingMethod'
  | 'prefilledEmail'
  | 'contactInformationEdit'
  | 'citrusImpressions'
  | 'oemBrandName'
  | 'oemPartNumber'
  | 'searchMethod'
  | 'visualCategoryRank'
  | 'typeAheadValue'
  | 'resetPasswordFrom'
  | 'performedSearch'
  | 'savedForLaterItem'
  | 'lwTestExperience'
  | 'sflNotification'
  | 'productRank'
  | 'pageCount'
  | 'returnToSearchResults'
  | 'xFusionQueryId'
  | 'filterSelectionAnalytics';

export const sessionStorage: Readonly<Storage<SessionStorageKey>> =
  new PersistentStorage<SessionStorageKey>(root.sessionStorage);
