/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const analyticsPageNames = {
  azSavings: 'az:deals:deals-savings',
  azProfile: 'az:myzone:profile',
  azContactUs: 'az:customer-service:contact-us',
  azOrderHistory: 'az:myzone:order-history',
  azRewardsLanding: 'az:myzone:rewards-landing',
  azOrderDetails: 'az:myzone:order-details',
  azServiceHistory: 'az:myzone:vehicles:service-history',
  azTrackOrder: 'az:myzone:track-order',
  azMiniCart: 'az:cart:mini-cart',
  azRewardsCredit: 'az:myzone:rewards-credit',
  azRecalls: 'az:customer-service:recalls',
  azrepairHelp: 'az:repair-help',
  azGuide: 'az:repair-help:maintenance-guide',
  azVehicles: 'az:myzone:vehicles',
  azCreate: 'az:myzone:create',
  azMyzone: 'az:myzone',
  azSignIn: 'az:signIn',
  azContinueAsGuest: 'az:continueAsGuest',
  azShopByMake: 'az:catalog:shop-by-make',
  azOrderConfirmation: 'az:order-confirmation',
  azHome: 'az:home',
  azFullCart: 'az:cart:full-cart',
  azCartPlain: 'cart',
  azCart: 'az:cart',
  azCheckout: 'az:checkout',
  azCheckoutPlain: 'checkout',
  azMyStore: 'az:mystore',
  azMystoreLocator: 'az:store-locator',
  azStoreDetails: 'az:storedetails',
  azYmmeOverlay: 'az:myzone:ymme-overlay',
  azGiftCardBalance: 'az:giftcardbalance',
};
