/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Locale configs
export const LOCALE_US = 'en-US';

export const LOCALE_MX = 'es-MX';

export const LOCALE_DOMAINS = {
  [LOCALE_US]: 'https://www.autozone.com',
  [LOCALE_MX]: 'https://www.autozone.com.mx',
} as const;

export const DEFAULT_GATEWAY_URL = 'https://qa9-api.autozone.com';

export const DEFAULT_PUBLIC_GATEWAY_URL = 'https://st-api.autozone.com';
