/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { UrlWithQuery } from '@/types';

export const parseUrl = (path: string): UrlWithQuery => {
  const [url = '', query = ''] = path.split(/[?#]/);
  const searchParams = new URLSearchParams(query);

  const parsedParams: Record<string, string> = {};
  searchParams.forEach(function (value, key) {
    parsedParams[key] = value;
  });
  return { url, query: parsedParams };
};
