/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import environmentConfig from '../../../config/environment';

const { DEFAULT_TIMEOUT, API_TIMEOUT_M, API_TIMEOUT_S, API_TIMEOUT_XL, API_TIMEOUT_XS } =
  environmentConfig;

// ideally we would move these to the serviceApi definitions, but not all of the endpoints match up
const retryUrls = [
  {
    url: '/order/item',
    timeout: DEFAULT_TIMEOUT,
  },
  {
    url: '/shoppingCart/mini-cart',
    timeout: DEFAULT_TIMEOUT,
  },
  {
    url: '/order/details',
    timeout: API_TIMEOUT_S,
  },
  {
    url: '/checkout/commit',
    timeout: API_TIMEOUT_XL,
  },
  {
    url: '/currentUser/orders',
    timeout: API_TIMEOUT_M,
  },
  {
    url: '/order/verifyForCheckout',
    timeout: API_TIMEOUT_XL,
  },
  {
    url: '/order/shipment',
    timeout: DEFAULT_TIMEOUT,
  },
  {
    url: '/order/address',
    timeout: DEFAULT_TIMEOUT,
  },
  {
    url: '/browse/category-products',
    timeout: API_TIMEOUT_S,
  },
  {
    url: '/browse/sku-details',
    timeout: API_TIMEOUT_S,
  },
  {
    url: '/browse/topNavMenu',
    timeout: DEFAULT_TIMEOUT,
  },
  {
    url: '/message/messageContent',
    timeout: API_TIMEOUT_XS,
  },
  {
    url: '/searchresult',
    timeout: API_TIMEOUT_S,
  },
  {
    url: '/xm/home',
    timeout: DEFAULT_TIMEOUT,
  },
  {
    url: '/page/header',
    timeout: API_TIMEOUT_S,
  },
];

export default retryUrls;
