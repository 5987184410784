/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const checkoutConstants = {
  loginsuccess: 'loginsuccess',
  pleaseTryAgain: 'Invalid Id. Please try again.',
  giftCard: 'giftCard',
  freebie: 'freebie',
  secondary: 'secondary',
  nonInStoreOrderTypesParams: '?orderTypes=SHIP_TO_HOME&&orderTypes=BOPUS',
  giftCardTitle: 'AutoZone Gift Card',
};
