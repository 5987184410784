/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ActionTypes } from './action-types';
import { AppState } from './interface';

export const saveUIState = (newState: Partial<AppState>) => ({
  type: ActionTypes.UISTATE_SET,
  payload: newState,
});
