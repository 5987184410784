/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const cookieConstant = {
  names: {
    prevChangeInRoutes: 'prevChangeInRoutes',
    prevPageType: 'prevPageType',
    brandShelfListGridView: 'brandShelfListGridView',
    shelfListGridView: 'shelfListGridView',
    myVehicleListView: 'myVehicleListView',
    filterOpenStates: 'filterOpenStates',
  },
  values: {
    PDPToCart: 'PDPToCart',
    ShelfToCart: 'ShelfToCart',
    OtherToCart: 'OtherToCart',
    shelfListGridView: 'grid',
    shelfListListView: 'list',
  },
} as const;

export const COOKIES = {
  JSESSIONID: 'JSESSIONID',
  WWW_WS_ROUTE: 'WWW-WS-ROUTE',
  preferredVehicleId: 'preferredVehicleId',
  userId: 'profileId',
  rewardsId: 'rewardsId',
  quotientSessionId: '_elevaate_session_id',
  MTV: 'mt.v',
  bearer_token: 'bearer_token',
  REQUEST_ID: 'REQUEST_ID',
} as const;
