/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { useLabel } from '@/hooks/useLabels';
import { BackButton } from './BackButton';
import { CloseButton } from './CloseButton';

type Props = {
  title?: React.ReactNode;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  handleBack?: () => void;
  handleClose?: () => void;
};

export function ModalHeaderV2({
  title,
  showBackButton,
  showCloseButton,
  handleBack,
  handleClose,
}: Props) {
  const labelBack = useLabel('label_back');

  const backRef = useRef<HTMLButtonElement>(null);
  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (showCloseButton && closeRef.current) {
      closeRef.current.focus();
    }
  }, [showCloseButton]);

  useEffect(() => {
    if (showBackButton && backRef.current) {
      backRef.current.focus();
    }
  }, [showBackButton]);

  return (
    <div className={styles.headerContainer}>
      {showBackButton ? (
        <BackButton handleBack={handleBack} label={labelBack} backRef={backRef} />
      ) : (
        <h2 className={styles.headerTitle}>{title}</h2>
      )}
      {showCloseButton ? <CloseButton closeRef={closeRef} handleClose={handleClose} /> : null}
    </div>
  );
}
