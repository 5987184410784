/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { createContext } from 'react';

export const ProductListViewContext = createContext<{
  isMobileFilterOpen: boolean;
  setIsMobileFilterOpen: (a: boolean) => void;
  accordionState: Record<string, boolean> | Record<string, never>;
  accordionDispatch: (arg: { dimensionName: string; expanded: boolean }) => void;
  listViewState: { isListView: boolean; isBrandListView: boolean };
  toggleListView: (arg: { type: string; payload: boolean }) => void;
}>({
  isMobileFilterOpen: false,
  setIsMobileFilterOpen: () => undefined,
  accordionState: {},
  accordionDispatch: () => undefined,
  listViewState: { isListView: true, isBrandListView: true },
  toggleListView: () => undefined,
});
