/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { serializeError } from 'serialize-error';
import logger from './logger';
import environmentConfig from '@/config/environment';

const nodeEnv = environmentConfig.NODE_ENV || 'development';
const isDev = nodeEnv === 'development';

/**
 * `dispatchEvent` is a function that dispatches a custom event with a given name and detail.
 * It creates a new CustomEvent with the provided eventName and detail, and dispatches it using Custom Events.
 * If the event dispatch fails, it logs a warning with the serialized error.
 *
 * @param {string} eventName - The name of the event to be dispatched.
 * @param {unknown} [detail] - The detail of the event to be dispatched. Optional.
 *
 * @throws {Error} If the event dispatch fails, it logs a warning with the serialized error.
 */
export function dispatchEvent(eventName: string, detail?: unknown) {
  if (__IS_SERVER__) {
    return;
  }

  try {
    const event = detail ? new CustomEvent(eventName, { detail }) : new CustomEvent(eventName);
    window.dispatchEvent(event);
    if (isDev) {
      // eslint-disable-next-line testing-library/no-debugging-utils
      logger.debug({ message: 'Event dispatched: ', meta: { eventName, detail } });
    }
  } catch (e) {
    logger.warn({
      message: 'Failed to dispatch event',
      meta: serializeError(e),
    });
  }
}
