/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './ContentBlock.module.scss';
import ContentBlockWrapper from './ContentBlockWrapper';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import NextImage from '@/components/NextImage';

const isURL = (targetPath: string): boolean => {
  return typeof targetPath === 'string' && targetPath.includes('://');
};

type Props = {
  label?: string;
  CTALink: string;
  Icon?: boolean;
  name: string;
  handleOpenModal?: () => void;
  index?: number;
  altText?: string;
  imageUrl?: string;
};

function ContentBlock({
  CTALink,
  imageUrl,
  name,
  altText,
  handleOpenModal,
  label = '',
  Icon = false,
  index,
}: Props) {
  const locale = useLocale();
  const isUS = locale === countryCodes.us;
  const isSocialIcon = Icon && isURL(CTALink);
  return (
    <ContentBlockWrapper
      CTALink={CTALink}
      label={label}
      Icon={Icon}
      handleOpenModal={handleOpenModal}
      name={name}
      index={index}
    >
      <div className={styles.footerTopItem} data-testid={`at_footer_icon_${name.split(' ')[0]}`}>
        <div className={isSocialIcon ? undefined : styles.footerTopItemLogo}>
          {imageUrl ? (
            <NextImage
              src={imageUrl}
              alt={Icon ? altText : ''}
              data-testid={`at_footer_icon_image_${name.split(' ')[0]}`}
              height={25}
              width={isSocialIcon ? 25 : 30}
              loading="lazy"
            />
          ) : null}
        </div>
        <div
          className={isUS ? styles.footerTopItemLabel : styles.mxFooterTopItemLabel}
          id={`at_footer_icon_button_${name.split(' ')[0]}`}
          data-testid={`at_footer_icon_button_${name.split(' ')[0]}`}
        >
          {label.toLowerCase()}
        </div>
      </div>
    </ContentBlockWrapper>
  );
}

export default ContentBlock;
