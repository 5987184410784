/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FormattedContentStackHeader } from '@/features/contentstack/interface';
import type { HeaderPromoContent } from '../interface';

const devices = {
  desktop: true,
  tablet: true,
  mobile: true,
};

export const mapHeaderPromoFromContentstackToXM = (
  headerPromo: NonNullable<FormattedContentStackHeader['headerPromo']>[0] | undefined
): HeaderPromoContent[] | undefined => {
  if (!headerPromo) {
    return undefined;
  }

  const [ad1, ad2] = headerPromo.ad || [];

  if (ad1 === undefined || ad2 === undefined) {
    return undefined;
  }

  return [
    {
      ctaName1: ad1.cta_name ?? '',
      ctaName2: ad2.cta_name ?? '',
      text1: `<p>${ad1.text_content ?? ''}</p>`,
      text2: `<p>${ad2.text_content ?? ''}</p>`,
      ctaLink1: ad1.cta_link ?? '',
      ctaLink2: ad2.cta_link ?? '',
      ...devices,
    },
  ];
};
