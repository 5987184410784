/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AppState, AppStateAction } from '../interface';
import { ActionTypes } from '../action-types';

export const uiStateReducer = (state: AppState, action: AppStateAction): AppState => {
  switch (action.type) {
    case ActionTypes.UISTATE_SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
