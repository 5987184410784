/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { startTransition, useState } from 'react';
import { saveUIState } from '@/stores/actions';
import { useAppState } from '@/stores/AppState';
import dynamic from 'next/dynamic';

const LazyYMMEModalBody = dynamic(
  () => import('./YMMEModalBody').then((mod) => mod.YMMEModalBody),
  { ssr: false }
);

export type WrapperProps = {
  showYmme?: boolean;
  pageType?: string;
  closeYmmeWidget?: () => void;
  bannerImage?: string;
  bannerAltText?: string;
  ymmeCaptionText?: string;
  ctaButtonText?: string;
  campaignFlow?: boolean;
  onUpdateVehicleSuccess?: () => void;
};

export function YMMEModal({
  pageType = '',
  showYmme = false,
  closeYmmeWidget,
  bannerImage,
  bannerAltText,
  ymmeCaptionText,
  ctaButtonText,
  campaignFlow = false,
  onUpdateVehicleSuccess,
}: WrapperProps) {
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const { dispatch: dispatchAppState } = useAppState();

  const closeYmme = () => {
    startTransition(() => {
      setShowAddVehicle(false);
      closeYmmeWidget?.();
      dispatchAppState(saveUIState({ isYmmeTooltipEnabled: false }));
    });
  };

  if (showYmme) {
    return (
      <LazyYMMEModalBody
        showYmme={showYmme}
        pageType={pageType}
        setShowAddVehicle={setShowAddVehicle}
        showAddVehicle={showAddVehicle}
        closeYmmeWidget={closeYmme}
        bannerImage={bannerImage}
        bannerAltText={bannerAltText}
        ymmeCaptionText={ymmeCaptionText}
        ctaButtonText={ctaButtonText}
        campaignFlow={campaignFlow}
        onUpdateVehicleSuccess={() => {
          setShowAddVehicle(false);
          onUpdateVehicleSuccess?.();
        }}
      />
    );
  }

  return null;
}
