/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { createRipple, removeRipple } from '@/utils/buttonUtils';
import { LoadingIndicatorDots } from '@/components/LoadingIndicatorDots';
import { ButtonBase } from '@/components/ButtonBase/ButtonBase';

type Props = {
  title: string | React.ReactNode;
  onPress: (e: React.KeyboardEvent | React.MouseEvent) => void;
  size?: 'small' | 'large';
  font?: 'default' | 'system';
  shape?: 'square' | 'rounded' | 'pill';
  width?: string;
  variant: 'primary' | 'secondary' | 'super' | 'danger' | 'link';
  iconPosition?: 'lead' | 'trail';
  icon?: string;
  buttonState?: 'enabled' | 'disabled' | 'loading';
  testId?: string;
  className?: string;
  ariaLabel?: string;
  dialogIsOpen?: boolean;
  removeZIndex?: boolean;
  component?: React.ElementType;
  role?: string;
};

export const AZButton = ({
  title,
  onPress,
  shape = 'rounded',
  size = 'large',
  variant,
  font = 'default',
  width,
  iconPosition,
  icon,
  buttonState,
  testId,
  className,
  ariaLabel,
  dialogIsOpen,
  removeZIndex,
  component,
  role,
}: Props) => {
  const showRippleEffect = variant !== 'link' && buttonState === 'enabled';
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (showRippleEffect) {
      removeRipple(e, styles);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (showRippleEffect) {
      createRipple(e, styles);
    }
  };

  const handleOnMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (showRippleEffect) {
      removeRipple(e, styles);
    }
  };

  React.useEffect(() => {
    dialogIsOpen && buttonRef && buttonRef.current && buttonRef.current.focus();
  }, [dialogIsOpen, buttonRef]);

  return (
    <ButtonBase
      ref={buttonRef}
      component={component}
      role={role}
      ariaLabel={ariaLabel}
      className={cx(
        styles.button,
        styles[variant],
        styles[size],
        shape ? styles[shape] : null,
        styles[font],
        {
          [styles.disabled]: buttonState === 'disabled',
          [styles.loading]: buttonState === 'loading',
        },
        className
      )}
      style={
        width
          ? {
              width,
            }
          : undefined
      }
      onClick={buttonState === 'disabled' || buttonState === 'loading' ? undefined : onPress}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleOnMouseLeave}
      disabled={buttonState === 'disabled'}
      data-testid={testId}
    >
      {buttonState === 'loading' ? (
        <LoadingIndicatorDots
          color={variant !== 'secondary' && variant !== 'link' ? '#fff' : '#000'}
        />
      ) : (
        <>
          {icon && iconPosition !== 'trail' ? (
            <img alt="button-icon" src={icon} className={styles.leftIcon} />
          ) : null}
          {showRippleEffect && !removeZIndex ? (
            <div className={styles.buttonText}>{title}</div>
          ) : showRippleEffect && removeZIndex ? (
            <div className={styles.removeZIndex}>{title}</div>
          ) : (
            <span>{title}</span>
          )}
          {icon && iconPosition === 'trail' ? (
            <img alt="button-icon" src={icon} className={styles.rightIcon} />
          ) : null}
        </>
      )}
    </ButtonBase>
  );
};
