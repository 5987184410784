/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { toErrorWithMessage } from './toErrorWithMessage';

export function getErrorMessage(
  error: unknown,
  prioritizeDefaultMessage?: boolean
): string | undefined {
  if (error) {
    return toErrorWithMessage(error, prioritizeDefaultMessage).message;
  } else {
    return undefined;
  }
}
