/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  id?: string | undefined;
  type?: string;
  withPadding?: boolean;
  withMargin?: boolean;
  classes?: any | string;
  className?: string;
  children: any;
  isTransparent?: boolean;
  'data-testid'?: string;
  onClick?: (a: any) => unknown;
};

const ContentContainer = ({
  type = 'simple',
  withPadding = false,
  withMargin = false,
  classes = '',
  children,
  className = '',
  id,
  isTransparent = false,
  ...props
}: Props) => (
  <div
    id={id}
    className={`${styles[type]} ${withPadding ? styles.withPadding : ''}
      ${withMargin ? styles.withMargin : ''} ${isTransparent ? styles.bgTransparent : ''} ${String(
      className
    )} ${String(classes)}`}
    {...props}
  >
    {children}
  </div>
);

export default ContentContainer;
