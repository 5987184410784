/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { GLOBALPHONENUMBER } from '../validatorRegex';

export function validateGlobalPhoneNumber(message: string) {
  let PhoneNumberVal: string | undefined = undefined;
  return (phoneNumberValue: string) => {
    if (!GLOBALPHONENUMBER.test(phoneNumberValue)) {
      PhoneNumberVal = message;
    }

    return PhoneNumberVal;
  };
}
