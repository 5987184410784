/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import App, { type AppProps } from 'next/app';
import Head from 'next/head';
import '../theme/normalize.css';
import '../theme/configTechOverrides.css';
import '../theme/loqateOverrides.css';
import '@az/starc-ui/themes/starc.css';
import '../theme/globals.scss';
import { createWrapper } from 'next-redux-wrapper';
import { QueryClientProvider, Hydrate, type DehydratedState } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { Store } from '../types';
import AppComp from '@/components/App';
import ErrorBoundaryComp from '@/components/ErrorBoundary/ErrorBoundary';
import { ThemeContext, createTheme } from '@/theme/ThemeContext';
// this needs to be imported after stylesheets to ensure correct css order
import configureStore from '../configureStore';
import { getAxios, getAxiosForClient } from '@/lib/axios';
import { configureAxiosForClientWithStore } from '@/lib/axios/configureAxiosForClient';
// This is needed to workaround an issue related to dynamic imports in Next.js
// For reference, see https://track.autozone.com/browse/SB-1261 and https://github.com/vercel/next.js/issues/17464
import '../components/AZCustomComponent/FormGroup/TextField/formikTextField';
import { getClientSideQueryClient, createQueryClient } from '@/lib/react-query';
import { AppStateProvider } from '@/stores/AppState';
import type { AppState } from '@/stores/interface';
import { XMPreviewStateProvider } from '@/stores/XMPreviewState';
import { fetchInitialData } from '@/utils/fetchInitialData.server';
import dynamic from 'next/dynamic';
import { getDeviceType } from '@/utils/getDeviceType.server';
import { Starc, useIsomorphicLayoutEffect } from '@az/starc-ui';
import { SnackBarProvider } from '@/components/SnackBarContext';
import { SignInFlowTypeProvider } from '@/components/SignInFlowTypeContext';
import { Banner90DayProvider } from '@/components/Banner90DayContext';
import { SearchBoxProvider } from '@/components/SearchBoxContext';
import { LinkRewardsProvider } from '@/components/LinkRewardsContext';
import { ModalTCPromptProvider } from '@/components/ModalTCPromptContext';
import getServerAxios from '@/lib/getAxios.server';
import { handleCitrusForceProdPassthrough } from '@/features/citrus/lib/handleCitrusForceProdPassthrough.server'; // eslint-disable-line no-restricted-imports
import { setCookie } from '@/utils/cookie';
import { getGlobalConfigFromCache, getFeatureFlag } from '@/features/globalConfig';
import { StarcLinkOverride } from '@/components/StarcLinkOverride/StarcLinkOverride';
import { useDispatchNavigationEvents } from '@/hooks/useDispatchNavigationEvents';
import environmentConfig from '@/config/environment';
import { useNavigationCount } from '@/hooks/useNavigationCount';

if (process.env.NEXT_PUBLIC_API_MOCKING === 'true') {
  require('../mocks');
}

const wrapper = createWrapper<Store>((context) => {
  const store = configureStore(
    getAxios(__IS_SERVER__ && 'ctx' in context ? getServerAxios(context.ctx) : undefined)
  );
  return store;
});

const reuseDataFetchedInBrowser = () => {
  const initialDataElement = document.querySelector('#__NEXT_DATA__');
  return initialDataElement !== null ? JSON.parse(initialDataElement.innerHTML).props : {};
};

const getInitialData = async (__IS_SERVER__: boolean) => {
  if (!__IS_SERVER__) {
    return reuseDataFetchedInBrowser();
  }
};

type PageProps = {
  initialAppState: AppState;
  dehydratedState: DehydratedState;
};

type Props = AppProps<PageProps> & {
  Component: React.ComponentType<PageProps> & {
    getLayout: (page: React.ReactElement, pageProps?: object) => void;
  };
  responsiveContext: {
    width: number;
  };
  deviceType: string;
};

const ReactQueryDevtoolsProduction = dynamic(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((m) => m.ReactQueryDevtools)
);

const AppDevtools = dynamic(() =>
  import('@/features/common/components/AppDevtools').then((m) => m.AppDevtools)
);

const LazyINPMeasurements = dynamic(
  () =>
    import('@/features/performance/components/INPMeasurements').then((mod) => mod.INPMeasurements),
  { ssr: false }
);

const starcOverrides = {
  link: StarcLinkOverride,
};

function CustomApp(props: Props) {
  const { Component, ...rest } = props;
  const { store, props: wrappedProps } = wrapper.useWrappedStore(rest);
  const { pageProps, deviceType } = wrappedProps as Props;
  const [queryClient] = useState(() =>
    __IS_SERVER__ ? createQueryClient() : getClientSideQueryClient()
  );
  const [showDevtools, setShowDevtools] = useState(false);
  const [shouldLogINP, setShouldLogINP] = useState(false);

  useDispatchNavigationEvents();
  useNavigationCount();
  useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  useIsomorphicLayoutEffect(() => {
    const MILISECONDS_IN_MONTH = 2629746000;
    setCookie('eCookieId', pageProps.initialAppState.eCookieId, {
      secure: true,
      expires: new Date(Date.now() + MILISECONDS_IN_MONTH * 13),
      sameSite: 'lax',
      path: '/',
    });
  }, [pageProps.initialAppState.eCookieId]);

  const getLayout = Component.getLayout || ((page) => page);

  useIsomorphicLayoutEffect(() => {
    configureAxiosForClientWithStore(getAxiosForClient(), store, queryClient);
    // eslint-disable-next-line
  }, []);

  /**
   * There is a known issue in Nextjs (CSS Module resolution conflict with dynamic import in production mode)
   * Opened Issue in Github (https://github.com/vercel/next.js/issues/40080) - We must be aware of this issue
   * and make sure it is resolved before adding lazy loading to any styled components
   * We have begun work toward this in https://gitlab.autozone.com/digital-platform-retail/user-experience/web/web-ui/-/merge_requests/11458
   */

  const theme = useMemo(() => createTheme(deviceType), [deviceType]);

  useEffect(() => {
    const globalConfig = getGlobalConfigFromCache(queryClient);
    setShouldLogINP(
      getFeatureFlag(globalConfig, 'PERFORMANCE_INP_MEASUREMENTS_ENABLED') === 'true'
    );
  }, [queryClient]);

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <meta
          key="metaViewport"
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0"
        />
      </Head>
      <Hydrate state={pageProps.dehydratedState}>
        <XMPreviewStateProvider>
          <Provider store={store}>
            <AppStateProvider initialState={pageProps.initialAppState}>
              <ThemeContext.Provider value={theme}>
                <Starc overrides={starcOverrides}>
                  <SignInFlowTypeProvider>
                    <SnackBarProvider>
                      <ModalTCPromptProvider>
                        <Banner90DayProvider>
                          <SearchBoxProvider>
                            <LinkRewardsProvider>
                              <ErrorBoundaryComp>
                                {getLayout(
                                  <AppComp>
                                    <Component {...pageProps} />
                                  </AppComp>,
                                  pageProps
                                )}
                              </ErrorBoundaryComp>
                            </LinkRewardsProvider>
                          </SearchBoxProvider>
                        </Banner90DayProvider>
                      </ModalTCPromptProvider>
                    </SnackBarProvider>
                  </SignInFlowTypeProvider>
                </Starc>
                {shouldLogINP && <LazyINPMeasurements />}
              </ThemeContext.Provider>
              {environmentConfig.NODE_ENV === 'development' && <AppDevtools />}
            </AppStateProvider>
          </Provider>
        </XMPreviewStateProvider>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
      {showDevtools ? (
        <>
          <ReactQueryDevtoolsProduction />
          <AppDevtools />
        </>
      ) : null}
    </QueryClientProvider>
  );
}

/**
 * Since next.js 9.3 getInitialProps is not called on the initial page load on the client.
 * It is called on client only in case of client-side routing.
 * While routing, we don't want to get all page initial data, we only want to get page-specific data.
 * */
CustomApp.getInitialProps = wrapper.getInitialAppProps((_store) => async (appContext) => {
  const { ctx } = appContext;
  const { req, pathname } = ctx;

  const [initialData] = await Promise.all([
    getInitialData(__IS_SERVER__),
    __IS_SERVER__ && fetchInitialData(ctx),
  ]);

  // we call Next's internal `getInitialProps` (to get props for the current page) inside the if statement
  // because it needs to be called after fetchInitialData

  if (__IS_SERVER__) {
    handleCitrusForceProdPassthrough(ctx);
    const appProps = await App.getInitialProps(appContext);
    const deviceType = getDeviceType(req).toUpperCase(); // on server we return everything, labels, pageProps etc.

    if (!ctx.res?.headersSent && !appProps.pageProps.initialAppState) {
      throw new Error(
        `initialAppState is missing in ${pathname}. Make sure that you're returning it in getInitialProps.`
      );
    }

    return {
      ...appProps,
      deviceType,
    };
  } else {
    const appProps = await App.getInitialProps(appContext);
    // on client we return { pageProps, initialData, deviceType } object
    // initialData is needed to avoid bugs when people come to the website
    // however, an individual page's props should not be passed
    // down since it overwrites the next page.
    // subpages, like the shelf page, etc., directly from a URL

    return {
      ...appProps,
      ...initialData,
    };
  }
});

export default CustomApp;
