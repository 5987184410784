/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { FeatureFlag } from '@/features/globalConfig';
import { useAppState } from '@/stores/AppState';
import Script from 'next/script';
import { getTealiumBaseUrl } from './utils/getTealiumBaseUrl';

export const UtagSync = () => {
  const { proxiedHostname } = useAppState().state;

  return (
    <FeatureFlag flag="DISABLE_UTAG_SYNC" negate>
      <Script
        src={`${getTealiumBaseUrl(proxiedHostname || '')}/utag.sync.js`}
        type="text/javascript"
        strategy="beforeInteractive"
      />
    </FeatureFlag>
  );
};
