/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from '@/constants/locale';
import type { Locale } from '@/types/i18n';
import { Label } from '@/features/i18n';
import { NAME, NAME_MX } from '../validatorRegex';

export function validateCityName(value: string, locale?: Locale | null) {
  if (value && value.replace(/ /g, '').length < 1) {
    return <Label label="error_Validation_YouAreMissingACityName" />;
  } else if (
    (locale === countryCodes.mx && !NAME_MX.test(value)) ||
    (locale !== countryCodes.mx && !NAME.test(value))
  ) {
    return <Label label="label_MyAccountMyProfile_editProfile_PleaseEnterAValidCity" />;
  } else {
    return undefined;
  }
}
