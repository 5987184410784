/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import SearchSuggestions from '@/components/SearchSuggestions';
import styles from './SearchBox.module.scss';
import { setCookie } from '@/utils/cookie';
import { cookieConstant } from '@/constants/cookies';
import { SuggestionsData } from '@/features/search';
import { sessionStorage } from '../../../utils/sessionStorage';
import cx from 'classnames';

type Props = {
  suggestions: SuggestionsData;
  searchDrawerOpen: boolean;
  isMobile: boolean;
  accordionDispatch: (arg: { dimensionName: string; expanded: boolean }) => void;
  onSearchTextChange: (text: string) => void;
  updateAnalytics: (suggestion?: string) => void;
  cursor: number;
  handleClose: () => void;
  searchText: string;
  addRecentSearch: (searchTerm: string) => void;
  filteredRecentSearches: string[];
};

const Suggestions = ({
  suggestions,
  searchDrawerOpen,
  isMobile,
  accordionDispatch,
  onSearchTextChange,
  updateAnalytics,
  cursor,
  handleClose,
  searchText,
  addRecentSearch,
  filteredRecentSearches,
}: Props) => {
  if (isMobile && !searchDrawerOpen) {
    return null;
  }

  if (
    !searchDrawerOpen ||
    (suggestions.generalSuggestions.length === 0 && !suggestions.productTitleSuggestions?.length)
  ) {
    return null;
  }

  return (
    <div
      className={cx(styles.suggestionParentCont, {
        [styles.hiddenOverflow]:
          suggestions.productTitleSuggestions?.length === 1 &&
          !suggestions.generalSuggestions.length,
      })}
    >
      <div
        data-testid="search-suggestion-container"
        id="search-suggestion-container"
        className={styles.suggestionContainer}
      >
        <SearchSuggestions
          suggestions={suggestions}
          onSuggestionClick={(suggestion: string, type: 'Most Popular' | 'Type Ahead'): void => {
            sessionStorage.setItem('searchMethod', type);
            setCookie(cookieConstant.names.filterOpenStates, '');
            accordionDispatch({ dimensionName: 'clearAccordionOpenStates', expanded: false });
            if (type === 'Most Popular') {
              onSearchTextChange(suggestion);
            } else {
              sessionStorage.setItem('productFindingMethod', 'Internal Search');
              sessionStorage.setItem('typeAheadValue', suggestion);
            }
            updateAnalytics(suggestion);
            addRecentSearch(suggestion);
          }}
          cursor={cursor}
          handleClose={handleClose}
          searchText={searchText}
          filteredRecentSearches={filteredRecentSearches}
        />
      </div>
    </div>
  );
};

export default Suggestions;
