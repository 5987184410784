/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import logger from '@/utils/logger';

export function getTealiumBaseUrl(hostname = ''): string {
  const url = '//tags.tiqcdn.com/utag/autozone/';
  const isDev = __DEV__ || hostname.includes('dev');
  const mexico = isDev ? 'b2c-responsive-mexico-sandbox' : 'b2c-responsive-mexico';
  const us = isDev ? 'b2c-responsive-us-sandbox' : 'b2c-responsive-us';
  const br = 'azbrazil';
  const partURL = hostname.includes('mx')
    ? url + mexico
    : hostname.includes('br')
    ? url + br
    : url + us;

  if (typeof window === undefined) {
    // eslint-disable-next-line testing-library/no-debugging-utils
    logger.debug({
      message: `current hostname: ${hostname || 'not available'}`,
    });
  }

  switch (hostname) {
    case 'www.autozone.com':
    case 'autozone.com':
    case 'm.autozone.com':
    case 'www.autozone.com.mx':
    case 'autozone.com.mx':
    case 'm.autozone.com.mx':
    case 'www.autozone.com.br':
    case 'autozone.com.br':
      return `${partURL}/prod`;
    default:
      if (hostname.includes('pre-prod') || hostname.includes('staging')) {
        return `${partURL}/qa`;
      } else {
        return `${partURL}/dev`;
      }
  }
}
