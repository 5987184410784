/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { countryCodes } from '@/constants/locale';
import type { Locale } from '@/types/i18n';

export const formatPhoneNumber = (phoneNumber: string, locale?: Locale): string => {
  if (!phoneNumber) {
    return '';
  }

  if (locale === countryCodes.ptBr) {
    const enddigits = phoneNumber.slice(2);
    const startDigits = phoneNumber.slice(0, 2);
    const firstGroupEnd = enddigits.length === 9 ? 5 : 4;
    return `(${startDigits}) ${enddigits.slice(0, firstGroupEnd)}-${enddigits.slice(
      firstGroupEnd
    )}`;
  }

  const enddigits = phoneNumber.slice(3);
  const startDigits = phoneNumber.slice(0, 3);
  return `(${startDigits}) ${enddigits.slice(0, 3)}-${enddigits.slice(3)}`;
};
