/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import appData from './app';
import cartUpdate from './cartUpdate';
import checkout from './checkout';
import complete from './complete';
import loginConfirmation from './loginConfirmation';
import orders from './orders';
import paymentOption from './payment';
import shelf from './shelf';
import cartItemState from './cartItemState';

export const reducers = {
  appData,
  cartItemState,
  cartUpdate,
  checkout,
  complete,
  loginConfirmation,
  orders,
  paymentOption,
  shelf,
};
