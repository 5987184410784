/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import { ContactUsModalBody } from './ContactUsModalBody';
import styles from './ContactUsModal.module.scss';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

type Props = {
  isContactUsModalOpen: boolean;
  setIsContactUsModalOpen: (value: boolean) => void;
};

export function ContactUsModal({ isContactUsModalOpen, setIsContactUsModalOpen }: Props) {
  const [isQualtricsSelected, setIsQualtricsSelected] = useState(false);
  const locale = useLocale();
  const label = useLabel('label_contact_us_modal_what_would_you_like_to_do');
  const qualtricsUrl =
    locale === countryCodes.mx
      ? 'https://autoresearch.qualtrics.com/jfe/form/SV_6fiSZ2QhArinRvn'
      : 'https://autoresearch.qualtrics.com/jfe/form/SV_di0SGRQbqqFAfv7';

  const handleSelectQualtrics = () => {
    setIsQualtricsSelected(true);
  };

  const handleBackFromQualtrics = () => {
    setIsQualtricsSelected(false);
  };

  const handleCloseModal = () => {
    setIsContactUsModalOpen(false);

    if (isQualtricsSelected) {
      handleBackFromQualtrics();
    }
  };

  return (
    <Modal
      body={
        isQualtricsSelected ? (
          <iframe className={styles.qualtricsIframe} src={qualtricsUrl} />
        ) : (
          <ContactUsModalBody
            onCloseModal={handleCloseModal}
            onSelectQualtrics={handleSelectQualtrics}
          />
        )
      }
      navV2
      isOpen={isContactUsModalOpen}
      desktopSize={isQualtricsSelected ? 'large' : 'small'}
      tabletSize="fullscreen"
      showCloseButton
      handleClose={handleCloseModal}
      showBackButton={isQualtricsSelected}
      handleBack={handleBackFromQualtrics}
      role="dialog"
      ariaLabel={label}
      ariaModal={true}
    />
  );
}
