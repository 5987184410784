/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { Fragment } from 'react';
import Hidden from '@/components/Hidden';
import { useRouter } from 'next/router';
import ContentContainer from '@/components/AZCustomComponent/ContentContainer';
import { Button } from '@/components/Button';
import { sessionStorage } from '@/utils/sessionStorage';
import AZLogo from '@/components/AZCustomComponent/AZLogo';
import { routePaths } from '@/constants/routePaths';
import NextImage from '@/components/NextImage';
import { countryCodes } from '@/constants/locale';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { useFeatureFlag } from '@/features/globalConfig';
import { useStoreDetailsData } from '../../api/getStoreDetails';

export const Logo = () => <AZLogo variant="Full" customClassName={styles.azLogoFull} />;
const lableMap = {
  label_cart_checkout: 'label_cart_checkout',
  label_checkout_os_BackToCart: 'label_checkout_os_BackToCart',
};

export const HeaderSecondary = () => {
  const router = useRouter();
  const labels = useLabels(lableMap);
  const checkoutVersionEnableV3_us = useFeatureFlag('CHECKOUT_V3_ENABLED_US') === 'true';
  const checkoutVersionEnableV1_us = useFeatureFlag('CHECKOUT_V1_ENABLED_US') === 'true';
  const checkoutVersionEnableV2_us = useFeatureFlag('CHECKOUT_V2_ENABLED_US') === 'true';
  const checkoutVersionEnableV1_mx = useFeatureFlag('CHECKOUT_V1_ENABLED_MX') === 'true';
  const checkoutVersionEnableV2_mx = useFeatureFlag('CHECKOUT_V2_ENABLED_MX') === 'true';
  const checkoutVersionEnableV3_mx = useFeatureFlag('CHECKOUT_V3_ENABLED_MX') === 'true';
  const locale = useLocale();
  const isMX = locale === countryCodes.mx;
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;

  return (
    <Fragment>
      <header className={styles.checkoutHeader} role="banner">
        <ContentContainer
          type="simple"
          withPadding
          isTransparent
          className={checkoutVersionEnableV3_us && !isMX ? styles.headerBoxV3 : styles.headerBox}
        >
          {checkoutVersionEnableV3_us ||
          checkoutVersionEnableV3_mx ? null : checkoutVersionEnableV1_us ||
            checkoutVersionEnableV2_us ||
            checkoutVersionEnableV1_mx ||
            checkoutVersionEnableV2_mx ? (
            <Hidden mdUp>
              <Button
                data-testid="checkout-to-cart-back-button"
                variant="ghost"
                leftArrow
                discreet
                customClass={styles.backButton}
                onClick={() => {
                  sessionStorage.setItem('fromCheckout', 'checkout');
                  router.push(routePaths.cart);
                }}
              >
                {labels.label_checkout_os_BackToCart}
              </Button>
            </Hidden>
          ) : (
            <Hidden mdUp>
              <Button
                data-testid="checkout-to-cart-back-button"
                variant="ghost"
                leftArrow
                discreet
                customClass={styles.backButton}
                onClick={() => {
                  sessionStorage.setItem('fromCheckout', 'checkout');
                  router.push(routePaths.cart);
                }}
              >
                {labels.label_checkout_os_BackToCart}
              </Button>
            </Hidden>
          )}
          {checkoutVersionEnableV3_us || checkoutVersionEnableV3_mx ? (
            <div className={styles.azLogoFullV3}>
              <Logo />
            </div>
          ) : checkoutVersionEnableV1_us ||
            checkoutVersionEnableV2_us ||
            checkoutVersionEnableV1_mx ||
            checkoutVersionEnableV2_mx ? (
            <Logo />
          ) : (
            <Logo />
          )}
          {(checkoutVersionEnableV3_us ||
            (checkoutVersionEnableV3_mx && isBopusMexicoStoreEnabled)) && (
            <div>
              <NextImage
                src="/images/icon-lock.svg"
                alt=""
                width={9.33}
                height={13.33}
                className={styles.lockIcon}
                data-testid="checkout-title-img"
              />
              <h1 className={styles.checkoutTitle}> {labels.label_cart_checkout}</h1>
            </div>
          )}
        </ContentContainer>
      </header>
    </Fragment>
  );
};
