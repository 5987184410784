/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * These cookies are used on endpoints whose results are dependent on the vehicle the user has
 * selected.
 */
import { deleteCookie, setCookie } from '@/utils/cookie';
import { analyticsGlobalPreferredVehicle } from './analyticsGlobalPreferredVehicle';

const vehicleCookies = [
  'preferredVehicleYear',
  'preferredVehicleMake',
  'preferredVehicleModel',
  'preferredVehicleEngine',
  'preferredVehicleId',
] as const;

export function setPreferredVehicleCookies({
  vehicleYear,
  vehicleMake,
  vehicleModel,
  vehicleId,
  vehicleEngine,
}: {
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleId: string;
  vehicleEngine: string;
}) {
  if (!__IS_SERVER__) {
    setCookie('preferredVehicleYear', vehicleYear);
    setCookie('preferredVehicleMake', vehicleMake);
    setCookie('preferredVehicleModel', vehicleModel);
    setCookie('preferredVehicleEngine', vehicleEngine);
    setCookie('preferredVehicleId', vehicleId);

    analyticsGlobalPreferredVehicle();
  }
}

export function deletePreferredVehicleCookies() {
  if (!__IS_SERVER__) {
    vehicleCookies.forEach((info) => {
      deleteCookie(info);
    });

    // vehicle cookies are connected with utag_data
    if (window.utag_data !== undefined) {
      vehicleCookies.forEach((info) => {
        window.utag_data = {
          ...window.utag_data,
          ...{ [info]: '' },
        };
      });
    }
  }
}
