/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import type { Ref } from 'react';
import cx from 'classnames';
import { Backdrop } from '../Backdrop/Backdrop';
import type { Props as BackdropProps } from '../Backdrop/Backdrop';
import StickyButtonContainer from '../StickyButtonContainer/StickyButtonContainer';
import { Paper } from '../Paper';
import { useLabel } from '@/hooks/useLabels';
import type { Props as PaperProps } from '../Paper';
import { pascalCase } from '../../utils/pascalCase';
import styles from './Drawer.module.scss';

type Props = {
  /**
   * Method that will be used for two things:
   *     - Rendering a Sticky container (and button) in the bottom of the drawer.
   *     - Triggering a callback logic when the Sticky container button is clicked.
   */
  handleStickyButtonClick?: () => void;
  /**
   * Sticky Button label
   */
  stickyBtnLabel?: string;
  /**
   * Side from which the drawer will appear.
   */
  anchor?: 'left' | 'right';

  /**
   * @ignore
   */
  BackdropProps?: BackdropProps;

  /**
   * The contents of the drawer.
   */
  children?: React.ReactNode;

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes?: {
    root?: string;
    paper?: string;
    paperAnchorRight?: string;
    paperAnchorLeft?: string;
  };

  /**
   * @ignore
   */
  className?: string;

  /**
   * Callback fired when the component requests to be closed.
   *
   * @param {object} event The event source of the callback.
   */
  onClose?: (event: React.MouseEvent<HTMLElement>, option?: boolean) => unknown;

  /**
   * If `true`, the drawer is open.
   */
  open?: boolean;

  /**
   * Props applied to the [`Paper`](/api/paper/) element.
   */
  PaperProps?: PaperProps;

  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   */
  transitionDuration?: number;
  style?: any;
  id?: string;
  drawerRef?: Ref<any>;
  disableScrolling?: boolean;
};
/**
 * The props of the [Modal](/api/modal/) component are available
 * when `variant="temporary"` is set.
 */

export function Drawer({
  anchor = 'left',
  BackdropProps,
  children,
  classes,
  className,
  stickyBtnLabel = 'Apply',
  handleStickyButtonClick,
  onClose,
  open = false,
  PaperProps,
  transitionDuration = 200,
  disableScrolling = true,
  drawerRef,
  ...other
}: Props) {
  const [closingTransitionEnd, setClosingTransitionEnd] = React.useState(true); // Block scrolling when search box is in focus
  const lblApply = useLabel('label_ShelfPage_leftSideBar_Apply');
  const stickyLabel = stickyBtnLabel || lblApply;

  // this function removes rubberband scrolling on the drawer
  // which caused for the body behind of drawer to scroll
  // this function disables scrolling when the drawer content is not long enough to cause scrolling on the drawer
  const removeRubberBand = (elementClass: string) => {
    const overlay = document.getElementsByClassName(elementClass)[0] as HTMLElement;
    let initialClientY: number;

    overlay.addEventListener(
      'touchstart',
      function (event) {
        if (event.targetTouches.length === 1) {
          initialClientY = event.targetTouches[0].clientY;
        }
      },
      false
    );

    overlay.addEventListener(
      'touchmove',
      function (event) {
        if (event.targetTouches.length === 1) {
          disableRubberBand(event);
        }
      },
      false
    );

    function disableRubberBand(event: TouchEvent) {
      const clientY = event.targetTouches[0].clientY - initialClientY;

      if (overlay.scrollTop === 0 && clientY > 0) {
        // element is at the top of its scroll
        event.preventDefault();
      }

      if (isOverlayTotallyScrolled() && clientY < 0) {
        //element is at the top of its scroll
        event.preventDefault();
      }
    }

    function isOverlayTotallyScrolled() {
      return overlay.scrollHeight - overlay.scrollTop <= overlay.clientHeight;
    }
  };

  // Block scrolling when search box is in focus
  React.useEffect(() => {
    if (open && disableScrolling) {
      document.body?.style.setProperty('overflow', 'hidden');
      removeRubberBand(styles.paper);
    }

    return () => {
      document.body?.style.removeProperty('overflow');
    };
  }, [open, disableScrolling]);

  // We use closingTransitionEnd to visually hide Drawer once it's outside of
  // the window
  React.useEffect(() => {
    let timeout: any;

    if (!open) {
      timeout = setTimeout(() => {
        setClosingTransitionEnd(true);
      }, transitionDuration);
    } else {
      setClosingTransitionEnd(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [open, transitionDuration]);
  return (
    <div
      className={cx(
        classes?.root,
        styles.root,
        {
          [styles.open]: open,
        },
        className
      )}
      role="presentation"
      aria-hidden={!open}
      {...other}
    >
      <Backdrop onClick={onClose} open={open} {...BackdropProps} />
      <Paper
        elevation={0}
        square
        ref={drawerRef}
        {...PaperProps}
        className={cx(
          cx(classes?.paper, styles.paper),
          cx(
            // @ts-expect-error
            classes?.[`paperAnchor${pascalCase(anchor)}`],
            styles[`paperAnchor${pascalCase(anchor)}`]
          ),
          {
            [styles[`paperOpen${pascalCase(anchor)}`]]: open,
            [styles[`paperClosing${pascalCase(anchor)}`]]: !open && !closingTransitionEnd,
          },
          { [styles.stickyContainerShowing]: !!handleStickyButtonClick },
          PaperProps?.className
        )}
      >
        {!closingTransitionEnd ? children : null}
      </Paper>
      {handleStickyButtonClick ? (
        <StickyButtonContainer
          containerClassName={cx({
            [styles[`stickyBtnContainerOpen${pascalCase(anchor)}`]]: open,
            [styles[`stickyBtnContainerClosing${pascalCase(anchor)}`]]:
              !open && !closingTransitionEnd,
          })}
          onClick={handleStickyButtonClick}
          tabIndex={open ? 0 : -1}
          onBlur={() => {
            (
              document.querySelectorAll(
                '[data-testid="close-filter-drawer-button"]'
              )[0] as HTMLElement
            )?.focus();
          }}
        >
          {stickyLabel}
        </StickyButtonContainer>
      ) : null}
    </div>
  );
}
