/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const BREAKPOINTS = {
  MOBILE_MAX: 719,
  TABLET_MIN: 720,
  TABLET_MAX: 1139,
  DESKTOP_MIN: 1140,
};
