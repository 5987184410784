/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * map an seoURL: '/batteries-starting-and-charging/battery/b/brand/acdelco~duralast-platinum' to an array as below
 * [
 *    '/batteries-starting-and-charging/battery',
 *    '/batteries-starting-and-charging/battery/b/brand/acdelco',
 *    '/batteries-starting-and-charging/battery/b/brand/duralast-platinum'
 * ]
 *
 * seoURL: '/motor-oil-and-transmission-fluid/engine-oil/bn' to
 * [
 *   '/motor-oil-and-transmission-fluid/engine-oil'
 * ]
 */
export const mapToIndividualBrandUrl = (seoURLPath: string) => {
  const parts = seoURLPath.split('/b/brand/');
  if (parts.length === 2) {
    const brandUrls = parts[1].split('~').map((brand) => `${parts[0]}/b/brand/${brand}`);
    return [parts[0], ...brandUrls];
  }
  return [seoURLPath.replace(/(\/bn\/?|\/b\/).*/, '')];
};

/**
 * Extract brandURl from taxonomyPaths.
 * for seoURLPath '/batteries-starting-and-charging/battery/b/brand/acdelco~duralast-platinum'
 * it returns ['/b/brand/acdelco','/b/brand/duralast-platinum']
 *
 * seoURL '/motor-oil-and-transmission-fluid/engine-oil/bn' return blank array
 */
export const getBrandURL = (seoURLPath: string) => {
  const parts = seoURLPath.split('/b/brand/');
  if (parts.length === 2) {
    return parts[1].split('~').map((brand) => `/b/brand/${brand}`);
  }
  return [];
};

/**
 * Returns true if firstPath startsWith secondPath
 * isPathStartsWith('/battery/b/brand/acdelco','/battery') - true
 * isPathStartsWith('/battery/b/brand/duralast-platinum','/battery/b/brand/duralast') - false
 * @param firstPath
 * @param secondPath
 * @returns boolean
 */
export const isPathStartsWith = (firstPath: string, secondPath: string) => {
  if (firstPath === secondPath) return true;
  const firstPathTokens = firstPath.split('/');
  const secondPathTokens = secondPath.split('/');
  return secondPathTokens.every((t, i) => firstPathTokens[i] === t);
};

/**
 * @param pageTaxonomyPaths are like:
 *  [
 *    '/parts/batteries-starting-and-charging/battery',
 *    '/parts/batteries-starting-and-charging/battery/b/brand/acdelco',
 *    '/parts/batteries-starting-and-charging/battery/b/brand/duralast-platinum'
 *  ]
 * cmsItemTaxonomyPaths is like:
 *  [
 *    '/parts/batteries-starting-and-charging',
 *    '/interior-accessories/stereo-speakers-and-audio/stereo',
 *    '/parts/filters-and-pcv/oil-filter-and-oil-filter-accessories',
 *  ]
 *  this function checks if there is item in @param pageTaxonomyPaths which startsWith any item in cmsItemTaxonomyPaths
 */
export const isTaxonomyPathMatching = <
  T extends {
    taxonomy?: boolean;
    taxonomy_selector?: { invertSelection: boolean; selectedItems: unknown };
  }
>(
  CMSItem: T,
  pageTaxonomyPaths: string[],
  exactMatch = false
): boolean => {
  if (!CMSItem.taxonomy) {
    return true;
  }

  // this part is for entries that had taxonomies selected before the invert selection flag was added
  if (Array.isArray(CMSItem.taxonomy_selector)) {
    const cmsItemTaxonomyPaths = CMSItem.taxonomy_selector.map(
      ({ seoUrl }: { seoUrl: string }) => seoUrl
    );
    return pageTaxonomyPaths.some((pageTaxonomyPath) =>
      cmsItemTaxonomyPaths.some((cmsItemTaxonomyPath) =>
        exactMatch
          ? pageTaxonomyPath === cmsItemTaxonomyPath
          : isPathStartsWith(pageTaxonomyPath, cmsItemTaxonomyPath)
      )
    );
  }

  if (typeof CMSItem.taxonomy_selector === 'object' && CMSItem.taxonomy_selector) {
    const shouldInvertSelection = CMSItem.taxonomy_selector.invertSelection;
    const selectedItems = CMSItem.taxonomy_selector.selectedItems;
    if (Array.isArray(selectedItems)) {
      const cmsItemTaxonomyPaths = selectedItems.map(({ seoUrl }: { seoUrl: string }) => seoUrl);

      const matches = pageTaxonomyPaths.some((pageTaxonomyPath) =>
        cmsItemTaxonomyPaths.some((cmsItemTaxonomyPath) =>
          exactMatch
            ? pageTaxonomyPath === cmsItemTaxonomyPath
            : isPathStartsWith(pageTaxonomyPath, cmsItemTaxonomyPath)
        )
      );
      return shouldInvertSelection ? !matches : matches;
    }
  }
  return false;
};
