/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AppState, AppStateAction } from '../interface';
import { ActionTypes } from '../action-types';

const setProxiedHostName = (state: AppState, payload: any): AppState => {
  return {
    ...state,
    proxiedHostname: payload.proxiedHostname,
  };
};

export const serverParamReducer = (state: AppState, action: AppStateAction): AppState => {
  switch (action.type) {
    case ActionTypes.SERVER_PROXIEDHOSTNAME_SET:
      return setProxiedHostName(state, action.payload);
    default:
      return state;
  }
};
