/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { AnyAction } from 'redux';
import type { AppData } from '../types';
import actionTypes from '../types/action';
type State = AppData;

export const initialState: State = {
  isHeaderPresent: true,
  isSecondaryHeaderFooterPresent: false,
  isFooterPresent: true,
  isChatAgentAvailable: false,
  isFetchingLocation: false,
  appBannerShown: true,
  userAuthenticated: false,
  deviceType: '',
  userAgent: '',
  canShowToastNotification: false,
  chatAgentRequestStatus: 'idle',
};

const appData = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case actionTypes.SET_CAN_SHOW_TOAST_NOTIFICATION:
      return {
        ...state,
        canShowToastNotification: action.canShowToastNotification,
      };
    case actionTypes.SET_HEADER_STATE:
      return { ...state, isHeaderPresent: action.status };

    case actionTypes.SET_SECONDARY_HEADER_FOOTER_STATE:
      return { ...state, isSecondaryHeaderFooterPresent: action.status };

    case actionTypes.SET_CHAT_AGENT_STATUS:
      return {
        ...state,
        isChatAgentAvailable: action.status,
        chatAgentRequestStatus: action.requestStatus,
      };

    case actionTypes.SET_FETCHING_LOCATION_STATUS:
      return { ...state, isFetchingLocation: action.status };

    case actionTypes.SET_FOOTER_STATE:
      return { ...state, isFooterPresent: action.status };

    case actionTypes.UPDATE_AUTHENTICATION:
      return { ...state, userAuthenticated: action.sessionAuthState };

    case actionTypes.SET_DEVICE_INFO:
      return {
        ...state,
        deviceType: action.deviceInfo.type,
        userAgent: action.deviceInfo.userAgent,
      };

    case actionTypes.SET_APP_BANNER_SHOWN:
      return { ...state, appBannerShown: action.appBannerShown };

    default:
      return state;
  }
};

export default appData;
