/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { SmartLink as Link } from '../../../utils/smartLink';
import azCommonStyles from '../../../theme/globals.module.scss';
import { Button } from '@/components/Button';
import Image from '@/components/NextImage';
import { TopPencilNav } from '../../PencilTopNavComp';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import { useEagerLoad } from '../../../hooks/useEagerLoad';
import styles from './styles.module.scss';

const pencilContentConstant = {
  light: 'light',
  shippingTruckLogo: 'Shipping Truck Logo',
  pencilNav: 'PencilNav',
};

const Theme = ({
  ctaLink1,
  ctaName1,
  altText1,
  imagePath1,
  theme,
  text = '',
  eager,
}: {
  ctaLink1: string;
  ctaName1: string;
  altText1: string;
  imagePath1: string;
  theme: string;
  text: string;
  eager: boolean;
}) => {
  const themeClassName =
    theme && theme.toLowerCase() === pencilContentConstant.light
      ? styles.lightTheme
      : styles.darkTheme;
  const imgUrl = imagePath1;
  const themeButtonClass =
    theme && theme.toLowerCase() === pencilContentConstant.light
      ? `${styles.ctaLink} ${styles.lightAZBtn}`
      : `${styles.ctaLink} ${styles.darkAZBtn}`;

  return (
    <div className={azCommonStyles['az-margin-bottom-s']}>
      <Link to={ctaLink1 || '#'}>
        <div>
          <div className={themeClassName}>
            {imgUrl && (
              <Image
                priority={eager}
                src={imgUrl}
                alt={altText1}
                className={styles.shippingTruckLogo}
                width={30}
                height={20}
              />
            )}
            <div
              className={styles.dynamicContent}
              dangerouslySetInnerHTML={(() => ({
                __html: text,
              }))()}
            />
            <Button variant="ghost" rightArrow customClass={themeButtonClass} fakeButton={true}>
              {ctaName1}
            </Button>
          </div>
        </div>
      </Link>
    </div>
  );
};

const PencilNavWithLink = ({ data, dataLink }: { data: any; dataLink: string }) => {
  if (dataLink) {
    return (
      <Link to={dataLink}>
        <TopPencilNav data={data} />
      </Link>
    );
  }

  return <TopPencilNav data={data} />;
};

type Props = {
  data: any;
};

const PencilComponent = ({ data }: Props) => {
  const eager = useEagerLoad({
    isEagerLoadDesktop: data?.isEagerLoadDesktop,
    isEagerLoadMobile: data?.isEagerLoadMobile,
    isEagerLoadTablet: data?.isEagerLoadTablet,
  });

  if (!data) {
    return null;
  }

  const {
    link,
    layoutCode,
    themeCode,
    text1,
    desktop,
    tablet,
    mobile,
    ctaLink1,
    ctaName1,
    altText1,
    imagePath1,
  } = data;

  const text = text1?.replace(/\n/g, '');

  if (layoutCode === pencilContentConstant.pencilNav) {
    return <PencilNavWithLink data={data} dataLink={link} />;
  }

  return (
    <DeviceWrapper desktop={desktop || false} tablet={tablet || false} mobile={mobile || false}>
      <Theme
        eager={eager}
        ctaLink1={ctaLink1}
        ctaName1={ctaName1}
        altText1={altText1}
        imagePath1={imagePath1}
        theme={themeCode}
        text={text}
      />
    </DeviceWrapper>
  );
};

export default PencilComponent;
