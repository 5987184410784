/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRef, useState } from 'react';
import { Button, Hidden, Icon, Text, View } from '@az/starc-ui';
import { Close, Hamburger } from '@az/starc-ui-icons';
import styles from './BrazilHeader.module.scss';
import { useCMSTopNavContent } from '@/features/topNav/api/getCMSTopNav';
import { useScrollBelowThreshold } from '@/hooks/useScrollBelowThreshold';
import NextImage from '@/components/NextImage';
import { SmartLink as Link } from '@/utils/smartLink';
import { Drawer } from '@/components/Drawer/Drawer';
import { NavItem } from './NavItem';

export function BrazilHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef<HTMLElement | null>(null);
  const close = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent) => {
    event && event.stopPropagation();
    setIsOpen(false);
  };

  const toggle = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent) => {
    event && event.stopPropagation();
    setIsOpen((s) => {
      return !s;
    });
  };

  const { data: topNavData } = useCMSTopNavContent({
    enabled: true,
  });
  const [scrolled, setScrolled] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRefCallback = (element: HTMLElement | null) => {
    headerRef.current = element;

    if (element && headerHeight < 1) {
      setHeaderHeight(element.getBoundingClientRect().height);
    }
  };

  useScrollBelowThreshold(
    (belowThreshold) => {
      if (scrolled != belowThreshold) {
        setScrolled(belowThreshold);
      }
    },
    [scrolled, headerHeight],
    headerHeight
  );

  return (
    <>
      <View
        direction="row"
        padding={5}
        shadow="medium"
        align="center"
        justify="space-between"
        className={styles.headerContainer}
        attributes={{
          ref: headerRefCallback,
          onClick: close,
          style: {
            position: !scrolled ? 'relative' : 'fixed',
          },
        }}
      >
        <Link to="/">
          <NextImage
            src="/images/AZBrasilLogoV2.svg"
            width="188"
            height="50"
            alt="AutoZone"
            className={styles.logo}
            priority
          />
        </Link>
        <View.Item>
          <Button variant="ghost" onClick={toggle} className={styles.menuButton}>
            <View justify="center" direction="column" align="center" gap={1}>
              <Icon
                svg={isOpen ? Close : Hamburger}
                attributes={{ style: { margin: 0 } }}
                size={{ s: 6, m: 7 }}
              />
              <Text textCase="capitalize" size="062" color="500" className={styles.hamburgerMenu}>
                {isOpen ? 'Fechar' : 'Menu'}
              </Text>
            </View>
          </Button>
        </View.Item>
      </View>
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={close}
        className={styles.drawerWrapper}
        PaperProps={{ className: styles.drawerContent }}
        BackdropProps={{ className: styles.backdrop }}
      >
        <Hidden hide={{ s: false, m: true }}>
          <View
            backgroundColor="primary"
            direction="row"
            justify="space-between"
            align="center"
            padding={{ s: [1, 4] }}
          >
            <Text variant="button" color="secondary" className={styles.menuTitle}>
              MENU
            </Text>
            <Button variant="ghost" onClick={close} className={styles.closeButton}>
              <Icon svg={Close} size={6} color="secondary" />
            </Button>
          </View>
        </Hidden>
        <View padding={{ s: [5, 0] }}>
          {topNavData?.menuList?.map((menuLinkList, index) => (
            <>
              {menuLinkList.links?.map((menuLink) => (
                <>
                  <NavItem
                    close={close}
                    link={menuLink.menu_link}
                    target={menuLink.target}
                    key={`${menuLink.menu_link.title}-${index}`}
                  />
                  {menuLink.child_links &&
                    menuLink.child_links.map(
                      (childLink, index) =>
                        childLink.menu_link && (
                          <NavItem
                            isNested
                            close={close}
                            link={childLink.menu_link}
                            key={`${menuLink.menu_link.title}-${index}-${index}`}
                            target={childLink.target}
                          />
                        )
                    )}
                </>
              ))}
            </>
          ))}
        </View>
      </Drawer>
    </>
  );
}
