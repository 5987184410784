/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const stringifyUrl = (
  {
    url,
    query,
  }: {
    url: string;
    query: Record<string, string | string[] | number | boolean | null | undefined>;
  },
  options?: {
    skipNull?: boolean;
  }
): string => {
  if (options?.skipNull) {
    Object.keys(query).forEach((key) => {
      if (query[key] == null) {
        delete query[key];
      }
    });
  }

  // @ts-expect-error extended query string
  const URLQuery = new URLSearchParams(query);
  return `${url}${Object.keys(query).length ? `?${URLQuery.toString()}` : ''}`;
};
