/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { usePageType } from '@/features/category/api/getPageType';
import { useCMSHeaderContent } from '../api/getCMSHeaderContent';
import { useRouter } from 'next/router';
import { isFilterableByTaxonomy } from '@/features/contentstack/utils/typeGuards/isFilterableByTaxonomy';
import { isTaxonomyPathMatching } from '@/features/contentstack/utils/isTaxonomyPathMatching';
import { useEffect, useMemo } from 'react';
import { mapHeaderPromoFromContentstackToXM } from '../utils/mapHeaderPromoFromContentstackToXM';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { pageTypes } from '@/constants/page';
import { useAppState } from '@/stores/AppState';
import { saveUIState } from '@/stores/actions';

export const useTaxonomyFilteredHeaderPromo = () => {
  const router = useRouter();
  const { data: cmsHeaderData } = useCMSHeaderContent();
  const isSEOPage = router.route === '/[...seoUrlPath]';
  const isPDP = router.route === '/products/[...slug]';

  const { data: pageTypeData } = usePageType({
    enabled: isSEOPage,
    keepPreviousData: true,
  });

  const { data: productDetailsData } = useProductDetails({
    enabled: isPDP,
    keepPreviousData: true,
  });

  const { state: appState, dispatch } = useAppState();

  // This is need to preserve the previous taxonomy path when navigating away from browse pages to PDP and vice-versa
  useEffect(() => {
    if (productDetailsData?.product?.taxonomyUrl) {
      dispatch(saveUIState({ previousTaxonomyPath: productDetailsData.product.taxonomyUrl }));
    }

    if (pageTypeData?.taxonomyPath) {
      dispatch(saveUIState({ previousTaxonomyPath: pageTypeData.taxonomyPath }));
    }
  }, [productDetailsData?.product?.taxonomyUrl, pageTypeData?.taxonomyPath, dispatch]);

  const isCategoryPage = pageTypeData?.pageType === pageTypes.CategoryGrouping;

  const taxonomyPath =
    productDetailsData?.product?.taxonomyUrl ||
    pageTypeData?.taxonomyPath ||
    (isSEOPage || isPDP ? appState.previousTaxonomyPath : '');

  const taxonomySpecificPromos = cmsHeaderData?.taxonomySpecificPromos;

  const maybeFilteredPromo = useMemo(
    () =>
      taxonomySpecificPromos?.find((promo) => {
        if (taxonomyPath && isFilterableByTaxonomy(promo)) {
          const isMatchingTaxonomy = isTaxonomyPathMatching(promo, [taxonomyPath], isCategoryPage);
          if (isMatchingTaxonomy) {
            return true;
          }
        }
        return false;
      }),
    [taxonomyPath, taxonomySpecificPromos, isCategoryPage]
  );

  if (!taxonomySpecificPromos?.length || !taxonomyPath) {
    return null;
  }

  return maybeFilteredPromo ? mapHeaderPromoFromContentstackToXM(maybeFilteredPromo) : null;
};
