/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { Fragment } from 'react';
import DynamicContent from '../DynamicContent';
import DeviceWrapper from '../AZCustomComponent/DeviceWrapper';
import styles from './styles.module.scss';

type Props = {
  data: any;
};

const renderTheme = (text = '', content: any) =>
  content && (
    <DynamicContent
      tagName="div"
      attrs={{
        className: styles.headerPencilAd,
      }}
      innerHtml={text}
    />
  );

export function TopPencilNav(props: Props) {
  const data = props?.data;
  const contentBlock = data?.contentBlock;
  const pencilcontents = contentBlock?.childContentBlockList;
  const content = pencilcontents?.pencilAdContentBlock1 || pencilcontents?.pencilAdContentBlock2;
  const text = content?.text?.replace(/\n/g, '');
  const desktop = contentBlock?.desktop || false;
  const tablet = contentBlock?.tablet || false;
  const mobile = contentBlock?.mobile || false;

  return (
    <DeviceWrapper desktop={desktop} tablet={tablet} mobile={mobile}>
      <Fragment>{renderTheme(text, content)}</Fragment>
    </DeviceWrapper>
  );
}

export default TopPencilNav;
