/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useLayoutEffect, useEffect } from 'react';

// @ts-expect-error refine
export const useIsomorphicLayoutEffect: (
  create: () => any,
  inputs?: ReadonlyArray<unknown> | null
) => void = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
