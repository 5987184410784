/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import DynamicContent from '../../DynamicContent';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import type { HTMLBlock } from '@/types/legacy';

type Props = {
  content: Omit<HTMLBlock, 'contentType'>;
};

const HtmlBlock = (props: Props) => {
  const { content, desktop, tablet, mobile } = props.content;

  const deviceClassName = {
    desktop: desktop,
    tablet: tablet,
    mobile: mobile,
  };

  return content ? (
    <DeviceWrapper {...deviceClassName}>
      <DynamicContent tagName="div" innerHtml={content} />
    </DeviceWrapper>
  ) : null;
};

export default HtmlBlock;
