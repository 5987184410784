/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const analyticsPageType = {
  dealsDealsSavings: 'deals_deals-savings',
  myzoneProfile: 'myzone_profile',
  customerServiceContactUs: 'customer-service_contact-us',
  myzoneOrderHistory: 'myzone_order-history',
  myzoneRewardsLanding: 'myzone_rewards',
  myzoneOrderDetails: 'myzone_order-details',
  myzoneVehiclesServiceHistory: 'myzone_vehicles_service-history',
  myzoneTrackOrder: 'myzone_track-order',
  cart: 'cart',
  myzoneRewardsCredit: 'myzone_rewards',
  myzoneCreateAccount: 'myzone_create-account',
  repairHelp: 'repair-help',
  repairHelpMaintenanceGuide: 'repair-help_maintenance-guide',
  myzoneVehicles: 'myzone_vehicles',
  myzone: 'myzone',
  continueAsGuest: 'continueAsGuest',
  catalogShopByMake: 'catalog_shop-by-make',
  orderConfirmation: 'order-confirmation',
  home: 'home',
  catalogProductDetail: 'catalog_product-detail',
  catalogShelf: 'catalog_shelf',
  checkout: 'checkout',
  myStore: 'mystore',
  myStoreLocator: 'store-locator',
  storeDetails: 'storedetails',
  overlay: 'Overaly',
  customerServiceRecalls: 'customer-service_recalls',
  giftCardBalance: 'giftcardbalance',
  signIn: 'signIn',
} as const;
