/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance, AxiosResponse } from 'axios';
// @ts-expect-error no typings
import { parse } from '@tusbar/cache-control';
import logger from '../../../utils/logger';
import { loggerURL } from '../../../config/serviceAPI';
import type { Store } from '../../../types';
import { deleteCookie } from '@/utils/cookie';

const createUpdateAuthStateInterceptor =
  (axiosInstance: AxiosInstance, store: Store) =>
  async (response: AxiosResponse): Promise<AxiosResponse> => {
    // don't even check for the jsession-authenticated header on API calls that are cached by the browser
    // since the jsession-authenticated value is old
    const cacheControlString =
      response.headers['cache-control'] || response.headers['CACHE-CONTROL'];
    const cacheControl = cacheControlString && parse(cacheControlString);

    if (cacheControl?.maxAge > 0) {
      return response;
    }

    /*
      The real reason why the header was being called twice was due to the fact that for many api
      calls, the response's jession-authenticated flag is returned by ATG as undefined, which caused
      the client to set it to false. We should instead ignore authentication checks for any calls
      where the jsession-authenticated is undefined.
    */

    const jsessionAuthenticated =
      response.headers['jsession-authenticated'] || response.headers['JSESSION-AUTHENTICATED'];

    if (jsessionAuthenticated !== undefined) {
      const newAuthenticationState = jsessionAuthenticated === 'true';
      const previousAuthenticationState = store.getState().appData.userAuthenticated;

      if (
        newAuthenticationState !== previousAuthenticationState &&
        response.config.url !== loggerURL
      ) {
        logger.warn({
          message: 'Received a new value for jsession-authenticated header on an API call',
          meta: {
            previous: previousAuthenticationState,
            next: newAuthenticationState,
            url: response.config.url,
          },
        });

        store.dispatch({
          type: 'UPDATE_AUTHENTICATION',
          sessionAuthState: newAuthenticationState,
        });

        if (!newAuthenticationState) {
          logger.warn({
            message:
              'jsession-authenticated header is false on API response. Signing the user out automatically',
          });

          deleteCookie('JSESSIONID');
        }
      }
    }
    return response;
  };

export default createUpdateAuthStateInterceptor;
