/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { isAxiosError } from './isAxiosError';

type ErrorWithMessage = {
  message: string;
};

export function toErrorWithMessage(
  maybeError: unknown,
  prioritizeDefaultMessage?: boolean
): ErrorWithMessage {
  if (isAxiosError(maybeError)) {
    if (prioritizeDefaultMessage) {
      let nestedError;
      if (Array.isArray(maybeError.response?.data.errorDetails)) {
        nestedError =
          maybeError.response?.data.errorDetails[0]?.errorMessageDefault ||
          maybeError.response?.data.errorDetails[0]?.errorMessageKey;
      }
      return new Error(
        nestedError ||
          maybeError.response?.data.errorMessageDefault ||
          maybeError.response?.data.errorMessageKey
      );
    } else {
      let nestedError;
      if (Array.isArray(maybeError.response?.data.errorDetails)) {
        nestedError =
          maybeError.response?.data.errorDetails[0]?.errorMessageKey ||
          maybeError.response?.data.errorDetails[0]?.errorMessageDefault;
      }
      return new Error(
        nestedError ||
          maybeError.response?.data.errorMessageKey ||
          maybeError.response?.data.errorMessageDefault
      );
    }
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}
