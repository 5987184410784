/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import * as React from 'react';
import cx from 'classnames';
import { IconButton } from '../../IconButton/IconButton';
import { Snackbar } from '../../Snackbar';
import CloseIcon from '../../../../public/images/close.svg';
import iconStyles from '../../../theme/iconStyles.module.scss';

type Props = {
  open: boolean;
  message: string | React.ReactElement;
  anchorOrigin: {
    vertical: 'bottom' | 'top';
    horizontal: 'center' | 'left' | 'right';
  };
  onClose?: () => void;
  className?: string;
  anchorClasses?: {
    anchorOriginBottom?: string | undefined;
  };
  autoHideDuration?: number;
  isClosable?: boolean;
};

const AZSnackBar = ({
  open,
  message,
  anchorOrigin,
  onClose,
  className,
  anchorClasses,
  autoHideDuration = 5000,
  isClosable = true,
}: Props) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      className={className}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      ContentProps={{
        'data-testid': 'snackbar-message',
      }}
      anchorClasses={anchorClasses}
      action={
        isClosable ? (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            data-testid="snack-bar-close-button"
          >
            <CloseIcon className={cx(iconStyles.defaultIconStyle, iconStyles.fontSizeSmall)} />
          </IconButton>
        ) : null
      }
    />
  );
};

export default AZSnackBar;
