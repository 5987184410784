/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// eslint-disable-next-line no-restricted-imports
import {
  deleteCookie as deleteCookieNext,
  setCookie as setCookieNext,
  getCookies as getCookiesNext,
} from 'cookies-next';
// eslint-disable-next-line no-restricted-imports
import type { CookieValueTypes, OptionsType } from 'cookies-next/lib/types';
import type { CookieName } from '@/types/cookieTypes';

export type { CookieValueTypes, OptionsType };

/**
 * Set of universal cookie utility functions
 * Works in both server and client seamlessly
 */

export const deleteCookie = (key: CookieName, options?: OptionsType): void => {
  if (__IS_SERVER__) {
    if (options?.req && options?.res) {
      deleteCookieNext(key, options);
    } else {
      throw new Error(
        `Cannot delete cookie on the server. req and/or res are missing for cookie - ${key}`
      );
    }
  }
  deleteCookieNext(key, options);
};

export const setCookie = (
  key: CookieName,
  value: CookieValueTypes,
  options?: OptionsType
): void => {
  if (__IS_SERVER__) {
    if (options?.req && options?.res) {
      return setCookieNext(key, value, options);
    } else {
      throw new Error(
        `Cannot set cookie on the server. req and/or res are missing for cookie - ${key}`
      );
    }
  }
  setCookieNext(key, value, options);
};

/**
 * Note: Prefer to use `getDecodedCookies`
 */
export const getCookies = getCookiesNext;

type CookieGetter<T> = {
  get: (key: T) => string | undefined;
};

/**
 * Parse cookies (server and client side) and URL decode them.
 *
 * Important: the getter returns the values for the cookies
 * when they were first decoded, not at the moment of being called.
 */
export const getDecodedCookies = <T extends CookieName>(
  keys: T[],
  options?: OptionsType
): CookieGetter<T> => {
  if (__IS_SERVER__ && !options?.req && !options?.res) {
    throw new Error('Cannot get cookies on the server. req and/or res are missing');
  }

  const cookies = getCookiesNext(options);
  const decodedCookies: Record<T, string | undefined> = keys.reduce((newCookies, key) => {
    const value = cookies[key];

    newCookies[key] = value === undefined ? undefined : decode(value);

    return newCookies;
  }, {} as Record<T, string | undefined>);

  const get: CookieGetter<T>['get'] = (k: T) => {
    if (k in decodedCookies) {
      return decodedCookies[k];
    } else {
      throw new Error(
        `Trying to read value for cookie "${k}", but it wasn't decoded. Add it to the parameters of getDecodedCookies`
      );
    }
  };

  return {
    get,
  };
};

const decode = (str: string): string => {
  if (!str) {
    return str;
  }

  return str.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
};
