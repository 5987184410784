/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AnyAction } from 'redux';
import type { CartUpdateData } from '@/types/reduxStore/cartUpdate';
import actionTypes from '../types/action';
const {
  STORECHANGE_FAILURE,
  STORECHANGE_SUCCESS,
  CHECKOUT_INVALID,
  CHECKOUT_VERIFY_FAILURE,
  CHECKOUT_VERIFY_REQUESTING,
  CHECKOUT_VERIFY_SUCCESS,
  PROMOCODE_APPLY_REQUESTING,
  PROMOCODE_APPLY_FAILURE,
  PROMOCODE_APPLY_SUCCESS,
  RESET_PROMO_CART,
  PROMOCODE_REMOVE_FAILURE,
  PROMOCODE_REMOVE_SUCCESS,
  CART_MINICART_FAILURE,
  CART_MINICART_SUCCESS,
  CART_VERIFY_REQUESTING,
  CART_VERIFY_SUCCESS,
  CART_VERIFY_FAILURE,
  CLEAR_CART_PAGE_DATA,
  EMPTY_DATA,
  PROMOCODE_REMOVE_REQUESTING,
  CART_SENDLISTTOSTORE_ORDERVERIFY_SUCCESS_INELIGIBLE,
} = actionTypes;

export const initialState = {
  readyStatus: 'ITEMQUANTITY_INVALID',
  // TODO: this value is not valid anymore, but changing it may result in unexpected behavior. Investigate
  err: null,
  orderSummaryData: {} as {},
  checkoutData: {} as {},
  quantityData: {} as {},
  removeData: {} as {},
  storeData: {} as {},
  promoData: null,
  promoMessage: false,
  commerceId: '',
  cartSuccessNote: false,
  cartMiniCartData: {} as {},
  checkoutMessageData: {} as {},
  rewardsData: {} as {},
  quantityValue: 0,
  cartData: {} as {},
  cartItemError: {} as {},
  errorValue: null,
  promoCode: '',
};
let cartItemError = {};

const cartUpdate = (state: CartUpdateData = initialState, action: AnyAction): CartUpdateData => {
  switch (action.type) {
    case STORECHANGE_FAILURE:
      return {
        ...state,
        readyStatus: STORECHANGE_FAILURE,
        err: action.err,
        commerceId: action.id,
      };

    case STORECHANGE_SUCCESS:
      return {
        ...state,
        readyStatus: STORECHANGE_SUCCESS,
        storeData: action.data,
        commerceId: action.id,
        err: null,
      };

    case CHECKOUT_VERIFY_FAILURE:
      return {
        ...state,
        readyStatus: CHECKOUT_VERIFY_FAILURE,
        err: action.err,
      };

    case CHECKOUT_VERIFY_REQUESTING:
      return { ...state, readyStatus: CHECKOUT_VERIFY_REQUESTING };

    case CHECKOUT_VERIFY_SUCCESS:
      return {
        ...state,
        readyStatus: CHECKOUT_VERIFY_SUCCESS,
        promoMessage: true,
        checkoutMessageData: action.data,
      };

    case CART_SENDLISTTOSTORE_ORDERVERIFY_SUCCESS_INELIGIBLE:
      return {
        ...state,
        readyStatus: CART_SENDLISTTOSTORE_ORDERVERIFY_SUCCESS_INELIGIBLE,
        checkoutMessageData: action.data,
      };

    case PROMOCODE_APPLY_REQUESTING:
      return {
        ...state,
        readyStatus: PROMOCODE_APPLY_REQUESTING,
        promoCode: '',
        promoMessage: false,
        err: null,
        promoData: null,
      };

    case PROMOCODE_APPLY_FAILURE:
      return {
        ...state,
        readyStatus: PROMOCODE_APPLY_FAILURE,
        promoCode: action.value,
        promoMessage: true,
        err: action.err,
        promoData: action.data,
      };

    case PROMOCODE_APPLY_SUCCESS:
      return {
        ...state,
        readyStatus: PROMOCODE_APPLY_SUCCESS,
        promoCode: action.value,
        promoMessage: true,
        promoData: action.data,
        err: null,
      };

    case RESET_PROMO_CART:
      return {
        ...state,
        readyStatus: CHECKOUT_INVALID,
        promoMessage: false,
        promoData: null,
        promoCode: '',
        checkoutMessageData: {},
        err: null,
        errorValue: null,
      };

    case PROMOCODE_REMOVE_REQUESTING: {
      return {
        ...state,
        readyStatus: PROMOCODE_REMOVE_REQUESTING,
        promoCode: '',
        promoMessage: false,
        err: null,
        promoData: null,
      };
    }

    case PROMOCODE_REMOVE_FAILURE:
      return {
        ...state,
        readyStatus: PROMOCODE_REMOVE_FAILURE,
        promoCode: action.values,
        promoMessage: true,
        err: action.err,
        promoData: action.data,
      };

    case PROMOCODE_REMOVE_SUCCESS:
      return {
        ...state,
        readyStatus: PROMOCODE_REMOVE_SUCCESS,
        promoMessage: true,
        promoCode: action.values,
        promoData: action.data,
        err: null,
      };

    case CART_MINICART_FAILURE:
      return { ...state, readyStatus: CART_MINICART_FAILURE, err: action.err };

    case CART_MINICART_SUCCESS:
      return {
        ...state,
        readyStatus: CART_MINICART_SUCCESS,
        cartMiniCartData: action.data.miniCartMap,
      };

    case CART_VERIFY_REQUESTING:
      return { ...state, readyStatus: CART_VERIFY_REQUESTING };

    case CART_VERIFY_SUCCESS:
      cartItemError = action.data.commerceItemsList.filter((item: any) => item.itemHasError);
      return { ...state, readyStatus: 'CART_SUCCESS', cartItemError };

    case CART_VERIFY_FAILURE:
      return { ...state, readyStatus: CART_VERIFY_FAILURE, err: action.err };

    case CLEAR_CART_PAGE_DATA:
      return {
        ...state,
        err: null,
        rewardsData: {},
        promoMessage: false,
        cartSuccessNote: false,
        promoData: null,
      };

    case EMPTY_DATA:
      return {
        ...state,
        err: null,
        promoMessage: false,
        cartSuccessNote: false,
      };

    default:
      return state;
  }
};

export default cartUpdate;
