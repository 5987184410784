/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { useFormControl } from '../FormControl/FormControlContext';
import { formControlState, State } from '../FormControl/formControlState';
import styles from './FormHelperText.module.scss';

type Props = React.HTMLAttributes<HTMLElement> & {
  /**
   * The content of the component.
   *
   * If `' '` is provided, the component reserves one line height for displaying a future message.
   */
  children?: React.ReactNode;
  /**
   * If `true`, the helper text should be displayed in a disabled state.
   */
  disabled?: boolean;
  /**
   * If `true`, helper text should be displayed in an error state.
   */
  error?: boolean;
  /**
   * If `true`, the helper text should use filled classes key.
   */
  filled?: boolean;
  /**
   * If `true`, the helper text should use focused classes key.
   */
  focused?: boolean;
  /**
   * If `dense`, will adjust vertical spacing. This is normally obtained via context from
   * FormControl.
   */
  margin?: 'dense';
  /**
   * If `true`, the helper text should use required classes key.
   */
  required?: boolean;
  /**
   * The variant to use.
   */
  variant?: 'standard' | 'outlined' | 'filled';
  className?: string;
  role?: string;
  component?: React.ElementType;
};

export const FormHelperText = React.forwardRef<HTMLElement, Props>(function FormHelperText(
  props,
  ref
) {
  const {
    children,
    className,
    component: Component = 'p',
    disabled,
    error,
    filled,
    focused,
    margin,
    required,
    variant,
    ...other
  } = props;
  const muiFormControl = useFormControl();
  const fcs = formControlState({
    props,
    muiFormControl,
    states: Object.keys({
      disabled,
      error,
      filled,
      focused,
      margin,
      required,
      variant,
    }) as State[],
  });
  return (
    <Component
      className={cx(
        styles.root,
        {
          [styles.contained]: fcs.variant === 'filled' || fcs.variant === 'outlined',
          [styles.marginDense]: fcs.margin === 'dense',
          [styles.disabled]: fcs.disabled,
          [styles.error]: fcs.error,
          [styles.filled]: fcs.filled,
          [styles.focused]: fcs.focused,
          [styles.required]: fcs.required,
        },
        className
      )}
      ref={ref}
      {...other}
    >
      {children === ' ' ? (
        <span
          dangerouslySetInnerHTML={{
            __html: '&#8203;',
          }}
        />
      ) : (
        children
      )}
    </Component>
  );
});
