/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect } from 'react';

export function useUtagReady() {
  const [isUtagReady, setIsUtagReady] = useState(false);

  useEffect(() => {
    const triggerPageView = setInterval(() => {
      if (typeof window !== 'undefined' && window.utag) {
        setIsUtagReady(true);
        clearInterval(triggerPageView);
      }
    }, 1000);

    return () => {
      clearInterval(triggerPageView);
    };
  }, []);

  return { isUtagReady };
}
