/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const key = ['pageSpecificData'];

type PageSpecificData = {
  productFindingMethod: string;
  pfmLocation: string;
  searchOriginalPartTypeId: string;
  searchProductRepositoryId: string;
  searchResultPosition: string;
  ignoreNextPageViewCall: boolean;
  eventType: string;
};

const initialData = {
  productFindingMethod: '',
  pfmLocation: '',
  searchOriginalPartTypeId: '',
  searchProductRepositoryId: '',
  searchResultPosition: '',
  ignoreNextPageViewCall: false,
  eventType: '',
};

const setPageSpecificData = (data: Partial<PageSpecificData>) => {
  return Promise.resolve(data);
};

export const usePageSpecificData = () => {
  const queryClient = useQueryClient();
  const { data } = useQuery<PageSpecificData>(key, {
    initialData,
    staleTime: Infinity,
  });
  const mutation = useMutation(key, setPageSpecificData, {
    onSuccess: (newData) => {
      queryClient.setQueryData(key, { ...data, ...newData });
    },
  });

  return {
    pageSpecificData: data,
    setPageSpecificData: mutation.mutate,
  };
};
