/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { forwardRef } from 'react';
import { SmartLink as Link } from '@/utils/smartLink';
import { type StarcLinkOverrideComponent } from '@az/starc-ui';

/**
 * https://starc.autozone.com/web/recipes/LinkOverride/
 */
export const StarcLinkOverride: StarcLinkOverrideComponent = forwardRef(
  function StarcLinkOverrideComp({ href, ...restProps }, ref) {
    return href === undefined ? (
      <a {...restProps} ref={ref}>
        {restProps.children}
      </a>
    ) : (
      <Link to={href} {...restProps} ref={ref} />
    );
  }
);
