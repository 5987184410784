/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Label } from '@/features/i18n';

export function validateRequired(value: string | number, errorMessage?: string) {
  let requiredVal = null;

  if (!value) {
    requiredVal = (
      <Label label={errorMessage?.length ? errorMessage : 'error_Common_Validation_Required'} />
    );
  }
  return requiredVal;
}
