/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';

type ContainProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
};

export const ContainOverflow = ({ children, fullWidth }: ContainProps) => (
  <div
    style={{
      maxWidth: fullWidth ? '1560px' : '1600px',
      margin: '0 auto',
      width: fullWidth ? '100%' : 'auto',
      paddingLeft: fullWidth ? '0' : 'var(--st-unit-5)',
      paddingRight: fullWidth ? '0' : 'var(--st-unit-5)',
    }}
  >
    {children}
  </div>
);

export default ContainOverflow;
