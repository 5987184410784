/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const clickTrack = (
  data: Record<
    string,
    string | number | undefined | boolean | null | Array<string | undefined | number | null>
  >
) => {
  if (!__IS_SERVER__) {
    if (window.utag !== undefined) {
      window.utag.link(data);
    }
  }
};
