/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './styles.module.scss';
import cx from 'classnames';

type SpinnerProps = {
  size?: number;
  spinnerWidth?: number;
  className?: string;
  dataTestId?: string;
  id?: string;
};

export const Spinner = ({
  size = 100,
  spinnerWidth = 9,
  className,
  dataTestId,
  id = 'Loading',
}: SpinnerProps) => {
  return (
    <div
      className={cx(styles.spinnerContainer, className)}
      data-testid={dataTestId}
      style={{ width: `${size}px`, height: `${size}px` }}
      role="img"
      aria-label="Loading spinner"
      title="Loading spinner"
      id={id}
    >
      <div className={styles.spinnerWrapper}>
        <div
          className={styles.spinner}
          style={{
            borderWidth: `${spinnerWidth}px`,
          }}
        />
      </div>
    </div>
  );
};
