/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FilterableByTaxonomy } from '../../interface/models';

export const isFilterableByTaxonomy = (item: unknown): item is FilterableByTaxonomy => {
  if (!item) {
    return false;
  }

  return Boolean(
    typeof item === 'object' &&
      'taxonomy' in item &&
      item.taxonomy &&
      'taxonomy_selector' in item &&
      (Array.isArray(item.taxonomy_selector) ||
        (typeof item.taxonomy_selector === 'object' &&
          item.taxonomy_selector &&
          'selectedItems' in item.taxonomy_selector &&
          Array.isArray(item.taxonomy_selector.selectedItems)))
  );
};
