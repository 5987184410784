/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { forwardRef, useRef, useEffect } from 'react';
import Link from 'next/link'; // eslint-disable-line no-restricted-imports
import type { Url } from '../next-types';
// TODO: refactor this module to fix eslint violation
/* eslint-disable react/jsx-no-target-blank */

interface Props extends React.ComponentPropsWithoutRef<'a'> {
  samePage?: boolean;
  noReferrer?: boolean;
  children?: React.ReactNode;
  setFocusToAnchorTag?: boolean;
  to: Url;
  isPhoneNumber?: boolean;
  ariaLabel?: string;
  scroll?: boolean;
  handleClick?: () => void;
}

export const getPath = (val: Url) => (typeof val === 'string' ? val : val?.pathname);

export const SmartLink = forwardRef<unknown, Props>(function SmartLinkComp(
  {
    to,
    children,
    setFocusToAnchorTag,
    samePage,
    noReferrer = true,
    href,
    isPhoneNumber = false,
    ariaLabel,
    scroll = true,
    handleClick,
    ...rest
  }: Props,
  _forwardedRef
) {
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    setFocusToAnchorTag && ref.current?.focus();
  }, [setFocusToAnchorTag]);

  const targetPath = href ? getPath(href) : getPath(to); // If it's a relative url such as '/path', 'path' and does not contain a protocol we can assume it is internal.

  if (isPhoneNumber) {
    return (
      <a href={`tel:${targetPath}`} {...rest}>
        {children}
      </a>
    );
  }

  if (typeof targetPath === 'string' && targetPath.includes('://')) {
    return (
      <a
        href={targetPath}
        aria-label={ariaLabel}
        target={samePage && !/videos|diy/.test(targetPath) ? '' : '_blank'}
        rel={noReferrer ? 'noopener noreferrer' : undefined}
        {...rest}
        onClick={handleClick}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      href={targetPath || '#'}
      prefetch={false}
      scroll={scroll}
      ref={ref}
      aria-label={ariaLabel}
      target={samePage || !/videos|diy/.test(targetPath) ? '' : '_blank'}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Link>
  );
});
