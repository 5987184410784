/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext, useState, ReactNode, useEffect, useContext } from 'react';
import { useMyProfile } from '@/features/myProfile/api/getMyProfile';
import { countryCodes } from '@/constants/locale';
import { useLocale } from '@/hooks/useLocale';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { CustomerLookupAnalytics } from '@/types/analytics';
import { localStorage } from '@/utils/localStorage';
import { STAND_ALONE_USER_TYPE } from './OrderConfirmation/Rewards/constants';
import { analyticsPageNames } from '@/constants/analytics/analyticsPageNames';
import { pageTypes } from '@/constants/page';
import { siteSections } from '@/utils/siteSections';

type LinkRewardsTypeProvider = {
  openModal: boolean;
  hideModal: boolean;
  isUS: boolean;
  modalReadyStatus: string;
  selectedCustomer: any;
  userRewardProfileData: any;
  show: null | boolean;
  accountExistData: any;
  lastName: string;
  lastNameError: boolean;
  firstName: string;
  firstNameError?: string;
  phoneNumberError: string;
  zipCodeError: boolean;
  emailError: boolean;
  rewardsNumberError: boolean;
  phoneNumber: string;
  zipOrEmail: string;
  email: string;
  zipcode: string;
  rewardsNumber: string;
  globalError: string;
  questionValidationError: string | null;
  analyticsData: CustomerLookupAnalytics | undefined;
  setOpenModal: (open: boolean) => void;
  setModalReadyStatus: (status: string) => void;
  setUserRewardProfileData: (data: any) => void;
  setSelectedCustomer: (data: any) => void;
  setAccountExistData: (data: any) => void;
  changePopUpType: (popUpType: string, data?: any) => void;
  showRewardSigninView: (show: boolean) => void;
  clearModal: () => void;
  setShow: (show: boolean) => void;
  setLastName: (lastName: string) => void;
  setLastNameError: (lastNameError: string) => void;
  setFirstName: (firstName: string) => void;
  setFirstNameError: (firstNameError: string | undefined) => void;
  setPhoneNumberError: (phoneNumberError: string) => void;
  setEmailError: (emailError: string) => void;
  setQuestionValidationError: (error: any) => void;
  setZipCodeError: (zipCodeError: string) => void;
  setRewardsNumberError: (zipCodeError: string | Element | undefined | unknown) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setZipOrEmail: (zipOrEmail: string) => void;
  setEmail: (email: string) => void;
  setZipCode: (zipcode: string) => void;
  setRewardsNumber: (rewardsNumber: string) => void;
  setGlobalError: (error?: string | null) => void;
  setAnalyticsData: (data: any) => void;
  setIsUS: (data: boolean) => void;
};

export const LinkRewardsContext = createContext<LinkRewardsTypeProvider>(
  {} as LinkRewardsTypeProvider
);

export const useLinkRewardsContext = () => {
  const context = useContext(LinkRewardsContext);
  if (context === undefined) {
    throw new Error('useLinkRewardsContext must be used within a LinkRewardsTypeProvider');
  }
  return context;
};

type LinkRewardsProviderProps = {
  children: ReactNode;
};

export const LinkRewardsProvider = ({ children }: LinkRewardsProviderProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [isUS, setIsUS] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [show, setShow] = useState<null | boolean>(null);
  const [modalReadyStatus, setModalReadyStatus] = useState('');
  const [userRewardProfileData, setUserRewardProfileData] = useState<any>(null);
  const [accountExistData, setAccountExistData] = useState<any>(null);
  const [lastNameError, setLastNameError] = useState<any>(null);
  const [questionValidationError, setQuestionValidationError] = useState<string | null>(null);
  const [firstNameError, setFirstNameError] = useState<string | undefined>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [zipCodeError, setZipCodeError] = useState<any>(null);
  const [rewardsNumberError, setRewardsNumberError] = useState<any>(null);
  const [emailError, setEmailError] = useState<any>(null);
  const [globalError, setGlobalError] = useState<any>(null);

  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [zipOrEmail, setZipOrEmail] = useState('');
  const [email, setEmail] = useState('');
  const [zipcode, setZipCode] = useState('');
  const [rewardsNumber, setRewardsNumber] = useState('');
  const [analyticsData, setAnalyticsData] = useState(undefined);
  const { data: userData } = useMyProfile();

  const locale = useLocale();
  const [hideModal, setHideModal] = useState<boolean>(false);

  const storedHideModal = localStorage.getItem('hideModal') === 'true';

  useEffect(() => {
    const userType = userData?.userType;
    const isStandAloneUser = userType === Number(STAND_ALONE_USER_TYPE);

    if (isStandAloneUser) {
      setIsUS(locale === countryCodes.us);
      setHideModal(storedHideModal);
      if (!storedHideModal) {
        showRewardSigninView(true);
      }
    }
  }, [userData?.userType, locale, storedHideModal]);

  const changePopUpType = (readyStatusVal: string, data: any) => {
    setModalReadyStatus(readyStatusVal);
    if (data) {
      const newData = { ...userRewardProfileData, ...data };
      setUserRewardProfileData({ ...newData });
    }
  };

  const showRewardSigninView = (show: boolean) => {
    setShow(show);
    setOpenModal(show);
    if (show) {
      setModalReadyStatus('SHOW_REWARD_SIGNIN');
      setHideModal(true);
    }
  };

  const clearModal = () => {
    /**Start Analytics */
    clickTrack({
      loginFLow: 'Standalone Online',
      customLinkName: 'Skip for now',
      pageName: analyticsPageNames.azHome,
      pageType: pageTypes.home,
      siteSection: siteSections.siteSectionHome,
    });
    /**End Analytics */
    setShow(false);
    setOpenModal(false);
    setModalReadyStatus('');
    setUserRewardProfileData(null);
    setAccountExistData(null);
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
    setZipOrEmail('');
    setEmail('');
    setZipCode('');
    setRewardsNumber('');
    setLastNameError(false);
    setFirstNameError('');
    setPhoneNumberError('');
    setZipCodeError(false);
    setEmailError(false);
    setRewardsNumberError(false);
    setGlobalError(null);
    setQuestionValidationError(null);
    setHideModal(true);
    localStorage.setItem('hideModal', 'true');
  };

  return (
    <LinkRewardsContext.Provider
      value={{
        openModal,
        show,
        analyticsData,
        modalReadyStatus,
        userRewardProfileData,
        accountExistData,
        changePopUpType,
        showRewardSigninView,
        clearModal,
        lastNameError,
        firstNameError,
        setShow,
        isUS,
        selectedCustomer,
        lastName,
        firstName,
        phoneNumber,
        zipOrEmail,
        phoneNumberError,
        zipCodeError,
        globalError,
        emailError,
        email,
        zipcode,
        rewardsNumber,
        rewardsNumberError,
        setIsUS,
        setOpenModal,
        setModalReadyStatus,
        setUserRewardProfileData,
        setAccountExistData,
        setSelectedCustomer,
        setLastName,
        setFirstName,
        setPhoneNumber,
        setZipOrEmail,
        setEmail,
        setZipCode,
        setRewardsNumber,
        setLastNameError,
        setFirstNameError,
        setPhoneNumberError,
        setRewardsNumberError,
        setZipCodeError,
        setEmailError,
        setGlobalError,
        questionValidationError,
        setQuestionValidationError,
        setAnalyticsData,
        hideModal,
      }}
    >
      {children}
    </LinkRewardsContext.Provider>
  );
};
