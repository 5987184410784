/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

function circularActionBuffer(this: any, size: number) {
  this.size = size;
  this.buffer = new Array(size);
  this.currentIndex = -1;
}

circularActionBuffer.prototype.printLIFO = function () {
  const list = [];
  let curIndex = this.currentIndex;

  for (let i = 0; i < this.size; i++) {
    const action = this.buffer[curIndex];
    if (action) {
      list.push(action);
    }
    curIndex = (curIndex - 1) % this.size;
  }

  return list;
};

circularActionBuffer.prototype.log = function () {
  const ref = this;
  return () => (next: (a: any) => void) => (action: any) => {
    ref.currentIndex = (ref.currentIndex + 1) % ref.size;
    ref.buffer[ref.currentIndex] = action;
    next(action);
  };
};

export const actionLogger: {
  size: number;
  buffer: Array<any>;
  currentIndex: number;
  log: () => () => (next: (a: any) => void) => (action: any) => void;
  printLIFO: () => Array<any>;
  // @ts-expect-error refine
} = new circularActionBuffer(4);
