/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { RouteQueryParamsType } from '@/types/filterTypes';

export const sanitizeUrlWithParamList = (
  paramObj: Record<string, string | string[] | number | boolean | null | undefined>,
  paramList: RouteQueryParamsType,
  basePath: string
): string => {
  // filter the url down to only the whitelisted params
  const filteredParams = Object.keys(paramObj).filter(
    // @ts-expect-error refine
    (key: string) => paramList[key] === true && paramObj[key] !== undefined
  );
  // create an object from the whitelisted keys to stringify back to a clean path
  const sanitizedObj = filteredParams.reduce(
    (
      previousValue: Record<string, string | string[] | number | boolean | null | undefined>,
      currentValue
    ) => {
      previousValue[currentValue] = paramObj[currentValue];
      return previousValue;
    },
    {}
  );

  // @ts-expect-error extended query string
  const sanitizedParams = new URLSearchParams(sanitizedObj);
  // we need the param char as some use cases will be using the newly sanitized path as a param itself. (ex: seourl)
  return `${basePath}${
    Object.keys(sanitizedObj).length > 0 ? `?${sanitizedParams.toString()}` : ''
  }`;
};
