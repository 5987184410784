/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { AxiosInstance } from 'axios';
import { type QueryClient } from '@tanstack/react-query';
import { createQuery } from '@/utils/createReactQuery';
import { useRouter } from 'next/router';
import { PageTypeResponseModel } from '@/api/types/browse-search-types';
import { parseUrl } from '@/utils/urlHelpers';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { sanitizeUrlWithParamList } from '../../../utils/urlHelpers';
import { acceptableShelfQueryParams } from '../../../constants/queryParams';
import { useLocale } from '@/hooks/useLocale';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import type { Country, PageTypeData } from '@/types';
import { routePaths } from '@/constants/routePaths';
import { Url } from '@/next-types';

type QueryOptions = {
  canonicalPath: string | undefined;
  makeModelYearPath?: string;
  preview: boolean;
  country: string;
};

type PageTypeResponseData = PageTypeResponseModel;

const EXTERNAL_SITES = ['/expertips', '/company', '/diy', '/locations'];

const URL = `${requestBaseURL}/external/product-discovery/browse-search/v2/page-types`;

const getPageType = async (options: QueryOptions, axiosInstance?: AxiosInstance) => {
  const { canonicalPath, preview, country } = options;

  const response = await getAxios(axiosInstance).get<PageTypeResponseData>(URL, {
    params: {
      canonicalPath,
      preview,
      country,
    },
  });
  return selector(response.data);
};

const {
  query: pageTypeQuery,
  useData: usePageTypeAPI,
  prefetch: prefetchPageTypeAPI,
} = createQuery<PageTypeData, QueryOptions>('pageType', getPageType);

const selector = ({ redirectUrl, pageTypeResult }: PageTypeResponseData): PageTypeData => ({
  redirectLocation: redirectUrl,
  ...pageTypeResult,
});

export const usePageType = ({
  canonicalPath,
  enabled = true,
  keepPreviousData = false,
}:
  | {
      canonicalPath?: string;
      enabled?: boolean;
      keepPreviousData?: boolean;
    }
  | undefined = {}) => {
  const router = useRouter();
  const locale = useLocale();

  const country = getCountryFromLocale(locale);
  const parsedUrl = parseUrl(canonicalPath || router.asPath);
  const newCanonicalPath = parsedUrl.url;
  const isExternalSite = EXTERNAL_SITES.some((route) => newCanonicalPath.includes(route));

  return usePageTypeAPI({
    enabled:
      router.route !== '/errorPage' &&
      router.route === '/[...seoUrlPath]' &&
      !newCanonicalPath.includes('.') &&
      !isExternalSite &&
      !!enabled,
    keepPreviousData,
    canonicalPath: newCanonicalPath,
    staleTime: Infinity,
    preview: showXMPreviewDate(),
    country,
    onSuccess: async (data) => {
      if (!data.redirectLocation) {
        return;
      }

      const { intcmp } = router.query;
      const { url, query: queryParams } = parseUrl(data.redirectLocation);
      const sanitizedRedirectPath = sanitizeUrlWithParamList(
        {
          ...queryParams,
          intcmp,
        },
        acceptableShelfQueryParams,
        url
      );

      await router.replace(sanitizedRedirectPath);
    },
    onError: async (error) => {
      try {
        // eslint-disable-next-line no-console
        console.log(`An error occurred while requesting page type: `, error);
        await router.replace(routePaths.errorPage as Url);
        return;
      } catch (error) {
        throw new Error(`There was a problem redirecting to ${routePaths.errorPage}`);
      }
    },
  });
};

export const getPageTypeFromCache = (
  queryClient: QueryClient,
  canonicalPath: string,
  country: Country
) => {
  const parsedUrl = parseUrl(canonicalPath).url;
  const options = {
    canonicalPath: parsedUrl,
    preview: showXMPreviewDate(),
    country,
  } as const;
  return queryClient.getQueryData<PageTypeData>(pageTypeQuery.getFullKey(options));
};

export const prefetchPageType = async (
  queryClient: QueryClient,
  axiosInstance: AxiosInstance,
  canonicalPath: string,
  preview: boolean,
  country: Country
) => {
  const parsedUrl = parseUrl(canonicalPath).url;

  const options = {
    canonicalPath: parsedUrl,
    preview,
    country,
  } as const;

  return prefetchPageTypeAPI(queryClient, options, axiosInstance);
};
