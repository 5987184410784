/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect, useState } from 'react';
import { trackFooterNavMenu } from '../utils/track';
import styles from './ContentBlock.module.scss';
import { SmartLink as Link } from '@/utils/smartLink';

const trackFooterLink = (linkName: string) => {
  trackFooterNavMenu(linkName);
  if (!__IS_SERVER__) {
    window.scrollTo(0, 0);
  }
};

type Props = {
  label: string;
  CTALink: string;
  children: React.ReactNode;
  Icon: boolean;
  name: string;
  handleOpenModal?: () => void;
  index?: number;
};

function ContentBlockWrapper({ children, CTALink, label, Icon, name, handleOpenModal }: Props) {
  const [shouldFocusTop, setShouldFocusTop] = useState(false);
  useEffect(() => {
    if (shouldFocusTop) {
      const azLogoFixed = document.getElementById('azLogoLinkFixed');
      azLogoFixed?.focus();
      setShouldFocusTop(false);
    }
  }, [shouldFocusTop]);

  return (
    <>
      {CTALink === '' ? (
        <div
          data-testid="openStoreModalBtn"
          role="button"
          onClick={handleOpenModal ? () => handleOpenModal() : undefined}
          className={`${styles.footerTopIconLabelButton} ${styles.mobileRectangle}`}
          onKeyPress={(event) => {
            if (handleOpenModal && event.key === 'Enter') {
              handleOpenModal();
            }
          }}
          tabIndex={0}
        >
          {children}
        </div>
      ) : (
        <Link
          to={CTALink || '#'}
          onClick={() => {
            setShouldFocusTop(true);
            trackFooterLink(label);
          }}
          className={`${Icon ? styles.footerTopSocialIcon : styles.footerTopIconLabelButton} ${
            styles.mobileRectangle
          }`}
          data-testid={`footer_content_block_${name.split(' ')[0]}`}
        >
          {children}
        </Link>
      )}
    </>
  );
}

export default ContentBlockWrapper;
