/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { dispatchEvent } from '@/utils/eventPublisher';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useDispatchNavigationEvents() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      dispatchEvent('routeChange', {
        url,
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
}
