/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const pageTypes = {
  cart: 'cart',
  CategoryGrouping: 'CategoryGrouping',
  LeafCategory: 'LeafCategory',
  TopNavCategory: 'TopNavCategory',
  ProductDetail: 'ProductDetail',
  ProductShelf: 'ProductShelf',
  BrandShelf: 'BrandShelf',
  MerchShelf: 'MerchShelf',
  header: 'header',
  checkout: 'checkout',
  signIn: 'signIn',
  signInCheckout: 'signincheckout',
  signInNotCheckout: 'signinnotcheckout',
  signInList: 'signinlist',
  search: 'search',
  productShelf: 'productShelf',
  shelf: 'shelf',
  home: 'home',
  filter: 'filter',
  category: 'category',
  landingPage: 'landingPage',
  notFound: '404',
  ymmeAlert: 'ymmeAlert',
  vehicleDetail: 'vehicleDetail',
  profile: 'profile',
  myWarranty: 'MyWarranty',
  myWarrantyDetails: 'MyWarrantyDetails',
} as const;
