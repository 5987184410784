/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import styles from './Fade.module.scss';

type Props = {
  open: boolean | undefined | null;
  duration?: number;
  children: React.ReactElement<any>;
};

export function Fade({ open, duration = 200, children }: Props) {
  const [closingTransitionEnd, setClosingTransitionEnd] = React.useState(true); // We use closingTransitionEnd to avoid initial transition and only apply it
  // when opacity changes

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!open) {
      timeout = setTimeout(() => {
        setClosingTransitionEnd(true);
      }, duration);
    } else {
      setClosingTransitionEnd(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [duration, open]);

  return open || (!open && !closingTransitionEnd)
    ? React.cloneElement(children, {
        className: cx(children.props.className, {
          [styles.fadeIn]: open,
          [styles.fadeOut]: !open && !closingTransitionEnd,
        }),
        style: { ...children.props.style, animationDuration: `${duration}ms` },
      })
    : null;
}
