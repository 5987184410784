/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { HeaderPromoContent } from '../../interface';
import { SmartLink as Link } from '@/utils/smartLink';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import styles from './styles.module.scss';

type Props = {
  content: HeaderPromoContent & {
    deviceClassName: {
      desktop: boolean;
      tablet: boolean;
      mobile: boolean;
    };
  };
};

const HeaderPromo = ({ content }: Props) => {
  const { ctaLink1, ctaLink2, text1, text2, deviceClassName } = content;
  const title = text1?.replace(/\n/g, '');
  const label = text2?.replace(/\n/g, '');
  const dynamicContent = (
    <div
      className={styles.dynamicContent}
      dangerouslySetInnerHTML={(() => ({
        __html: `${title} ${label}`,
      }))()}
    />
  );
  return (
    <DeviceWrapper {...deviceClassName}>
      {ctaLink1 || ctaLink2 ? (
        <Link to={ctaLink1 || ctaLink2}>{dynamicContent}</Link>
      ) : (
        dynamicContent
      )}
    </DeviceWrapper>
  );
};

export default HeaderPromo;
