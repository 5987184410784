/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { setCookie } from '@/utils/cookie';
import { analyticsGlobalPreferredVehicle } from '@/utils/analytics/dataLayer/analyticsGlobalPreferredVehicle';
import type { VehicleSummary } from '@/types';
import { VehiclesInfo } from '../interface';

export const trackPreferredVehicleData = (ymme: VehicleSummary | VehiclesInfo) => {
  if (!__IS_SERVER__) {
    setCookie('preferredVehicleYear', ymme.year || '');
    setCookie('preferredVehicleMake', ymme.make || '');
    setCookie('preferredVehicleModel', ymme.model || '');
    setCookie(
      'preferredVehicleEngine',
      ymme.vehicleName.replace(`${ymme.year} ${ymme.make} ${ymme.model}`, '').trim() || ''
    );
    setCookie('preferredVehicleId', ymme.catalogVehicleId || '');
    analyticsGlobalPreferredVehicle();
  }
};
