/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import type { MiddleLabelContent } from '../interface';
import { FooterLink } from './FooterLink';
import { CookieButton } from './CookieButton';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import styles from './FooterMiddle.module.scss';

type Props = {
  title: string;
  middleLabelContent: MiddleLabelContent[];
};

function LinkColumnContainer({ title, middleLabelContent }: Props) {
  function renderColumnLinkList() {
    return (
      <ul>
        {middleLabelContent.map((content, index) => (
          <li key={`link_${index}`} className={styles.linkItem}>
            <FooterLink
              key={`link_${index}`}
              className={`${styles.linkItem} ${
                content.name.length > 30 ? styles.specialWidth : ''
              }`}
              {...content}
            />
          </li>
        ))}
      </ul>
    );
  }
  return (
    <div
      className={styles.linkColumnContainer}
      data-testid={`footer_link_colum_${title.split(' ')[0]?.toLowerCase()}`}
    >
      <h2
        className={styles.linkColumTitle}
        data-testid={`footer_link_colum_title_${title.split(' ')[0]?.toLowerCase()}`}
      >
        {title}
      </h2>
      {middleLabelContent && renderColumnLinkList()}
    </div>
  );
}

function OtherAutoZoneSitesContainer({ title, middleLabelContent }: Props) {
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div
      className={styles.otherAutoZoneSitesContainer}
      data-testid={`footer_link_colum_${title.toLowerCase()}`}
    >
      <div>
        <h2
          className={styles.otherAutoZoneSitesLinkColumTitle}
          data-testid={`footer_link_colum_title_${title.split(' ')[0]?.toLowerCase()}`}
        >
          {title}
        </h2>
        <div className={styles.linksAndCookieBtn}>
          <ul className={styles.otherAutoZoneSitesLinkItemsContainer}>
            {middleLabelContent.map((content, index) => (
              <li className={styles.listItems} key={`other_${index}`}>
                <FooterLink
                  footerSectionName="middle"
                  className={styles.otherAutoZoneSitesLinkItem}
                  {...content}
                />
                {middleLabelContent.length - 1 !== index ? <span className={styles.stick} /> : ''}
              </li>
            ))}
            <CookieButton isLinkColumn={true} isMobile={matchesMobile} />
          </ul>
        </div>
      </div>
    </div>
  );
}

export { LinkColumnContainer, OtherAutoZoneSitesContainer };
