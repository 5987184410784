/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import cx from 'classnames';
import React, { useState } from 'react';
import { Dialog } from '../Dialog/Dialog';
import { DialogContent } from '../Dialog/DialogContent';
import { ModalHeaderV2 } from './ModalHeaderV2';
import styles from './styles.module.scss';
import { AZButton } from '@/components/AZButton';
import { Button } from '@/components/Button';
import { IconButton } from '@/components/IconButton/IconButton';
import { useLabel } from '@/hooks/useLabels';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import CloseIcon from '@/public/images/close.svg';
import KeyboardBackspaceIcon from '@/public/images/keyboard-backspace.svg';
import iconStyles from '@/theme/iconStyles.module.scss';

type ModalClasses = {
  root?: string;
  headerNav?: string;
  modalBody?: string;
  backButton?: string;
  title?: string;
  customDialogClass?: string;
  closeButton?: string;
};

type Props = {
  body: React.ReactNode;
  classes?: any;
  isOpen: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  desktopSize?: string;
  tabletSize?: string;
  title?: React.ReactNode;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  backButtonContent?: string | JSX.Element;
  backButtonAriaLabel?: string;
  heading?: React.ReactNode;
  inset?: boolean;
  noGap?: boolean;
  noMargin?: boolean;
  mediumGap?: boolean;
  whiteArrow?: boolean;
  noNav?: boolean;
  navV2?: boolean;
  handleClose?: () => void;
  handleBack?: () => void;
  darkHeader?: boolean;
  ariaDescription?: string;
  modalClasses?: ModalClasses;
  role?: string;
  ariaLabelledBy?: string;
  ariaModal?: boolean;
  ariaLabel?: string;
  fullWidth?: boolean;
  warnOnClose?: boolean;
  id?: string;
};

type ModalNavProps = {
  title?: React.ReactNode;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  backButtonAriaLabel?: string;
  handleClose?: () => void;
  handleBack?: () => void;
  uppercase?: boolean;
  darkHeader?: boolean;
  whiteArrow?: boolean;
  modalClasses?: ModalClasses;
  rounded?: boolean;
  disableEscapeKeyDown?: boolean;
  backButtonContent?: string | JSX.Element;
};
type ModalBodyProps = {
  heading?: React.ReactNode;
  body: React.ReactNode;
  inset: boolean;
  noGap?: boolean;
  noMargin?: boolean;
  mediumGap?: boolean;
  whiteArrow?: boolean;
  modalClasses?: ModalClasses;
};

export const ModalNav = (props: ModalNavProps) => {
  const {
    title,
    handleClose,
    showCloseButton,
    showBackButton,
    backButtonAriaLabel = 'back',
    handleBack,
    uppercase,
    darkHeader,
    whiteArrow,
    modalClasses = {},
    rounded,
    disableEscapeKeyDown = false,
    backButtonContent,
  } = props;
  const labelBack = useLabel('label_back');

  React.useEffect(() => {
    const esc = (event: any) => {
      if (event.data === 'Escape Hit' && !disableEscapeKeyDown && handleClose) {
        handleClose();
      }
    };
    window.addEventListener('message', esc);
    return () => window.removeEventListener('message', esc);
  }, [disableEscapeKeyDown, handleClose]);

  const renderBackButton = () => {
    if (!showBackButton) {
      return null;
    }

    return (
      <Button
        className={cx(styles.backButton, {
          [styles.backBtnTabFullscreen]: darkHeader,
          [modalClasses.backButton || '']: modalClasses.backButton,
        })}
        data-testid="back-button"
        ariaLabel={backButtonAriaLabel}
        onClick={handleBack}
      >
        {backButtonContent ? (
          backButtonContent
        ) : (
          <>
            <KeyboardBackspaceIcon
              className={cx(
                iconStyles.defaultIconStyle,
                iconStyles.fontSizeSmall,
                darkHeader && whiteArrow
                  ? styles.whiteBackIcon
                  : darkHeader
                  ? styles.orangeBackIcon
                  : styles.backIcon
              )}
            />
            {labelBack?.toUpperCase()}
          </>
        )}
      </Button>
    );
  };

  const renderTitle = () => {
    if (!title) {
      return null;
    }

    return (
      <div
        id="modal-title"
        data-testid="modal-title"
        className={cx(styles.headerNavTitle, {
          [styles.uppercase]: uppercase,
          [modalClasses.title || '']: modalClasses.title,
        })}
      >
        {title}
      </div>
    );
  };

  const renderCloseButton = () => {
    if (!showCloseButton) {
      return null;
    }

    return (
      <IconButton
        aria-label="Close"
        className={cx(styles.headerCloseButton, {
          [modalClasses.closeButton || '']: modalClasses.closeButton,
        })}
        onClick={handleClose}
        data-testid="modal-close-btn"
      >
        <CloseIcon
          className={cx(iconStyles.defaultIconStyle, iconStyles.fontSizeSmall)}
          data-testid="modal-close-btn-icon"
        />
      </IconButton>
    );
  };
  const onlyCloseButton = !title && !showBackButton;
  return (
    <div
      className={cx(styles.headerNav, {
        [styles.flexEnd]: onlyCloseButton,
        [styles.rounded]: rounded,
        [styles.darkHeader]: darkHeader,
        [modalClasses.headerNav || '']: modalClasses?.headerNav,
      })}
    >
      {renderBackButton()}
      {renderTitle()}
      {renderCloseButton()}
    </div>
  );
};

const ModalBody = (props: ModalBodyProps) => {
  const { heading, body, inset, noGap, noMargin, mediumGap, modalClasses = {} } = props;
  let headingText = null;

  if (heading) {
    headingText = <header className={styles.headerText}>{heading}</header>;
  }

  return (
    <DialogContent
      className={`${mediumGap ? styles.mediumGap : ''}  ${
        inset ? styles.modalContentInset : styles.modalContent
      } ${noGap ? styles.noGap : ''} ${noMargin ? styles.noMargin : ''} ${
        modalClasses.modalBody ? modalClasses.modalBody : styles.modalBody
      }`}
    >
      {headingText}
      {body}
    </DialogContent>
  );
};

const Modal = (props: Props) => {
  const {
    isOpen,
    disableEscapeKeyDown = false,
    disableBackdropClick = true,
    desktopSize = 'medium',
    tabletSize = 'small',
    title,
    handleClose,
    showCloseButton = false,
    showBackButton = false,
    backButtonContent,
    handleBack,
    backButtonAriaLabel,
    heading,
    body,
    inset = false,
    noGap = false,
    noMargin = noGap,
    noNav,
    navV2,
    mediumGap = false,
    whiteArrow = false,
    darkHeader,
    classes = {},
    modalClasses = {},
    role,
    ariaModal,
    ariaLabelledBy,
    ariaLabel,
    fullWidth,
    warnOnClose,
    id = '',
  } = props;

  let width = '';
  const isTabletOrMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [warning, setWarning] = useState(false);

  const handleCloseModal = () => {
    if (handleClose) {
      if (warnOnClose) {
        setWarning(true);
      } else {
        handleClose();
      }
    }
  };

  switch (true) {
    case fullWidth:
      width = styles.fullWidth;
      break;
    case desktopSize === 'large' && tabletSize === 'small':
      width = styles.largeDskSmallTab;
      break;
    case desktopSize === 'medium' && tabletSize === 'small':
    default:
      width = styles.mediumDskSmallTab;
      break;
    case desktopSize === 'small' && tabletSize === 'small':
      width = styles.smallDskSmallTab;
      break;
    case desktopSize === 'large' && tabletSize === 'fullscreen':
      width = styles.largeDskFullscreenTab;
      break;
    case desktopSize === 'xlarge' && tabletSize === 'fullscreen':
      width = styles.xlargeDskFullscreenTab;
      break;
    case desktopSize === 'medium' && tabletSize === 'fullscreen':
      width = styles.mediumDskFullscreenTab;
      break;
    case desktopSize === 'small' && tabletSize === 'fullscreen':
      width = styles.smallDskFullscreenTab;
      break;
  }

  return (
    <>
      {warning && (
        <Dialog
          role={role}
          ariaModal={ariaModal}
          ariaLabel={ariaLabel}
          ariaLabelledBy={ariaLabelledBy}
          open={isOpen}
          disableBackdropClick={disableBackdropClick}
          disableEscapeKeyDown={disableEscapeKeyDown}
          onClose={() => {
            if (warnOnClose) {
              setWarning(false);
            }
          }}
          className={cx(styles.modalAlert, {
            [modalClasses.customDialogClass || '']: modalClasses.customDialogClass,
          })}
          classes={{
            paper: cx(width, {
              [modalClasses.root || '']: modalClasses.root,
            }),
            dialogContainer: cx({
              [styles.xlargeDialog]: desktopSize === 'xlarge',
            }),
            ...classes,
          }}
          PaperProps={{
            square: isTabletOrMobile,
          }}
        >
          <ModalBody
            heading={heading}
            body={
              <div className={styles.confirmation}>
                <div>Are you sure you want to close?</div>{' '}
                <div className={styles.confirmationButtons}>
                  <AZButton
                    variant="primary"
                    title={'YES'}
                    onPress={() => {
                      if (handleClose) {
                        handleClose();
                      }
                    }}
                  ></AZButton>
                  <AZButton
                    variant="secondary"
                    title={'NO'}
                    onPress={() => {
                      setWarning(false);
                    }}
                  ></AZButton>
                </div>
              </div>
            }
            inset={inset}
            noGap={noGap}
            mediumGap={mediumGap}
            whiteArrow={whiteArrow}
            noMargin={noMargin}
            modalClasses={modalClasses}
          />
        </Dialog>
      )}
      <Dialog
        id={id}
        role={role}
        ariaModal={ariaModal}
        ariaLabel={ariaLabel}
        ariaLabelledBy={ariaLabelledBy}
        open={isOpen}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        onClose={handleCloseModal}
        className={cx(styles.modal, {
          [modalClasses.customDialogClass || '']: modalClasses.customDialogClass,
        })}
        classes={{
          paper: cx(width, {
            [modalClasses.root || '']: modalClasses.root,
          }),
          dialogContainer: cx({
            [styles.xlargeDialog]: desktopSize === 'xlarge',
          }),
          ...classes,
        }}
        PaperProps={{
          square: isTabletOrMobile,
        }}
      >
        {noNav ? null : navV2 ? (
          <ModalHeaderV2
            title={title}
            showBackButton={showBackButton}
            showCloseButton={showCloseButton}
            handleBack={handleBack}
            handleClose={handleCloseModal}
          />
        ) : (
          <ModalNav
            title={title}
            handleClose={handleCloseModal}
            showCloseButton={showCloseButton}
            showBackButton={showBackButton}
            backButtonContent={backButtonContent}
            backButtonAriaLabel={backButtonAriaLabel}
            handleBack={handleBack}
            darkHeader={darkHeader}
            whiteArrow={whiteArrow}
            modalClasses={modalClasses}
            rounded={!isTabletOrMobile}
            disableEscapeKeyDown={disableEscapeKeyDown}
          />
        )}
        <ModalBody
          heading={heading}
          body={body}
          inset={inset}
          noGap={noGap}
          mediumGap={mediumGap}
          whiteArrow={whiteArrow}
          noMargin={noMargin}
          modalClasses={modalClasses}
        />
      </Dialog>
    </>
  );
};

export default Modal;
