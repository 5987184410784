/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { createContext, useContext } from 'react';
import { BREAKPOINTS } from '../constants/screen';

export type Keys = 'sm' | 'md' | 'lg' | 'xl';

const keys: Keys[] = ['sm', 'md', 'lg', 'xl'];
const values = {
  sm: 0,
  md: BREAKPOINTS.TABLET_MIN,
  lg: BREAKPOINTS.DESKTOP_MIN,
  xl: 1600,
};

export type Theme = {
  breakpoints: {
    keys: typeof keys;
    values: {
      [key: string]: number;
    };
    up: (a: Keys) => string;
    down: (a: Keys) => string;
    only: (a: Keys) => string;
    between: (b: number | Keys, a: number | Keys) => string;
  };
  deviceType: string;
};
const defaultTheme = {
  breakpoints: {
    keys,
    values,
    down,
    up,
    between,
    only,
  },
  deviceType: 'PHONE',
};
const step = 5;

function up(key: Keys) {
  const value = typeof values[key] === 'number' ? values[key] : key;
  return `@media (min-width:${value}px)`;
}

function down(key: Keys) {
  const endIndex = keys.indexOf(key) + 1;
  const upperbound = values[keys[endIndex]];

  if (endIndex === keys.length) {
    // xl down applies to all sizes
    return up('sm');
  }

  const value = typeof upperbound === 'number' && endIndex > 0 ? upperbound : key;
  // @ts-expect-error -- refine MUI logic
  return `@media (max-width:${value - step / 100}px)`;
}

function between(start: number | Keys, end: number | Keys) {
  // @ts-expect-error -- refine MUI logic
  const endIndex = keys.indexOf(end);

  if (endIndex === keys.length - 1) {
    // @ts-expect-error -- refine MUI logic
    return up(start);
  }

  return (
    `@media (min-width:${
      // @ts-expect-error -- refine MUI logic
      typeof values[start] === 'number' ? values[start] : start
    }px) and ` +
    `(max-width:${
      // @ts-expect-error
      (endIndex !== -1 && typeof values[keys[endIndex + 1]] === 'number'
        ? values[keys[endIndex + 1]]
        : end) -
      step / 100
    }px)`
  );
}

function only(key: Keys) {
  return between(key, key);
}

export const ThemeContext: React.Context<Theme> = createContext<Theme>(defaultTheme);

export const useTheme = (): Theme => {
  return useContext(ThemeContext);
};

export function createTheme(deviceType = 'PHONE'): Theme {
  const theme = { ...defaultTheme, deviceType };
  return theme;
}
