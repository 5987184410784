/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { setCookie } from '@/utils/cookie';
import type { Dispatch, ThunkAction } from '../types';
import actionConstants from '../types/action';

export const hideConfirmationPopup = (): ThunkAction => async (dispatch: Dispatch) =>
  new Promise<void>((resolve) => {
    dispatch({
      type: actionConstants.HIDE_POPUP,
    });
    dispatch({
      type: actionConstants.CLEAR_VALIDATE_REWARDS,
    });
    setCookie('AZ_SIGNED_IN', 'true');
    resolve();
  });

export const showRewardSigninPopup = (): {
  type: string;
} => ({
  type: actionConstants.SHOW_REWARD_SIGNIN,
});

export const changePopupType =
  (type: string, state: any = {}): ThunkAction =>
  async (dispatch: Dispatch) => {
    dispatch({
      type,
      state,
    });
  };

export default hideConfirmationPopup;
