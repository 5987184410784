/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const createRipple = (event: any, styles: any) => {
  const button = event.currentTarget;
  const rippleContainer = document.createElement('span');
  rippleContainer.classList.add(styles.rippleContainer);
  rippleContainer.addEventListener('transitionend', (event: any) => {
    if (event.target?.classList?.contains(styles.rippleContainer)) {
      rippleContainer.remove();
    }
  });
  const ripple = document.createElement('span');
  ripple.classList.add(styles.ripple);
  rippleContainer.appendChild(ripple);
  button.appendChild(rippleContainer);
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  ripple.style.width = ripple.style.height = `${diameter}px`;
  ripple.classList.add(styles.rippleTransform);
};

export const removeRipple = (event: any, styles: any) => {
  const button = event.currentTarget;
  const rippleContainer = button.querySelectorAll(`.${styles.rippleContainer}:last-child`);
  rippleContainer[0] && rippleContainer[0].classList.add(styles.rippleContainerRemoved);
};
