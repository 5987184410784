/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { Spinner } from '../Spinner';
import styles from './styles.module.scss';

type Props = {
  noScroll?: boolean;
};

type State = {
  show: boolean; // this is for waiting till the site hydrates to match SSR with initial CSR
};

export default class ProgressModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.setState({
      show: true,
    });
  }

  componentWillUnmount() {
    const documentElementStyle = document.documentElement ? document.documentElement.style : {};
    const scrollOptionsSupported = 'scrollBehavior' in documentElementStyle;

    if (!this.props.noScroll && scrollOptionsSupported) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else if (!this.props.noScroll) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.state.show ? (
      <React.Fragment>
        <Spinner className={styles.modalSpinner} dataTestId="progressModal" />
        <span className={styles.dialog} />
      </React.Fragment>
    ) : null;
  }
}
