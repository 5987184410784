/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Button } from '@/components/Button';
import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  onBlur?: () => void;
  containerClassName?: string;
  tabIndex?: number;
};

const StickyButtonContainer = ({
  containerClassName,
  onClick,
  children,
  tabIndex,
  onBlur,
}: Props): React.ReactElement<'div'> => {
  return (
    <div className={cx(styles.stickyButtonContainer, containerClassName)}>
      <Button
        customClass={cx(styles.applyBtn)}
        variant="contained"
        onClick={onClick}
        onBlur={onBlur}
        fullWidth
        tabIndex={tabIndex}
      >
        {children}
      </Button>
    </div>
  );
};

export default StickyButtonContainer;
