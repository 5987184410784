/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { formControlState, State } from '../FormControl/formControlState';
import { useFormControl } from '../FormControl/FormControlContext';
import { pascalCase } from '../../utils/pascalCase';
import styles from './FormLabel.module.scss';

type Props = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: 'primary' | 'secondary';
  /**
   * If `true`, the label should be displayed in a disabled state.
   */
  disabled?: boolean;
  /**
   * If `true`, the label should be displayed in an error state.
   */
  error?: boolean;
  /**
   * If `true`, the label should use filled classes key.
   */
  filled?: boolean;
  /**
   * If `true`, the input of this label is focused (used by `FormGroup` components).
   */
  focused?: boolean;
  /**
   * If `true`, the label will indicate that the input is required.
   */
  required?: boolean;
  className?: string;
  'data-shrink'?: boolean;
  classes?: {
    [key: string]: string;
  };
  htmlFor?: string;
  'data-testid'?: string;
  id?: string;
  component?: React.ElementType;
};

export const FormLabel = React.forwardRef<HTMLElement, Props>(function FormLabel(props, ref) {
  const {
    children,
    classes = {},
    className,
    color,
    component: Component = 'label',
    disabled,
    error,
    filled,
    focused,
    required,
    ...other
  } = props;
  const muiFormControl = useFormControl();
  const fcs = formControlState({
    props,
    muiFormControl,
    states: Object.keys({
      color,
      required,
      focused,
      disabled,
      error,
      filled,
    }) as State[],
  });
  return (
    <Component
      className={cx(
        styles.root,
        styles[`color${pascalCase(fcs.color || 'primary')}`],
        {
          [cx(classes.disabled, styles.disabled)]: fcs.disabled,
          [cx(classes.error, styles.error)]: fcs.error,
          [cx(classes.filled, styles.filled)]: fcs.filled,
          [cx(classes.focused, styles.focused)]: fcs.focused,
          [cx(classes.required, styles.required)]: fcs.required,
        },
        className
      )}
      ref={ref}
      {...other}
    >
      {children}
      {fcs.required && (
        <span
          aria-hidden
          className={cx(classes.asterisk, styles.asterisk, {
            [cx(classes.error, styles.error)]: fcs.error,
          })}
        >
          &thinsp;{'*'}
        </span>
      )}
    </Component>
  );
});
