/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { AutoCompleteSuggestionsResponse, SuggestionsData, SuggestionsOptions } from '../interface';
import { AxiosInstance } from 'axios';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { createQuery } from '@/utils/createReactQuery';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { GetAutoSuggestUsingGetParamsModel } from '@/api/types/autocomplete-types';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';

const URL = `${requestBaseURL}/external/product-discovery/autocomplete/v1/suggestions`;

type Options = {
  searchTerm: string;
  locale: Locale;
  preview: boolean;
  vehicleId?: string;
};

const getSuggestions = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { locale, searchTerm, preview, vehicleId } = options;
  const country = getCountryFromLocale(locale);

  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  const params: GetAutoSuggestUsingGetParamsModel = {
    country,
    customerType,
    salesChannel,
    preview,
    ...(vehicleId && {
      vehicleId,
    }),
    ...(searchTerm && {
      searchTerm,
    }),
  };
  const response = await getAxios(axiosInstance).get<AutoCompleteSuggestionsResponse>(URL, {
    params,
  });
  return newSelector(response.data);
};

const newSelector = (data: AutoCompleteSuggestionsResponse): SuggestionsData => {
  return {
    generalSuggestions: data.generalSuggestions ?? [],
    productTitleSuggestions: data.productTitleSuggestions ?? [],
  };
};

const { useData: useSearchSuggestions } = createQuery<SuggestionsData, Options>(
  'searchSuggestions',
  getSuggestions
);

export const useSuggestions = ({
  enabled = true,
  ...options
}: SuggestionsOptions & { enabled?: boolean }) => {
  const locale = useLocale();
  const preferredVehicle = usePreferredVehicle();
  return useSearchSuggestions({
    enabled,
    locale,
    vehicleId: preferredVehicle?.catalogVehicleId,
    keepPreviousData: true,
    ...options,
    preview: showXMPreviewDate(),
  });
};
