/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const azLogo = {
  Full: {
    src: '/images/az-logo-full.svg',
    alt: '',
    height: 32,
    width: 265,
  },
  FullMobile: {
    src: '/images/az-logo-full.svg',
    alt: '',
    height: 20,
    width: 160,
  },
  Medium: {
    src: '/images/az-logo-medium.svg',
    alt: '',
    height: 32,
    width: 212,
  },
  MediumMobile: {
    src: '/images/az-logo-medium.svg',
    alt: '',
    height: 20,
    width: 160,
  },
  SmallSize: {
    src: '/images/az-logo-full.svg',
    alt: '',
    height: 18.24,
    width: 151.05,
  },
  SmallSizeMobile: {
    src: '/images/az-logo-full.svg',
    alt: '',
    height: 18.24,
    width: 151.05,
  },
  'es-MX': {
    src: '/images/logoautozonemx.svg',
    height: 54,
    width: 215,
  },
  'es-MXMobile': {
    src: '/images/logoautozonemx.svg',
    height: 32,
    width: 160,
  },
  'pt-BR': {
    src: '/images/AZBrasilLogoV2.svg',
    height: 54,
    width: 215,
  },
  'pt-BRMobile': {
    src: '/images/AZBrasilLogoV2.svg',
    height: 32,
    width: 160,
  },
};
