/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useRef } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { eventConstants } from '@/constants/event';
import { Label } from '@/features/i18n';
import azCommonStyles from '@/theme/globals.module.scss';
import styles from './styles.module.scss';
import { type SuggestionsData } from '@/features/search';
import { Card, Text, View } from '@az/starc-ui';
import Image from '@/components/NextImage';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useFeatureFlag } from '@/features/globalConfig';

type Props = {
  suggestions: SuggestionsData;
  onSuggestionClick: (suggestion: string, type: 'Most Popular' | 'Type Ahead') => void;
  cursor: number;
  searchText: string;
  handleClose: () => void;
  filteredRecentSearches: string[];
};

export function SearchSuggestionsComp(props: Props) {
  const suggestionRefs = useRef<HTMLElement>(null);
  const { data: headerData } = useHeaderData();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const selectedVehicleData = getPreferredVehicle(headerData);
  const searchCharLength =
    Number(useFeatureFlag('SEARCH_CHARACTERS_LENGTH_TO_SHOW_PRODUCT_RECS')) || 5;
  const unHighlightSearchText = (suggestion: string) => {
    const searchText = props.searchText;
    const currentIndex = suggestion.toLowerCase().indexOf(searchText.toLowerCase());
    return (
      <span id="suggestionHigh">
        {suggestion.substring(0, currentIndex)}
        <span className={cx({ [styles.matchedText]: currentIndex > -1 })}>
          {suggestion.substring(currentIndex, currentIndex + searchText?.length)}
        </span>
        {suggestion.substring(currentIndex + searchText?.length)}
      </span>
    );
  };

  const { onSuggestionClick, suggestions, handleClose, cursor = 0 } = props;
  const lblSearchSuggestions = <Label label="label_Search_Results_SearchSuggestions" />;
  const lblFitsVehicle = <Label label="label_fits_vehicle" />;
  const shouldShowProductRecs = Boolean(
    suggestions.productTitleSuggestions?.length &&
      suggestions.productTitleSuggestions.length >= 1 &&
      props.searchText.length >= Number(searchCharLength)
  );

  const renderMobileProductRecs = () => (
    <>
      <View direction="row">
        {selectedVehicleData?.catalogVehicleId && (
          <View.Item>
            <div className={styles.suggestionListDiv}>
              <h1
                className={cx(styles.fitsYourVehicle, {
                  [styles.withoutTopMargin]: suggestions.generalSuggestions.length === 0,
                })}
              >
                {lblFitsVehicle}
              </h1>
            </div>
          </View.Item>
        )}
      </View>
      <View.Item className={styles.productSuggestions}>
        <ul
          className={cx(
            azCommonStyles['az-padding-top-xxs'],
            styles.suggestionsList,
            styles.suggestionsListMobile,
            {
              [styles.withMostPopularSuggestions]:
                Boolean(suggestions.generalSuggestions.length) || shouldShowProductRecs,
              [styles.hiddenOverflow]:
                suggestions?.productTitleSuggestions?.length === 1 &&
                !suggestions.generalSuggestions.length,
            }
          )}
        >
          <SuggestedProducts
            isMobile
            suggestions={suggestions}
            handleClose={handleClose}
            onSuggestionClick={onSuggestionClick}
            searchText={props.searchText}
          />
        </ul>
      </View.Item>
    </>
  );
  return (
    <section className={styles.active} ref={suggestionRefs}>
      {shouldShowProductRecs && isMobile && renderMobileProductRecs()}
      <View direction="row">
        {Boolean(suggestions.generalSuggestions.length) && (
          <View.Item columns={shouldShowProductRecs && !isMobile ? 6 : 12}>
            <div className={styles.suggestionListDiv}>
              <h1 className={styles.searchSuggestions}>{lblSearchSuggestions}</h1>
            </div>
          </View.Item>
        )}
        {shouldShowProductRecs && !isMobile && selectedVehicleData?.catalogVehicleId && (
          <View.Item columns={6}>
            <div className={styles.suggestionListDiv}>
              <h1
                className={cx(styles.searchSuggestions, {
                  [styles.withoutTopMargin]: suggestions.generalSuggestions.length === 0,
                })}
              >
                {lblFitsVehicle}
              </h1>
            </div>
          </View.Item>
        )}
      </View>
      <View
        direction="row"
        divided={props.searchText.length >= Number(searchCharLength)}
        className={styles.popularSearchesContainer}
      >
        {Boolean(suggestions.generalSuggestions.length) && (
          <View.Item columns={shouldShowProductRecs && !isMobile ? 6 : 12}>
            <ul className={cx(azCommonStyles['az-padding-top-xxs'], styles.suggestionsList)}>
              <SuggestionList
                handleClose={handleClose}
                onSuggestionClick={onSuggestionClick}
                cursor={cursor}
                suggestions={suggestions}
                unHighlightSearchText={unHighlightSearchText}
                filteredRecentSearches={props.filteredRecentSearches}
              />
            </ul>
          </View.Item>
        )}
        {shouldShowProductRecs && !isMobile && (
          <View.Item
            columns={suggestions.generalSuggestions.length ? 6 : 12}
            className={styles.productSuggestions}
          >
            <ul className={cx(azCommonStyles['az-padding-top-xxs'], styles.suggestionsList)}>
              <SuggestedProducts
                searchText={props.searchText}
                suggestions={suggestions}
                handleClose={handleClose}
                onSuggestionClick={onSuggestionClick}
              />
            </ul>
          </View.Item>
        )}
      </View>
    </section>
  );
}

function SuggestedProducts({
  suggestions,
  handleClose,
  onSuggestionClick,
  isMobile,
  searchText,
}: {
  suggestions: SuggestionsData;
  handleClose: () => void;
  onSuggestionClick: (suggestion: string, type: 'Type Ahead') => void;
  isMobile?: boolean;
  searchText: string;
}) {
  const router = useRouter();
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (
      e.keyCode === eventConstants.escKeyCode ||
      (e.keyCode !== eventConstants.tabKeyCode &&
        e.keyCode !== eventConstants.enterKeyCode &&
        e.keyCode !== eventConstants.shiftKeyCode)
    ) {
      handleClose();
    }
  };
  const searchCharLength =
    Number(useFeatureFlag('SEARCH_CHARACTERS_LENGTH_TO_SHOW_PRODUCT_RECS')) || 5;
  const renderCard = (children: JSX.Element) =>
    isMobile &&
    (Boolean(suggestions.generalSuggestions?.length) ||
      Boolean(
        suggestions.productTitleSuggestions?.length &&
          suggestions.productTitleSuggestions?.length > 1
      )) ? (
      <Card hasHoverEffect={false}>{children}</Card>
    ) : (
      children
    );
  return (
    <>
      {searchText.length >= Number(searchCharLength) &&
        suggestions.productTitleSuggestions?.slice(0, isMobile ? 3 : 5)?.map((suggestion, index) =>
          renderCard(
            <li
              key={suggestion.suggestedSearchTerm}
              className={cx(styles.suggestionItem, {
                [styles.productSuggestion]: suggestions.generalSuggestions.length,
                [styles.suggestionItemHeight]:
                  Boolean(suggestions.generalSuggestions.length) ||
                  Boolean(
                    suggestions.productTitleSuggestions?.length &&
                      suggestions.productTitleSuggestions?.length <= 1
                  ),
              })}
            >
              <button
                role="link"
                data-suggestionitem={true}
                onMouseDown={() => {
                  router.push(suggestion.productDetailsPageUrl);
                  onSuggestionClick(suggestion.suggestedSearchTerm, 'Type Ahead');
                  handleClose();
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === eventConstants.enterKeyCode) {
                    router.push(suggestion.productDetailsPageUrl);
                    onSuggestionClick(suggestion.suggestedSearchTerm, 'Type Ahead');
                    handleClose();
                  }
                  if (
                    e.keyCode === eventConstants.tabKeyCode &&
                    index === (suggestions.productTitleSuggestions?.length ?? 0) - 1
                  ) {
                    handleClose();
                  }
                  handleOnKeyDown(e);
                }}
              >
                <View direction="row" align="center" gap={2} padding={2}>
                  <View width="47px" height="47px">
                    <View.Item className={styles.productImage}>
                      <Image
                        alt={suggestion.suggestedSearchTerm}
                        src={suggestion.productImageUrl}
                        width={47}
                        height={47}
                      />
                    </View.Item>
                  </View>
                  <View.Item grow>
                    <Text maxLines={2} size="087">
                      {suggestion.suggestedSearchTerm}
                    </Text>
                  </View.Item>
                </View>
              </button>
            </li>
          )
        )}
    </>
  );
}

function SuggestionList({
  suggestions,
  cursor,
  handleClose,
  unHighlightSearchText,
  onSuggestionClick,
  filteredRecentSearches,
}: {
  suggestions: SuggestionsData;
  cursor: number;
  filteredRecentSearches: string[];
  handleClose: () => void;
  unHighlightSearchText: (text: string) => React.ReactNode;
  onSuggestionClick: (suggestion: string, type: 'Most Popular') => void;
}) {
  const router = useRouter();
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (
      e.keyCode === eventConstants.escKeyCode ||
      (e.keyCode !== eventConstants.tabKeyCode &&
        e.keyCode !== eventConstants.enterKeyCode &&
        e.keyCode !== eventConstants.shiftKeyCode)
    ) {
      handleClose();
    }
  };

  return (
    <>
      {suggestions.generalSuggestions.map((suggestion, index) => {
        return (
          <li
            key={suggestion}
            className={cx(azCommonStyles['az-body-2-heavy'], styles.suggestionItem, {
              [styles.activeItem]: index + filteredRecentSearches.length === cursor,
              [styles.suggestionItemHeight]:
                Boolean(suggestions.generalSuggestions.length) ||
                Boolean(
                  suggestions.productTitleSuggestions?.length &&
                    suggestions.productTitleSuggestions?.length <= 1
                ),
            })}
          >
            <button
              role="link"
              data-suggestionitem={true}
              onMouseDown={() => {
                router.push(`/searchresult?searchText=${encodeURIComponent(suggestion)}`);
                onSuggestionClick(suggestion, 'Most Popular');
                handleClose();
              }}
              onKeyDown={(e) => {
                if (e.keyCode === eventConstants.enterKeyCode) {
                  router.push(`/searchresult?searchText=${encodeURIComponent(suggestion)}`);
                  onSuggestionClick(suggestion, 'Most Popular');
                  handleClose();
                }
                if (
                  e.keyCode === eventConstants.tabKeyCode &&
                  index === (suggestions.generalSuggestions?.length ?? 0) - 1 &&
                  !suggestions.productTitleSuggestions?.length
                ) {
                  handleClose();
                }
                handleOnKeyDown(e);
              }}
            >
              {unHighlightSearchText(suggestion)}
            </button>
          </li>
        );
      })}
    </>
  );
}

export default SearchSuggestionsComp;
