/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { routePaths } from '@/constants/routePaths';
const labelsMap = {
  label_Homepage_content_ShopDeals: 'label_Homepage_content_ShopDeals',
  lblTopDeals: 'label_Deals_TopDeals',
  lblStoreAd: 'label_Deals_Filter_LocalStoreAd',
  lblRebates: 'label_Deals_Filter_Rebates',
  lblRepairHelp: 'label_Sitemap_SectionHeader_RepairHelp',
  lblContactUs: 'label_MyAccountOrder_details_ContactUs',
  lblShopByMake: 'label_menu_ShopbyMake',
  lblShopByModel: 'label_menu_ShopbyModel',
  lblTrustworthyAdvice: 'label_menu_TrustworthyAdvice',
  lblRepairGuides: 'label_menu_RepairGuides',
  lblHowTosAdvice: 'label_menu_HowTosAdvice',
  lblFixFinder: 'label_menu_FixFinder',
  lblFindARepairShop: 'label_menu_FindaRepairShop',
  lblInStoreServices: 'label_Footer_main_InStoreServices',
};

export const shopDealsData = {
  header: labelsMap.label_Homepage_content_ShopDeals,
  data: [
    {
      label: labelsMap.lblTopDeals,
      to: {
        pathname: routePaths.landingPageDeals,
      },
      id: 'top-deals',
    },
    {
      label: labelsMap.lblStoreAd,
      to: {
        pathname: routePaths.circular,
      },
      id: 'store-ad',
    },
    {
      label: labelsMap.lblRebates,
      to: {
        pathname: routePaths.dealsRebate,
      },
      id: 'rebates',
    },
  ],
};

export const shopByData = {
  header: 'Shop By',
  data: [
    {
      label: 'Shop by Brand',
      to: {
        pathname: routePaths.shopByBrand,
      },
      id: 'nav-bar-menu-list-link-shop-by-brand',
    },
    {
      label: labelsMap.lblShopByMake,
      to: {
        pathname: routePaths.shopByMake,
      },
      id: 'nav-bar-menu-list-link-shop-by-make',
    },
    {
      label: labelsMap.lblShopByModel,
      to: {
        pathname: routePaths.shopByModel,
      },
      id: 'nav-bar-menu-list-link-shop-by-model',
    },
  ],
};

export const trustworthyAdviceData = {
  header: labelsMap.lblTrustworthyAdvice,
  data: [
    {
      label: labelsMap.lblHowTosAdvice,
      to: {
        pathname: routePaths.azDiy,
      },
      id: 'nav-bar-menu-list-link-how-tos',
    },
    {
      label: labelsMap.lblFixFinder,
      to: {
        pathname: routePaths.landingPageFixFinder,
      },
      id: 'nav-bar-menu-list-link-fix-finder',
    },
    {
      label: labelsMap.lblFindARepairShop,
      to: {
        pathname: routePaths.landingPageFindARepairShop,
      },
      id: 'nav-bar-menu-list-link-find-repair-shop',
    },
    {
      label: labelsMap.lblRepairHelp,
      to: {
        pathname: routePaths.landingPageRepairHelp,
      },
      id: 'nav-bar-menu-list-link-repair-help',
    },
    {
      label: labelsMap.lblInStoreServices,
      to: {
        pathname: routePaths.landingPageInStoreServices,
      },
      id: 'nav-bar-menu-list-link-in-store-services',
    },
  ],
};

export const contactData = {
  data: [
    {
      label: labelsMap.lblContactUs,
      to: {
        pathname: routePaths.contactUs,
      },
      id: 'contactus-link',
    },
  ],
};

export const Routes = {
  parts: '/parts',
  interiorAccessories: '/interior-accessories',
  exteriorAccessories: '/exterior-accessories',
  trucks: '/truck-and-towing',
  tools: '/tools-and-equipment',
  chemicals: '/fluids-and-chemicals',
  performance: '/performance',
  myWarranties: '/myWarranties',
};

export const dataTestIds = {
  menuNavButtonFixedHeader: 'menu-nav-button-top-nav-fixed-header',
  menuNavButtonMobile: 'menu-nav-button-mobile',
  menuNavButton: 'menu-nav-button',
  deskTopVehicleMenuNavFixedTop: 'deskTopVehicle-menu-nav-fixed-top',
  deskTopVehicleMenuLg: 'deskTopVehicle-menu-lg',
  deskTopVehicleMenuSm: 'deskTopVehicle-menu-sm',
  storeNameText: 'store-name-text',
  storeNameTextTopHeader: 'store-name-text-top-header',
  desktopSearchInputFixedTopHeader: 'desktop-search-input-fixed-top-header',
  desktopSearchInput: 'desktop-search-input',
  locationSearchBtn: 'locationSearch-btn',
  locationSearchBtnIsMobileSearchDrawer: 'locationSearch-btn-isMobile-searchDrawer',
  locationSearchBtnScroll: 'locationSearch-scroll',
  locatnSrchBtnScrollIsMobSrchDrawer: 'locationSearch-scroll-isMobile-searchDrawer',
  cartLink: 'top-header-cart-link',
  cartLinkTopHeaderWrapper: 'top-header-cart-link-topHeaderWrapper',
} as const;
