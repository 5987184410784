/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { GlobalConfig } from '@/features/globalConfig';

export function getCustomScripts(globalConfig: GlobalConfig | undefined) {
  if (globalConfig) {
    const matchedValues = [];
    for (const [key, value] of Object.entries(globalConfig)) {
      if (key.match(/^HEADJS_\d+$/) && typeof value === 'string') {
        try {
          matchedValues[parseInt(key.split('HEADJS_')[1])] = JSON.parse(value);
        } catch (e) {}
      }
    }
    return matchedValues;
  }

  return [];
}
