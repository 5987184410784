/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { localStorage } from '@/utils/localStorage';
import { ymmeActionSources } from './ymmeHelpers';

type Keys = keyof typeof ymmeActionSources;
type YmmeActionSource = (typeof ymmeActionSources)[Keys];

export const setYmmeActionSource = (ymmeSource?: YmmeActionSource): void => {
  if (ymmeSource && localStorage.getItem(ymmeActionSources.storage) !== ymmeSource) {
    localStorage.setItem(ymmeActionSources.storage, ymmeSource);
  }
};

export const removeYmmeActionSource = (): void => {
  localStorage.removeItem(ymmeActionSources.storage);
};

export const getStoredYmmeActionSource = (): string => {
  return localStorage.getItem(ymmeActionSources.storage) || '';
};
