/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getAxiosForClient } from '@/lib/axios';
import {
  QueryClient,
  QueryFunctionContext,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import type { FormattedContentStackHeader } from '@/features/contentstack/interface';
import { getHeaderContent } from '@/features/contentstack/lib/header/getHeaderContent'; // eslint-disable-line no-restricted-imports
import { PageContextType } from '@/next-types';
import { useMonetateDecisionFlag } from '@/features/kibo';
import { useSegments } from './getSegments';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

const URL = '/api/contentstack/header';

const getCMSHeaderContent = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getHeaderContentKey>>) => {
  const [, , { segments }] = queryKey;
  const { data } = await getAxiosForClient().get<FormattedContentStackHeader>(URL, {
    params: {
      segments: segments.join(','),
    },
  });

  return selector(data);
};

const selector = (data: FormattedContentStackHeader): FormattedContentStackHeader => data;

const getHeaderContentKey = (segments: string[]) => ['cms', 'header', { segments }] as const;

export const useCMSHeaderContent = () => {
  const { data: segments, isSuccess } = useSegments();
  const locale = useLocale();
  const cmsHeaderEnabled = useMonetateDecisionFlag('cmsHeaderEnabled');
  const enabled = cmsHeaderEnabled && (isSuccess || locale === countryCodes.ptBr);
  const data = useGetCMSHeaderContent({
    enabled,
    segments: segments ?? [],
    staleTime: Infinity,
  });

  return {
    enabled,
    ...data,
  };
};

const useGetCMSHeaderContent = (options: UseQueryOptions & { segments: string[] }) => {
  return useQuery({
    queryKey: getHeaderContentKey(options.segments.sort()),
    queryFn: getCMSHeaderContent,
    enabled: options.enabled,
    staleTime: Infinity,
  });
};

export const prefetchCMSHeaderContent = (
  queryClient: QueryClient,
  context: PageContextType,
  userSegments: string[]
) => {
  return queryClient.prefetchQuery({
    queryKey: getHeaderContentKey(userSegments),
    queryFn: () => getHeaderContent(context.req, context.query, userSegments),
  });
};
