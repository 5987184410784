/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { PRODUCT_SPECIFIC_DATALAYER_VARIABLES } from '@/constants/analytics/productSpecificDatalayerVariables';
import type { UTagData } from '@/types';
import root from 'window-or-global';

export const setupAnalytics = (
  hasSetupAnalytics: boolean,
  pathname: string,
  isUtagDefined: boolean,
  updateHasSetupAnalytics?: () => void
): void => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const { pageName = '', pageType = '', siteSection = '', pageURL = '' } = root.utag_data || {};
  const previousPageURL = pageURL;
  const url = root.location.href;
  const documentReferrer = root.document.referrer;
  let marketingChannel;
  let referrer = '';

  if (!documentReferrer && !previousPageURL) {
    marketingChannel = 'Direct Traffic';
    referrer = url;
  }

  if (documentReferrer && !previousPageURL) {
    referrer = documentReferrer;
  } else if (previousPageURL) {
    referrer = previousPageURL;
  }

  let productFindingMethod;

  if (referrer.includes('/locations/')) {
    productFindingMethod = 'Locations';
  } else if (url.includes('intcmp')) {
    productFindingMethod = 'Internal campaign';
  } else if (url.includes('cmpid') || (!!referrer && !referrer.includes('autozone.com'))) {
    productFindingMethod = 'External channels';
  }

  const passPageInfo =
    hasSetupAnalytics &&
    root.utag_data &&
    root.utag_data['dom.pathname'] !== pathname &&
    isUtagDefined;

  root.utag_data = {
    ...((root.utag_data && filterDataLayer(root.utag_data, pathname)) ?? {}),
    pageURL: url,
    previousPageURL,
    referrer,
    marketingChannel,
    previousPageName: passPageInfo ? pageName : '',
    previousPageType: passPageInfo ? pageType : '',
    previousPageSiteSection: passPageInfo ? siteSection : '',
    productFindingMethod,
  };

  if (!hasSetupAnalytics && updateHasSetupAnalytics) {
    updateHasSetupAnalytics();
  }
};

const filterDataLayer = (utagData: UTagData, pathname: string) => {
  if (ACCOUNT_PAGES.includes(pathname)) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    PRODUCT_SPECIFIC_DATALAYER_VARIABLES.forEach((key) => delete utagData[key]);
  }

  return utagData;
};

const ACCOUNT_PAGES = [
  '/user/myAccount',
  '/user/myVehicle',
  '/user/myProfile',
  '/user/orderHistory',
  '/user/rewards/requestCredit',
  '/giftCard/cardBalance',
  '/user/programRules',
];
