/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { ErrorMessage } from '@/types';
import type {
  GooglePayGlobalState,
  PayPalGlobalState,
  PreferredStoreGlobalState,
  RewardsGlobalState,
  SaveForLaterGlobalState,
  SearchGlobalState,
} from '@/types/globalState';
import { type QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, type SetStateAction } from 'react';

export type GlobalStateKeys =
  | 'rewardsData'
  | 'paypal'
  | 'store'
  | 'googlePay'
  | 'saveForLater'
  | 'search';

type GlobalStateValues = {
  rewardsData: RewardsGlobalState;
  paypal: PayPalGlobalState;
  store: PreferredStoreGlobalState;
  googlePay: GooglePayGlobalState;
  saveForLater: SaveForLaterGlobalState;
  search: SearchGlobalState;
};

type Value<Key> = Key extends GlobalStateKeys
  ? GlobalStateValues[Key]
  : ErrorMessage<'Invalid global state key'>;

export const globalStateKeys = new Set<GlobalStateKeys>([
  'rewardsData',
  'paypal',
  'store',
  'googlePay',
  'saveForLater',
  'search',
]);

const getFullKey = (key: GlobalStateKeys) => ['globalState', key] as const;

export const useGlobalState = <Key extends GlobalStateKeys>(
  key: Key
): [
  state: Value<Key> | undefined,
  setState: (updater: SetStateAction<Partial<Value<Key>>>) => void
] => {
  const queryClient = useQueryClient();

  const { data: state } = useQuery<Value<Key>>(
    getFullKey(key),
    () => {
      throw new Error("This query shouldn't be refetched");
    },
    {
      enabled: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const setState = useCallback(
    (updater: SetStateAction<Partial<Value<Key>>>) => {
      setGlobalStateQueryData(key, updater, queryClient);
    },
    [queryClient, key]
  );

  return [state, setState];
};

export const getGlobalStateQueryData = <Key extends GlobalStateKeys>(
  key: Key,
  queryClient: QueryClient
): Value<Key> | undefined => {
  if (!globalStateKeys.has(key)) {
    throw new Error('Invalid global state key');
  }
  return queryClient.getQueryData<Value<Key>>(getFullKey(key));
};

export const setGlobalStateQueryData = <Key extends GlobalStateKeys>(
  key: Key,
  updater: SetStateAction<Partial<Value<Key>>>,
  queryClient: QueryClient
): void => {
  if (!globalStateKeys.has(key)) {
    throw new Error('Invalid global state key');
  }
  queryClient.setQueryData<Value<Key>>(getFullKey(key), (prevState) => {
    if ('call' in updater) {
      if (prevState) {
        return { ...prevState, ...updater(prevState) };
      } else {
        throw new Error("Can't use setter callback without prevState. Set the value directly");
      }
    } else {
      // @ts-expect-error prevState should contail all keys
      const newState: Value<Key> = {
        ...prevState,
        ...updater,
      };

      return newState;
    }
  });
};
