/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const analyticsCookie = {
  cookieZipCode: 'preferredZipCode',
  cookieStoreId: 'preferredStoreId',
  cookieVehicleYear: 'preferredVehicleYear',
  cookieVehicleMake: 'preferredVehicleMake',
  cookieVehicleModel: 'preferredVehicleModel',
  cookieVehicleEngine: 'preferredVehicleEngine',
  cookieVehicleId: 'preferredVehicleId',
  cookieEpsilonId: 'eCookieId',
  cookieuserVehicleCount: 'userVehicleCount',
  cookieprofileId: 'profileId',
  cookieBadgesInfo: 'badgesInfo',
  cookierewardsId: 'rewardsId',
  cookieProductPartIds: 'cartProductPartIds',
  cookieProductSkus: 'cartProductSkus',
  cookieProductTitles: 'cartProductTitles',
  cookieProductVendors: 'cartProductVendors',
  cookieUnitPrice: 'cartUnitPrice',
  cookieCorePrice: 'cartCorePrice',
  cookieDiscountPriceList: 'cartDiscountPriceList',
  cookieisSignedIn: 'isSuccessFullLogin',
  cookieUserType: 'userType',
  cookiePreferredStoreId: 'preferredStoreId',
  cookiePreferredZipCode: 'preferredZipCode',
  cookieRewardPoints: 'rewardPoints',
  cookieNddMarket: 'nddMarket',
  cookieNddHubStore: 'nddHub',
  cookieNddStore: 'nddStore',
  cookieAvailableRewardBalance: 'availableRewardBalance',
  cookieHashedEId: 'hashedEId',
  cookieSearchTerms: 'allSearchTerms',
  cookieLoginFlow: 'loginFlow',
  cookieLoginInteractionMethod: 'loginInteractionMethod',
  cookieLoginMethod: 'loginMethod',
} as const;
