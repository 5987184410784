/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const PRODUCT_SPECIFIC_DATALAYER_VARIABLES = [
  'availableQuantity',
  'Billing_Email',
  'Billing_Phone',
  'bopusArrayShelf',
  'bopusOrderId',
  'categoryLevel1List',
  'categoryLevel2List',
  'categoryLevel3List',
  'giftSavingsAmount',
  'EstimatedDeliverytext',
  'grandTotalAmount',
  'revenueBucket',
  'dealFlagList',
  'nddEligibleArrayShelf',
  'noBopusAvailable',
  'noSTHAvailable',
  'oppbopusFullfillmentAvailabilityList',
  'oppsthFullfillmentAvailabilityList',
  'originalPartTypeIdList',
  'sthArrayShelf',
  'bopus',
  'bopusFullfillmentAvailabilityList',
  'dealFlag',
  'nddaftercutoff',
  'nddFullfillmentAvailabilityList',
  'noNDDAvailable',
  'oppnddFullfillmentAvailabilityList',
  'paymentType',
  'productAvailableQuantityList',
  'productBrandList',
  'productCorePriceList',
  'productDiscountPriceList',
  'productFulfillmentTypeList',
  'productPartList',
  'productPriceList',
  'productQuantityList',
  'productRepositoryIdList',
  'productId',
  'productIdList',
  'productRequestedQuantityList',
  'promoSavingsAmount',
  'requestedQuantity',
  'Shipping_Email',
  'Shipping_Phone',
  'shippingAmountList',
  'ShippingDiscountAmount',
  'shippingGroupIdList',
  'shippingMethodList',
  'sth',
  'sthFullfillmentAvailabilityList',
  'sthOrderId',
  'subTotalAmount',
  'taxAmountList',
  'totalDiscountAmount',
  'totalShippingAmount',
  'fulfillmentAvailabilityList',
  'fulfillmentAvailableAtSelectedQtyList',
] as const;
