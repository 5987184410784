/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const keyCodes = {
  enter: 13,
  zero: 48,
  nine: 57,
};
