/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import * as React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import NextImage from '@/components/NextImage';
import { createRipple, removeRipple } from '@/utils/buttonUtils';
import { LoadingIndicatorDots } from '@/components/LoadingIndicatorDots';

export type Props = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'aria-label' | 'aria-expanded' | 'aria-live' | 'aria-hidden'
> & {
  data?: string;
  placeholder?: string;
  formNoValidate?: boolean;
  // Replace above with HTMLButtonElement if possible
  angled?: boolean;
  leftArrow?: boolean;
  rightArrow?: boolean;
  discreet?: boolean;
  fullWidth?: boolean;
  children: string | React.ReactNode;
  variant?: string;
  fakeButton?: boolean;
  customClass?: any | string;
  iconClear?: boolean;
  emphasis?: string;
  className?: string;
  loading?: boolean;
  reference?: React.Ref<any>;
  size?: string;
  shape?: 'rounded' | 'pill';
  disabled?: boolean;
  ariaHidden?: boolean;
  ariaExpanded?: boolean;
  ariaLabel?: string;
  ariaLive?: 'off' | 'assertive' | 'polite' | undefined;
};

const Button = ({
  angled = false,
  leftArrow = false,
  rightArrow = false,
  discreet = false,
  fullWidth = false,
  size = 'large',
  shape = 'rounded',
  children,
  variant,
  fakeButton,
  customClass,
  iconClear,
  emphasis,
  loading,
  onClick,
  onBlur,
  onKeyDown,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  reference,
  disabled,
  ariaHidden,
  ariaExpanded,
  ariaLabel,
  ariaLive,
  ...props
}: Props) => {
  const emphasisClass = cx({
    [styles.lowEmphasis]: emphasis === 'low',
    [styles.highEmphasis]: emphasis === 'high',
  });
  const sizeClass = cx({
    [styles.large]: size === 'large',
    [styles.small]: size === 'small',
  });
  const customStyles = cx(
    {
      [styles.angled]: angled,
      [styles.iconLeft]: leftArrow,
      [styles.iconRight]: rightArrow,
      [styles.iconClear]: iconClear,
      [styles.discreet]: discreet,
      [styles.fullWidth]: fullWidth,
      [styles[`${shape}`]]: shape,
    },
    sizeClass,
    emphasisClass,
    customClass
  );

  const showRippleEffect =
    variant === 'super' || variant === 'contained' || variant === 'outlined' || variant === 'gray';

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (loading || disabled) {
      return;
    }

    if (onClick) {
      onClick(e);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (loading) {
      return;
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  if (fakeButton === true) {
    return (
      //@ts-expect-error fake button may have shared props as regular buttons but will not have button-exclusive props
      <div
        className={cx(
          styles[`${variant}`],
          {
            [styles.loading]: loading,
          },
          customStyles
        )}
        ref={reference}
        {...props}
      >
        {loading ? (
          <LoadingIndicatorDots color={variant !== 'outlined' ? '#fff' : '#000'} />
        ) : angled ? (
          <span className={styles.angledText}>{children}</span>
        ) : showRippleEffect ? (
          <span className={styles.buttonText}>{children}</span>
        ) : variant === 'chevron' ? (
          <>
            {children}
            <span className={styles.chevronIcon}>
              <NextImage
                src="/images/right-arrow.svg"
                alt="right arrow"
                width={18}
                height={16}
                loading="lazy"
              />
            </span>
          </>
        ) : (
          children
        )}
      </div>
    );
  }

  if (variant === 'link') {
    return (
      <button
        className={cx(styles.link, customStyles)}
        onClick={handleClick}
        onBlur={handleOnBlur}
        onMouseDown={onMouseDown}
        aria-label={ariaLabel}
        {...props}
      >
        {loading ? <LoadingIndicatorDots /> : children}
      </button>
    );
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!loading && !disabled && showRippleEffect) {
      createRipple(e, styles);
    }
    if (onMouseDown) {
      onMouseDown(e);
    }
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (showRippleEffect) {
      removeRipple(e, styles);
    }

    if (onMouseUp) {
      onMouseUp(e);
    }
  };

  const handleOnMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (showRippleEffect) {
      removeRipple(e, styles);
    }

    if (onMouseLeave) {
      onMouseLeave(e);
    }
  };

  return (
    <button
      className={cx(
        styles[`${variant}`],
        {
          [styles.loading]: loading,
        },
        customStyles
      )}
      onClick={handleClick}
      onBlur={handleOnBlur}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleOnMouseLeave}
      ref={reference}
      disabled={disabled}
      aria-hidden={ariaHidden}
      aria-expanded={ariaExpanded}
      aria-label={ariaLabel}
      aria-live={ariaLive}
      {...props}
    >
      {loading ? (
        <LoadingIndicatorDots color={variant !== 'outlined' ? '#fff' : '#000'} />
      ) : angled ? (
        <span className={styles.angledText}>{children}</span>
      ) : showRippleEffect ? (
        <span className={styles.buttonText}>{children}</span>
      ) : variant === 'chevron' ? (
        <>
          {children}
          <span className={styles.chevronIcon}>
            <NextImage
              src="/images/right-arrow.svg"
              alt="right arrow"
              width={18}
              height={16}
              loading="lazy"
            />
          </span>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
