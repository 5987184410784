/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { AxiosInstance, AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import type { Store } from '../../../types';
import environmentConfig from '../../../config/environment';
import { fetchOrderDetails } from '../../../actions/details';
import { signout } from '../../../actions/signout';
import retryUrls from './retryUrls';
import { QueryClient } from '@tanstack/react-query';
import { HEADER_PRIMARY_KEY, getHeaderDataFromCache } from '@/features/header/api/getHeader';

const wait = (amount: number): Promise<void> =>
  new Promise<void>((resolve) => setTimeout(resolve, amount));

const MULTI_SESSION_ERROR_CODES = [7081, 7082, 7083];
const errorCodes = [401, 500, 502, 503, 504];

const maxRetries = 2;
const firstRetryTimeout = 2000;
const subsequentRetryTimeout = 4000;
type RetryConfig = AxiosRequestConfig & {
  numberOfRetries: number;
};
type RetryError<T = any> = {
  config: RetryConfig;
  response?: {
    config: RetryConfig;
  } & AxiosResponse<T>;
} & AxiosError;

export const createErrorHandlerInterceptor =
  (axiosInstance: AxiosInstance, store: Store, queryClient: QueryClient) =>
  async (error: RetryError) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    const headerData = getHeaderDataFromCache(queryClient);
    const { headers, method, url, data } = error.response.config;
    const { errorCode } = error.response.data;
    const requestMethod = method || 'get';

    if (error.response?.status === 401) {
      const url = new URL(error.request.responseURL);
      if (url.pathname.startsWith('/ecomm/b2c/v1/currentUser/v2/loginUser')) {
        return Promise.reject(error);
      }

      const rememberMeEnabled = headerData?.myAccountMap?.autoLogin === 'true';
      if (rememberMeEnabled) {
        queryClient.removeQueries({
          queryKey: [HEADER_PRIMARY_KEY],
        });
      } else if (store.getState().appData.canShowToastNotification) {
        store.dispatch(signout(queryClient));
        return Promise.reject(error);
      } else {
        store.dispatch(signout(queryClient));
        return Promise.reject(error);
      }
    }

    if (errorCodes.includes(error.response?.status)) {
      const retryUrlObj = retryUrls.find((obj) => url?.includes(obj.url));

      const eligibleToRetry = retryUrlObj && errorCodes.includes(error.response.status);

      if (eligibleToRetry) {
        let numberOfRetries =
          error.response?.config?.numberOfRetries || error.response?.data?.numberOfRetries || 0;

        if (numberOfRetries <= 1) {
          return Promise.reject(error);
        }

        numberOfRetries--;

        const delay = numberOfRetries === maxRetries ? firstRetryTimeout : subsequentRetryTimeout;
        await wait(delay);

        const isCheckoutAPI = url?.includes('/order');
        const isPaymentApiError = url?.includes('/order/payment') && method === 'patch';
        const isMultiSessionError = MULTI_SESSION_ERROR_CODES.includes(parseInt(errorCode));
        const shouldFetchOrderDetails = isCheckoutAPI && isMultiSessionError && method !== 'get';

        if (shouldFetchOrderDetails && !isPaymentApiError) {
          await store.dispatch(fetchOrderDetails(undefined, queryClient));
        }

        return axiosInstance({
          headers,
          method: requestMethod,
          url,
          data,
          params: {
            ...(error.response.data?.params || ''),
          },
          timeout: retryUrlObj ? retryUrlObj.timeout : environmentConfig.DEFAULT_TIMEOUT,
        });
      }
    }

    return Promise.reject(error);
  };

export default createErrorHandlerInterceptor;
