/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { Currency, Locale } from '@/types/i18n';

export const defaultStoreUS = '6997';

export const countryCodes = {
  us: 'en-US',
  en_us: 'en-USA',
  usa: 'USA',
  mx: 'es-MX',
  mxn: 'MXN',
  mc: 'MC',
  en: 'en',
  es: 'es',
  enUS: 'en-US',
  pr: 'PR',
  vi: 'VI',
  ptBr: 'pt-BR',
} as const;

export const currencyLocales: Record<Locale, Currency> = {
  'en-US': 'USD',
  'es-MX': 'MXN',
  'pt-BR': 'BRL',
};

export const currencySymbols: Record<Locale, string> = {
  'en-US': '$',
  'es-MX': '$',
  'pt-BR': 'R$',
};
