/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import cx from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';
import { TrapFocus } from './TrapFocus';
import { Fade } from '@/components/Fade/Fade';
import { Backdrop } from '@/components/Backdrop/Backdrop';

type Props = {
  children: React.ReactNode;
  className: string;
  disableEscapeKeyDown: boolean;
  onClose?: (event: React.MouseEvent | React.KeyboardEvent, a: string) => void | (() => void);
  open: boolean;
  onClick: React.MouseEventHandler;
};

export const Modal = ({
  children,
  className,
  disableEscapeKeyDown,
  onClose,
  open,
  onClick,
}: Props) => {
  React.useEffect(() => {
    const bodyOverflow = (action?: string) => {
      if (document.body && action) {
        document.body.style.overflow = action;
      } else if (document.body) {
        document.body.style.overflow = '';
      }
    };

    if (open) {
      bodyOverflow('hidden');
    }

    return () => {
      bodyOverflow();
    };
  }, [open]);

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    if (onClose) {
      onClose(event, 'backdropClick');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Escape') {
      return;
    }

    if (!disableEscapeKeyDown) {
      event.stopPropagation();

      if (onClose) {
        onClose(event, 'escapeKeyDown');
      }
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <Fade open={open}>
      <div
        role="presentation"
        onKeyDown={handleKeyDown}
        onClick={onClick}
        className={cx(styles.modalRoot, className)}
      >
        <Backdrop open={open} onClick={handleBackdropClick} />
        <TrapFocus open={open}>{children}</TrapFocus>
      </div>
    </Fade>,
    document.body
  );
};
