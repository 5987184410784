/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const commonValidation = {
  tel52: 'tel:+52',
  tel55: 'tel:+55',
  tel1: 'tel:+1',
  miles: 'miles',
  AZRWEB: 'AZRMFWEB',
  AZRTAB: 'AZRMFTAB',
  AZRMOB: 'AZRMFMOB',
  info: 'info',
  error: 'error',
  verbose: 'verbose',
  success: 'success',
};
