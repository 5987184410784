/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { createContext, useReducer, useMemo, useContext } from 'react';
import type { AppState, AppStateAction } from './interface';
import { rootReducer } from './reducers';

export const defaultState: AppState = {
  eCookieId: '',
  userId: null,
  rewardsId: null,
  isYmmeTooltipEnabled: true,
  proxiedHostname: null,
  campaignModalEnabled: true,
  locale: null,
  isMobileApp: false,
  IS_RA_SEARCH_PART_TYPE_API: false,
  IS_DEFAULT_GRID_VIEW_ENABLED: false,
  reqUrl: null,
  mobileAppVersion: '',
};

type Dispatch = (action: AppStateAction) => void;

const AppStateContext = createContext<{ state: AppState; dispatch: Dispatch } | undefined>(
  undefined
);

type AppStateProviderProps = {
  initialState: AppState;
  children: React.ReactNode;
};

export function AppStateProvider({ initialState, children }: AppStateProviderProps) {
  const [state, dispatch] = useReducer(rootReducer, initialState || defaultState);

  const value = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateProvider');
  }
  return context;
}
