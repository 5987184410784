/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { useForkRef } from '@/hooks/useForkRef';
import { useEventCallback } from '../../hooks/useEventCallback';
import { useIsFocusVisible } from '../../hooks/useIsFocusVisible';
import styles from './ButtonBase.module.scss';

export type Props = React.ButtonHTMLAttributes<HTMLElement> & {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;

  /**
   * If `true`, the base button will be disabled.
   */
  disabled?: boolean;
  /**
   * For screen readers
   */
  ariaLabel?: string;

  /**
   * Callback fired when the component is focused with a keyboard.
   * We trigger a `onFocus` callback too.
   */
  component?: React.ElementType;
  tabIndex?: number | null;
  className?: string;
  onFocusVisible?: (event: Event) => unknown;
  value?: string; // TODO: not sure what's "value" for. Investigate
};
/**
 * `ButtonBase` contains as few styles as possible.
 * It aims to be a simple building block for creating a button.
 * It contains a load of style reset and some focus logic.
 */

export const ButtonBase = React.forwardRef<HTMLElement, Props>(function ButtonBase(props, ref) {
  const {
    children,
    className,
    component = 'button',
    disabled = false,
    ariaLabel,
    onFocusVisible,
    tabIndex = 0,
    type = 'button',
    onMouseLeave,
    onMouseDown,
    onBlur,
    onFocus,
    onKeyDown,
    onClick,
    onKeyUp,
    ...other
  } = props;
  const buttonRef = React.useRef<HTMLElement | null>(null);
  const [focusVisible, setFocusVisible] = React.useState(false);

  if (disabled && focusVisible) {
    setFocusVisible(false);
  }

  const { isFocusVisible, onBlurVisible, ref: focusVisibleRef } = useIsFocusVisible();

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    if (focusVisible) {
      event.preventDefault();
    }

    if (onMouseLeave) {
      onMouseLeave(event);
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (focusVisible) {
      onBlurVisible();
      setFocusVisible(false);
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleFocus = useEventCallback((event) => {
    // Fix for https://github.com/facebook/react/issues/7769
    if (!buttonRef.current) {
      buttonRef.current = event.currentTarget;
    }

    if (isFocusVisible(event)) {
      setFocusVisible(true);

      if (onFocusVisible) {
        onFocusVisible(event);
      }
    }

    if (onFocus) {
      onFocus(event);
    }
  });

  const isNonNativeButton = () => {
    return (
      component &&
      component !== 'button' &&
      // @ts-expect-error refine type
      !(buttonRef.current?.tagName === 'A' && buttonRef.current?.href)
    );
  };

  const handleKeyDown = useEventCallback((event) => {
    if (event.target === event.currentTarget && isNonNativeButton() && event.key === ' ') {
      event.preventDefault();
    }

    if (onKeyDown) {
      onKeyDown(event);
    } // Keyboard accessibility for non interactive elements

    if (
      event.target === event.currentTarget &&
      isNonNativeButton() &&
      event.key === 'Enter' &&
      !disabled
    ) {
      event.preventDefault();

      if (onClick) {
        onClick(event);
      }
    }
  });
  const handleKeyUp = useEventCallback((event) => {
    // calling preventDefault in keyUp on a <button> will not dispatch a click event if Space is pressed
    // https://codesandbox.io/s/button-keyup-preventdefault-dn7f0
    if (event.key === ' ' && focusVisible && !event.defaultPrevented) {
      event.persist();
    }

    if (onKeyUp) {
      onKeyUp(event);
    } // Keyboard accessibility for non interactive elements

    if (
      onClick &&
      event.target === event.currentTarget &&
      isNonNativeButton() &&
      event.key === ' ' &&
      !event.defaultPrevented
    ) {
      onClick(event);
    }
  });
  let ComponentProp = component;

  // @ts-expect-error refine type
  if (ComponentProp === 'button' && other.href) {
    ComponentProp = 'a';
  }

  const handleOwnRef = useForkRef(focusVisibleRef, buttonRef);
  const handleRef = useForkRef(ref, handleOwnRef);
  return (
    <ComponentProp
      className={cx(
        styles.root,
        {
          [styles.disabled]: disabled,
          [styles.focusVisible]: focusVisible,
        },
        className
      )}
      onBlur={handleBlur}
      onClick={onClick}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      ref={handleRef}
      aria-label={ariaLabel}
      tabIndex={disabled ? -1 : tabIndex}
      {...(ComponentProp === 'button'
        ? {
            type,
            disabled,
          }
        : {
            // @ts-expect-error refine type
            ...(ComponentProp !== 'a' || !other.href
              ? {
                  role: 'button',
                }
              : undefined),
            'aria-disabled': disabled,
          })}
      {...other}
    >
      {children}
    </ComponentProp>
  );
});
