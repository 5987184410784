/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import root from 'window-or-global';
import removeWrappingParagraphTag from './removeWrappingParagraphTag';
/**
 * DynamiContent is a component which render the provided htm dynamically we can use this
 * component in place of using dangerouslySetInnerHTML.
 * @param    {string}    tagName     wrapper html element for the provided html.
 * @param    {object}    attrs       html attributes of the wrapper element.
 * @param    {string}    innerHtml   inner html contents of the element.
 * @return   {[Object]}  Return a react element.
 */

type Props = {
  tagName: string;
  attrs?: {};
  innerHtml: string;
};

const DynamicContent = ({ tagName, attrs = {}, innerHtml }: Props) => {
  React.useEffect(() => {
    //script tags cannot be evaluated by dangerouslySetInnerHTML, need to evaluate separately.
    const inlineScriptTags = Array.from(document.querySelectorAll('script[inner-page-script]'));
    const scriptTags: Array<HTMLScriptElement> = Array.from(
      document.querySelectorAll('script[content-page-script]') as any
    );

    function loadInlineScript() {
      const scriptTags = Array.from(document.querySelectorAll('script[inner-page-script]'));

      if (scriptTags.length > 0) {
        scriptTags.forEach((scriptTag) => {
          if (scriptTag.innerHTML) {
            root.eval(scriptTag.innerHTML);
          }
        });
      }
    }

    if (scriptTags.length > 0) {
      scriptTags.forEach((scriptTag) => {
        if (!scriptTag.innerHTML && scriptTag.src) {
          const script = document.createElement('script');

          if (document.head) {
            document.head.appendChild(script);
          }

          script.async = false;
          script.onload = loadInlineScript;
          script.src = scriptTag.src;
        }
      });
    }

    if (inlineScriptTags.length > 0) {
      inlineScriptTags.forEach((scriptTag) => {
        if (
          scriptTag.innerHTML &&
          scriptTag.innerHTML.indexOf('$') < 0 &&
          scriptTag.innerHTML.indexOf('jQuery') < 0 &&
          scriptTag.innerHTML.indexOf('Invodo') < 0
        ) {
          root.eval(scriptTag.innerHTML);
        }
      });
    }
  }, []);

  const html = removeWrappingParagraphTag(innerHtml, tagName);

  if (html) {
    return React.createElement(tagName, {
      'data-testid': 'dynamic-content',
      ...attrs,
      dangerouslySetInnerHTML: {
        __html: html,
      },
    });
  }

  return null;
};

export default DynamicContent;
