/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { IconButton } from '@/components/IconButton/IconButton';
import CloseIcon from '@/public/images/close.svg';
import DarkCloseIcon from '@/public/images/close-dark.svg';
import iconStyles from '@/theme/iconStyles.module.scss';

type CloseButtonProps = {
  closeRef: React.Ref<HTMLButtonElement>;
  handleClose?: () => void;
  color?: 'black' | 'grey';
};

export const CloseButton = ({ closeRef, handleClose, color = 'grey' }: CloseButtonProps) => {
  return (
    <IconButton
      ref={closeRef}
      aria-label="Close"
      className={styles.headerCloseButton}
      onClick={handleClose}
      data-testid="modal-close-btn"
    >
      {color === 'grey' ? (
        <CloseIcon
          className={cx(iconStyles.defaultIconStyle, styles.closeIcon)}
          data-testid="modal-close-btn-icon"
        />
      ) : (
        <DarkCloseIcon
          className={cx(iconStyles.defaultIconStyle, styles.closeIcon)}
          data-testid="modal-close-btn-icon"
        />
      )}
    </IconButton>
  );
};
