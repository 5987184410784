/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useHeaderData } from '../api/getHeader';
import { getPreferredVehicle } from '../utils/getPreferredVehicle';

export const usePreferredVehicle = () => {
  const { data: headerData } = useHeaderData();
  return getPreferredVehicle(headerData);
};
