/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { createQuery } from '@/utils/createReactQuery';
import { AxiosInstance } from 'axios';
import type { ProductData, ProductDetailsOptions, ProductResponse } from '../interface';
import { getAxios } from '@/lib/axios';
import { GetProductUsingGetParamsModel } from '@/api/types/products-types';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { useLocale } from '@/hooks/useLocale';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { useRouter } from 'next/router';
import { QueryClient, UseQueryResult } from '@tanstack/react-query';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { getPathnameFromAsPath } from '@/utils/urlHelpers';
import { getProductDetailsURL } from '../utils/getProductDetailsURL';

const URL = `${requestBaseURL}/external/product-discovery/product/v1/products`;

const getProductDetails = async (options: ProductDetailsOptions, axiosInstance?: AxiosInstance) => {
  const {
    locale,
    itemIds,
    vehicleId,
    storeId,
    partNumber,
    userSegment,
    productDetailsPageUrl,
    lineCode,
    preview,
  } = options;

  const country = getCountryFromLocale(locale);
  const salesChannel = 'ECOMM';
  const customerType = 'B2C';

  const params: GetProductUsingGetParamsModel = {
    country,
    salesChannel,
    customerType,
    itemIds,
    vehicleId,
    storeId,
    partNumber,
    userSegment,
    productDetailsPageUrl,
    preview,
    lineCode,
  };
  const response = await getAxios(axiosInstance).get<ProductResponse>(URL, { params });
  return response.data;
  // TODO: Restore after old api is removed
  // return newSelector(response.data);
};

const selector = (data: ProductResponse): ProductData => {
  return {
    product: data.pdpResults?.products?.[0],
    redirectPageType: data.redirectPageType,
    redirectURL: data.redirectURL,
    xFusionQueryId: data.xFusionQueryId,
  };
};

const {
  useData,
  prefetch: prefetchProductDetails,
  getFromCache,
} = createQuery<ProductResponse, ProductDetailsOptions>('productDetails', getProductDetails);

type IsStrictlyAny<T> = (T extends never ? true : false) extends false ? false : true;

type UseDataResult<Result> = UseQueryResult<IsStrictlyAny<Result> extends true ? unknown : Result>;

export const useProductDetails = ({
  enabled = true,
  keepPreviousData = false,
  select = selector,
}: {
  enabled?: boolean;
  keepPreviousData?: boolean;
  select?: (data: ProductResponse) => ProductData;
} = {}): UseDataResult<ProductData> => {
  const locale = useLocale();
  const headerResult = useHeaderData();
  const storeNumber = headerResult.data?.storeNumber;
  const vehicleId = getPreferredVehicle(headerResult.data)?.catalogVehicleId ?? undefined;

  const router = useRouter();
  const { slug: slugFromQuery } = router.query;
  // TODO: Should be simplified to router.query.slug once https://github.com/vercel/next.js/issues/45340 is fixed

  const slug =
    slugFromQuery ??
    getPathnameFromAsPath(router.asPath)
      .split('/')
      .map((pathItem) => {
        if (pathItem !== '') {
          return pathItem.split('#')[0];
        }
      });

  const slugString = Array.isArray(slug) ? `${slug.join('/')}` : '';
  const seoUrl = getProductDetailsURL(slugString.startsWith('/') ? slugString : `/${slugString}`);

  return useData({
    keepPreviousData,
    locale,
    vehicleId: vehicleId ? Number(vehicleId) : 0,
    storeId: storeNumber ? Number(storeNumber) : undefined,
    lineCode: undefined,
    productDetailsPageUrl: seoUrl,
    userSegment: undefined,
    partNumber: undefined,
    itemIds: undefined,
    preview: showXMPreviewDate(),
    enabled,
    select,
  });
};

const getProductDetailsFromCache = (
  queryClient: QueryClient,
  options: ProductDetailsOptions
): ProductData | undefined => {
  const data = getFromCache(queryClient, options);

  if (!data) {
    return data;
  }

  return selector(data);
};

export { prefetchProductDetails, getProductDetailsFromCache };
