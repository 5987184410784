/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { isFilled, isAdornedStart } from '../InputBase/utils';
import { pascalCase } from '../../utils/pascalCase';
import FormControlContext from './FormControlContext';
import styles from './FormControl.module.scss';

function isMuiElement(element: React.ReactElement, muiNames: string[]) {
  return (
    React.isValidElement(element) &&
    // @ts-expect-error muiName is manually added by some components
    muiNames.indexOf(element.type.muiName) !== -1
  );
}

type Props = {
  /**
   * The contents of the form control.
   */
  children?: React.ReactNode;
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: 'primary' | 'secondary';
  /**
   * If `true`, the label, input and helper text should be displayed in a disabled state.
   */
  disabled?: boolean;
  /**
   * If `true`, the label should be displayed in an error state.
   */
  error?: boolean;
  /**
   * If `true`, the component will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * If `true`, the component will be displayed in focused state.
   */
  focused?: boolean;
  /**
   * If `true`, the label will be hidden.
   * This is used to increase density for a `FilledInput`.
   * Be sure to add `aria-label` to the `input` element.
   */
  hiddenLabel?: boolean;
  /**
   * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
   */
  margin?: 'dense' | 'none' | 'normal';
  /**
   * If `true`, the label will indicate that the input is required.
   */
  required?: boolean;
  /**
   * The size of the text field.
   */
  size?: 'small' | 'medium';
  /**
   * The variant to use.
   */
  variant?: 'standard' | 'outlined' | 'filled';
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: React.ElementType;
  className?: string;
  'aria-describedby'?: string;
  'data-testid'?: string;
  id?: string;
};
/**
 * Provides context such as filled/focused/error/required for form inputs.
 * Relying on the context provides high flexibility and ensures that the state always stays
 * consistent across the children of the `FormControl`.
 * This context is used by the following components:
 *
 *  - FormLabel
 *  - FormHelperText
 *  - Input
 *  - InputLabel
 *
 * You can find one composition example below and more going to [the demos](/components/text-fields/#components).
 *
 * ```jsx
 * <FormControl>
 *   <InputLabel htmlFor="my-input">Email address</InputLabel>
 *   <Input id="my-input" aria-describedby="my-helper-text" />
 *   <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
 * </FormControl>
 * ```
 *
 * ⚠️Only one input can be used within a FormControl.
 */

export const FormControl = React.forwardRef<HTMLElement, Props>(function FormControl(props, ref) {
  const {
    children,
    className,
    color = 'primary',
    component: Component = 'div',
    disabled = false,
    error = false,
    fullWidth = false,
    focused: visuallyFocused,
    hiddenLabel = false,
    margin = 'none',
    required = false,
    size,
    variant = 'standard',
    ...other
  } = props;
  const [adornedStart, setAdornedStart] = React.useState(() => {
    // We need to iterate through the children and find the Input in order
    // to fully support server-side rendering.
    let initialAdornedStart = false;

    if (children) {
      React.Children.forEach(children, (child) => {
        if (!React.isValidElement(child)) {
          return;
        }
        if (!isMuiElement(child, ['Input', 'Select'])) {
          return;
        }

        const input = isMuiElement(child, ['Select']) ? child.props.input : child;

        if (input && isAdornedStart(input.props)) {
          initialAdornedStart = true;
        }
      });
    }

    return initialAdornedStart;
  });
  const [filled, setFilled] = React.useState(() => {
    // We need to iterate through the children and find the Input in order
    // to fully support server-side rendering.
    let initialFilled = false;

    if (children) {
      React.Children.forEach(children, (child) => {
        if (!React.isValidElement(child)) {
          return;
        }
        if (!isMuiElement(child, ['Input', 'Select'])) {
          return;
        }

        if (isFilled(child.props, true)) {
          initialFilled = true;
        }
      });
    }

    return initialFilled;
  });
  const [_focused, setFocused] = React.useState(false);
  const focused = visuallyFocused !== undefined ? visuallyFocused : _focused;

  if (disabled && focused) {
    setFocused(false);
  }

  let registerEffect;

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const registeredInput = React.useRef(false);

    registerEffect = () => {
      if (registeredInput.current) {
        // eslint-disable-next-line no-console
        console.error(
          [
            'There are multiple InputBase components inside a FormControl.',
            'This is not supported. It might cause infinite rendering loops.',
            'Only use one InputBase.',
          ].join('\n')
        );
      }

      registeredInput.current = true;
      return () => {
        registeredInput.current = false;
      };
    };
  }

  const onFilled = React.useCallback(() => {
    setFilled(true);
  }, []);
  const onEmpty = React.useCallback(() => {
    setFilled(false);
  }, []);
  const childContext = {
    adornedStart,
    setAdornedStart,
    color,
    disabled,
    error,
    filled,
    focused,
    fullWidth,
    hiddenLabel,
    margin: (size === 'small' ? 'dense' : undefined) || margin,
    onBlur: () => {
      setFocused(false);
    },
    onEmpty,
    onFilled,
    onFocus: () => {
      setFocused(true);
    },
    registerEffect,
    required,
    variant,
  };
  return (
    <FormControlContext.Provider value={childContext}>
      <Component
        className={cx(
          styles.root,
          {
            [styles[`margin${pascalCase(margin)}`]]: margin !== 'none',
            [styles.fullWidth]: fullWidth,
          },
          className
        )}
        ref={ref}
        {...other}
      >
        {children}
      </Component>
    </FormControlContext.Provider>
  );
});
