/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useRef, MouseEvent, KeyboardEvent } from 'react';
import styles from './styles.module.scss';
import { View } from '@az/starc-ui';
import { setCookie } from '@/utils/cookie';
import { cookieConstant } from '@/constants/cookies';
import { useRouter } from 'next/router';
import RecentSearchItem from './RecentSearchItem';
import { useLabel } from '@/hooks/useLabels';
import { sessionStorage } from '../../utils/sessionStorage';

const isKeyboardEvent = (e: React.KeyboardEvent | React.MouseEvent): e is React.KeyboardEvent => {
  return (e as React.KeyboardEvent).key !== undefined;
};

type RecentSearchesProps = {
  recentSearches: string[];
  searchText: string;
  cursor: number;
  addRecentSearch: (searchTerm: string) => void;
  deleteRecentSearch: (index: number) => void;
  onSearchTextChange: (searchTerm: string) => void;
  handleClose: () => void;
  magnifierButtonRef?: React.MutableRefObject<HTMLButtonElement | null>;
};

const RecentSearches = ({
  recentSearches,
  searchText,
  cursor,
  addRecentSearch,
  deleteRecentSearch,
  onSearchTextChange,
  handleClose,
  magnifierButtonRef,
}: RecentSearchesProps) => {
  const deleteEntryButtonRefs = [
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
  ];
  const router = useRouter();
  const lblRecentSearches = useLabel('label_recentSearches');

  const handleEntryClick = (e: MouseEvent | KeyboardEvent, entry: string) => {
    if (
      deleteEntryButtonRefs.some((ref) => ref.current === e.target) ||
      (isKeyboardEvent(e) && e.code !== 'Enter' && e.code !== 'Space')
    ) {
      return;
    }
    router.push(`/searchresult?searchText=${encodeURIComponent(entry)}`);
    setCookie(cookieConstant.names.filterOpenStates, '');
    sessionStorage.setItem('searchMethod', 'Most Recent');
    sessionStorage.setItem('performedSearch', 'true');
    onSearchTextChange(entry);
    addRecentSearch(entry);
    handleClose();
  };

  const handleDeleteClick = (e: MouseEvent | KeyboardEvent, index: number) => {
    deleteRecentSearch(index);
    magnifierButtonRef?.current?.focus();
  };

  return (
    <View as="section" className={styles.recentSearchesContainer}>
      <View padding={[0, 4]}>
        <span className={styles.header}>{lblRecentSearches}</span>
      </View>
      <View as="ul">
        {recentSearches.map((entry, index) => (
          <RecentSearchItem
            key={`recent-${entry}`}
            handleDeleteClick={handleDeleteClick}
            handleEntryClick={handleEntryClick}
            searchText={searchText}
            entry={entry}
            index={index}
            cursor={cursor}
            deleteEntryButtonRef={deleteEntryButtonRefs[index]}
          />
        ))}
      </View>
    </View>
  );
};

export default RecentSearches;
