/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from '@/constants/locale';
import { Label } from '@/features/i18n';
import type { Locale } from '@/types/i18n';
import { NAME, NAME_MX } from '../validatorRegex';

const numToWord = {
  '1': 'only one space',
  '2': 'only two spaces',
  '3': 'only three spaces',
};

export function nameValidator(allowedSpaces: 1 | 2 | 3, name: string) {
  if (!name) {
    return undefined;
  }
  const spaces = name.match(/ /g) || [];
  const invalid = name.match(/[^A-Za-z '-]/g) || [];
  const symbols = name.match(/[^A-Za-z]/g) || [];

  switch (true) {
    case spaces.length > allowedSpaces: {
      const labelSuffix = numToWord[`${allowedSpaces}`];
      return <Label label={`label_Account_page_${labelSuffix}`} />;
    }
    case invalid.length > 0:
      return <Label label="label_Only_dashes_and_apostrophes_are_allowed_in_names." />;
    case symbols.length === name.length:
      return <Label label="label_Your_name_must_contain_letters." />;
    case name.replace(/ /g, '').length < 2:
      return <Label label="error_Common_Validation_InvalidName" />;
    default:
      return undefined;
  }
}

export function validateFirstName(value: string | undefined | null, locale?: Locale | null) {
  if (value && value.replace(/ /g, '').length < 1) {
    return <Label label="error_Validation_YouAreMissingAFirstName" />;
  } else if (
    (locale === countryCodes.mx && !NAME_MX.test(value || '')) ||
    (locale !== countryCodes.mx && !NAME.test(value || ''))
  ) {
    return <Label label="error_Common_Validation_InvalidFirstName" />;
  }

  const spaces = (value || '').split(' ').length - 1;

  if (spaces > 1) {
    return `Only ${numToWord['1']} allowed in this name.`;
  }
}

export function validateLastName(value: string | undefined | null, locale?: Locale | null) {
  if (value && value.replace(/ /g, '').length < 1) {
    return <Label label="error_Validation_YouAreMissingALastName" />;
  } else if (
    (locale === countryCodes.mx && !NAME_MX.test(value || '')) ||
    (locale !== countryCodes.mx && !NAME.test(value || ''))
  ) {
    return <Label label="error_Common_Validation_InvalidLastName" />;
  }

  const spaces = (value || '').split(' ').length - 1;

  if (spaces > 3) {
    return `Only ${numToWord['3']} allowed in this name.`;
  }
}
