/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { Paper } from '../Paper';
import { pascalCase } from '../../utils/pascalCase';
import styles from './AppBar.module.scss';

type Props = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;

  /**
   * @ignore
   */
  className?: string;

  /**
   * The positioning type. The behavior of the different options is described
   * [in the MDN web docs](https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Positioning).
   * Note: `sticky` is not universally supported and will fall back to `static` when unavailable.
   */
  position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
};

export const AppBar = React.forwardRef<HTMLElement, Props>(function AppBar(props, ref) {
  const { className, position = 'fixed', ...other } = props;
  return (
    <Paper
      square
      component="header"
      elevation={0}
      className={cx(styles.root, styles[`position${pascalCase(position)}`], className)}
      ref={ref}
      {...other}
    />
  );
});
