/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Label } from '@/features/i18n';
import { EMAIL } from '../validatorRegex';

export function validateEmail(email: string, type?: string) {
  if (!EMAIL.test(email)) {
    switch (type) {
      case 'rewards':
        return (
          <Label label="label_Please_enter_the_5_digit_ZIP_or_email_address_on_your_account" />
        );

      default:
        return <Label label="error_login_body_PleaseEnterAValidEmail" />;
    }
  }
  return undefined;
}
