/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const countryNames = {
  'en-US': 'United States',
  'es-MX': 'Mexico',
  'es-PR': 'Puerto Rico',
  'pt-BR': 'Brazil',
};
