/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { requestBaseURL } from '@/config/serviceAPI';
import { getAxiosForClient } from '@/lib/axios';
import { useMutation } from '@tanstack/react-query';
import { useGlobalState } from '@/hooks/useGlobalState';
import { crashReporter } from '@/utils/crashReporter';
import { dispatchEvent } from '@/utils/eventPublisher';

const URL = `${requestBaseURL}/ecomm/b2c/v1/store/preferredStore`;

type PostPreferredStoreOptions = {
  storeNumber: string;
};

const postPreferredStore = async ({ storeNumber }: PostPreferredStoreOptions) => {
  const response = await getAxiosForClient().post<string>(URL, { storeNumber });

  return selector(response.data);
};

const selector = (data: string) => data;

export const useSetPreferredStoreMutation = () => {
  const [, setStoreState] = useGlobalState('store');

  return useMutation({
    mutationFn: postPreferredStore,
    onMutate: () => {
      setStoreState({
        setStoreStatus: 'loading',
      });
    },
    onSuccess: (_, { storeNumber }) => {
      setStoreState({
        storeNumber: storeNumber,
        setStoreStatus: 'success',
      });
      dispatchEvent('preferredStoreUpdated', { storeNumber: storeNumber });
    },
    onError: (error) => {
      crashReporter('Error setting preferred store', 'mutation-error', error, 'error');
      setStoreState({
        setStoreStatus: 'error',
        error,
      });
    },
  });
};
