/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect } from 'react';
import type { Field, Form } from 'formik';
import root from 'window-or-global';
import cx from 'classnames';
import { Input } from '../../../Input/Input';
import { InputLabel } from '../../../InputLabel/InputLabel';
import { FormHelperText } from '../../../FormHelperText/FormHelperText';
import { FormControl } from '../../../FormControl/FormControl';
import usePrevious from '../../../../utils/usePrevious';
import { validateMaxLength } from '@/utils/validator/validateMaxLength';
import styles from './styles.module.scss';

export type Props = {
  maxLength?: number;
  maxLengthError?: boolean;
  disabled?: boolean;
  helperText?: React.ReactNode;
  id?: string;
  // @ts-expect-error formik
  field: Field;
  // @ts-expect-error formik
  form: Form;
  input?: any;
  label?: React.ReactNode;
  onBlur?: Function;
  placeholder?: string;
  required?: boolean;
  type?: string;
  normalize?: (b: string, a: string) => unknown;
  isBorderBottom?: boolean;
  isLeftAligned?: boolean;
  tabIndex?: number;
  inputMode?: string;
  passOnChange?: Function;
  showErrorUntilChange?: boolean;
  showErrorIfNotTouched?: boolean;
  isMobileApp?: boolean;
  noMessage?: boolean;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  classes?: { root?: string; formControl?: string; input?: string };
}; // This is a custom text field made to work with
// formik for reference visit
// https://jaredpalmer.com/formik/docs/api/field#component

export default function FormikTextField(props: Props) {
  const {
    disabled = false,
    helperText = '',
    id = '',
    input,
    label = '',
    field,
    form: { touched, errors, setFieldValue, setFieldError },
    placeholder = '',
    type = 'text',
    required = false,
    isBorderBottom = false,
    isLeftAligned = false,
    tabIndex = undefined,
    inputMode = null,
    normalize = null,
    passOnChange = undefined,
    showErrorUntilChange = false,
    showErrorIfNotTouched = false,
    maxLength,
    maxLengthError = false,
    isMobileApp,
    noMessage = false,
    classes = {},
    multiline,
    ...params
  } = props;
  const [showError, setShowError] = useState(showErrorUntilChange);
  const previousValue = usePrevious(field.value);

  useEffect(() => {
    if (
      errors[field.name] &&
      showError &&
      previousValue !== undefined &&
      field.value !== previousValue
    ) {
      setShowError(false);
      setFieldError(field.name, '');
    } else {
      setShowError(true);
    }

    if (maxLengthError && maxLength) {
      const maxError = validateMaxLength(field.value, maxLength) || null;
      maxError && setFieldError(field.name, maxError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);
  const errorMessage =
    (field.value || showErrorIfNotTouched || touched[field.name]) && errors[field.name];
  const idValue = id || input?.name;
  const isFocused = root.document?.activeElement && root.document?.activeElement?.id === idValue;
  const showHelperText = helperText || errorMessage;
  const rootStyles =
    errorMessage && (!isFocused || showErrorUntilChange) ? styles.inputError : styles.inputRoot;
  const formControlStyles = disabled ? styles.disabledFormControl : styles.formControl;
  let inputStyle;
  let labelStyle;
  let helperTextBlock = null;

  if (label) {
    inputStyle = isBorderBottom ? styles.inputBorderBottom : styles.inputWithLabel;

    labelStyle = isLeftAligned ? styles.labelLeftAligned : styles.label;
  } else {
    inputStyle = styles.input;
  }

  if (isMobileApp) {
    inputStyle = `${inputStyle} ${styles.appBorderRadius}`;
  }

  if (!noMessage && (showErrorUntilChange || !isFocused) && (showHelperText || errorMessage)) {
    helperTextBlock = (
      <FormHelperText
        disabled={disabled}
        error={!!errorMessage}
        id={`${String(idValue)}-helper-text`}
        required={required}
        role="alert"
      >
        {errorMessage ? errorMessage : helperText}
      </FormHelperText>
    );
  }

  return (
    <FormControl
      className={styles.textField}
      disabled={disabled}
      error={!!errorMessage && !isFocused}
      fullWidth
      required={required}
    >
      {label ? (
        <InputLabel
          classes={{
            root: labelStyle || '',
            focused: styles.labelFocused,
            shrink: styles.labelShrink,
            disabled: styles.labelDisabled,
            error: styles.error,
            required: styles.labelRequired,
            multiline: multiline ? styles.labelMultiline : '',
          }}
          disabled={disabled}
          error={!!errorMessage && !isFocused}
          htmlFor={idValue}
          required={required}
        >
          {label}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          root: cx(rootStyles, { [classes.root || '']: classes.root }),
          formControl: cx(formControlStyles, {
            [classes.formControl || '']: classes.formControl,
          }),
          input: cx(inputStyle, { [classes.input || '']: classes.input }),
        }}
        disableUnderline
        inputProps={{
          inputMode,
          maxLength,
          tabIndex,
        }}
        value={field.value}
        multiline={multiline}
        {...field}
        disabled={disabled}
        error={!!errorMessage && !isFocused}
        id={idValue}
        placeholder={placeholder}
        required={required}
        type={type}
        onChange={(e: any) => {
          if (passOnChange) {
            passOnChange(e);
          }

          if (normalize) {
            setFieldValue(field.name, normalize(e.target.value, field.value));
          } else {
            setFieldValue(field.name, e.target.value);
          }
        }}
        {...params}
        {...input}
      />
      {helperTextBlock}
    </FormControl>
  );
}
