/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { HeaderData } from '../interface';

export const getPreferredVehicle = (headerData: HeaderData | undefined) => {
  // Header data still contains an object for the preferred vehicle even if none is selected
  // But the `vehicleid` and other properties are empty strings
  // So we check if it's empty don't return the dummy object in that case
  if (headerData?.vehicleMap?.vehicleId) {
    return headerData?.vehicleMap;
  }

  return undefined;
};
