/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import azURL from '../../../config/serviceAPI';
import environmentConfig from '../../../config/environment';
import { routePaths } from '../../../constants/routePaths';
import { parseUrl } from '../../../utils/urlHelpers';
import { getHeaderDataFromCache } from '@/features/header/api/getHeader';
import { QueryClient } from '@tanstack/react-query';
import Router from 'next/router';
import { localStorage } from '@/utils/localStorage';
import { signout } from '@/actions/signout';
import { isRouteProtected } from '@/protectedRoutes';

const API_CALL_PATH_PREFIX = '/ecomm/b2c';
const idleSessionTimeout = environmentConfig.IDLE_SESSION_TIMEOUT;
const startSession = azURL('startSession');
let lastAPICall = Date.now();
let newSessionRequest: null | Promise<any> = null;

const createSessionRefreshInterceptor = (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  dispatch: Function
) => {
  return async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    const headerData = getHeaderDataFromCache(queryClient);
    // @ts-expect-error possible bug or misleading typings
    const { url } = parseUrl(config.url);
    const isStartSessionCall = config.url === startSession;
    const isApiCall = url.includes(API_CALL_PATH_PREFIX);
    const isProtectedRoute = isRouteProtected(Router.asPath);

    if (isStartSessionCall || !isApiCall) {
      return config;
    }

    const now = Date.now();

    const skuDetailsRoutes = [
      '/[...seoUrlPath]',
      routePaths.searchResult,
      '/products/[...slug]',
      '/brands/[brandName]',
    ];
    if (
      skuDetailsRoutes.includes(Router.route) &&
      now - lastAPICall <= 500 &&
      config.url?.includes('/browse/sku/details')
    ) {
      lastAPICall = new Date(-8640000000000000).valueOf();
    }

    if (newSessionRequest) {
      await newSessionRequest;
    } else {
      const sessionExpired = now - lastAPICall > idleSessionTimeout;

      if (sessionExpired) {
        if (Router.asPath === routePaths.checkout) {
          Router.push(routePaths.cart);
        }
        if (newSessionRequest == null) {
          newSessionRequest = new Promise((resolve, reject) => {
            const refreshSession = async () => {
              if (
                headerData?.myAccountMap?.autoLogin !== 'true' &&
                headerData?.myAccountMap?.anonymous === 'false'
              ) {
                dispatch(signout(queryClient));
                if (isProtectedRoute) {
                  Router.push(routePaths.signIn);
                } else {
                  localStorage.setItem('canShowToastNotification', 'true');
                }
              }

              await axiosInstance.get(startSession);
            };
            refreshSession().then(resolve).catch(reject);
          });
        }

        await newSessionRequest;
        // eslint-disable-next-line require-atomic-updates
        newSessionRequest = null;
      }
    }

    // eslint-disable-next-line require-atomic-updates
    lastAPICall = now;
    return config;
  };
};

export default createSessionRefreshInterceptor;
