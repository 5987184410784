/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useHeaderData } from '../api/getHeader';
import { useStoreDetailsData } from '../api/getStoreDetails';
import { AllHeaderData } from '../interface';
import { useCMSHeaderContent } from './getCMSHeaderContent';
import { mapHeaderPromoFromContentstackToXM } from '../utils/mapHeaderPromoFromContentstackToXM';

// TODO web5 this helper is an indication the the "header" feature is doing too much.
// Consider extracting to separate features once we have a better overview of where things belong.
export const useGetAllHeaderData = (): AllHeaderData => {
  const { enabled: isCMSHeaderEnabled, data: cmsHeaderData } = useCMSHeaderContent();
  const { data: headerData } = useHeaderData();
  const { data: storeData } = useStoreDetailsData();
  const headerPromo = isCMSHeaderEnabled
    ? mapHeaderPromoFromContentstackToXM(cmsHeaderData?.headerPromo?.[0])
    : headerData?.headerPromo;

  return {
    headerData,
    headerPromo,
    storeData,
  };
};
