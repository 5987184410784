/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const ymmeActionSources = {
  contentBlock: 'YMME' as const,
  vinLookup: 'Enter VIN' as const,
  directURL: 'URL' as const,
  vehicleSearch: 'Vehicle Search' as const,
  storage: 'ymmeActionSource' as const,
};
