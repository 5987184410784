/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { Locale } from '@/types/i18n';
import { useAppState } from '@/stores/AppState';

export const useLocale = (): Locale => {
  const { locale } = useAppState().state;

  if (locale == null) {
    throw new Error('Locale is not set');
  }

  return locale;
};
