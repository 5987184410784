/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { trackFooterNavMenu } from '../utils/track';
import NextImage from '@/components/NextImage';

type Props = {
  name: string;
  imageUrl?: string;
  altText?: string;
  className?: string;
  CTALink: string;
  footerSectionName?: 'top' | 'middle' | 'bottom' | '';
  aTagStyle?: string;
  width?: number;
  height?: number;
};

export function FooterIcon({
  name,
  imageUrl,
  altText,
  className,
  CTALink,
  footerSectionName = '',
  aTagStyle = '',
  width,
  height,
}: Props) {
  const imageComponent = (
    <div className={className}>
      {imageUrl ? (
        <NextImage
          src={imageUrl}
          alt={altText}
          data-testid={`footer_${footerSectionName}_icon_image_${name
            .split(' ')[0]
            ?.toLowerCase()}`}
          height={height}
          width={width}
          loading="lazy"
        />
      ) : null}
    </div>
  );
  return CTALink ? (
    <a
      href={CTALink}
      rel="noopener noreferrer"
      target="_blank"
      className={aTagStyle}
      onClick={() => trackFooterNavMenu(name)}
      data-testid={`footer_${footerSectionName}_icon_a_${name.split(' ')[0]?.toLowerCase()}`}
    >
      {imageComponent}
    </a>
  ) : (
    imageComponent
  );
}
