/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const getRewardsBalance = (rewardsBalance: string) => {
  let rewardsBalToNum: number = +rewardsBalance;
  if (!isNaN(rewardsBalToNum)) {
    if (rewardsBalToNum % 1 === 0) {
      rewardsBalToNum = Math.floor(rewardsBalToNum);
    }
    if (rewardsBalToNum < 0) {
      rewardsBalToNum = Math.abs(rewardsBalToNum);
    }
  } else {
    return '';
  }
  return `$${rewardsBalToNum}`;
};
