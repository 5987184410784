/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useRouter } from 'next/router';
import { parseUrl, stringifyUrl, sanitizeUrlWithParamList } from '../../../utils/urlHelpers';
import { FACETED_URL } from '@/utils/validatorRegex';
import { acceptableShelfQueryParams } from '@/constants/queryParams';
import { usePageType } from '../api/getPageType';

export const useRedirectAfterVehicleChanged = () => {
  const router = useRouter();
  const { asPath } = router;
  const { query, url } = parseUrl(asPath);
  const { data: pageTypeData } = usePageType();
  const shopByUrlSuffix = pageTypeData?.makeModelYearPath;
  const strippedYMMUrl = shopByUrlSuffix ? url.replace(shopByUrlSuffix, '') : url;
  return async () => {
    if (FACETED_URL.test(strippedYMMUrl)) {
      //if faceted nav url filters selected
      const newPathname = strippedYMMUrl;
      await router.push(
        stringifyUrl(
          {
            url: newPathname,
            query: {
              ...query,
              pageNumber: null,
              vehicleId: null,
            },
          },
          {
            skipNull: true,
          }
        )
      );
    } else {
      if (
        query.pageNumber ||
        query.vehicleId ||
        query.filters ||
        query.minPrice ||
        query.maxPrice
      ) {
        delete query.pageNumber;
        delete query.vehicleId;
        await router.push(
          sanitizeUrlWithParamList(query, acceptableShelfQueryParams, strippedYMMUrl)
        );
      } else if (shopByUrlSuffix) {
        const redirectUrl = asPath.replace(shopByUrlSuffix, '');
        await router.push(redirectUrl);
      }
    }
  };
};
