/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React from 'react';
import cx from 'classnames';
import { useLabel } from '@/hooks/useLabels';
import styles from './ContactUsModal.module.scss';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { eventConstants } from '../../../constants/event';
import { useHeaderData } from '../../header/api/getHeader';
import { useAppState } from '@/stores/AppState';

type Props = {
  onClick: () => void;
};

const chatBubblesIconWhite = {
  src: '/images/chat-bubbles-white.svg',
  width: 18,
  height: 22,
};

export function NeedHelpTab({ onClick }: Props) {
  const lblContactUs = useLabel('label_contact_us_tab');
  const router = useRouter();
  const { data: headerData } = useHeaderData();
  const miniCartMap = headerData?.miniCartMap;
  const itemCount = miniCartMap?.itemCount || 0;
  const isCartPage = router.asPath.includes(routePaths.cart);
  const isBrandsPage = router.asPath === routePaths.shopByBrand;
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) =>
    e.key === eventConstants.enterKeyType && onClick();
  const { isMobileApp } = useAppState().state;

  if (isMobileApp) return null;

  return (
    <div
      role="button"
      tabIndex={0}
      aria-label={lblContactUs}
      onClick={onClick}
      onKeyDown={(e) => handleKeyDown(e)}
      className={cx(styles.needHelpTab, {
        [styles.needHelpTabInCart]: isCartPage && itemCount > 0,
        [styles.needHelpTabInCartWithSTSForMX]: isCartPage && itemCount > 0 && !isBopusEnabled,
        [styles.needHelpTabBrands]: isBrandsPage,
      })}
    >
      <img
        className={styles.needHelpTabIcon}
        height={chatBubblesIconWhite.height}
        width={chatBubblesIconWhite.width}
        src={chatBubblesIconWhite.src}
        alt=""
        decoding="async"
      />
      <div className={styles.needHelpTabLabel}>{lblContactUs}</div>
    </div>
  );
}
