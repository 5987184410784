/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { IconButton } from '../IconButton/IconButton';
import { Paper } from '../Paper';
import { capitalize } from '../../utils/capitalize';
import CloseIcon from '../../../public/images/close.svg';
import iconStyles from '../../theme/iconStyles.module.scss';
import styles from './Alert.module.scss';
const iconMapping = {
  success: (
    // eslint-disable-next-line react/forbid-elements
    <svg
      style={{
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: 'inherit',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
      }}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
    </svg>
  ),
  warning: (
    // eslint-disable-next-line react/forbid-elements
    <svg
      style={{
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: 'inherit',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
      }}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
    </svg>
  ),
  error: (
    // eslint-disable-next-line react/forbid-elements
    <svg
      style={{
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: 'inherit',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
      }}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </svg>
  ),
  info: (
    // eslint-disable-next-line react/forbid-elements
    <svg
      style={{
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: 'inherit',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
      }}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z" />
    </svg>
  ),
};

export function Alert({
  action,
  children,
  className,
  closeText = 'Close',
  color,
  icon,
  onClose,
  role = 'alert',
  severity = 'success',
  variant = 'standard',
  ...other
}: Props) {
  return (
    <Paper
      role={role}
      square
      elevation={0}
      className={cx(styles.root, styles[`${variant}${capitalize(color || severity)}`], className)}
      {...other}
    >
      {icon !== false ? <div className={styles.icon}>{icon || iconMapping[severity]}</div> : null}
      <div className={styles.message}>{children}</div>
      {action != null ? <div className={styles.action}>{action}</div> : null}
      {action == null && onClose ? (
        <div className={styles.action}>
          <IconButton
            size="small"
            aria-label={closeText}
            title={closeText}
            color="inherit"
            onClick={onClose}
            data-testid="close-alert-button"
          >
            <CloseIcon className={cx(iconStyles.defaultIconStyle, iconStyles.fontSizeSmall)} />
          </IconButton>
        </div>
      ) : null}
    </Paper>
  );
}
type Props = {
  /**
   * The action to display. It renders after the message, at the end of the alert.
   */
  action?: React.ReactNode;

  /**
   * The content of the component.
   */
  children?: React.ReactNode;

  /**
   * Override the default label for the *close popup* icon button.
   *
   * For localization purposes, you can use the provided [translations](/guides/localization/).
   * @default 'Close'
   */
  closeText?: string;

  /**
   * The main color for the alert. Unless provided, the value is taken from the `severity` prop.
   */
  color?: 'error' | 'info' | 'success' | 'warning' | string;

  /**
   * Override the icon displayed before the children.
   * Unless provided, the icon is mapped to the value of the `severity` prop.
   */
  icon?: React.ReactNode;

  /**
   * Callback fired when the component requests to be closed.
   * When provided and no `action` prop is set, a close icon button is displayed that triggers the callback when clicked.
   * @param {object} event The event source of the callback.
   */
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;

  /**
   * The ARIA role attribute of the element.
   * @default 'alert'
   */
  role?: string;

  /**
   * The severity of the alert. This defines the color and icon used.
   * @default 'success'
   */
  severity?: 'error' | 'info' | 'success' | 'warning';

  /**
   * The variant to use.
   * @default 'standard'
   */
  variant?: 'filled' | 'outlined' | 'standard' | string;

  /**
   * @ignore
   */
  className?: string;
  'data-testid'?: string;
  id?: string;
};
