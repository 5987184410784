/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FooterData, FooterResponse } from '../interface';
import { getAxios } from '@/lib/axios';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import { stringifyUrl } from '@/utils/urlHelpers';
import { requestBaseURL } from '@/config/serviceAPI';
import { cache } from '@/utils/serverQueryCache';
import { AxiosInstance } from 'axios';
import { createQuery } from '@/utils/createReactQuery';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import type { FormattedContentStackFooter } from '@/features/contentstack/interface';
import { getFooterContent } from '@/features/contentstack/lib/footer/getFooterContent'; // eslint-disable-line no-restricted-imports
import { useMonetateDecisionFlag } from '@/features/kibo';
import { type QueryClient } from '@tanstack/react-query';
import { PageContextType } from '@/next-types';

const URL = `${requestBaseURL}/ecomm/b2c/v1/page/footer`;
const CMS_URL = '/api/contentstack/footer';

type Options = {
  workspaceId: string | undefined;
  previewDate: string | undefined;
  // we're using locale only to have separate cache for US and MX pages
  locale: Locale;
  cmsEnabled?: boolean;
};

const getCMSFooterContent = async (axiosInstance?: AxiosInstance) => {
  const { data } = await getAxios(axiosInstance).get<FormattedContentStackFooter>(CMS_URL);

  return selector(data);
};

const {
  useData: useFooter,
  prefetch: prefetchFooterData,
  query: footerQuery,
} = createQuery<FooterData | null, Options>('footerData', async (options, axiosInstance) =>
  options.cmsEnabled ? getCMSFooterContent(axiosInstance) : getFooterData(options, axiosInstance)
);

const getFooterData = async (options: Options, axiosInstance?: AxiosInstance) => {
  const cacheQueryKey = ['footerData', options];

  if (__IS_SERVER__) {
    const data = cache.getItem<FooterData>(cacheQueryKey);
    if (data) {
      return data;
    }
  }

  const { previewDate, workspaceId } = options;
  const maybeXMPreviewUrl =
    workspaceId && previewDate
      ? stringifyUrl({
          url: URL,
          query: { workspaceId, previewDate },
        })
      : URL;

  const response = await getAxios(axiosInstance).get<FooterResponse>(maybeXMPreviewUrl);
  const selectedData = selector(response.data);

  if (__IS_SERVER__) {
    selectedData && cache.setItem(cacheQueryKey, selectedData);
  }

  return selectedData;
};

const middleSctionMapper = (middleFooter: FormattedContentStackFooter['footerMiddle']) => {
  if (!middleFooter) {
    return;
  }

  return middleFooter.footer_middle_group?.map((footerContentGroup) => ({
    title: footerContentGroup.title ?? '',
    displayPosition: footerContentGroup.display_position ?? 'Horizontal',
    middleLabelContent: (footerContentGroup.menu_link ?? []).map((link) => ({
      name: link.link_name ?? '',
      CTALink: link.link_cta ?? '',
    })),
    name: footerContentGroup.title ?? '',
  }));
};

const topSctionMapper = (topFooter: FormattedContentStackFooter['footerTop']) => {
  if (!topFooter) {
    return;
  }

  const getCtaLink = (ctaLink: string | undefined) => (ctaLink?.startsWith('##') ? '' : ctaLink);

  return topFooter.menu_link?.map((footerContentGroup) => ({
    CTALink: getCtaLink(footerContentGroup.link_cta) ?? '',
    imageUrl: footerContentGroup.image ?? '',
    name: footerContentGroup.link_name ?? '',
    altText: footerContentGroup.alt_text ?? '',
    label: footerContentGroup.link_name ?? '',
    Icon: !footerContentGroup.link_name,
    displayPosition: footerContentGroup.display_position,
  }));
};

const bottomSectionMapper = (bottomFooter: FormattedContentStackFooter['footerBottom']) => {
  if (!bottomFooter) {
    return;
  }

  return bottomFooter.menu_link?.map((bottomMenuLink) => ({
    altText: bottomMenuLink.alt_text ?? '',
    label: bottomMenuLink.link_name ?? '',
    imageUrl: bottomMenuLink.image ?? '',
    name: bottomMenuLink.alt_text ?? '',
    CTALink: bottomMenuLink.link_cta ?? '',
    Icon: !!bottomMenuLink.image,
    displayPosition: bottomMenuLink.display_position,
  }));
};

const selector = (data: FooterResponse | FormattedContentStackFooter): FooterData | null => {
  if ('contents' in data) {
    const contents = data.contents[0];
    return {
      footerMiddle: contents.footerMiddle,
      footerTop: contents.footerTop,
      footerBottom: contents.footerBottom,
    };
  }

  if ('footerTop' in data) {
    return {
      footerMiddle: [
        {
          contents: middleSctionMapper(data.footerMiddle) ?? [],
        },
      ],
      footerTop: topSctionMapper(data.footerTop) ?? [],
      footerBottom: bottomSectionMapper(data.footerBottom) ?? [],
    };
  }

  return null;
};

const useFooterData = () => {
  const { workspaceId, previewDate } = useXMPreviewState();
  const cmsEnabled = useMonetateDecisionFlag('cmsFooterEnabled');
  const locale = useLocale();

  return useFooter({
    workspaceId,
    previewDate,
    locale,
    staleTime: Infinity,
    cmsEnabled,
  });
};

const prefetchCMSFooterContent = (
  queryClient: QueryClient,
  context: PageContextType,
  options: Options
) => {
  return queryClient.prefetchQuery({
    queryKey: footerQuery.getFullKey(options),
    queryFn: async () => {
      const footerData = await getFooterContent(context.req, context.query);
      return selector(footerData);
    },
  });
};

export { prefetchFooterData, prefetchCMSFooterContent, useFooterData };
