/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState } from 'react';
import type { MiddleLabelContent } from '../interface';
import { FooterLink } from './FooterLink';
import styles from './FooterMiddle.module.scss';
import NextImage from '@/components/NextImage';
import { Button } from '@/components/Button';
import { useLabel } from '@/hooks/useLabels';
import { eventConstants } from '@/constants/event';

const chevron = '/images/footer/footer_down_arrow.svg';

export function MobileLinkColumn({
  title,
  middleLabelContent,
}: {
  title: string;
  middleLabelContent: MiddleLabelContent[];
}) {
  const columnName = title.split(' ')[0]?.toLowerCase() || '';
  const [chevronUp, setChevronUp] = useState(false);
  const toggleChevron = () => setChevronUp(!chevronUp);
  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === eventConstants.enterKeyType) {
      setChevronUp(!chevronUp);
    }
  };
  const lblExpansiblePanel = useLabel('label_ShelfPage_leftSideBar_ExpansiblePanel');

  return (
    <li className={styles.linkColumnContainer} data-testid={`footer_link_colum_${columnName}`}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.linksColumTitleContainer}
        onClick={toggleChevron}
        onKeyPress={handleOnKeyPress}
      >
        <Button
          variant="ghost"
          customClass={`${styles.footerLinkDownArrow} ${styles.linkColumTitle}`}
          onClick={toggleChevron}
          data-testid={`footer_link_colum_title_${columnName}`}
          role="presentation"
          tabIndex={-1}
        >
          {title}
        </Button>
        <NextImage
          tabIndex={0}
          role="button"
          aria-expanded={chevronUp}
          alt={`${title} ${lblExpansiblePanel}`}
          src={chevron}
          data-testid={`footer_link_colum_title_${columnName}`}
          className={`${styles.chevron} ${chevronUp ? styles.chevronUp : styles.chevronDown}`}
          width={22}
          height={12}
          loading="lazy"
        />
      </div>
      <ul className={`${styles.linksContainer} ${chevronUp ? styles.showLinks : styles.hideLinks}`}>
        {middleLabelContent.map((content, index) => (
          <li key={`link_${index}`} className={styles.linkItem}>
            <FooterLink
              className={`${styles.linkItem} ${
                content.name.length > 30 ? styles.specialWidth : ''
              }`}
              {...content}
            />
          </li>
        ))}
      </ul>
      <hr className={styles.mobileLinkColumnHr} />
    </li>
  );
}
