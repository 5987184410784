/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import root from 'window-or-global';
import { BREAKPOINTS } from '@/constants/screen';

/**
 * Important: don't use this function for UI conditionals. Use `useDeviceType()` instead.
 */
export const getDeviceType = (): string => {
  if (root) {
    if (root.matchMedia(`(min-width: ${BREAKPOINTS.DESKTOP_MIN}px)`).matches) {
      return 'desktop';
    } else if (
      root.matchMedia(
        `(min-width: ${BREAKPOINTS.TABLET_MIN}px) and (max-width: ${BREAKPOINTS.TABLET_MAX}px)`
      ).matches
    ) {
      return 'tablet';
    } else if (root.matchMedia(`(max-width: ${BREAKPOINTS.MOBILE_MAX}px)`).matches) {
      return 'mobile';
    }
  }

  return 'desktop';
};
