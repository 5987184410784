/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { localStorage } from '@/utils/localStorage';
import { useCallback, useEffect, useState } from 'react';

const getRecentSearches = () => {
  if (__IS_SERVER__) {
    return JSON.parse('[]');
  }
  const saved = localStorage.getItem('recentSearches') ?? '[]';
  const initial = JSON.parse(saved);
  return initial;
};

export const useRecentSearches = (searchText: string) => {
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [filteredRecentSearches, setFilteredRecentSearches] = useState(recentSearches);

  const addRecentSearch = (searchTerm: string) => {
    if (searchTerm.length <= 0) {
      return;
    }
    const searchesSet = new Set([searchTerm, ...recentSearches]);
    const newSearches = Array.from(searchesSet).slice(0, 3);
    setRecentSearches(newSearches);
    storeRecentSearches(newSearches);
  };

  const deleteRecentSearch = (index: number) => {
    const newSearches = [...recentSearches];
    newSearches.splice(index, 1);
    setRecentSearches(newSearches);
    storeRecentSearches(newSearches);
  };

  const storeRecentSearches = (newSearches: string[]) => {
    const stringifiedSearches = JSON.stringify(newSearches);
    localStorage.setItem('recentSearches', stringifiedSearches);
    window.dispatchEvent(new Event('storage'));
  };

  const localStorageListener = useCallback(() => {
    setRecentSearches(getRecentSearches());
  }, []);

  useEffect(() => {
    setRecentSearches(getRecentSearches());
  }, []);

  useEffect(() => {
    window.addEventListener('storage', localStorageListener);

    return () => window.removeEventListener('storage', localStorageListener);
  }, [localStorageListener]);

  useEffect(() => {
    const filtered = searchText
      ? recentSearches.filter((entry) => entry.toLowerCase().includes(searchText.toLowerCase()))
      : recentSearches;
    setFilteredRecentSearches(filtered);
  }, [recentSearches, searchText]);

  return {
    filteredRecentSearches,
    recentSearches,
    addRecentSearch,
    deleteRecentSearch,
  };
};
