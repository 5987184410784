/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { QueryClient, QueryFunctionContext, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
type IsStrictlyAny<T> = (T extends never ? true : false) extends false ? false : true;

type QueryOptions<Result> = {
  enabled?: boolean;
  keepPreviousData?: boolean;
  onSuccess?: (data: Result) => void;
  onError?: <TError>(error: TError) => void;
  cacheTime?: number;
  staleTime?: number;
};

export function createQuery<Result, Options extends {}>(
  key: string,
  get: (options: Options, axiosInstance?: AxiosInstance) => Promise<Result>
) {
  const query = {
    getPrimaryKey: () => key,
    getFullKey: (options: Options) => [key, options] as const,
  };

  const queryFn = async (
    { queryKey }: QueryFunctionContext<ReturnType<typeof query.getFullKey>>,
    axiosInstance?: AxiosInstance
  ) => {
    const [, options] = queryKey;
    return get(options, axiosInstance);
  };

  // This makes sure that we always specify a type for the data
  type UseDataResult<Result> = UseQueryResult<
    IsStrictlyAny<Result> extends true ? unknown : Result
  >;

  // For the type inference to work properly, this overload needs to be defined first
  function useData<Data, Select extends (data: Result) => Data | Result = (data: Result) => Result>(
    options: Options & QueryOptions<Result> & { select?: Select }
  ): UseDataResult<ReturnType<Select>>;
  function useData(
    ...args: {} extends Options
      ? [options: QueryOptions<Result> | undefined] | []
      : [options: QueryOptions<Result>]
  ): UseDataResult<Result>;
  function useData(...args: unknown[]): unknown {
    // @ts-expect-error: the type of options is conditionally set, so TypeScript is unable to infer it
    const { enabled, keepPreviousData, onSuccess, onError, select, cacheTime, staleTime, ...rest } =
      args[0] || {};

    const optionsWithoutUndefined = Object.entries({
      enabled,
      keepPreviousData,
      onSuccess,
      onError,
      select,
      cacheTime,
      staleTime,
    }).reduce(
      // @ts-expect-error: the type of options is conditionally set, so TypeScript is unable to infer it
      (entry, [key, value]) => (value === undefined ? entry : ((entry[key] = value), entry)),
      {}
    );

    return useQuery({
      ...optionsWithoutUndefined,
      // @ts-expect-error: the type of options is conditionally set, so TypeScript is unable to infer it
      queryKey: query.getFullKey(rest),
      queryFn,
    });
  }

  const prefetch = (queryClient: QueryClient, options: Options, axiosInstance: AxiosInstance) => {
    return queryClient.prefetchQuery({
      queryKey: query.getFullKey(options),
      queryFn: (ctx) => queryFn(ctx, axiosInstance),
    });
  };

  const getFromCache = (queryClient: QueryClient, options: Options) => {
    return queryClient.getQueryData<Result>(query.getFullKey(options));
  };

  return { query, useData, prefetch, getFromCache } as const;
}
