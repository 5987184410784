/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
const unwrapParagraghTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

// BMF-7552, DFS-371 – the content from Oracle rich text editor comes wrapped
// with <p> tags. When the tagName is a heading, we want to strip this <p> tag
// to get valid HTML.

export default function removeWrappingParagraphTag(innerHtml: string, tagName: string): string {
  const shouldRemoveParagraph = unwrapParagraghTags.includes(tagName);
  const htmlWithoutNulls = innerHtml.trim().replace('null', '');
  return shouldRemoveParagraph
    ? htmlWithoutNulls.replace(/^<p[^>]*>/i, '').replace(/<\/p>$/i, '')
    : htmlWithoutNulls;
}
