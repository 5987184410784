/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useContext } from 'react';
import { ProductListViewContext } from '../ProductListViewContext';

export const useProductListView = () => {
  const context = useContext(ProductListViewContext);
  return context;
};
