/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './Footer.module.scss';
import { FooterBottom } from './FooterBottom';
import FooterMiddle from './FooterMiddle';
import FooterTop from './FooterTop';
import { useFooterData } from '../api/getFooterData';

export function Footer() {
  const { data } = useFooterData();
  const socialIcons = data?.footerTop.filter((contentBlock) => contentBlock.Icon);

  return (
    <footer className={styles.footerContainer} data-testid={'footer-main-container'}>
      <nav aria-label="footer links">
        <FooterTop content={data?.footerTop} socialIcons={socialIcons} />
        <hr className={styles.topHrTag} />
        <FooterMiddle content={data?.footerMiddle[0]?.contents} />
        <hr className={styles.bottomHrTag} />
        <FooterBottom content={data?.footerBottom} socialIcons={socialIcons} />
        <div id="qualtrics-div" />
      </nav>
    </footer>
  );
}
