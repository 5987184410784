/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useSelector } from 'react-redux';
import { ReduxState } from '../types';
type DeviceType = 'phone' | 'tablet' | 'desktop' | 'bot';

export function useDeviceType(): DeviceType {
  return useSelector<ReduxState>(({ appData }) => appData.deviceType) as DeviceType;
}
