/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import { isTrue } from '@/utils/isTrue';
import { stringifyUrl } from '@/utils/urlHelpers';
import { AxiosInstance } from 'axios';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { HeaderData, HeaderResponse } from '../interface';
import { getKiboDecisionFlag, useMonetateDecisionFlag } from '@/features/kibo';
import { dispatchEvent } from '@/utils/eventPublisher';

const url = `${requestBaseURL}/ecomm/b2c/v2/page/header`;

const getHeaderData = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>,
  axiosInstance?: AxiosInstance
) => {
  const [, options] = queryKey;
  const { previewDate, workspaceId, hideXMContent } = options;
  const maybeXMPreviewUrl =
    workspaceId && previewDate
      ? stringifyUrl({
          url,
          query: { previewDate, workspaceId },
        })
      : url;
  const params = {
    hideXMContent: hideXMContent ? true : undefined,
  };
  const response = await getAxios(axiosInstance).get<HeaderResponse>(maybeXMPreviewUrl, { params });
  dispatchEvent('getHeader', response.data);

  return selector(response.data, response.headers);
};

const selector = (responseResult: HeaderResponse, headers: any): HeaderData => {
  return {
    analyticsData: responseResult.analyticsData,
    isStagingApp: isTrue(responseResult.isStagingApp),
    headerPromo: responseResult.headerPromo?.contents,
    miniCartMap: responseResult.miniCartMap,
    myAccountMap: responseResult.myAccountMap,
    storeDetails: responseResult.storeDetails || undefined,
    storeNumber: responseResult.storeDetails?.storeNumber,
    vehicleMap: responseResult.vehicleMap,
    apiResponseHeaders: {
      token_id: headers.token_id,
    },
    segmentMessageContent: responseResult.segmentMessageContent,
    miniCartSuccessNote: false,
    segments: responseResult.cmsSegmentedContent,
    savedOrderMap: responseResult.savedOrderMap,
  };
};

type Options = {
  previewDate: string | undefined;
  workspaceId: string | undefined;
  hideXMContent: boolean | undefined;
};

export const HEADER_PRIMARY_KEY = 'header';

// TODO web5 tie header data with `vehicleId` so that it updates automatically whenever vehicle is changed.
// TODO web5 tie header data with `itemCount` from `miniCartMap` so that it updates automatically whenever item count in cart is changed.
// This should make us remove all the imperative `refetchHeaderData` calls.
// We should consider optimistic updates as well to avoid delays.
export const getHeaderDataKey = (options: Options) => [HEADER_PRIMARY_KEY, { ...options }] as const;

export function useHeaderData<SelectReturnType = HeaderData>(options?: {
  select?: (headerData: HeaderData) => SelectReturnType;
}) {
  const { previewDate, workspaceId } = useXMPreviewState();
  const isCMSHeaderEnabled = useMonetateDecisionFlag('cmsHeaderEnabled') ?? false;

  const hideXMContent = isCMSHeaderEnabled || undefined;

  return useQuery({
    queryKey: getHeaderDataKey({ previewDate, workspaceId, hideXMContent }),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>) =>
      getHeaderData(context),
    staleTime: Infinity,
    select: options?.select,
  });
}

export const prefetchHeaderData = (queryClient: QueryClient, axiosInstance: AxiosInstance) => {
  const cmsHeaderEnabled = getKiboDecisionFlag(queryClient, 'cmsHeaderEnabled');

  const options: Options = {
    previewDate: undefined,
    workspaceId: undefined,
    hideXMContent: cmsHeaderEnabled || undefined,
  };
  return queryClient.prefetchQuery({
    queryKey: getHeaderDataKey(options),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getHeaderDataKey>>) =>
      getHeaderData(context, axiosInstance),
  });
};

// Return header data from react query cache. Useful to access this data outside of a hook/component
export const getHeaderDataFromCache = (queryClient: QueryClient) => {
  const cmsHeaderEnabled = getKiboDecisionFlag(queryClient, 'cmsHeaderEnabled');

  return queryClient.getQueryData<HeaderData>(
    getHeaderDataKey({
      previewDate: undefined,
      workspaceId: undefined,
      hideXMContent: cmsHeaderEnabled || undefined,
    })
  );
};
