/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';

export type Context = {
  adornedStart: boolean;
  color: string;
  disabled: boolean;
  error: boolean;
  filled: boolean;
  focused: boolean;
  fullWidth: boolean;
  hiddenLabel: boolean;
  margin: 'dense' | 'none' | 'normal' | undefined;
  onBlur: (event?: React.FocusEvent<HTMLElement>) => void;
  onEmpty: () => void;
  onFilled: () => void;
  onFocus: (event: React.FocusEvent<HTMLElement>) => void;
  registerEffect: void | (() => () => void);
  required: boolean;
  setAdornedStart: (a: ((a: boolean) => boolean) | boolean) => void;
  variant: string;
};

const FormControlContext = React.createContext<Context | undefined>(undefined);

export function useFormControl(): Context | undefined {
  return React.useContext(FormControlContext);
}

export default FormControlContext;
