/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const openChatWindow = () => {
  const chatWindow = window.open(
    '/chat',
    '_blank',
    'width=408,height=720,left=890,top=210,status=yes,draggable=false,resizable=false'
  );
  chatWindow?.moveTo(890, 210);
  chatWindow?.focus();
};
