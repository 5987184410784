/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { analyticsDataLayerVariable } from '../analyticsDataLayerVariable';
import { getDeviceType } from '@/utils/getDeviceType'; // eslint-disable-line no-restricted-imports
import { getDecodedCookies } from '@/utils/cookie';
import { getRewardsBalance } from '@/utils/getRewardsBalance';
import { analyticsCookie as ac } from '@/constants/analytics/analyticsCookie';
import { getPageHierarchy } from '../getPageHierarchy';

export type AnalyticsGlobalVariablesParams = {
  userAuthenticated?: boolean;
};

export function analyticsGlobalVariables({
  userAuthenticated = false,
}: AnalyticsGlobalVariablesParams = {}) {
  if (__IS_SERVER__) {
    return;
  }

  const cookies = getDecodedCookies([
    ac.cookieSearchTerms,
    ac.cookieLoginFlow,
    ac.cookieLoginInteractionMethod,
    ac.cookieLoginMethod,
    ac.cookieZipCode,
    ac.cookieStoreId,
    ac.cookieVehicleYear,
    ac.cookieVehicleMake,
    ac.cookieVehicleModel,
    ac.cookieVehicleEngine,
    ac.cookieVehicleId,
    ac.cookieEpsilonId,
    ac.cookieuserVehicleCount,
    ac.cookieprofileId,
    ac.cookieBadgesInfo,
    ac.cookierewardsId,
    ac.cookieRewardPoints,
    ac.cookieProductPartIds,
    ac.cookieProductSkus,
    ac.cookieProductTitles,
    ac.cookieProductVendors,
    ac.cookieUnitPrice,
    ac.cookieCorePrice,
    ac.cookieDiscountPriceList,
    ac.cookieNddMarket,
    ac.cookieNddHubStore,
    ac.cookieNddStore,
    ac.cookieUserType,
    ac.cookieAvailableRewardBalance,
    ac.cookieHashedEId,
  ]);

  const previousPageName = window.utag_data?.pageName ?? '';
  const previousPageType = window.utag_data?.pageType ?? '';
  const previousPageSiteSection = window.utag_data?.siteSection ?? '';
  const pageURL = window.utag_data?.pageURL || '';
  const previousPageURL = window.utag_data?.previousPageURL || '';
  const referrer = window.utag_data?.referrer || '';
  const authenticationStatus = userAuthenticated;
  const pageHierarchy = getPageHierarchy();
  const allSearchTerms = cookies.get(ac.cookieSearchTerms);
  const loginFlow = cookies.get(ac.cookieLoginFlow);
  const loginInteractionMethod = cookies.get(ac.cookieLoginInteractionMethod);
  const loginMethod = cookies.get(ac.cookieLoginMethod);
  const preferredZipCode = cookies.get(ac.cookieZipCode);
  const preferredStoreId = cookies.get(ac.cookieStoreId);
  const preferredVehicleYear = cookies.get(ac.cookieVehicleYear);
  const preferredVehicleMake = cookies.get(ac.cookieVehicleMake);
  const preferredVehicleModel = cookies.get(ac.cookieVehicleModel);
  const preferredVehicleEngine = cookies.get(ac.cookieVehicleEngine);
  const preferredVehicleId = cookies.get(ac.cookieVehicleId);
  const eCookieId = cookies.get(ac.cookieEpsilonId);
  const userVehicleCount = cookies.get(ac.cookieuserVehicleCount) || '0';
  const profileId = cookies.get(ac.cookieprofileId);
  const badgesInfo = cookies.get(ac.cookieBadgesInfo);
  const rewardsId = cookies.get(ac.cookierewardsId);
  const rewardPoints = cookies.get(ac.cookieRewardPoints);
  const cartProductPartIds = cookies.get(ac.cookieProductPartIds);
  const cartProductSkus = cookies.get(ac.cookieProductSkus);
  const cartProductTitles = cookies.get(ac.cookieProductTitles);
  const cartProductVendors = cookies.get(ac.cookieProductVendors);
  const cartUnitPrice = cookies.get(ac.cookieUnitPrice);
  const cartCorePrice = cookies.get(ac.cookieCorePrice);
  const cartDiscountPriceList = cookies.get(ac.cookieDiscountPriceList);
  const nddMarket = cookies.get(ac.cookieNddMarket);
  const nddHub = cookies.get(ac.cookieNddHubStore);
  const nddStore = cookies.get(ac.cookieNddStore);
  const rewardsStatus = rewardsId ? 'true' : 'false';
  const userType = cookies.get(ac.cookieUserType) || '';
  const device = getDeviceType();
  const rewardsBalance = getRewardsBalance(String(cookies.get(ac.cookieAvailableRewardBalance)));
  const availableRewardBalance = rewardsBalance ? rewardsBalance : '';
  const hashedEId = cookies.get(ac.cookieHashedEId);
  const data = {
    allSearchTerms,
    loginFlow,
    loginInteractionMethod,
    loginMethod,
    pageHierarchy,
    authenticationStatus,
    preferredZipCode,
    preferredStoreId,
    preferredVehicleYear,
    preferredVehicleMake,
    preferredVehicleModel,
    preferredVehicleEngine,
    preferredVehicleId,
    eCookieId,
    userVehicleCount,
    profileId,
    badgesInfo,
    rewardsId,
    rewardPoints,
    rewardsCustomerStatus: rewardsStatus,
    cartProductPartIds: String(cartProductPartIds).split(','),
    cartProductSkus: String(cartProductSkus).split(','),
    cartProductTitles: String(cartProductTitles).split(','),
    cartProductVendors: String(cartProductVendors).split(','),
    cartUnitPrice: String(cartUnitPrice).split(','),
    cartCorePrice: String(cartCorePrice).split(','),
    cartDiscountPriceList: String(cartDiscountPriceList).split(','),
    device,
    previousPageName,
    previousPageType,
    previousPageSiteSection,
    pageURL,
    previousPageURL,
    referrer,
    userType,
    nddMarket,
    nddHub,
    nddStore,
    availableRewardBalance,
    hashedEId,
  };
  analyticsDataLayerVariable(data);
}
