/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export async function getSHA256(data: any) {
  //encode as UTF-8
  const dataEncoder = new TextEncoder().encode(data);

  //hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', dataEncoder);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
