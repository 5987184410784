/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useUtagReady } from './useUtagReady';
import { setupAnalytics } from './config/setupAnalytics';

export const useAnalytics = (): boolean => {
  const { asPath } = useRouter();
  const { isUtagReady } = useUtagReady();
  const [hasSetupAnalytics, setHasSetupAnalytics] = useState(false);

  useEffect(() => {
    if (isUtagReady && !hasSetupAnalytics) {
      setupAnalytics(hasSetupAnalytics, asPath, isUtagReady, () => setHasSetupAnalytics(true));
    }
  }, [asPath, hasSetupAnalytics, isUtagReady]);
  return hasSetupAnalytics;
};
