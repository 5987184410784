/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { openChatWindow } from '../utils/openChatWindow';
import styles from './ProactiveChat.module.scss';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Button } from '@/components/Button';
import { localStorage } from '@/utils/localStorage';
import Modal from '@/components/Modal';
import { Label } from '@/features/i18n';
import azCommonStyles from '@/theme/globals.module.scss';
import { FeatureFlag, useFeatureFlag } from '@/features/globalConfig';
const THIRTY_SECONDS = 30 * 1000;

export const ProactiveChat = () => {
  const PROACTIVE_CHAT_RESET_PERIOD =
    parseInt(useFeatureFlag('GENESYS_RESET_IN_DAYS') || '30') * 86400000;
  const PROACTIVE_CHAT_POPUP_ENABLED = useFeatureFlag('PROACTIVE_CHAT_POPUP_ENABLED') === 'true';
  const { asPath } = useRouter();
  const isTabletAndUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(false);
  const isNotRestrictedPath = asPath !== '/cart' && asPath !== '/checkout' && asPath !== '/chat';
  const shouldShowModal = isTabletAndUp && open && isNotRestrictedPath;

  const openModal = () => {
    const disableProactiveChatExpiry = localStorage.getItem('disableProactiveChatExpiry');
    if (
      PROACTIVE_CHAT_POPUP_ENABLED &&
      (!disableProactiveChatExpiry || parseInt(disableProactiveChatExpiry) < Date.now())
    ) {
      setOpen(true);
      const newExpiryTime = Date.now() + PROACTIVE_CHAT_RESET_PERIOD;
      localStorage.setItem('disableProactiveChatExpiry', newExpiryTime.toString());
    }
  };

  const handleChatNowClick = () => {
    openChatWindow();
    setOpen(false);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const modalBody = (
    <div className={styles.modalBodyContainer}>
      <h2
        className={cx(azCommonStyles['az-title-3-regular'], azCommonStyles['az-margin-bottom-4xs'])}
      >
        <Label label="proactive_hero_one" />{' '}
        <strong className={azCommonStyles['az-title-3-heavy']}>
          <Label label="proactive_hero_two" />
        </strong>
      </h2>
      <div className={styles.separator} />
      <p
        className={cx(
          azCommonStyles['az-body-1-regular'],
          azCommonStyles['az-margin-bottom-m'],
          azCommonStyles['az-margin-top-s']
        )}
      >
        <Label label="proactive_body" />
      </p>
      <Button fullWidth variant="contained" onClick={handleChatNowClick}>
        <Label label="label_Chat_Now" />
      </Button>
    </div>
  );

  React.useEffect(() => {
    if (isTabletAndUp && isNotRestrictedPath) {
      const modalTimeout = setTimeout(openModal, THIRTY_SECONDS);
      return () => {
        clearTimeout(modalTimeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  return (
    <FeatureFlag flag="PROACTIVE_CHAT_POPUP_ENABLED">
      <Modal
        desktopSize="small"
        body={modalBody}
        isOpen={shouldShowModal}
        handleClose={closeModal}
        showCloseButton
        inset
      />
    </FeatureFlag>
  );
};
