/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import type { CustomLinkName } from '@/types/analytics';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { analyticsPageType } from '@/constants/analytics/analyticsPageType';
import { analyticsPageNames } from '@/constants/analytics/analyticsPageNames';
import { getAxiosForClient } from '@/lib/axios';
import { getErrorCode } from '@/utils/errorsHandling';
import { useMutation } from '@tanstack/react-query';
import type {
  ValidateRewardsAccountData,
  ValidateRewardsAccountRequest,
  ValidateRewardsAccountResponse,
  ValidateRewardsAccountMutationResult,
} from '../interface';
import { siteSections } from '@/utils/siteSections';
import { AxiosError } from 'axios';

const URL = `${requestBaseURL}/ecomm/b2c/v1/currentUser/rewards/validateRewardsAccount`;

const postValidateRewards = async (values: ValidateRewardsAccountRequest) => {
  const response = await getAxiosForClient().post<ValidateRewardsAccountResponse>(URL, values);

  return selector(response.data);
};

const selector = (data: ValidateRewardsAccountResponse): ValidateRewardsAccountData => data;

type ValidateRewardsAccountMutationProps = {
  onSuccess?: (data: ValidateRewardsAccountResponse) => void;
  onError?: (error: AxiosError, values: ValidateRewardsAccountRequest) => void;
};

export const useValidateRewardsAccountMutation = ({
  onSuccess,
  onError,
}: ValidateRewardsAccountMutationProps): ValidateRewardsAccountMutationResult => {
  return useMutation({
    mutationFn: postValidateRewards,
    onSuccess: (data: ValidateRewardsAccountResponse) => {
      onSuccess?.(data);
    },
    onError: (error: AxiosError, values) => {
      onError?.(error, values);
      if (getErrorCode(error) === '1089') {
        const createAccountLinkAccountPopup: CustomLinkName =
          'Link Account: Complete Account Popup';
        const data = {
          eventType: 'completeAccountMsg',
          pageName: analyticsPageNames.azCreate,
          pageType: analyticsPageType.myzoneCreateAccount,
          siteSection: siteSections.siteSectionMyzone,
          customLinkName: createAccountLinkAccountPopup,
        };
        clickTrack(data);
      }
    },
  });
};
