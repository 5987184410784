/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NAME = /^[a-zA-Z-\s.,]{2,30}$/;

export const NAME_MX = /^[a-zA-Z-\s.,ñáéíóúüÑÁÉÍÓÚÜ]{2,30}$/;

export const PHONENUMBER = /^(\((\d{3})\)\s(\d{3})-(\d{4}))$/;

export const ZIPCODE = /^\d{5}(?:[\s]?[-\s][\s]?\d{4})?$/;

export const ORDERID = /^[0-9]*$/i;

export const REWARDSID = /^\d{16}$/;

export const GLOBALPHONENUMBER = /^(\(([0-9]{3})\)\s([0-9]{3})-([0-9]{4}))$/;

export const EXPIRATIONDATE = /^(0[1-9]|1[0-2])*\/?([0-9]{2})$/;

export const ONLYSPACES = /\s/g;

export const FACETED_URL = /(\/bn|\/b\/[\w-]+)\/[\d-]+$/;

export const FACETED_URL_V2 = /(\/bn|\/b\/[\w-]+)\/[\w(-|~)]+$/;

export const SEARCH_STRING_WHITE_LIST = /[^a-zA-Z0-9^!#&'\-":,/ ]/g;

export const ADDRESS = /^[.0-9a-zA-Z\s,-]{0,255}[#&\/]?[.0-9a-zA-Z\s,-]{0,20}$/;

export const ADDRESS_MX =
  /^[.0-9a-zA-Z\s\,\-ñáéíóúüÑÁÉÍÓÚÜ]{0,255}[#&\/]?[.0-9a-zA-Z\s\,\-ñáéíóúüÑÁÉÍÓÚÜ]{0,20}$/;

export const USERNAME = /^(([^<>()[\]\\.,;:\s"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/;

export const OTPCODE = /^\d{6}$/;

export const VIN = /^[a-zA-Z0-9]{11,17}$/;

export const REFERENCENUMBER = /^\d+$/;

export const CURRENCY = /\$?\d+(\..?\d+)?$/;

export const TIMEUTCFORMAT = /[T]+\d\d[:]\d\d[:]\d\d/;
