/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { ReduxState } from '../../../types';

export type DeviceWrapperProps = {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
  children: React.ReactElement;
};

const DeviceWrapper = ({ desktop, tablet, mobile, children }: DeviceWrapperProps) => {
  let matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  let matchesDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  let matchesTablet =
    useMediaQuery((theme) => theme.breakpoints.only('md')) || (!matchesMobile && !matchesDesktop);

  const deviceType = useSelector<ReduxState>(({ appData }) => appData.deviceType);
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    switch (deviceType) {
      case 'desktop': {
        matchesDesktop = true;
        matchesTablet = false;
        matchesMobile = false;
        break;
      }
      case 'tablet': {
        matchesDesktop = false;
        matchesTablet = true;
        matchesMobile = false;
        break;
      }
      case 'mobile': {
        matchesDesktop = false;
        matchesTablet = false;
        matchesMobile = true;
        break;
      }
      case 'phone': {
        matchesDesktop = false;
        matchesTablet = false;
        matchesMobile = true;
        break;
      }
      default:
        break;
    }
  }

  if (!desktop && !tablet && !mobile) {
    return null;
  }

  if (desktop && matchesDesktop) {
    return children;
  }

  if (tablet && matchesTablet) {
    return children;
  }

  if (mobile && matchesMobile) {
    return children;
  } else if (!hasMounted && deviceType === 'bot') {
    return children;
  }

  return null;
};

export default DeviceWrapper;
