/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { UTagData } from '@/types';

export function analyticsDataLayerVariable(data: Partial<UTagData>) {
  if (__IS_SERVER__) {
    return;
  }

  window.utag_data = { ...window.utag_data, ...data };
}
