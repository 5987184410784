/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import type { CommonProps } from './types';
import HiddenCss from './HiddenCss';
import HiddenJs from './HiddenJs';

type JsProps = Omit<CommonProps, 'className'> & {
  implementation: 'js';
};

type CssProps = CommonProps & {
  implementation?: 'css';
};

type Props = JsProps | CssProps;

const Hidden = ({ implementation = 'css', ...other }: Props) => {
  if (implementation === 'js') {
    return <HiddenJs {...(other as JsProps)} />;
  }

  return <HiddenCss {...(other as CssProps)} />;
};

export default Hidden;
