/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { RefObject, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import root from 'window-or-global';
import cx from 'classnames';
import NextImage from '@/components/NextImage';
import azCommonStyles from '@/theme/globals.module.scss';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import { vehicleSelectedIconSource } from '@/constants/images/vehicleSelectedIconSource';
import { Button } from '@/components/Button';
import { AZButton } from '@/components/AZButton';
import { pageTypes } from '@/constants/page';
import {
  PopperProps,
  PopperBodyProps,
  PopperHeaderProps,
} from '@/components/AZCustomComponent/Popper/Popper';
import { YMMEModal } from '@/features/ymme/components/YMMEModal';
import styles from './styles.module.scss';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { useAppState } from '@/stores/AppState';
import { saveUIState } from '@/stores/actions';
import dynamic from 'next/dynamic';
import { useRedirectAfterVehicleChanged } from '@/features/category/hooks/useRedirectAfterVehicleChanged';
import { useIsMobileQRExperience } from '@/hooks/useIsMobileQRExperience';

const vehicleIconSource = '/images/vehicle-new.svg';
const errorIconSource = '/images/notification/error-orange.svg';

const LazyPopper = dynamic<PopperProps>(() =>
  import('@/components/AZCustomComponent/Popper/Popper').then((mod) => mod.Popper)
);

const LazyPopperHeader = dynamic<PopperHeaderProps>(() =>
  import('@/components/AZCustomComponent/Popper/Popper').then((mod) => mod.PopperHeader)
);

const LazyPopperBody = dynamic<PopperBodyProps>(() =>
  import('@/components/AZCustomComponent/Popper/Popper').then((mod) => mod.PopperBody)
);

const trackChangeVehicleSearchResults = () => {
  clickTrack({
    eventType: 'changeVehicleSearchFilter',
  });
};

type Props = {
  currentSize: 'mobile' | 'tablet' | 'desktop';
  dataTestIdDesktopVehicle?: string;
  isFixedHeader?: boolean;
  showTooltip?: boolean;
  tooltipAnchorEl?: RefObject<HTMLElement> | HTMLElement;
  vehicleText: string;
};

export const Vehicle = ({
  currentSize,
  dataTestIdDesktopVehicle,
  isFixedHeader = false,
  showTooltip = false,
  tooltipAnchorEl,
  vehicleText,
}: Props) => {
  const isQRMobileExperience = useIsMobileQRExperience();
  const router = useRouter();
  const [showYmme, setShowYmme] = useState(false);
  const [refocusVehicleBtn, setRefocusVehicleBtn] = useState(false);
  const [ymmeInteractionLocation, setYmmeInteractionLocation] = useState<string>(pageTypes.header);
  const vehicleBtn = useRef<HTMLButtonElement>();
  const vehicleBtnImage = useRef(null);
  const addVehicle = useLabel('label_myvehicle');
  const anchorEl = tooltipAnchorEl ? tooltipAnchorEl : vehicleBtnImage.current;
  const isPDP = router.pathname === '/products/[...slug]';
  const buttonLabel = useLabel(`label_ymme_tooltip_body_button${isPDP ? '_pdp' : ''}`);
  const { dispatch: dispatchAppState } = useAppState();
  const isBrowsePageType = router.route === '/[...seoUrlPath]';
  const redirectAfterVehicleChanged = useRedirectAfterVehicleChanged();

  useEffect(() => {
    if (refocusVehicleBtn) {
      vehicleBtn.current?.focus();
    }
  }, [showYmme, refocusVehicleBtn]);

  const closeYMMETooltip = () => {
    dispatchAppState(saveUIState({ isYmmeTooltipEnabled: false }));
  };

  const handleOpenYmme = (interactionLocation: string) => {
    setShowYmme(true);
    setYmmeInteractionLocation(interactionLocation);
    vehicleBtn.current?.blur();

    if (root.utag_data ?? 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (root.utag_data?.pageHierarchy === 'searchresult') {
        trackChangeVehicleSearchResults();
      }
    }
  };

  const closeYmme = () => {
    setShowYmme(false);
    setRefocusVehicleBtn(true);
  };

  let content = null;

  if (vehicleText) {
    const selectedVehicle = vehicleText.length > 50 ? vehicleText.slice(0, 50) : vehicleText;
    const vehicleIconWidth = isFixedHeader
      ? 20
      : currentSize == 'desktop' || currentSize == 'tablet'
      ? 27
      : 21;
    const vehicleIconHeight = isFixedHeader
      ? 20
      : currentSize == 'desktop' || currentSize == 'tablet'
      ? 23
      : 19;
    content = (
      <div
        data-testid="saved-vehicle-logo"
        className={cx(styles.savedVehicleAlign, {
          [styles.savedVehicleAlignSticky]: isFixedHeader,
        })}
      >
        <div
          className={cx(styles.vehicleIconContainer, {
            [styles.vehicleIconContainerSticky]: isFixedHeader,
          })}
        >
          <img
            src={vehicleSelectedIconSource[currentSize]}
            alt={addVehicle}
            width={vehicleIconWidth}
            height={vehicleIconHeight}
            decoding="async"
          />
        </div>
        <div
          data-testid="vehicle-text"
          className={cx(styles.vehicleDetails, {
            [styles.vehicleDetailsSticky]: isFixedHeader,
          })}
        >
          {selectedVehicle}
        </div>
        <div
          className={cx(styles.vehicleArrowContainer, {
            [styles.vehicleArrowContainerSticky]: isFixedHeader,
          })}
        >
          <img
            className={styles.header_arrow}
            src={'/images/header-arrow.svg'}
            alt=""
            width={isFixedHeader ? 6 : 8}
            height={isFixedHeader ? 11 : 12}
            decoding="async"
          />
        </div>
      </div>
    );
  } else {
    const addVehicleIconWidth = isFixedHeader ? 18 : currentSize === 'desktop' ? 22 : 20;

    const addVehicleIconHeight = isFixedHeader ? 14 : currentSize == 'desktop' ? 16 : 15;
    content = (
      <div
        className={cx(styles.vehicleAlign, {
          [styles.vehicleAlignSticky]: isFixedHeader,
        })}
      >
        <div
          ref={vehicleBtnImage}
          className={cx(styles.addVehicleIconContainer, {
            [styles.addVehicleIconContainerSticky]: isFixedHeader,
          })}
        >
          <img
            className={styles.vehicleIcon}
            src={vehicleIconSource}
            alt=""
            width={addVehicleIconWidth}
            height={addVehicleIconHeight}
            decoding="async"
          />
        </div>
        <div
          className={cx(azCommonStyles['az-body-2-regular'], styles.addVehicleText, {
            [styles.addVehicleTextSticky]: isFixedHeader,
          })}
          key="addVehicleBtn"
          data-testid="add-vehicle-header-btn"
        >
          <Label label="button_YMME_Body_addvehicle" />
        </div>
        <div
          className={cx(styles.vehicleArrowContainer, {
            [styles.vehicleArrowContainerSticky]: isFixedHeader,
          })}
        >
          <img
            className={styles.header_arrow}
            src={'/images/header-arrow.svg'}
            alt=""
            width={isFixedHeader ? 6 : 8}
            height={isFixedHeader ? 11 : 12}
            decoding="async"
          />
        </div>
      </div>
    );
  }

  return (
    <div data-testid="vehicle-render">
      <Button
        data-testid={dataTestIdDesktopVehicle}
        onClick={() => handleOpenYmme(pageTypes.header)}
        className={cx(styles.vehicleButton, {
          [styles.vehicleButtonSticky]: isFixedHeader,
        })}
        disabled={showYmme}
        reference={vehicleBtn}
      >
        {content}
      </Button>
      <YMMEModal
        onUpdateVehicleSuccess={() => {
          isBrowsePageType && void redirectAfterVehicleChanged();
          closeYmme();
        }}
        showYmme={showYmme}
        closeYmmeWidget={closeYmme}
        pageType={ymmeInteractionLocation}
      />
      {anchorEl && !isQRMobileExperience && (
        <LazyPopper
          modifiers={{ arrow: { enabled: currentSize !== 'mobile' } }}
          strategy={isFixedHeader ? 'fixed' : 'absolute'}
          isOpen={!showYmme && showTooltip}
          anchorEl={anchorEl}
          classes={{
            popper: `${styles.ymmeTooltip} ${styles.ymmeTooltipRoot} ${
              isFixedHeader ? styles.fixedHeaderMargin : ''
            }`,
            paper: styles.ymmeTooltipPaper,
          }}
          placement={currentSize === 'mobile' ? 'bottom' : 'bottom-start'}
          role="presentation"
          elevation={4}
          hideForBots
        >
          <LazyPopperHeader
            handleClose={closeYMMETooltip}
            hideCloseIcon={false}
            classes={{
              title: styles.popperTitleWithoutBorder,
              heading: styles.popperHeaderText,
            }}
          >
            <div className={styles.ymmeTooltipHeader}>
              <NextImage
                src={errorIconSource}
                width={16}
                height={16}
                className={styles.addVehicleWarning}
                alt=""
              />
              <strong className={styles.addVehicleText}>
                <Label upper label="label_ymme_add_your_vehicle" />
              </strong>
            </div>
          </LazyPopperHeader>
          <LazyPopperBody>
            <div className={styles.ymmeTooltipBody}>
              {!isPDP && <Label label="label_ymme_tooltip_body_start" />}
              <AZButton
                className={styles.openYmmeButton}
                variant="link"
                onPress={() => handleOpenYmme(pageTypes.ymmeAlert)}
                title={buttonLabel}
              />
              <Label label={`label_ymme_tooltip_body_end${isPDP ? '_pdp' : ''}`} />
            </div>
          </LazyPopperBody>
        </LazyPopper>
      )}
    </div>
  );
};
