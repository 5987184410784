/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { AnyAction } from 'redux';
import type { CheckOutData } from '../types';
import { renameProp } from '../utils/renameProp';
import actionTypes from '../types/action';
import { globalConstant } from '../constants/global';
import { cardVendors } from '../constants/cardVendors';
const {
  CHECKOUT_INVALID,
  FEDX_VALIDATION_INVALID,
  CHECKOUT_REQUESTING,
  CHECKOUT_FAILURE,
  CHECKOUT_UNKNOWN,
  CHECKOUT_SUCCESS,
  UPDATE_DELIVERY_REQUESTING,
  UPDATE_DELIVERY_SUCCESS,
  UPDATE_DELIVERY_FAILURE,
  SET_UPDATE_FAIL,
  SET_LAYOUT,
  SET_CARD_TYPE,
  CLEAR_CHECKOUT_STORE,
  PAYPAL_INDICATOR_UPDATE,
  STORE_DETAILS_SUCCESS,
  SET_PAYMENT_STATUS,
  SET_PAYMENT_STATUS_SUCCESS,
  CHECKOUT_TEXT_SUBSCRIPTION,
  CHECKOUT_UPDATE_SUBSCRIPTIONS,
  SAVE_CONTINUE_AS_GUEST,
  SAVE_GOOGLE_CAPTCHA_TOKEN,
  RESET_GOOGLE_CAPTCHA_TOKEN,
} = actionTypes;

export const initialState = {
  readyStatus: CHECKOUT_INVALID,
  fedExStatus: FEDX_VALIDATION_INVALID,
  err: null,
  cartData: {} as {},
  checkOutData: {} as {},
  paymentLayout: cardVendors.card,
  fedExDetails: {} as {},
  formValueAdded: false,
  shopRunnerDetails: {} as {},
  orderSummary: {} as {},
  paymentMethodInfo: {} as {},
  GCorRewardsOnlyOrder: false,
  shippingMethods: {} as {},
  fedExError: '',
  shippingAddress: {} as {},
  billingAddress: {} as {},
  profileAddress: {} as {},
  isAddressError: false,
  enteredAddress: {} as {},
  lineItemList: [] as Array<any>,
  nddError: null,
  fedExSuccessFlag: false,
  shippingType: globalConstant.savedAddress,
  billingType: globalConstant.savedAddress,
  earnRewardsIndicator: false,
  giftCardIndicator: false,
  creditCardIndicator: false,
  paypalIndicator: false,
  checkedVal: globalConstant.shippingAddress,
  billingFormDefault: false,
  shippingFormDefault: false,
  shouldSaveAddressToProfile: false,
  giftCardList: {} as {},
  initialOrderSubtotal: 0,
  method: '',
  cardType: {} as {},
  showFormStateShipping: {} as {},
  showFormStateBilling: {} as {},
  updateFail: false,
  userAuthenticated: false,
  nextDayShippingMethods: [] as Array<any>,
  regularShippingMethods: [] as Array<any>,
  shoprunnerShippingMethods: [] as Array<any>,
  shippingInfo: null,
  bilingValidationType: false,
  storeDetails: {} as {},
  paymentStatus: false,
  checkPaymentStatus: false,
  appliedGiftCardList: [{} as {}],
  appliedRewardCardList: [{} as {}],
  checkoutSubscriptions: {
    receivePromoAndSpecialEvents: true,
    receiveTextMessage: false,
  },
  continueAsGuest: false,
  creditCardCaptcha: undefined,
};

const checkout = (state: CheckOutData = initialState, action: AnyAction): CheckOutData => {
  switch (action.type) {
    case CHECKOUT_REQUESTING:
      return {
        ...state,
        readyStatus: CHECKOUT_REQUESTING,
        fedExSuccessFlag: action.fedExSuccessFlag,
        shippingFormDefault: false,
        formValueAdded: false,
      };

    case CHECKOUT_FAILURE:
      return { ...state, readyStatus: CHECKOUT_FAILURE, err: action.err };

    case CHECKOUT_UNKNOWN:
      return { ...state, readyStatus: CHECKOUT_UNKNOWN };

    case CHECKOUT_SUCCESS:
      return {
        ...state,
        readyStatus: CHECKOUT_SUCCESS,
        checkOutData: action.data,
        shopRunnerDetails: action.data.shopRunnerDetails,
        profileAddress: action.data.profileAddress,
        orderSummary: action.data.orderSummaryVO,
        shippingMethods: action.data.shippingMethods,
        lineItemList: action.data.lineItemsListVO,
        fedExSuccessFlag: action.fedExSuccessFlag,
        paymentMethodInfo: action.data.paymentMethodInfo,
        initialOrderSubtotal: action.data.orderSummaryVO.orderSubtotal,
        GCorRewardsOnlyOrder: action.data.GCorRewardsOnlyOrder,
        giftCardList: action.data.giftCardDetails,
        nextDayShippingMethods: action.data.shippingMethods?.nextDayShippingMethods,
        regularShippingMethods: action.data.shippingMethods?.regularShippingMethods,
        shoprunnerShippingMethods: action.data.shippingMethods?.shoprunnerShippingMethods,
        fedExError: '',
        isAddressError: false,
        formValues: action.data.profileAddressAvailable
          ? renameProp(
              globalConstant.postalCode,
              globalConstant.zipCode,
              action.data.profileAddress
            )
          : null,
      };

    case UPDATE_DELIVERY_REQUESTING:
      return { ...state, fedExStatus: UPDATE_DELIVERY_REQUESTING };

    case UPDATE_DELIVERY_SUCCESS:
      return {
        ...state,
        readyStatus: CHECKOUT_SUCCESS,
        orderSummary: action.data.orderSummary,
        shippingMethodSelected: action.data.shippingMethodSelected,
        method: action.method ? action.method : state.method,
        shippingInfo: action.data.shippingMethodWarningMsg,
        lineItemList: action.data.lineItemsListVO,
        updateFail: false,
        nextDayShippingMethods: action.data.shippingMethods?.nextDay,
        regularShippingMethods: action.data.shippingMethods?.regular,
        shoprunnerShippingMethods: action.data.shippingMethods?.shopRunner,
        fedExStatus: UPDATE_DELIVERY_SUCCESS,
      };

    case UPDATE_DELIVERY_FAILURE:
      return {
        ...state,
        err: action.err,
        nddError: action.err.response.data.title,
        updateFail: true,
        fedExStatus: UPDATE_DELIVERY_FAILURE,
      };

    case SET_UPDATE_FAIL:
      return { ...state, updateFail: false };

    case SET_LAYOUT:
      return {
        ...state,
        readyStatus: CHECKOUT_SUCCESS,
        paymentLayout: action.layout,
      };

    case SET_CARD_TYPE:
      return { ...state, readyStatus: CHECKOUT_SUCCESS, cardType: action.data };

    case CLEAR_CHECKOUT_STORE:
      return {
        ...state,
        readyStatus: CHECKOUT_INVALID,
        fedExStatus: FEDX_VALIDATION_INVALID,
        checkOutData: {},
        shopRunnerDetails: {},
        orderSummary: {},
        shippingMethods: {},
        lineItemList: [],
        fedExSuccessFlag: false,
        fedExDetails: {},
        profileAddress: {},
        shippingAddress: {},
        billingAddress: {},
        enteredAddress: {},
        fedExError: '',
        isAddressError: false,
        giftCardList: {},
        nddError: null,
        nextDayShippingMethods: [],
        regularShippingMethods: [],
        shoprunnerShippingMethods: [],
        shippingInfo: null,
      };

    case PAYPAL_INDICATOR_UPDATE:
      return { ...state, paypalIndicator: action.ppvalue };

    case STORE_DETAILS_SUCCESS:
      return { ...state, storeDetails: action.storeData };

    case SET_PAYMENT_STATUS:
      return { ...state, paymentStatus: action.paymentStatus };

    case SET_PAYMENT_STATUS_SUCCESS:
      return { ...state, checkPaymentStatus: action.checkPaymentStatus };

    case CHECKOUT_TEXT_SUBSCRIPTION:
      return {
        ...state,
        checkoutSubscriptions: {
          ...state.checkoutSubscriptions,
          receiveTextMessage: action.setTextSubscription,
        },
      };

    case 'CHECKOUT_PROMOS_AND_EVENTS_SUBSCRIPTION':
      return {
        ...state,
        checkoutSubscriptions: {
          ...state.checkoutSubscriptions,
          receivePromoAndSpecialEvents: action.setPromosAndEventsSubscription,
        },
      };

    case CHECKOUT_UPDATE_SUBSCRIPTIONS:
      return { ...state, checkoutSubscriptions: action.data };

    case SAVE_CONTINUE_AS_GUEST:
      return { ...state, continueAsGuest: action.continueAsGuest };

    case SAVE_GOOGLE_CAPTCHA_TOKEN:
      return { ...state, creditCardCaptcha: action.googleRecaptchaToken };

    case RESET_GOOGLE_CAPTCHA_TOKEN:
      return { ...state, creditCardCaptcha: undefined };

    default:
      return state;
  }
};

export default checkout;
