/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useContext } from 'react';
import { ProductListContext } from '../ProductListContext';

export const useProductList = () => {
  const context = useContext(ProductListContext);

  return context;
};
