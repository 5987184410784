/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AnyAction } from 'redux';
import actionTypes from '../types/action';
import type { ShelfState } from '@/types';

export const initialState: ShelfState = {
  readyStatus: 'INITIAL_PART_STATUS',
  isRefresh: false,
  isList: true,
  err: null,
  cartSuccess: null,
  showWarranty: {
    canShow: false,
    showModalFrom: '',
    prodIndex: null,
  },
  errorSku: '',
  errorKey: '',
  accordionFilterOpenStates: {},
  isYmmeWidgetShowing: false,
  errorMessageDefault: '',
  loadingSkuNumber: null,
  filterScroll: false,
  filterScrollBrand: false,
  productFilterSelections: Object,
  selectedFilterValue: '',
  eventType: '',
  selectedNullCartridge: '',
  focusItemsPerPage: false,
  focusSortBy: false,
};
const {
  ADD_TO_CART_SHELF_FAILURE,
  ADD_TO_CART_SHELF_REQUESTING,
  ADD_TO_CART_SUCCESS,
  EMPTY_CART_ITEMS,
  EMPTY_DATA,
  INITIAL_PART_STATUS,
  SET_SELECTED_NULL_CARTRIDGE,
  SET_LOADING_FOR_PRODUCT,
  SET_PRODUCT_FILTER_EVENTS,
  CLEAR_PRODUCT_FILTER_EVENTS,
  CLEAR_PRODUCT_FILTER_SELECTION,
  SET_FILTER_SCROLL,
  SET_FILTER_SCROLL_BRAND,
  SET_ITEMS_PER_PAGE_OR_SORT_BY_FOCUS,
} = actionTypes;

const shelf = (state: ShelfState = initialState, action: AnyAction): ShelfState => {
  switch (action.type) {
    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        readyStatus: ADD_TO_CART_SUCCESS,
        cartSuccess: action.data,
      };
    }

    case ADD_TO_CART_SHELF_FAILURE:
      return {
        ...state,
        err: action.err,
        errorSku: action.errorSku,
        errorKey: action.errorKey,
        errorMessageDefault: action.errorMessageDefault,
        readyStatus: ADD_TO_CART_SHELF_FAILURE,
        quantity: action.quantity,
        orderType: action.orderType,
      };

    case ADD_TO_CART_SHELF_REQUESTING:
      return { ...state, readyStatus: ADD_TO_CART_SHELF_REQUESTING };

    case EMPTY_DATA:
      return { ...state, cartSuccess: null };

    case EMPTY_CART_ITEMS:
      return {
        ...state,
        readyStatus: INITIAL_PART_STATUS,
        err: null,
        cartSuccess: null,
      };

    case SET_LOADING_FOR_PRODUCT:
      return { ...state, loadingSkuNumber: action.skuNumber };

    case SET_SELECTED_NULL_CARTRIDGE: {
      return { ...state, selectedNullCartridge: action.value };
    }

    case SET_PRODUCT_FILTER_EVENTS: {
      return {
        ...state,
        filterScroll: action.filterScroll,
        productFilterSelections: getProductFilterSelection(state, action),
        selectedFilterValue: action.selectedFilterValue,
        eventType: action.eventType,
      };
    }

    case CLEAR_PRODUCT_FILTER_EVENTS: {
      return { ...state, selectedFilterValue: '', eventType: '' };
    }

    case CLEAR_PRODUCT_FILTER_SELECTION: {
      return {
        ...state,
        productFilterSelections: {},
        selectedFilterValue: '',
        eventType: '',
      };
    }

    case SET_FILTER_SCROLL: {
      return { ...state, filterScroll: action.value };
    }

    case SET_FILTER_SCROLL_BRAND: {
      return { ...state, filterScrollBrand: action.value };
    }

    case SET_ITEMS_PER_PAGE_OR_SORT_BY_FOCUS: {
      return {
        ...state,
        focusItemsPerPage: action.focusItemsPerPage,
        focusSortBy: action.focusSortBy,
      };
    }

    default:
      return state;
  }
};

function getProductFilterSelection(state: ShelfState, action: AnyAction): any {
  const { productFilterSelections = {} } = state;
  const { checked, selectedFilterValue = '', filterId } = action;
  // @ts-expect-error
  if (checked && !productFilterSelections[filterId]) {
    return { ...productFilterSelections, [filterId]: selectedFilterValue };
  } else if (!checked) {
    const {
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [filterId]: removedFilter,
      ...filteredSelections
    } = productFilterSelections;
    return filteredSelections;
  }
}

export default shelf;
