/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import type { CommonProps } from './types';

const HiddenJs = ({
  xlDown,
  xlUp,
  lgDown,
  lgUp,
  mdDown,
  mdUp,
  smDown,
  smUp,
  only,
  children,
}: CommonProps) => {
  const hideOnly = Array.isArray(only) ? only : [only];
  const hideMap = {
    sm: hideOnly.includes('sm') || smDown || xlDown || lgDown || mdDown || smUp,
    md: hideOnly.includes('md') || mdDown || xlDown || lgDown || mdUp || smUp,
    lg: hideOnly.includes('lg') || xlDown || lgUp || mdUp || smUp || lgDown,
    xl: hideOnly.includes('xl') || xlDown || xlUp || lgUp || mdUp || smUp,
  };
  const smScreen = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const mdScreen = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const lgScreen = useMediaQuery((theme) => theme.breakpoints.only('lg'));
  const xlScreen = useMediaQuery((theme) => theme.breakpoints.only('xl'));

  if (hideMap.sm && smScreen) {
    return null;
  }

  if (hideMap.md && mdScreen) {
    return null;
  }

  if (hideMap.lg && lgScreen) {
    return null;
  }

  if (hideMap.xl && xlScreen) {
    return null;
  }

  return <>{children}</>;
};

export default HiddenJs;
