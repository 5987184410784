/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const eventConstants = {
  enterKeyCode: 13,
  tabKeyCode: 9,
  escKeyCode: 27,
  shiftKeyCode: 16,
  spaceKeyCode: 32,
  enterKeyType: 'Enter',
  spaceKeyType: ' ',
  spaceKeyTypeString: 'Space',
  tabKeyType: 'Tab',
  arrowUpKeyType: 'ArrowUp',
  arrowDownKeyType: 'ArrowDown',
  escKeyType: 'Escape',
};

export const eventTypes = {
  topNavLinkInteraction: 'topNavLinkInteraction',
  breadcrumbLinkInteraction: 'breadcrumbLinkInteraction',
  clearFilterShelf: 'clearFilterShelf',
  clearFilterSearch: 'clearFilterSearch',
  categoryMostPopularPartsInteraction: 'categoryMostPopularPartsInteraction',
  displayViewInteractionShelf: 'displayViewInteractionShelf',
  paginationInteractionShelf: 'paginationInteractionShelf',
  imageInteractionPDP: 'imageInteractionPDP',
  addVehicle: 'addVehicle',
  mergeAccountFailure: 'mergeAccountFailure',
  successfulResetPassword: 'successfulResetPassword',
  successfulMergeAccounts: 'successfulMergeAccounts',
  sortReviewsOptionsInteractionPDP: 'sortReviewsOptionsInteractionPDP',
  reviewsLinkInteractionShelf: 'reviewsLinkInteractionShelf',
  removeFromCart: 'removeFromCart',
  errorLogCartItemLevel: 'errorLogCartItemLevel',
  changeStoreCtaCart: 'changeStoreCtaCart',
  checkoutCtaCart: 'checkoutCtaCart',
  paypalCtaCart: 'paypalCtaCart',
  dealsBuildBundle: 'dealsBuildBundle',
  dealsAvailableLink: 'dealsAvailableLink',
  dealDrawerError: 'dealDrawerError',
  findAnotherStoreShelf: 'inStockNearYouFlyoutCTA',
  findAnotherStoreCart: 'inStockNearYouFlyoutCTA',
  addToCart: 'addToCart',
  carouselPromotionHome: 'carouselPromotionHome',
  findAnotherStorePdp: 'inStockNearYouFlyoutCTA',
  setStoreGlobalHeader: 'setStoreGlobalHeader',
  checkOutAddToCartOverlay: 'checkOutAddToCartOverlay',
  seeStoreHoursCheckout: 'seeStoreHoursCheckout',
  viewPickupInstructionsCheckout: 'viewPickupInstructionsCheckout',
  searchLinkClickThrough: 'searchLinkClickThrough',
  removeVehicle: 'removeVehicle',
  viewMoreMyVehicles: 'viewMoreMyVehicles',
  serviceHistoryMyVehicles: 'serviceHistoryMyVehicles',
  maintenanceMyVehicles: 'maintenanceMyVehicles',
  repairGuidesMyVehicles: 'repairGuidesMyVehicles',
  recallsMyVehicles: 'recallsMyVehicles',
  registerAccountMyVehicles: 'registerAccountMyVehicles',
  signInAccountMyVehicles: 'signInAccountMyVehicles',
  optInOffersCheckout: 'optInOffersCheckout',
  optOutOffersCheckout: 'optOutOffersCheckout',
  maintenanceGuideTabInteraction: 'maintenanceGuideTabInteraction',
  editInfoMyProfile: 'editInfoMyProfile',
  editInfoSuccessMyProfile: 'editInfoSuccessMyProfile',
  formCompleteMyProfile: 'formCompleteMyProfile',
  formStartMyProfile: 'formStartMyProfile',
  editLoginAndPasswordMyProfile: 'editLoginAndPasswordMyProfile',
  orderHistoryDropDownInteraction: 'orderHistoryDropDownInteraction',
  leaveReviewOrderDetailsInteraction: 'leaveReviewOrderDetailsInteraction',
  orderHistoryTabInteraction: 'orderHistoryTabInteraction',
  rewardsActivityInteraction: 'rewardsActivityInteraction',
  updateQuantity: 'updateQuantity',
  paymentFailureCheckout: 'paymentFailureCheckout',
  shippingInfoCompleteCheckout: 'shippingInfoCompleteCheckout',
  billingInfoCompleteCheckout: 'billingInfoCompleteCheckout',
  signInShoprunnerCart: 'signInShoprunnerCart',
  learnMoreShoprunnerCart: 'learnMoreShoprunnerCart',
  profileMyAccount: 'profileMyAccount',
  rewardsMyAccount: 'rewardsMyAccount',
  vehiclesMyAccount: 'vehiclesMyAccount',
  orderHistoryMyAccount: 'orderHistoryMyAccount',
  communityMyAccount: 'communityMyAccount',
  questionsMyAccount: 'questionsMyAccount',
  startRewardsMyAccount: 'startRewardsMyAccount',
  repairGuideContentInteraction: 'repairGuideContentInteraction',
  dealsAndSavingsTabInteraction: 'dealsAndSavingsTabInteraction',
  footerNavLinkInteraction: 'footerNavLinkInteraction',
  homeInteractionsBySection: 'homeInteractionsBySection',
  filterCheckboxRemovalShelf: 'filterCheckboxRemovalShelf',
  getThisDealInteraction: 'getThisDealInteraction',
  successfulTrackOrder: 'successfulTrackOrder',
  errorTrackOrder: 'errorTrackOrder',
  newsletterSignupHome: 'newsletterSignupHome',
  readMoreInteractionPDP: 'readMoreInteractionPDP',
  printiconCart: 'printiconCart',
  shippingPaymentMethodCheckout: 'shippingPaymentMethodCheckout',
  filterCheckboxAdditionDeals: 'filterCheckboxAdditionDeals',
  filterCheckboxRemovalDeals: 'filterCheckboxRemovalDeals',
  filterCheckboxSelectionandRemovalShelf: 'filterCheckboxSelectionandRemovalShelf',
  paginationOrderHistoryInteraction: 'paginationOrderHistoryInteraction',
  shopDealsRewardsContentBlockHome: 'shopDealsRewardsContentBlockHome',
  myAccountRewardsContentBlockHome: 'myAccountRewardsContentBlockHome',
  signUpRewardsContentBlockHome: 'signUpRewardsContentBlockHome',
  signInRewardsContentBlockHome: 'signInRewardsContentBlockHome',
  editInYourCartCheckout: 'editInYourCartCheckout',
  continueAsGuestCheckout: 'continueAsGuestCheckout',
  applyRewardsCheckout: 'applyRewardsCheckout',
  applyRewardsCheckoutPage: 'applyRewardsCheckoutPage',
  removeRewardsCheckout: 'removeRewardsCheckout',
  nextDayShippingDeliveryMessageCheckout: 'nextdayShippingDeliveryMessageCheckout',
  useDifferentAddressCheckout: 'useDifferentAddressCheckout',
  rewardsCardPrintInteraction: 'rewardsCardPrintInteraction',
  userErrorsCheckout: 'userErrorsCheckout',
  shippingInfoStartCheckout: 'shippingInfoStartCheckout',
  billingInfoStartCheckout: 'billingInfoStartCheckout',
  faqLandingPageInteraction: 'faqLandingPageInteraction',
  programRulesLandingPageInteraction: 'programRulesLandingPageInteraction',
  missingCreditLandingPageInteraction: 'missingCreditLandingPageInteraction',
  rewardsSupportLandingPageInteraction: 'rewardsSupportLandingPageInteraction',
  rewardsCardEmailInteraction: 'rewardsCardEmailInteraction',
  addToCartDealMaker: 'addToCartDealMaker',
  contactUsMobileLandingPage: 'contactUsMobileLandingPage',
  categoryLevel1Interaction: 'categoryLevel1Interaction',
  categoryLevel2Interaction: 'categoryLevel2Interaction',
  categoryLevel3Interaction: 'categoryLevel3Interaction',
  successfulLogin: 'successfulLogin',
  storeRebatesInteraction: 'storeRebatesInteraction',
  downloadInteraction: 'downloadInteraction',
  changeVehicleSearchFilter: 'changeVehicleSearchFilter',
  closeOverlayMinicart: 'closeOverlayMinicart',
  continueShoppingCart: 'continueShoppingCart',
  chatLinkInteraction: 'chatLinkInteraction',
  requestCreditMobileLandingPage: 'requestCreditMobileLandingPage',
  dealMakerError: 'dealMakerError',
  buynowLinkcontentblock: 'buynowLinkcontentblock',
  contactUsHowWeHelp: 'contactUsHowWeHelp',
  globalErrorTrigger: 'globalErrorTrigger',
  formFieldErrorTrigger: 'formFieldErrorTrigger',
  createAccountLogin: 'createAccountLogin',
  createAccountFailure: 'createAccountFailure',
  completePurchaseCheckout: 'completePurchaseCheckout',
  customerselectingdeals: 'customerselectingdeals',
  rewardsLinkInteractionCart: 'rewardsLinkInteractionCart',
  MainetnanceGuideAddInteration: 'MainetnanceGuideAddInteration',
  promoCodeInvalidCheckout: 'promoCodeInvalidCheckout',
  promoCodeInvalid: 'promoCodeInvalid',
  promoClaimSuccessCheckout: 'promoClaimSuccessCheckout',
  promoClaimSuccess: 'promoClaimSuccess',
  promoCodeClaimSuccess: 'promoCodeClaimSuccess',
  promoCodeClaimFailure: 'promoCodeClaimFailure',
  promoCodeRemove: 'promoCodeRemove',
  removeDiscount: 'removeDiscount',
  dealIdClaimSuccess: 'dealIdClaimSuccess',
  dealIdClaimFailure: 'dealIdClaimFailure',
  myAccountCardEmailInteraction: 'myAccountCardEmailInteraction',
  myAccountCardPrintInteraction: 'myAccountCardPrintInteraction',
  getDirectionStore: 'getDirectionStore',
  phoneNumberStore: 'phoneNumberStore',
  clickHereCartPage: 'clickHereCartPage',
  backToCartCheckout: 'backToCartCheckout',
  applyRewardsCheckoutSignedIn: 'applyRewardsCheckoutSignedIn',
  shippingDeliveryMethodCheckout: 'shippingDeliveryMethodCheckout',
  formStart: 'form_start',
  formEnd: 'form_end',
  qohErrorCart: 'QOHErrorCart',
  itemsInYourMiniCart: 'itemsInYourMiniCart',
  changeVehicle: 'changeVehicle',
  searchButtonClicked: 'searchButtonClicked',
  createAccountConfirmation: 'createAccountConfirmation',
  checkoutError: 'checkoutError',
  addToCartQohError: 'addToCartQohError',
  gapToEarnUpdate: 'gapToEarnUpdate',
  fulfillmentChange: 'fulfillmentChange',
  cartPageFulfillmentChange: 'cartPageFulfillmentChange',
  loyaltyCustomerLookup: 'loyaltyCustLookup',
  loyaltyCustomerChooseAccount: 'loyaltyLookupChooseAccount',
  applyFilter: 'applyFilter',
  removeFilter: 'removeFilter',
  filterCheckboxSelectionShelf: 'filterCheckboxSelectionShelf',
  quickFilterEvent: 'Quick Filter',
  customPriceEvent: 'CustomPrice',
  prodView: 'prodView',
  productFitmentBanner: 'productFitmentBannerClick',
  productToastNotification: 'productToastNotificationClick',
};
