/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

export const Paper = React.forwardRef<HTMLElement, Props>(function Paper(
  {
    className,
    component: Component = 'div',
    square = false,
    elevation = 1,
    variant = 'elevation',
    ...other
  },
  ref
) {
  return (
    <Component
      className={cx(
        styles.root,
        {
          [styles.rounded]: !square,
          [styles[`elevation${elevation}`]]: variant === 'elevation',
          [styles.outlined]: variant === 'outlined',
        },
        className
      )}
      ref={ref}
      {...other}
    />
  );
});

export type Props = {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: React.ElementType;

  /**
   * Shadow depth, corresponds to `dp` in the spec.
   * It accepts values between 0 and 24 inclusive.
   */
  elevation?: number;

  /**
   * If `true`, rounded corners are disabled.
   */
  square?: boolean;

  /**
   * The variant to use.
   */
  variant?: 'elevation' | 'outlined';

  /**
   * @ignore
   */
  className?: string;
  'data-testid'?: string;
  id?: string;
  role?: string;
  style?: any;
  onBlur?: (event: React.MouseEvent<HTMLElement>) => unknown;
};
