/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackChatLink = (pageName: string) => {
  clickTrack({
    pageName,
    chatType: 'newWindow',
    eventType: 'chatLinkInteraction',
  });
};
