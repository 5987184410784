/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useRouter } from 'next/router';
import usePrevious from '../../../utils/usePrevious';
import { useScrollBelowThreshold } from '@/hooks/useScrollBelowThreshold';
import NextImage from '@/components/NextImage';
import CloseIcon from '../../../../public/images/close.svg';
import iconStyles from '../../../theme/iconStyles.module.scss';
import { ReduxState } from '../../../types';
import styles from './styles.module.scss';
import { useAppState } from '@/stores/AppState';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { setAppBannerShown } from '@/actions/app';

const azAppImg = {
  src: '/images/AZ_DIY_AppIcon_1x.png',
  alt: 'AZ',
};

export default function SmartAppBanner() {
  const { isMobileApp } = useAppState().state;
  const userAgent = useSelector((state: ReduxState) => state.appData.userAgent);
  const appBannerShown = useSelector(({ appData }: ReduxState) => appData.appBannerShown);
  const isABot = useSelector(({ appData }: ReduxState) => appData.deviceType === 'bot');
  const [showBanner, setShowBanner] = useState(!appBannerShown);
  const [scrolled, setScrolled] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);
  const router = useRouter();
  const prevPath = usePrevious(router.asPath);
  const dispatch = useDispatch();
  let platform = 'others';

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    platform = 'ios';
  } else if (/Android/i.test(userAgent)) {
    platform = 'android';
  }

  useScrollBelowThreshold(
    (belowThreshold: boolean) => {
      setScrolled(belowThreshold);
    },
    [scrolled, bannerHeight],
    bannerHeight / 1.5
  );

  const bannerRefCallback = (element: HTMLElement | null) => {
    if (element && bannerHeight < 1) {
      setBannerHeight(element.getBoundingClientRect().height);
    }
  };

  useEffect(() => {
    if (prevPath && router.asPath !== prevPath) {
      setShowBanner(false);
    }
  }, [router.asPath, prevPath]);

  const priceSuffix = (platform: string) => {
    if (platform === 'ios') {
      return ' - On the App Store';
    } else {
      return ' - In Google Play';
    }
  };

  const buttonUrl = (platform: string) => {
    if (platform === 'android') {
      return 'https://play.google.com/store/apps/details?id=com.autozone.mobile&hl=en_US';
    } else {
      return 'https://apps.apple.com/us/app/autozone/id375381161';
    }
  };

  const platformEnabled = (platform: string) => ['android', 'ios'].includes(platform);

  if (isABot || !showBanner || !platformEnabled(platform) || isMobileApp) {
    return null;
  }

  return (
    <div
      ref={bannerRefCallback}
      className={`${styles.smartbanner} ${
        platform === 'android' ? styles['smartbanner--android'] : ''
      } ${scrolled ? styles['smartbanner--scrolled'] : ''}`}
    >
      <button
        className={styles.smartbanner__exit}
        aria-label="Close"
        onClick={() => {
          dispatch(setAppBannerShown(true));
          setShowBanner(false);
        }}
      >
        <CloseIcon className={cx(iconStyles.defaultIconStyle, iconStyles.fontSizeSmall)} />
      </button>
      <NextImage
        src={azAppImg.src}
        alt={azAppImg.alt}
        className={styles.smartbanner__icon}
        height={64}
        width={64}
        priority
      />
      <div className={styles.smartbanner__info}>
        <div>
          <div className={styles.smartbanner__info__title}>
            AutoZone - Shop for Auto Parts & Accessories
          </div>
          <div className={styles.smartbanner__info__author}>AutoZone, Inc.</div>
          <div className={styles.smartbanner__info__price}>Free{priceSuffix(platform)}</div>
        </div>
      </div>
      <a
        href={buttonUrl(platform)}
        target="_blank"
        className={styles.smartbanner__button}
        rel="noreferrer"
        aria-label="VIEW"
      >
        <span className={styles.smartbanner__button__label}>VIEW</span>
      </a>
    </div>
  );
}
