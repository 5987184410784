/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { GlobalConfig } from '../interface';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { isTrue } from '@/utils/isTrue';

type FeatureProps = {
  flag: keyof GlobalConfig;
  negate?: boolean;
  children: React.ReactNode;
};

/**
 * <FeatureFlag flag="SMART_APP_BANNER_ENABLED">
 *    <SmartAppBanner />
 * </FeatureFlag>
 */
export const FeatureFlag = ({ flag, negate, children }: FeatureProps) => {
  let configVal = useFeatureFlag(flag);
  if (typeof configVal !== 'string') {
    return null;
  }

  if (negate) {
    if (isTrue(configVal)) {
      configVal = 'false';
    } else {
      configVal = 'true';
    }
  }

  if (!isTrue(configVal)) {
    return null;
  }

  return <>{children}</>;
};
