/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { LandingPageData, LandingPageFallbackData } from '@/types/legacy';

export function isLandingPage(
  pageContents: LandingPageData | LandingPageFallbackData | undefined
): pageContents is LandingPageData {
  return (pageContents as LandingPageData).landingTippyTop !== undefined;
}
