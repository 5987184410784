/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { azLogo } from '../../../constants/images/azLogo';
import styles from './styles.module.scss';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import cx from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type Props = {
  variant?: 'Full' | 'Medium' | 'SmallSize';
  customClassName?: any | string;
  containerClassName?: any | string;
};

const AZLogo = ({ variant = 'Full', customClassName, containerClassName }: Props) => {
  const locale = useLocale();
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const lblAZLogoAlt = useLabel('labels_AutoZoneAutoParts_Logo');
  const image =
    locale === countryCodes.us
      ? azLogo[`${variant}${matchesMobile ? 'Mobile' : ''}`]
      : locale && azLogo[`${locale}${matchesMobile ? 'Mobile' : ''}`];

  return (
    <div className={cx(styles.logoContainer, containerClassName)}>
      <img
        className={cx(
          styles.azLogo,
          customClassName ? customClassName : styles[`azLogo${variant}`]
        )}
        decoding="async"
        src={image.src}
        width={image.width}
        height={image.height}
        alt={lblAZLogoAlt}
        data-testid="autozone-top-header-logo"
      />
    </div>
  );
};

export default AZLogo;
