/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import styles from '../lib/components/styles.module.scss';
import { Text, Notification } from '@az/starc-ui';

type Props = {
  onClose: () => void;
  canOpen: boolean;
  errorMessage?: string;
  successData?: {
    userName: string;
  };
};

export const ResetPasswordMessageV2 = (props: Props) => {
  const { errorMessage, canOpen, onClose, successData } = props;
  const mf_resetpassword_success = useLabel('mf_resetpassword_success');
  const mf_resetpassword_thanks_msg = useLabel('mf_resetpassword_thanks_msg');

  if (successData) {
    return (
      <>
        {canOpen ? (
          <Notification type="success" hasCloseButton onClose={onClose}>
            <Text variant="subtitle-regular" className={styles.offsetNotificationMargin}>
              <span>
                <span
                  style={{ fontWeight: 'bold' }}
                >{`${mf_resetpassword_thanks_msg} ${successData?.userName}, `}</span>
                {` ${mf_resetpassword_success}`}
              </span>
            </Text>
          </Notification>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return (
      <Notification type="error" hasCloseButton onClose={onClose}>
        <Text variant="subtitle-regular" className={styles.offsetNotificationMargin}>
          <Label label={errorMessage ?? 'label_ResetPassword_TryAgain'} />
        </Text>
      </Notification>
    );
  }
};
