/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type QueryClient, useQuery, type QueryFunctionContext } from '@tanstack/react-query';
import type { GlobalConfig } from './interface';
import { getAxios } from '@/lib/axios';
import { requestBaseURL } from '@/config/serviceAPI';
import { cache } from '@/utils/serverQueryCache';
import { type AxiosInstance } from 'axios';
import type { Locale } from '@/types/i18n';

const URL = `${requestBaseURL}/ecomm/b2c/v1/globalConfigs/siteGlobalConfigs`;

const getGlobalConfig = async (
  _: QueryFunctionContext<ReturnType<typeof globalConfigKey>>,
  axiosInstance?: AxiosInstance
): Promise<GlobalConfig> => {
  const response = await getAxios(axiosInstance).get<GlobalConfig>(URL);
  return response.data;
};

export const globalConfigKey = () => ['config', 'global'];

export const useGlobalConfig = () => {
  return useQuery({
    queryKey: globalConfigKey(),
    queryFn: getGlobalConfig,
    staleTime: Infinity,
  });
};

export const prefetchGlobalConfig = async (
  queryClient: QueryClient,
  locale: Locale,
  axiosInstance: AxiosInstance
) => {
  const queryKey = globalConfigKey();
  const cacheQueryKey = queryKey.concat(locale);
  const data = cache.getItem(cacheQueryKey);
  if (data) {
    queryClient.setQueryData(queryKey, data);
    return;
  } else {
    await queryClient
      .fetchQuery({
        queryKey,
        queryFn: (ctx) => getGlobalConfig(ctx, axiosInstance),
      })
      .then((data) => {
        cache.setItem(cacheQueryKey, data);
      });
  }
};

// Return globalConfig from cache. Useful to access this data outside of a hook/component
export const getGlobalConfigFromCache = (queryClient: QueryClient): GlobalConfig | undefined => {
  return queryClient.getQueryData(globalConfigKey());
};
