/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

export function useInteractionCheck() {
  const [userInteracted, setUserInteracted] = useState(false);
  const enableUserInteracted = () => {
    setUserInteracted(true);
  };
  useEffect(() => {
    const INTERACTION_EVENTS = ['click', 'mousemove', 'pointermove', 'scroll', 'touchstart'];
    INTERACTION_EVENTS.forEach((interactionEvent) => {
      document.addEventListener(interactionEvent, enableUserInteracted);
    });

    return () => {
      INTERACTION_EVENTS.forEach((interactionEvent) => {
        document.removeEventListener(interactionEvent, enableUserInteracted);
      });
    };
  }, []);

  return userInteracted;
}
