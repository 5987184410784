/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import React from 'react';
import styles from './FooterSecondary.module.scss';
import ContentContainer from '@/components/AZCustomComponent/ContentContainer';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import azCommonStyles from '@/theme/globals.module.scss';

const digicertLogo = {
  src: '/images/footer/digicert.png',
  alt: '',
};

const labelMap = {
  copyright: 'label_footer_copyright',
  digicert: 'hyperlink_Footer_bottom_DigicertURL',
  logo: 'label_Digicert_Logo',
};

export const FooterSecondary = () => {
  const locale = useLocale();
  const labels = useLabels(labelMap);

  return (
    <footer data-testid="checkout-footer-container" className={styles.checkoutFooter}>
      <ContentContainer
        isTransparent
        className={cx(azCommonStyles['az-padding-xxs'], styles.footerBox)}
      >
        <div
          data-testid="copyright-container"
          className={cx(azCommonStyles['az-caption'], styles.copyright)}
        >
          {`© 2001-${new Date().getFullYear()} ${labels.copyright}`}
        </div>
        {locale === countryCodes.us ? (
          <div
            data-testid="digicert-container"
            className={cx(azCommonStyles['az-margin-left-xxs'], styles.digicert)}
          >
            <a href={labels.digicert} target="_blank" rel="noopener noreferrer">
              <img
                src={digicertLogo.src}
                alt={labels.logo}
                data-testid="footer-secondary-digi-cert"
                width={50}
                height={40}
              />
            </a>
          </div>
        ) : (
          ''
        )}
      </ContentContainer>
    </footer>
  );
};
