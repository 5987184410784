/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext, useState, ReactNode, useCallback, useMemo } from 'react';
import AZSnackBar from '@/components/AZCustomComponent/AZSnackBar';

type SnackBarContextType = {
  showSnackBar: (message: string) => void;
  open: boolean;
};

export const SnackBarContext = createContext<SnackBarContextType>({} as SnackBarContextType);

type SnackBarProviderProps = {
  children: ReactNode;
};

export const SnackBarProvider = ({ children }: SnackBarProviderProps) => {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const showSnackBar = useCallback((message: string) => {
    setSnackMessage(message);
    setSnackOpen(true);
  }, []);

  const value = useMemo(() => ({ showSnackBar, open: snackOpen }), [showSnackBar, snackOpen]);

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {snackMessage && (
        <AZSnackBar
          open={snackOpen}
          message={snackMessage}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() => setSnackOpen(false)}
        />
      )}
    </SnackBarContext.Provider>
  );
};
