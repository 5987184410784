/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useAppState } from '@/stores/AppState';
import Script from 'next/script';
import { getTealiumBaseUrl } from './utils/getTealiumBaseUrl';

export const Utag = () => {
  const { proxiedHostname } = useAppState().state;
  return (
    <Script
      src={`${getTealiumBaseUrl(proxiedHostname || '')}/utag.js`}
      type="text/javascript"
      strategy="lazyOnload"
    />
  );
};
