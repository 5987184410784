/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from '@/constants/locale';
import { useLocale } from '@/hooks/useLocale';
import type { Labels } from '@/types/i18n';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

/**
 * Get the localized labels. Pass a labelMap object to get a type-safe return type.
 *
 * Important: Always use a labelMap that is declared at the top level of a module (local or imported)
 *
 * Correct ✅
 * ```ts
 * import { externalLabelMap } from '../';
 * const labelMap = {};
 *
 * const MyComponent = () => {
 *    const labels = useLabels(labelMap);
 *    const externalLabels = useLabels(externalLabelMap);
 * }
 * ```
 *
 * Incorrect ❌
 * ```ts
 * const MyComponent = () => {
 *   const labelMap = {};
 *
 *   const labels = useLabels(labelMap);
 *   const otherLabels = useLabels({});
 * }
 * ```
 */
export const useLabels = <T extends Record<string, string>>(labelMap?: T): T => {
  const select = useCallback((data: Labels) => {
    const handler: ProxyHandler<Labels> = {
      get(data, prop: string, receiver) {
        if (labelMap) {
          const labelKey = labelMap[prop];
          const labelValue = Reflect.get(data, labelKey, receiver);
          return labelValue || labelKey;
        } else {
          const labelValue = Reflect.get(data, prop);
          return labelValue || prop;
        }
      },
    };

    const proxy = new Proxy<Labels>(data, handler);

    return proxy;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: labels } = useQuery({
    queryKey: labelsKey(),
    staleTime: Infinity,
    select,
  });

  return (labels ?? labelMap ?? {}) as T;
};

/**
 * @deprecated Use the `useLabels` hook instead
 */

export const useLabel = (label: string) => {
  const locale = useLocale();
  const query = useQuery({
    enabled: locale !== countryCodes.ptBr,
    queryKey: labelsKey(),
    select: useCallback((data: Labels) => data[label], [label]),
    staleTime: Infinity,
  });

  return query.data ?? label;
};

export const labelsKey = () => ['labels'];
