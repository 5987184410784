/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useFeatureFlag } from '@/features/globalConfig';

export const useRemoveUnsupportedSearchChars = (): ((searchQuery: string) => string) => {
  const specialChars = useFeatureFlag('SEARCH_SPECIAL_CHARACTERS_ALLOWED');

  return (searchQuery: string): string => {
    if (searchQuery) {
      const regxp = `[^a-zA-Z0-9 ^${specialChars?.replace('-', '\\-') ?? ''}]+`;
      const re = new RegExp(regxp, 'g');
      return searchQuery.replace(re, '');
    }
    return '';
  };
};
